import React from 'react';

function GlobalFilter ({filter, setFilter}) {
  return (
     <div className="flex space-x-10 items-center">
        <div className="">
            <label htmlFor="table-search" className="sr-only">Search</label>
            <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                </div>
                <input  
                value={filter || ''} 
                onChange={e => setFilter(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-none focus:ring-yellow-500 focus:border-yellow-500 block w-48 sm:w-80 pl-10 p-2" 
                placeholder="Search. . ."/>
            </div>
        </div>
    </div>
  )
}

export default GlobalFilter;