import React, { useEffect } from "react";
import { Box, CssBaseline, Typography } from "@mui/material";
import { motion } from "framer-motion"

import Dashboard_Peek from "../../images/Dashboard_Peek.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AuthLayout = ({ children }) => {
  const { user } = useSelector((state) => state?.auth);
  const navigate = useNavigate();

  console.log('user log', user)

  useEffect(() => {
    if (user) {
      navigate('/dashboard')
    }
  }, [])

  return (
    <div>
      <div className="flex justify-center lg:justify-start lg:col-span-2">
        <div className="w-full lg:w-5/12 flex justify-center px-6 lg:px-24">
          {children}
        </div>

        <div className="hidden lg:block fixed right-0 w-7/12 h-screen bg-primary">
            <div className="flex flex-col justify-between h-2/5">
                <Box className="ml-20 mt-20">
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                  >
                    <Typography>
                      <Typography
                      component={"span"}
                      display="inline"
                      variant="h5"
                      fontWeight="700"
                      color="white"
                      >
                      GET STARTED WITH{" "}
                      </Typography>
                      <Typography
                      component={"span"}
                      display="inline"
                      variant="h5"
                      fontWeight="700"
                      color="black"
                      >
                      DAGIZO
                      </Typography>
                    </Typography>
                  </motion.div>
                
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.9, delay: 0.2 }}
                  >
                    <Typography sx={{ color: "white", mt: 2 }}>
                      Here goes some information about Dagizo and a tagline <br></br>
                      that best describes the purpose of Dagizo
                    </Typography>
                  </motion.div>
                
                </Box>
                <motion.div
                  className="relative"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.4 }}
                >
                    <img
                        src={Dashboard_Peek}
                        alt="Dashboard Peek"
                        // style={{
                        //     borderTopLeftRadius: "25px",
                        // }}
                        className="absolute -right-10 left-10"
                    />
                </motion.div>
            </div>
        </div>
        
      </div>
    </div>
  );
};

export default AuthLayout;