import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import Dagizo_Logo from "../../images/Dagizo_Logo.png";

// SIGN IN FUNCTIONS
import { sendForgotPassword } from "../../functions/auth";
import AuthLayout from "../../components/Layout/Auth";

const ForgotPassword = () => {
  const [values, setValues] = useState({
    email: "",
    buttonText: "Send"
  });

 const { email, buttonText } = values;

  const navigate = useNavigate();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      setValues({ ...values, buttonText: "Sending..." });
   
    if (email) {
      console.log("EMAIL", email);

      return sendForgotPassword(email)
        .then((response) => {
          console.log("FORGOT PASSWORD SUCCESS", response);
          // toast.success(response.data.message);
        })
        .catch((err) => {
          // toast.error(error.response.data.error);
          setValues({ ...values, buttonText: "Send" });
        });
    }
  };

  return (
    <AuthLayout>
      <Grid>
        <Grid container spacing={1} sx={{ mt: 10 }}>
          <Grid>
            {" "}
            <Button
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosNewIcon />}
              sx={{ fontSize: "1.2rem" }}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mt: 6 }}>
          <Grid>
            {" "}
            <Box>
              {" "}
              <img src={Dagizo_Logo} alt="DAGIZO" width="150"></img>
            </Box>
            <ValidatorForm autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
              <Typography
                variant="h5"
                color="black"
                fontWeight="500"
                sx={{ mt: 3 }}
              >
                RESET PASSWORD
              </Typography>
              <Typography>
                Enter your Email below so that we can send you a link to reset
                your password
              </Typography>
              <Typography sx={{ mt: 2 }}>Email</Typography>
              <TextValidator
                sx={{
                  mt: 1,
                  backgroundColor: "secondary.input_field",
                  "& fieldset": {
                    border: "none",
                  },
                }}
                fullWidth
                onChange={handleChange("email")}
                name="email"
                value={values.email}
                validators={["required", "isEmail"]}
                errorMessages={["This Field is Required", "Email is invalid"]}
              />

              <Button
                type="submit"
                disableElevation
                fullWidth
                sx={{ bgcolor: "secondary.main", color: "white", mt: 2 }}
                variant="contained"
              >
                {buttonText}
              </Button>
            </ValidatorForm>
          </Grid>
          <Grid xs={2}></Grid>
        </Grid>
      </Grid>
      
    </AuthLayout>
  );
};

export default ForgotPassword;
