import {List, styled }from "@mui/material"

const StyledList = styled(List)(({ theme }) => ({
  // selected and (selected + hover) states
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: 5,
    "&, & .MuiListItemText-primary": {
      color: "white",
    },
    "&, & .MuiListItemIcon-root": {
      color: "white",
    },
  },
  // hover states
  //   "& .MuiListItemButton-root:hover": {
  //     backgroundColor: theme.palette.prima,
  //     "&, & .MuiListItemIcon-root": {
  //       color: "white",
  //     },
  //   },
}));

export default StyledList;