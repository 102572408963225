import { useMutation, useQuery, useQueryClient } from "react-query";
import AxiosPrivate from "../functions/axios/axiosPrivate";
import { notify } from "../components/Toast";

export const useGetPaymentsMode = (branch_id) => {
  const result = useQuery(["getPayments", branch_id], () =>
    AxiosPrivate
      .get(`/restaurant/create-payment-options/${branch_id}`)
      .then((res) => res.data)
  );

  return {
    ...result,
    payments: result.data,
  };
};

export const useCreatePayment = (branch_id) => {
  const queryClient = useQueryClient();

  return useMutation(
    (formData) =>
      AxiosPrivate({
        url: `/restaurant/create-payment-options/${branch_id}`,
        method: "POST",
        data: formData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getPayments");
        notify("Payment Created Successfully", "success")
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
};

export const useUpdatePayment = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (formData) =>
      AxiosPrivate({
        url: `/restaurant/edit-payment-options/${formData.id}`,
        method: "PATCH",
        data: formData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getPayments");
        notify("Payment Updated Successfully", "success")
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
};
