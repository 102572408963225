import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import {
  Box,
  Button,
  Stack,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Typography,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  CircularProgress,
} from "@mui/material";

import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import QrCodeScannerRoundedIcon from "@mui/icons-material/QrCodeScannerRounded";

import Grid from "@mui/material/Unstable_Grid2";

import Logo from '../../images/Restaurant_QRCode.png'

import Lottie from 'react-lottie';
import * as animationData from '../../data/qrcode.json'

// Functions
import { fetchBranches } from "../../functions/restaurant";
import { setAllBranches } from "../../features/branches/branchSlice";
import { fetchBranchRooms } from "../../functions/restaurant";
import { getRoomTables } from "../../functions/tables";
import BranchSelect from "../common/utils/BranchSelect";
import QRCodeModal from "../modals/FullModal";
import QRGenerator from "./QRGenerator";
import { PDFDownloadLink } from "@react-pdf/renderer";
import QRCodeDocument from "./QRCodeDocument";
import { fetchBranchMenus } from "../../functions/menu";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const CreateQrCode = () => {
  const [radioValue, setRadioValue] = React.useState("rooms");
  const [roomsData, setRoomsData] = useState(React.useMemo(() => [], []));
  const [areasData, setAreasData] = useState(React.useMemo(() => [], []));
  const [menuData, setMenuData] = useState(React.useMemo(() => [], []));
  const [iterables, setIterables] = useState([]);
  const [chosenPlace, setChosenPlace] = React.useState({});
  const [chosenMenu, setChosenMenu] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const ref = React.createRef();

  const [tables, setTables] = useState(React.useMemo(() => [], []));
  const [selectedTables, setSelectedTables] = useState(React.useMemo(() => [], []));
  const location = useLocation();
  let shouldGenerateQR = location?.state?.shouldGenerateQR;

  const { restaurant } = useSelector((state) => state.auth);
  const { currentBranch } = useSelector((state) => state.branch);
  const branchId = currentBranch?.id
  const allPlaces = radioValue === "rooms" ? roomsData : areasData

  const qrTables = tables.filter(f => selectedTables.some(item => item === f.id));

  const handlePlacesChange = (event) => {
    setChosenPlace(event.target.value);
    getRoomTables(event.target.value)
      .then((res) => {
        console.log("🚀 ~ file: CreateQrCode.js:88 ~ .then ~ res", res.data.data)
        setTables(res.data.data);
      })
      .catch((err) => {
        console.log("🚀 ~ file: CreateQrCode.js:91 ~ getRoomTables ~ err", err);
      });
  };

  const handleTablesChange = (event) => {
    const {
      target: { value },
    } = event;

    console.log('value', value)
    setSelectedTables(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    let roomsArray = [];
    let areasArray = [];
    fetchBranchRooms(branchId)
      .then((res) => {
        // console.log("🚀 ~ file: RoomsAndAreas.js:24 ~ useEffect ~ res", res);
        if (res.data.data !== undefined) {
          roomsArray = res.data.data.filter((item) => item.place_type === "Room");
          areasArray = res.data.data.filter((item) => item.place_type === "Area");
          setRoomsData(roomsArray);
          setAreasData(areasArray);
          setLoading(false)
        }

        if (radioValue === "rooms") {
          setIterables(roomsData);
          setRoomsData(roomsArray)
        } else if (radioValue === "areas") {
          setIterables(areasData);
          setRoomsData(areasArray)
        }
      });

  }, [radioValue, branchId]);

  console.log('restaurant', restaurant)

  let roomToGenerateQR = useSelector((state) => state?.room?.roomToGenerateQR);

  const prepopulateQRForms = () => {
    if (shouldGenerateQR) {
      setChosenPlace(roomToGenerateQR);
    } else {
      return;
    }
  };

  useEffect(() => {
    prepopulateQRForms();
  }, []);

  useEffect(() => {
    if (restaurant?.type === "Restaurant") {
      setRadioValue('areas')
    } else {
      setRadioValue('rooms')
    }
  }, [restaurant]);

  const getTables = (ids) => {
    const filtered = tables?.filter(i => ids.some(item => item === i.id));
    const tableList = filtered.map((i) => {
      return i.table_name
    })
    return tableList?.join(", ")
  }

  return (
    <Grid>
      <div className="md:flex md:col-span-2 gap-x-8 md:px-4">
        <div className="w-full md:w-2/6">

          <Stack sx={{ mt: 3, mb: 1 }}>
            <Typography variant="h5" fontWeight="600" sx={{ color: "black" }}>
              Generate QR-Codes For Tables
            </Typography>
          </Stack>{" "}

          {/* <Stack sx={{ mt: 7, mb: 1 }}>
            <Typography variant="p" sx={{ color: "black", fontSize: "17px", fontWeight: "500" }}>
              Select Branch
            </Typography>
            <Box sx={{ mt: 1 }}>
              {" "}
              <BranchSelect
                selectedBranch={selectedBranch}
                setSelectedBranch={setSelectedBranch}
                branches={branches}
                storeBranchData={storeBranchData}
              />
            </Box>
          </Stack> */}
          {restaurant?.type === "Hotel" && (
            <>
              <Stack sx={{ mt: 3 }}>
                <Typography variant="p" sx={{ color: "black", fontSize: "17px", fontWeight: "500" }}>
                  Select Rooms/Areas
                </Typography>
              </Stack>

              <Stack>
                <FormControl>
                  <RadioGroup row value={radioValue} onChange={(event) => setRadioValue(event.target.value)}>
                    <FormControlLabel
                      value="rooms"
                      control={<Radio />}
                      label="Rooms"
                    />
                    <FormControlLabel
                      value="areas"
                      control={<Radio />}
                      label="Areas"
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </>
          )}


          {/* <Stack sx={{ mt: 3 }}>
            <Typography variant="p" sx={{ color: "black", fontSize: "17px", fontWeight: "500" }}>
              Select Menu
            </Typography>
          </Stack>{" "}
          <Stack sx={{ mt: 1 }}>
            <Select value={chosenMenu} onChange={(e => setChosenMenu(e.target.value))}>
              {menuData.length > 0 &&
                menuData.map((menu) => (
                  <MenuItem value={menu.id}>{menu.menu_name}</MenuItem>
                ))}
            </Select>
          </Stack> */}

          <Stack sx={{ mt: 3 }}>
            <Typography variant="p" sx={{ color: "black", fontSize: "17px", fontWeight: "500" }}>
              Select Specific {restaurant?.type === "Restaurant" ? "Area" : "Room & Area"}
            </Typography>
          </Stack>{" "}
          <Stack sx={{ mt: 1 }}>
            {!loading && roomsData.length > 0 ? (
              <Select value={chosenPlace} onChange={handlePlacesChange}>
                {roomsData.length > 0 &&
                  roomsData.map((place) => (
                    <MenuItem value={place.id}>{place.place_name}</MenuItem>
                  ))}
              </Select>
            ) : (
              <div className="mt-4">
                {/* <CircularProgress color="primary" size="1.5rem" /> */}
                <div className="w-3/5 h-4 bg-gray-200 rounded animate-pulse"></div>
                <div className="w-2/5 h-3 bg-gray-200 rounded animate-pulse mt-2"></div>
              </div>
            )}
          </Stack>
          {tables.length > 0 && (
            <div>
              <Stack sx={{ mt: 2 }}>
                {!_.isEmpty(chosenPlace) && tables.length > 0 && (
                  <Typography variant="p" sx={{ color: "blue", fontSize: "15px", fontWeight: "400" }}>
                    * Total tables available in {chosenPlace.place_type}{" "}
                    {chosenPlace.place_name} is {tables.length}
                  </Typography>
                )}
              </Stack>
              <Stack sx={{ mt: 2 }}>
                {" "}
                <Typography variant="p" sx={{ color: "black", fontSize: "17px", fontWeight: "500" }}>
                  Select Tables to Generate QR-Codes For
                </Typography>
              </Stack>
              <Stack sx={{ mt: 1 }}>
                {" "}
                <Select
                  multiple
                  fullWidth
                  value={selectedTables}
                  onChange={handleTablesChange}
                  placeholder="Choose one area or several"
                  renderValue={(selected) => getTables(selected)}
                  MenuProps={MenuProps}
                >
                  {!_.isEmpty(chosenPlace) &&
                    tables.length > 0 &&
                    tables.map((table) => (
                      <MenuItem key={table.id} value={table.id}>
                        <Checkbox
                          checked={selectedTables.indexOf(table.id) > -1}
                        />
                        <ListItemText primary={"Table" + " " + table.table_name} />
                      </MenuItem>
                    ))}
                </Select>
              </Stack>
            </div>
          )}
        </div>

        <div className="w-full md:w-4/6 p-4 bg-[#FAFAFA]">
          {selectedTables.length > 0 ?
            <Stack>
              <div className="flex gap-4">
                {/* <button
                className="my-2 py-2 text-black bg-[#f4b31321] px-6 rounded font-bold"
              >
                <PDFDownloadLink
                  document={
                    <QRCodeDocument
                      ids={selectedTables}
                      room={allPlaces?.filter(i => i.id === chosenPlace)[0]}
                      tables={qrTables}
                      user={restaurant}
                    />
                  }
                  fileName={allPlaces?.filter(i => i.id === chosenPlace)[0]?.place_name + " " + new Date().getTime()}
                >
                  {({ loading }) => (loading ? "Loading..." : (<div className="flex gap-2"><DownloadIcon /> Download</div>))}
                </PDFDownloadLink>
              </button> */}

                <button
                  onClick={() => setOpen(true)}
                  className="flex gap-2 my-2 py-2 text-primary border border-[#f4b313] px-6 rounded font-bold"
                >
                  <VisibilityIcon /> Preview
                </button>
              </div>

              <div className="md:flex flex-wrap gap-6 mt-6">
                {!_.isEmpty(chosenPlace) &&
                  selectedTables.length > 0 &&
                  selectedTables.map((table) => (
                    <div className="flex flex-col items-center border shadow-lg rounded-lg py-2 px-4" key={table}>
                      <p className="mt-4 font-semibold">{allPlaces?.filter(i => i.id === chosenPlace)[0]?.place_name} / {tables.filter(i => i.id === table)[0]?.table_name}</p>

                      <div className="DagizoQRCode flex justify-centern items-center mt-2" ref={ref}>
                        {/* <QRGenerator value={`https://mobile.dagizo.io/?restaurant_code=${user.restaurant_code}&branch=${branchId}&menu=${chosenMenu}&room=${chosenPlace}&table_id=${table}`} documentId={table} /> */}
                        <QRGenerator value={`https://dagizo-prod.web.app/restaurant-menu/?restaurant_code=${branchId}&table_id=${table}&table_number=${tables.filter(i => i.id === table)[0]?.table_number}`} documentId={table} />
                        {/* <div className="relative">
                          <img alt="qr code" src={Logo} />
                          <div className="absolute top-12 left-10 bottom-8 right-8 overflow-hidden flex flex-row rounded-full">
                            <div className="p-3 overflow-hidden w-[50%]">
                              <QRGenerator value={`https://dagizo-prod.web.app/restaurant-menu/?restaurant_code=${branchId}&table_id=${table}&table_number=${tables.filter(i => i.id === table)[0]?.table_number}`} documentId={table} />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ))}
              </div>
            </Stack>
            :
            <div
              className="p-4 bg-[#FAFAFA] rounded-lg"
            >
              <div className="flex gap-6 flex-col justify-center items-center w-full h-96">
                <Typography variant="h6" sx={{ color: "black", fontWeight: "500", textAlign: "center" }}>Generated QR-Codes will appear here</Typography>
                <div className="w-1/2 mt-10">
                  <Lottie options={defaultOptions}
                  // height={200}
                  // width={200}
                  />
                </div>

              </div>
            </div>
          }

        </div>
      </div>


      <QRCodeModal
        open={open}
        setOpen={setOpen}
        ids={selectedTables}
        room={allPlaces?.filter(i => i.id === chosenPlace)[0]}
        tables={qrTables}
        user={restaurant}
      />
    </Grid>
  );
};

export default CreateQrCode;
