import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";


export { ProtectedRoute };

function ProtectedRoute({ children }) {
  const location = useLocation();

  const userData = useSelector((state) => state.auth);
  const { user, restaurant, accessToken } = userData;

  if (!user || !restaurant || !accessToken) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/signin" replace={true} />;
  }

  // authorized so return child components
  return children;
}
