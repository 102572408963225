import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import LightModeIcon from "@mui/icons-material/LightMode";
import LanguageIcon from "@mui/icons-material/Language";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

const SettingsNav = () => {
  const [value, setValue] = useState("profile");

  const navigate = useNavigate();
  const location = useLocation();
  const pathnameArray = location.pathname.split("/");

  useEffect(() => {
    if (pathnameArray[3] === undefined || pathnameArray[3] === "profile") {
      setValue("profile");
    } else {
      setValue(pathnameArray[3]);
    }
  }, [pathnameArray]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      sx={{
        flexGrow: 1,
        "& .MuiTab-root": {
          textTransform: "none",
          fontSize: "0.9rem",
          justifyContent: "flex-start",
        },
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{ hidden: true }}
        sx={{
          border: 0,
          wrapped: { alignItems: "left" },
        }}
      >
        <Tab
          value={"profile"}
          icon={<AccountCircleIcon />}
          iconPosition="start"
          label="Profile"
          onClick={() => navigate("profile")}
        />
        {/* <Tab
          value={"notification"}
          icon={<NotificationsIcon />}
          iconPosition="start"
          label="Notification"
          //   onClick={() => navigate("open-hours")}
        /> */}
        <Tab
          value={"employees"}
          icon={<PersonIcon />}
          iconPosition="start"
          label="Employees"
          onClick={() => navigate("employees")}
        />
        {/* <Tab
          value={"appearance"}
          icon={<LightModeIcon />}
          iconPosition="start"
          label="Appearance"
          //   onClick={() => navigate("profile")}
        /> */}
        {/* <Tab
          value={"language-time"}
          icon={<LanguageIcon />}
          iconPosition="start"
          label="Language & Time"
        //   onClick={() => navigate("open-hours")}
        /> */}
        {/* <Tab
          value={"support"}
          icon={<ContactSupportIcon />}
          iconPosition="start"
          label="Support"
        //   onClick={() => navigate("rooms-and-areas")}
        /> */}
        {/* <Tab
          value={"update-and-security"}
          icon={<VerifiedUserIcon />}
          iconPosition="start"
          label="Update and Security"
        //   onClick={() => navigate("rooms-and-areas")}
        /> */}
      </Tabs>
    </Box>
  );
};

export default SettingsNav;
