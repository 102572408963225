import React from 'react';
import Divider from '@mui/material/Divider';
import { Drawer, IconButton, styled, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const DrawerHeader = styled('div')(({ theme }) => ({
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function SideDrawer({ open, onClose, children, title, size = 500 }) {
    const theme = useTheme();

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <div>
            <Drawer
                container={container}
                anchor="right"
                variant="temporary"
                open={open}
                onClose={onClose}
                sx={{
                    // zIndex: 9991,
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        borderWidth: 0,
                        width: { xs: '100%', md: size },
                        backgroundColor: '#FAFAFA',
                        pb: 5
                    },
                }}
            >
                <DrawerHeader>
                    <IconButton onClick={onClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                    {title && (<h1 className='font-semibold'>{title}</h1>)}
                </DrawerHeader>
                <Divider />
                <div className='px-8'>
                    {children}
                </div>
            </Drawer>
        </div>
    );
}