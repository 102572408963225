import React, { useEffect, useState } from "react";
import ModalLayout from "../../modals/Layout";
import { Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import ActiveHours from "../../common/utils/ActiveHours";
import { notify } from "../../Toast";
import AxiosPrivate from "../../../functions/axios/axiosPrivate";

const PeriodMoal = ({ open, setOpen, period, edit, toDelete, refrech }) => {
    const [serving_type, setServing_type] = useState('')

    const [periodData, setPeriodData] = useState({
      day: serving_type,
      from_time: null,
      to_time: null,
    });

    const [loading, setLoading] = useState(false)

    useEffect(() => {
      if (period) {
        setServing_type(period.serving_type)
      } else {
        setServing_type("")
      }
    }, [period])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (serving_type) {
          setLoading(true)
          if (period) {
            const { from_time, to_time } = periodData;

            return AxiosPrivate.post(`/restaurant/serving-periods-crud/${period.id}`,
              { from_time, to_time, serving_type }
            )
            .then((res) => {
              notify(res.data.message, "success")
              setOpen(false)
              setLoading(false)
              refrech()
            })
            .catch((err) => {
              notify(err.data?.message)
              setLoading(false)
            });
          } else {
            const { from_time, to_time } = periodData;

            return AxiosPrivate.post(`/restaurant/serving-periods/`,
              { from_time, to_time, serving_type }
            )
            .then((res) => {
              notify(res.data.message, "success")
              refrech()
              setOpen(false)
              setLoading(false)
            })
            .catch((err) => {
              notify(err.data?.message)
              setLoading(false)
            });
          }
        }
    }

    const handleDelete = (e) => {
        e.preventDefault()
        setLoading(true)

        return AxiosPrivate.delete(`/restaurant/serving-periods-crud/${period.id}`)
        .then((res) => {
            notify(res.data.message, "success")
            refrech()
            setOpen(false)
            setLoading(false)
        })
        .catch((err) => {
            notify(err.data?.message)
            setLoading(false)
        });
    }

    return (
        <ModalLayout open={open} setOpen={setOpen} title={`${toDelete ? "Delete" : period ? "Edit" : "Add"} Serving Period`}>
            {!toDelete ? (
                <form className='' onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid xs={12}>
                            <Typography color="black" sx={{ mt: 2 }}>
                                Period Name
                            </Typography>
                            <TextField
                                fullWidth
                                required
                                sx={{ py: 1 }}
                                value={serving_type}
                                onChange={(e) => setServing_type(e.target.value)}
                            />

                            <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2}>
                                <Box>
                                    <ActiveHours day={periodData} setOpenHours={setPeriodData} />
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Button
                        type="submit"
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        sx={{ mt: 4, height: 55, fontSize: "1rem", color: "white" }}
                        variant="contained"
                        fullWidth
                    >
                        {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : period ? "Update Serving Hour" : "Save Serving Hour"}
                    </Button>
                </form>
            ) : (
                <div>
                  <p className="my-6">Are you sure you want to delete <b>{period.serving_type}</b> ?</p>
                  <div className="flex gap-4 justify-end">
                    <button onClick={() => setOpen(false)} className="bg-gray-200 px-6 py-2 rounded">Cancel</button>
                    <button disabled={loading} onClick={handleDelete} className="flex items-center gap-3 bg-red-600 px-6 py-2 text-red-100 rounded">
                      Delete {loading && (<CircularProgress color="inherit" size="1.2rem" />)}
                    </button>
                  </div>
                </div>
            )}
        </ModalLayout>
    );
};

export default PeriodMoal;