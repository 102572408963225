import { createJobPositions, editJobPositions } from "../../../functions/restaurant";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { employeeRoles } from "../../../utils/employeeRoles";
import { notify } from "../../Toast";
import { Box, Typography, Grid, Checkbox, Button, } from '@mui/material'
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const AddJobPosition = ({ handleClose, refreshData, editData }) => {
    const { currentBranch } = useSelector((state) => state?.branch);
    const { job_name } = useSelector((state) => state?.auth);
    const [permissions, setPermissions] = useState([]);
    const [position_name, setPositionName] = useState(null);

    useEffect(() => {
        if (editData) {
            setPermissions(editData.permissions);
            setPositionName(editData.position_name);
        }
    }, [editData]);

    const handleSelectPermissions = (permissionName) => {
        if (permissionName.code === 1000) {
            let arr = [];
            for (let p of employeeRoles.actionsPermissions()) {
                arr.push(p.code)
            }
            for (let x of employeeRoles.viewPermissions()) {
                arr.push(x.code)
            }
            setPermissions(arr)
        } else if (!permissions.includes(permissionName.code)) {
            setPermissions([...permissions, permissionName.code])
        } else {
            let arr = [...permissions];
            arr.splice(arr.indexOf(permissionName.code), 1);
            setPermissions(arr)
        }
    }

    const handleSubmitJobPost = (e) => {
        e.preventDefault();
        if (position_name.toLowerCase() === job_name.toLowerCase()) {
            notify(`You cant create another ${job_name} because you already logged in as ${job_name}`, "error")
        }
        else {
            createJobPositions(currentBranch.id, { position_name, permissions })
                .then((res) => {
                    if (res.data.status === true) {
                        notify(res.data.message, "success");
                        refreshData();
                        handleClose();
                        setPermissions([]);
                    } else if (res.data.status === false) {
                        notify(res.data.message, "error");
                    }
                })
                .catch((err) => {
                    notify(err.response.data.error, "error");
                });
        }
    };

    const handleSubmitEditJobPosition = () => {
        editJobPositions({
            position_name,
            permissions
        }, editData.id).then((res) => {
            if (res.data.status === true) {
                notify(res.data.message, "success");
                refreshData();
                handleClose();
                setPermissions([]);
            } else if (res.data.status === false) {
                notify(res.data.message, "error");
            }
        })
            .catch((err) => {
                notify(err.response.data.error, "error");
            });
    }

    return (
        <ValidatorForm
            autoComplete="off"
        >
            <Box>
                <Typography sx={{ mb: 4 }}>
                    Define a New Job Position and Assign View & Action Permissions
                </Typography>
                <Box>
                    <Typography sx={{ color: "black" }}>Position Name</Typography>
                    <TextValidator
                        fullWidth
                        onChange={(e) => setPositionName(e.target.value)}
                        name="position_name"
                        value={position_name}
                        validators={["required"]}
                        errorMessages={["This Field is Required"]}
                        sx={{
                            input: {
                                padding: "10px",
                            },
                        }}
                    />
                </Box>
            </Box>

            <Grid columnSpacing={16} container sx={{ marginTop: 2 }}>
                <Grid xs={6} item>
                    <Typography sx={{ fontWeight: 700, mb: 1 }}>
                        View Permisions
                    </Typography>
                    <Grid container columnSpacing={10}>
                        {[employeeRoles.viewPermissions().slice(0, 4), employeeRoles.viewPermissions().slice(4)].map((g, i) => (
                            g.map((el, i) => (
                                <Grid xs={6} item key={i}>
                                    <div className="flex flex-row items-center space-x-1" key={i}>
                                        <Checkbox size="small" checked={permissions.includes(el.code)} onChange={() => handleSelectPermissions(el)} />
                                        <Typography sx={{ whiteSpace: "nowrap", fontSize: "14px" }}>{el.name}</Typography>
                                    </div>
                                </Grid>
                            ))
                        ))}
                    </Grid>
                </Grid>

                <Grid xs={6} item>
                    <Typography sx={{ fontWeight: 700, mb: 1 }}>
                        Actions permissions
                    </Typography>

                    {employeeRoles.actionsPermissions().map((el, i) => (
                        <div className="flex flex-row items-center space-x-1" key={i}>
                            <Checkbox size="small" checked={permissions.includes(el.code)} onChange={() => handleSelectPermissions(el)} />
                            <Typography sx={{ whiteSpace: "nowrap", fontSize: "14px" }}>{el.name}</Typography>
                        </div>
                    ))}
                </Grid>
            </Grid>

            <Button
                onClick={editData ? handleSubmitEditJobPosition : handleSubmitJobPost}
                sx={{ mt: 4, color: "white", fontSize: "1rem" }}
                type="submit"
                variant="contained"
                fullWidth
                disableElevation
            >
                Save Changes
            </Button>
        </ValidatorForm>
    )
}

export default AddJobPosition