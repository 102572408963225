import AxiosPrivate from "./axios/axiosPrivate";

export const createBranch = async (
  branch_name,
  restaurant_image,
  first_contacts,
  second_contacts,
  email,
  latitude,
  longitude,
  address,
  restaurant_id
) => {
  return await AxiosPrivate.post(`/restaurant/branch/${restaurant_id}/`, {
    branch_name,
    restaurant_image,
    first_contacts,
    second_contacts,
    email,
    latitude,
    longitude,
    address,
  });
};

export const createSections = async (data, branchID) => {
  return await AxiosPrivate.post(`/restaurant/bulk-create-branch-rooms/${branchID}/`, data)
}

export const getSections = async (branchID) => {
  return await AxiosPrivate.get(`/restaurant/bulk-create-branch-rooms/${branchID}/`)
}

export const getSectionRooms = async (sectionID) => {
  return await AxiosPrivate.get(`/restaurant/bulk-show-branch-rooms/${sectionID}/`)
}
export const createPlace = async (
  place_type,
  place_name,
  maximum_tables,
  person_per_table,
  branchId
) => {
  return await AxiosPrivate.post(
    `/restaurant/restaurant-occupant-place/${branchId}/`,
    { place_type, place_name, maximum_tables, person_per_table }
  );
};

export const editPlace = async (
  place_type,
  place_name,
  maximum_tables,
  person_per_table,
  roomId
) => {
  return await AxiosPrivate.post(
    `/restaurant/rooms-areas-edit-delete/${roomId}`,
    { place_type, place_name, maximum_tables, person_per_table }
  );
};

export const deletePlace = async (roomId) => {
  return await AxiosPrivate.delete(
    `/restaurant/rooms-areas-edit-delete/${roomId}`
  );
};

export const createBulkRooms = async (
  place_type,
  from_no,
  prefix,
  units,
  person_per_table,
  branchId
) => {
  return await AxiosPrivate.post(
    `/restaurant/bulk-create-places/${branchId}/`,
    {
      place_type,
      from_no,
      prefix,
      units,
      person_per_table,
    }
  );
};

export const fetchBranchRooms = async (branchId) => {
  return await AxiosPrivate.get(
    `/restaurant/restaurant-occupant-place/${branchId}/`
  );
};

export const editBranch = async (
  branch_name,
  restaurant_image,
  first_contacts,
  second_contacts,
  email,
  latitude,
  longitude,
  address,
  restaurant_id
) => {
  return await AxiosPrivate.post(
    `/restaurant/branch-detail/${restaurant_id}/`,
    {
      branch_name,
      restaurant_image,
      first_contacts,
      second_contacts,
      email,
      latitude,
      longitude,
      address,
    }
  );
};

export const setBranchOperatingHours = async (day, branch_id) => {
  return await AxiosPrivate.post(
    `/restaurant/restaurant-open-hours/${branch_id}/`,
    {
      day,
    }
  );
};

export const fetchBranches = async (restaurant_id) => {
  return await AxiosPrivate.get(
    `/restaurant/all-branches-in-restaurant/${restaurant_id}/`
  );
};

export const getRestaurantStaff = async (restaurant_id) => {
  // return await AxiosPrivate.get(`/restaurant/staffs/${restaurant_id}`);
  return await AxiosPrivate.get(
    `employees/create-new-branch-employees/${restaurant_id}`
  );
  // /users/user-profile/
};

export const addNewEmployee = async (employeeData, restaurant_id) => {
  return await AxiosPrivate.post(
    `employees/create-new-branch-employees/${restaurant_id}`,
    { ...employeeData }
  );
};

export const getJobPositions = async (restaurant_id) => {
  return await AxiosPrivate.get(
    `employees/create-employees-job-positions/${restaurant_id}`
  );
};

export const createJobPositions = async (restaurant_id, data) => {
  return await AxiosPrivate.post(
    `/employees/create-employees-job-positions/${restaurant_id}`,
    { ...data, restaurant_id }
  );
};

export const editJobPositions = async (position_data, position_id) => {
  return await AxiosPrivate.patch(`employees/edit-employees-job-positions/${position_id}`, position_data)
}

export const deleteJobPositions = async (position_id) => {
  return await AxiosPrivate.delete(`employees/edit-employees-job-positions/${position_id}`)
};

export const deleteEmployee = async (restaurant_id) => {
  return await AxiosPrivate.delete(
    `employees/edit-branch-employee/${restaurant_id}`
  );
};

export const editEmployee = async (employee_id, data) => {
  return await AxiosPrivate.patch(`employees/edit-branch-employee/${employee_id}`, data);
}

export const activateEmployee = async (employee_id) => {
  return await AxiosPrivate.post(`employees/activate-branch-user/${employee_id}`)
}

export const deactivateEmployee = async (employee_id) => {
  return await AxiosPrivate.post(`employees/deactivate-branch-user/${employee_id}`)
}