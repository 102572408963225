import React, { useState } from "react";
import OpenHours from "./openHours";
import Taxes from "./tax";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import Payments from "./payments";
import Pesapal from "./pesapal";
import ActiveHours from "../../common/utils/ActiveHours";

const GeneralSettings = () => {
    const [currentTab, setCurrentTab] = useState("servingHours");
    const [sunday, setSunday] = useState({
        day: "sunday",
        from_time: null,
        to_time: null,
    });

    const handleChange = (event, newCurrentTab) => {
        setCurrentTab(newCurrentTab);
    };

    return (
        <div className="p-4">
            <Box sx={{ mb: 2 }}>
                <Typography variant="h5" sx={{ color: "black", fontWeight: 600 }}>
                    Set Standard Hours
                </Typography>
                <Typography>
                    Configure the Standard Hours of Operation For This Location{" "}
                </Typography>
            </Box>

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 5 }}>
                <Box>
                    <ActiveHours day={sunday} setOpenHours={setSunday} toggle={true} />
                </Box>
            </Stack>

            <Tabs value={currentTab} onChange={handleChange}>
                <Tab value={"servingHours"} label="Serving Periods" />
                <Tab value={"taxes"} label="Taxes" />
                <Tab value={"payments"} label="Payment Methods" />
                <Tab value={"pesapal"} label="PesaPal Setup" />
            </Tabs>

            {currentTab === "servingHours" && (
                <OpenHours />
            )}

            {currentTab === "taxes" && (
                <Taxes />
            )}

            {currentTab === "payments" && (
                <Payments />
            )}

            {currentTab === "pesapal" && (
                <Pesapal />
            )}
        </div>
    );
};

export default GeneralSettings;
