import React, { useState } from "react";
import CustomPagination from "../../styling/CustomComponents/CustomPagination";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { DataGrid } from "@mui/x-data-grid";
import { Avatar, IconButton, Menu, MenuItem, Box } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export function SortedDescendingIcon() {
  return <ArrowDropDownIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ArrowDropUpIcon className="icon" />;
}

const MenuListTable = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  const handleOpenMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElNav(null);
  };

  const columns = [
    {
      field: "foodItem",
      headerName: "Food Item",
      width: 300,
      renderCell: (params) => {
        // console.log(params);
        return (
          <>
            <Avatar
              sx={{ mr: 2 }}
              alt={params.value.foodItemName}
              src={params.value.avatar}
            />
            {params.value.foodItemName}
          </>
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      width: 300,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 300,
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
    },
    {
      field: "price",
      headerName: "Price",
      width: 250,
      renderCell: (params) => {
        return (
          <span
            style={{
              color: "brown",

              width: "100%",
            }}
          >
            ${params.value}
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton>
            <MoreHorizIcon onClick={handleOpenMenu} />
          </IconButton>
        );
      },
    },
  ];

  const rows = [
    {
      id: 1,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 11,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 12,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 13,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 14,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 15,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 16,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 17,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 18,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 19,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 21,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 31,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 41,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 51,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 61,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
    {
      id: 71,
      foodItem: {
        foodItemName: "Chilli Chicken",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtolMaX9RSx3jNe5wbm84YnBE3-_WRCbhmgw&usqp=CAU",
      },
      category: "Chicken",
      quantity: 300,
      status: "In Stock",
      price: 700,
    },
  ];

  return (
    <Box
      sx={{
        mt: 5,

        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "primary.lightest_gray",
          fontSize: 18,
          fontWeight: 700,
          color: "black",
        },
        width: "100%",
      }}
    >
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleCloseMenu}>Edit</MenuItem>

        <MenuItem onClick={handleCloseMenu}>Delete</MenuItem>
      </Menu>{" "}
      <DataGrid
        sx={{ height: 400, width: "100%" }}
        rows={rows}
        autoHeight
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        components={{
          // Toolbar: QuickSearchToolbar,
          ColumnSortedDescendingIcon: SortedDescendingIcon,
          ColumnSortedAscendingIcon: SortedAscendingIcon,
          Pagination: CustomPagination,
        }}
      />
    </Box>
  );
};

export default MenuListTable;
