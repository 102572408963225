import React, { useState } from "react";

import { Tabs, Tab, Stack, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import Box from "@mui/material/Box";

import CreateMenuModal from "./modals/CreateMenuModal";

const DishesNav = () => {
  const [value, setValue] = useState("All");
  const [tableView, setTableView] = useState("Split-Screen")
  const [openCreateMenuModal, setOpenCreateMenuModal] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    const handleTableViewChange = (event, newValue) => {
      setTableView(newValue);
    };

 const closeCreateMenuModal = () => setOpenCreateMenuModal(false);
  return (
    <Box
      sx={{
        width: "100%",

        "& .MuiTab-root": {
          textTransform: "none",
          fontSize: "1rem",

          mr: 10,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Box>
          <Tabs value={value} onChange={handleChange}>
            <Tab value="All" label="All" />
            <Tab value="Cold Dishes" label="Cold Dishes" />
            <Tab value="Soup" label="Soup" />
            <Tab value="Grill" label="Grill" />
            <Tab value="Appetizer" label="Appetizer" />
            <Tab value="Desert" label="Desert" />
          </Tabs>
        </Box>

        <Box>
          {" "}
          <CreateMenuModal
            openCreateMenuModal={openCreateMenuModal}
            closeCreateMenuModal={closeCreateMenuModal}
          />
          <Tabs
            TabIndicatorProps={{ hidden: true }}
            value={tableView}
            onChange={handleTableViewChange}
          >
            <Button
              disableElevation
              variant="contained"
              onClick={() => setOpenCreateMenuModal(true)}
              startIcon={<AddIcon />}
              sx={{ color: "white", mr:5, mb:1 }}
            >
              Add New
            </Button>{" "}
            <Tab value="Split-Screen" icon={<SplitscreenIcon />} />
            <Tab value="Grid-View" icon={<GridViewRoundedIcon />} />
          </Tabs>
        </Box>
      </Stack>
    </Box>
  );
};

export default DishesNav;
