import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomDataGrid from "../../../styling/CustomComponents/CustomDataGrid";
import { Avatar, IconButton, Menu, MenuItem, Box } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

// Functions
import {
  fetchRestaurantOrdersPerMenu,
  fetchBranchOrdersPerMenu,
} from "../../../functions/dashboard";

const FoodItemsTable = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [rowData, setRowData] = useState([]);

  const allBranches = useSelector((state) => state?.branch?.allBranches);

  const branch_id = useSelector(
    (state) => state?.branch?.allBranches.restaurant_code
  );

  const restaurant_code = useSelector((state) => state?.auth?.restaurant?.id);
  const handleOpenMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElNav(null);
  };

  const getRestaurantFoodSales = async () => {
    const res = await fetchRestaurantOrdersPerMenu(restaurant_code);

    const foodSales = res.data.data.food_sales_overview;

    let foodSalesArray = [];
    for (let i = 0; i < foodSales.length; i++) {
      foodSalesArray.push(foodSales[i]);
    }

    setRowData(foodSalesArray);
  };

  const getBranchFoodSales = async () => {
    if (branch_id !== undefined || branch_id !== null) {
      const res = await fetchBranchOrdersPerMenu(branch_id);
      const foodSales = res.data.data.food_sales_overview;
      let foodSalesArray = [];
      for (let i = 0; i < foodSales.length; i++) {
        foodSalesArray.push(foodSales[i]);
      }

      setRowData(foodSalesArray);
    }
  };

  const columns = [
    {
      field: "food_image",
      headerName: "Food Image",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            <Avatar
              sx={{ mr: 2 }}
              alt={params.value.foodItemName}
              src={params.value}
            />
            <p>{params.row.food_name}</p>
          </div>
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
    },
    {
      field: "no_orders",
      headerName: "Orders",
      width: 200,
    },
    {
      field: "sales",
      headerName: "Sales",
      width: 150,
      renderCell: (params) => {
        return (
          <span
            style={{
              color: `primary.main`,
              fontWeight: 600,
              width: "100%",
            }}
          >
            Ksh {params.value}
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton onClick={handleOpenMenu}>
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    if (allBranches.length > 0) {
      getRestaurantFoodSales();
    } else {
      getBranchFoodSales();
    }
  }, [restaurant_code, allBranches]);
  return (
    <Box
      display="flex"
      sx={{
        mt: 5,

        "& .MuiDataGrid-columnHeaders": {
          fontSize: 18,
          color: "black",
        },
        width: "100%",
      }}
    >
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleCloseMenu}>View Order</MenuItem>
        <MenuItem onClick={handleCloseMenu}>Edit</MenuItem>
        <MenuItem onClick={handleCloseMenu}>Cancel</MenuItem>
        <MenuItem onClick={handleCloseMenu}>Refund</MenuItem>
      </Menu>{" "}
      <CustomDataGrid
        sx={{ height: 400, width: "1000" }}
        rows={rowData}
        columns={columns}
      
        GridLinesVisibility="None"
      />
    </Box>
  );
};

export default FoodItemsTable;
