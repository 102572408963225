import moment from 'moment';
import React, { useState } from 'react'
import { useUpdateOrderStatus } from '../../../services/notifications';
import { CircularProgress, IconButton, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ref, child, update, set } from "firebase/database";
import { db } from "../../../firebase"

function NotificationCard({ notification, showDrawer }) {
    const [anchorElNav, setAnchorElNav] = useState(null);

    const changeStatus = useUpdateOrderStatus()
    const order = notification?.order?.order_number?.split(':');
    const table = notification?.table_number?.split('@');

    const handleChangeStatus = async (status) => {
        console.log('update', notification)
        const id = notification?.notification_id;
        if (id) {
            handleCloseMenu()
            
            update(ref(db, `${notification?.table_id ? "notifications" : "orders"}/${notification?.branch}/${notification?.notification_id}`), { is_read: status })
            .then((res) => {
                if(!notification.table_id && status) {
                    showDrawer(notification.order.order_id)
                }
            })
            .catch((err) => {
                console.log('err', err)
            })
        }
        // changeStatus.mutate({ id: notification.id, is_read: status })
    }

    const handleOpenMenu = (event) => {
        setAnchorElNav(event.currentTarget);
      };
    
      const handleCloseMenu = () => {
        setAnchorElNav(null);
      };

    const handleActionsClick = (event) => {
        handleOpenMenu(event);
    };

  return (
    <>
    <div className={`${!notification?.is_read && 'bg-gray-200 border-l-2 border-gray-300'} pl-3 flex justify-between items-center py-3 border-b`}>
        <div className="flex items-center space-x-4">
            <div className='w-14 h-14 flex flex-col items-center justify-center bg-gray-800 text-white rounded-lg'>
                <span className='text-xs'>{order ? order[0] : "Table"}</span>
                <span className='text-sm font-semibold'>{table && table[1] || order && order[1]}</span>
            </div>
            <div className="flex flex-col space-y-1">
                <span className="font-bold">{notification?.subject}</span>
                <span className="text-sm">{notification?.message}</span>
            </div>
        </div>
        <div className="flex flex-col items-end gap-2 px-4">
            <p className='text-stone-600 text-xs md:text-sm'>{moment(notification?.created).fromNow()}</p>
            {!notification?.is_read ? (
                <button
                    disabled={changeStatus.isLoading}
                    onClick={() => handleChangeStatus(true)}
                    className='flex items-center gap-2 text-primary text-xs px-3 py-1 rounded-full bg-white'
                >
                    {notification?.table_id ? "Mark as read" : "Open Order"}
                </button>
            ) : notification?.is_read && notification?.table_id ? (
                <button
                    disabled={changeStatus.isLoading}
                    onClick={() => handleChangeStatus(false)}
                    className='flex items-center gap-2 text-red-600 text-xs px-3 py-1 rounded-full bg-red-50'
                >
                    Mark as unread {changeStatus.isLoading && (<CircularProgress color="inherit" size="0.8rem" />)}
                </button>
            ) : (
                <IconButton onClick={(event) => handleActionsClick(event)}>
                    <MoreHorizIcon />
                </IconButton>
            )}
        </div>
    </div>

    <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => showDrawer(notification.order.order_id)}>view order</MenuItem>
        <MenuItem sx={{ color: 'red' }} onClick={() => handleChangeStatus(false)}>mark as unread</MenuItem>
      </Menu>
    </>
  )
}

export default NotificationCard