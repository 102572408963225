import logo from "../../images/Dagizo_Logo.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider, styled, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import axios from "axios"

const Receipt = () => {
  const { orderNo } = useParams();
  const [order, setOrder] = useState({});

  const BASE_URL = process.env.REACT_APP_API;
  const handleFetchData = async() => {
    try {
      const res = await axios.get(BASE_URL + `/orders/generate-customer-receipt/${orderNo}`)
      setOrder(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const theme = createTheme({
    typography: {
      fontFamily: ["Quicksand", "sans-serif"].join(","),
    },
  });

  useEffect(() => {
    handleFetchData()
  }, []);

  function getDiscount() {
    const val = [];

    for (const el of order?.order_details || []) {
      val.push(
        el?.order_list?.food__original_price - el?.order_list.food__price
      );
    }

    return val.reduce((acc, el) => acc + parseInt(el), 0);
  }

  function getTaxes() {
    const taxes = [];
    const taxAmount = [];

    for (const el of order?.order_details || []) {
      for (const tax of el?.order_list?.food__tax_object) {
        const taxObj = {
          name: tax.tax.tax_type,
          value:
            (parseInt(tax.tax.tax_rate) / 100) * el?.order_list?.food__price,
        };
        taxes.push(taxObj);
      }
    }

    for (let t of taxes) {
      const taxesTypes = [];
      const findName = taxesTypes.findIndex((el) => el.name === t.name);
      if (findName === -1) {
        taxesTypes.push(t);
      }

      for (let taxType of taxesTypes) {
        const taxesAmount = {
          name: taxType.name,
          amount: taxes
            .filter((t) => t.name === taxType.name)
            .reduce((acc, t) => acc + t.value, 0),
          value: taxes
            .filter((t) => t.name === taxType.name)
            .reduce((acc, t) => acc + t.value, 0)
            .toLocaleString("en", { style: "currency", currency: "KES" }),
        };

        const findItem = taxAmount.findIndex(
          (el) => el.name === taxesAmount.name
        );
        if (findItem === -1) {
          taxAmount.push(taxesAmount);
        }
      }
    }

    return taxAmount;
  }


  // function getFoodPrice(foodprice){
  //   foodPrice - ()
  // }

  const handleDownload = () => {
    html2canvas(document.querySelector("#receiptComponent"), {
      useCORS: true,
      scale: 2,
      ignoreElements: (elems) => elems.id === "downloadButton",
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      var pdf = new jsPDF("p", "mm", "a4");
      var imgProps = pdf.getImageProperties(imgData);
      var pdfWidth = pdf.internal.pageSize.getWidth();
      var pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      var newWidth = pdfWidth * 0.75;
      var newHeight = (pdfHeight * newWidth) / pdfWidth;

      var topPadding = 5;
      var bottomPadding = 5;

      newHeight = newHeight - topPadding - bottomPadding;
      pdf.addImage(
        imgData,
        "PNG",
        (pdfWidth - newWidth) / 2,
        topPadding,
        newWidth,
        newHeight
      );
      pdf.save("receipt.pdf");
    });
  };

  const columns = [
    {
      field: "items",
      headerName: "Items",
      flex: 3,
      sortable: false,
      renderCell: (param) => (
        <p className="capitalize">
          {param.row.order_list.food__food_name?.toLowerCase()}
        </p>
      ),
    },
    {
      field: "quantity",
      headerName: "Qty",
      sortable: false,
      flex: 1,
      renderCell: (param) => <p>{param.row.order_list.quantity}</p>,
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      sortable: false,
      flex: 1.5,
      renderCell: (param) => (
        <p>
          {param.row.order_list.food__original_price.toLocaleString("en", {
            style: "currency",
            currency: "kes",
          })}
        </p>
      ),
    },
  ];

  // console.log(order)

  return (
    <div className="h-screen overflow-hidden receipt">
      <div className="h-full overflow-auto">
        <div className="h-full py-4 justify-center lg:py-8">
          <div
            className="mx-auto bg-white p-4 rounded space-y-6 md:shadow-2xl md:w-1/2 lg:p-8 lg:w-[28%]"
            id="receiptComponent"
          >
            <div className="space-y-1">
              <img
                src={order?.restaurant?.restaurant_image}
                alt="placeholder"
                className="flex-1 h-12 w-auto mx-auto"
              />
              <div className="flex flex-row items-center justify-center space-x-2 font-semibold text-[14px]">
                <p>
                  {new Date().toLocaleDateString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </p>
                <FiberManualRecordIcon className="!text-[8px]" />
                <p>
                  {new Date().toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true,
                  })}
                </p>
              </div>
            </div>

            <div className="space-y-1 border-b section">
              {[
                { name: "Customer Email", value: order?.customer_email },
                { name: "Order Number", value: order?.order_number },
                { name: "Order Type", value: order?.order_type },
              ].map((el, i) => (
                <div
                  className="flex flex-row items-center justify-between text-[14px]"
                  key={i}
                >
                  <h2 className="text-gray-700 font-[500]">{el.name}</h2>
                  <p className="font-semibold">{el.value}</p>
                </div>
              ))}
            </div>

            <div className="section">
              <h2 className="font-bold text-lg">Order Summary</h2>
              <div className="h-auto">
                <ThemeProvider theme={theme}>
                  <DataGrid
                    rows={order.order_details || []}
                    columns={columns}
                    checkboxSelection={false}
                    disableRowSelectionOnClick
                    autoHeight={true}
                    disableColumnMenu={true}
                    hideFooter={true}
                    headerHeight={30}
                    rowHeight={30}
                    getRowId={(rows) => rows?.order_list?.food__food_code}
                    sx={{
                      "&.MuiDataGrid-root": {
                        "&>.MuiDataGrid-main": {
                          "& .MuiDataGrid-columnHeaderTitle": {
                            fontSize: 14,
                            fontWeight: "600",
                            fontFamily: "PT Mono",
                          },
                          "& .MuiDataGrid-columnHeader:focus": {
                            outline: "none",
                            border: "none",
                          },
                          "& .MuiDataGrid-columnHeader:focus-within": {
                            outline: "none !important",
                          },
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                          outline: "none !important",
                        },
                        borderColor: "transparent",
                        ".MuiDataGrid-columnHeaders": {
                          borderBottom: "none",
                        },
                        ".MuiDataGrid-columnHeader": {
                          padding: "0px",
                        },
                        ".MuiDataGrid-columnSeparator": {
                          display: "none",
                        },
                        ".MuiDataGrid-cell": {
                          padding: 0,
                          fontSize: 13,
                          fontFamily: "PT Mono",
                        },
                      },
                    }}
                  />
                </ThemeProvider>
              </div>
            </div>

            <div className="section">
              <div className="space-y-1">
                {[
                  {
                    name: "SubTotal",
                    value: (
                      order?.amount +
                      getDiscount() -
                      getTaxes().reduce((acc, t) => acc + t.amount, 0)
                    ).toLocaleString("en", {
                      style: "currency",
                      currency: "KES",
                    }),
                  },
                  {
                    name: "Discount",
                    value: getDiscount().toLocaleString("en", {
                      style: "currency",
                      currency: "KES",
                    }),
                  },
                  ...getTaxes(),
                  {
                    name: "Total",
                    value: order?.amount?.toLocaleString("en", {
                      style: "currency",
                      currency: "KES",
                    }),
                  },
                ].map((el, i) => (
                  <div
                    key={i}
                    className="flex flex-row items-center justify-between text-[14px] font-semibold"
                  >
                    <p>{el.name}</p>
                    <p>{el.value}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-[#dbeafe] !space-y-3 px-4 py-2">
              <h2 className="font-bold text-lg">Payment Summary</h2>
              <div className="space-y-2">
                {[
                  {
                    name: "Payment Method",
                    value:
                      order?.payment_status === "Partially Paid"
                        ? "Mpesa & Cash"
                        : order?.payment_mode,
                  },
                  {
                    name:
                      order?.payment_mode === "Mpesa"
                        ? "Transaction Code"
                        : null,
                    value:
                      order?.payment_mode === "Mpesa"
                        ? order?.payment?.receipt_code
                        : null,
                  },
                  {
                    name:
                      order?.payment_status === "Partially Paid"
                        ? "Partial Amount"
                        : null,
                    value:
                      order?.payment_status === "Partially Paid"
                        ? "KES" + " " + order?.payment?.amount
                        : null,
                  },
                  {
                    name:
                      order?.payment_mode === "Mpesa" &&
                      order?.payment_status !== "Partially Paid"
                        ? "Amount Paid"
                        : "Cash Received",
                    value:
                      order?.payment_mode === "Cash"
                        ? order?.cash > 0
                          ? order?.cash?.toLocaleString("en", {
                              style: "currency",
                              currency: "KES",
                            })
                          : order?.amount?.toLocaleString("en", {
                              style: "currency",
                              currency: "KES",
                            })
                        : order?.payment_status === "Partially Paid"
                        ? (
                            order?.amount - parseInt(order?.payment.amount)
                          ).toLocaleString("en", {
                            style: "currency",
                            currency: "KES",
                          })
                        : order?.amount?.toLocaleString("en", {
                            style: "currency",
                            currency: "KES",
                          }),
                  },
                ].map(
                  (el, i) =>
                    el.value && (
                      <div
                        key={i}
                        className="flex flex-row items-center justify-between text-[14px]"
                      >
                        <p>{el.name}</p>
                        <p>{el.value}</p>
                      </div>
                    )
                )}
              </div>
            </div>

            <Button
              fullWidth
              variant="contained"
              id="downloadButton"
              sx={{
                bgcolor: "secondary.main",
                color: "white",
              }}
              onClick={handleDownload}
            >
              Download Receipt
            </Button>

            <div>
              <p className="text-xs">
                If you have any questions, contact{" "}
                <span className="font-semibold">
                  {order?.restaurant?.branch_name}
                </span>{" "}
                at{" "}
                <a
                  href={`mailto:${order?.restaurant?.email}`}
                  className="font-semibold"
                >
                  {order?.restaurant?.email}
                </a>{" "}
                or call at{" "}
                <a
                  href={`tel:${order?.restaurant?.first_contacts}`}
                  className="font-semibold"
                >
                  {order?.restaurant?.first_contacts}
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Receipt;
