import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  InputAdornment,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Button,
  Box,
  Typography,
  Input
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link as MuiLink } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { setCredentials, setLastUserEmail } from "../../features/auth/authSlice";
// import GoogleIcon from "../../styling/CustomComponents/icons/GoogleIcon";
import Dagizo_Logo from "../../images/Dagizo_Logo.png";
import CustomSnackBar from "../../components/common/utils/CustomSnackBar";

import { motion } from "framer-motion"
import { Fade, Zoom } from "react-awesome-reveal"

// SIGN IN FUNCTIONS
import { signInWithEmailAndPassword } from "../../functions/auth";
import { notify } from "../../components/Toast";
import AuthLayout from "../../components/Layout/Auth";

const Signin = () => {
  const [values, setValues] = useState({
    username: "",
    password: "",
    openSnackBar: false,
    snackBarMessage: "",
    snackBarSeverity: "success",
    showPassword: false,
  });

  const [rememberMe, setRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);

  const {
    username,
    password,
    openSnackBar,
    snackBarMessage,
    snackBarSeverity,
  } = values;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { lastUserEmail } = useSelector((state) => state?.auth);
  const from = location.state?.from?.pathname || "/welcome";

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (lastUserEmail) {
      setValues({
        ...values,
        username: lastUserEmail,
      });
    }
  }, [lastUserEmail])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username && password) {
      const email = username
      setLoading(true)
      return signInWithEmailAndPassword(email, password)
        .then((res) => {
          if (res.data.status === true) {
            const { restaurant, user, user_permissions, job_name, branch_id } = res.data;

            if (restaurant?.restaurant?.is_active) {
              dispatch(
                setCredentials({
                  user: restaurant,
                  restaurant: restaurant.restaurant,
                  user_permissions,
                  accessToken: user.access_token,
                  job_name,
                  branch_id,
                })
              );

              localStorage.setItem("accessToken", user.access_token)
              notify(res.data.message, 'success');
              if (user_permissions.length === 0 && user.user_type === "Employee") {
                navigate("/permissions")
              } else {
                navigate('/dashboard')
              }
              // if (lastUserEmail || !lastUserEmail) {
              //   navigate('/dashboard')
              // } else {
              //   navigate(from);
              // }
            } else {
              // notify("Account not active", "error")
              navigate("/activation")
            }
          } else if (res.data.status === false) {
            console.log(res.data)
            setLoading(false)
            console.log("SIGN ERROR BRUH", res.data.status);
            notify(res.data.message, 'error')
          }
        })
        .catch((err) => {
          setLoading(false)
          console.log("SIGNIN ERROR MESSAGE", err);
          notify(err.response.data.error || err.response.data.detail, 'error')
        });
    }
  };

  return (
    <>
      {lastUserEmail ? (
        <div className="header-container">
          <div className="header h-screen flex justify-center items-center">
            <div className="w-5/6 md:w-3/6 lg:w-2/6 bg-white flex flex-col items-center px-8 py-6 rounded-lg" style={{ boxShadow: "5px 5px 40px 2px rgb(0 0 0 / 0.1)" }}>
              <Fade
                direction="up"
                cascade
                damping={0.1}
              >
                <Zoom className="my-8">
                  <img src={Dagizo_Logo} alt="DAGIZO" width="170px" />
                </Zoom>
                <h1 className="text-2xl md:text-4xl text-primary text-center mb-4">Your session has expired</h1>
                <p className="text-gray-400 text-center font-light">If you want to conitinue this session as <span className="text-primary">{lastUserEmail}</span> please re-write your password</p>

                <form className="w-full mt-6" onSubmit={(e) => handleSubmit(e)}>
                  <Typography sx={{ mt: 2 }}>Password</Typography>

                  <Input
                    id="standard-adornment-password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={handleChange("password")}
                    fullWidth
                    autoFocus
                    autoComplete='off'
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  <Button
                    type="submit"
                    disabled={loading}
                    disableElevation
                    fullWidth
                    sx={{ bgcolor: "secondary.main", color: "white", mt: 4 }}
                    variant="contained"
                  >
                    {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : "Sign In"}
                  </Button>

                  <button onClick={() => dispatch(setLastUserEmail(null))} className="w-full text-center bg-gray-300 text-black py-2 px-4 mt-4 rounded-md">Sign in with different Account</button>

                </form>
              </Fade>
            </div>
          </div>
        </div>
      ) : (
        <AuthLayout>
          <Grid container spacing={1} sx={{ mt: 5 }}>
            <Grid>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
                className="mb-10"
              >
                <img src={Dagizo_Logo} alt="DAGIZO" width="170px" />
              </motion.div>
              <ValidatorForm autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <Typography
                    variant="h5"
                    color="black"
                    fontWeight="700"
                    sx={{ mt: 3 }}
                  >
                    SIGN IN
                  </Typography>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                >
                  <Typography sx={{ fontSize: 14 }}>
                    New on Dagizo?{" "}
                    <MuiLink
                      sx={{ textDecoration: "none", fontWeight: 'bold' }}
                      component={Link}
                      to={"/signup"}
                    >
                      Sign Up{" "}
                    </MuiLink>
                    to start using Dagizo for your restaurant.
                  </Typography>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7 }}
                >
                  <Typography sx={{ mt: 4 }}>Username / Email</Typography>
                  <TextValidator
                    sx={{
                      mt: 1,
                      backgroundColor: "secondary.input_field",
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                    fullWidth
                    onChange={handleChange("username")}
                    name="email"
                    value={username.toLowerCase()}
                    validators={["required", "isEmail"]}
                    errorMessages={["This Field is Required", "Email is invalid"]}
                  />
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  <Typography sx={{ mt: 2 }}>Password</Typography>
                  <TextValidator
                    type={values.showPassword ? "text" : "password"}
                    sx={{
                      mt: 1,
                      backgroundColor: "secondary.input_field",
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                    fullWidth
                    name="password"
                    value={password}
                    onChange={handleChange("password")}
                    validators={["required"]}
                    errorMessages={["This Field is Required"]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <MuiLink
                      sx={{ textDecoration: "none" }}
                      component={Link}
                      to={"/forgot-password"}
                    >
                      Forgot Password?
                    </MuiLink>
                  </Box>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "&.MuiCheckbox-root": {
                              border: 0,
                              bgcolor: "secondary.input_field",
                            },
                          }}
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                        />
                      }
                      label="Remember me"
                    />
                  </FormGroup>

                  <Button
                    type="submit"
                    disabled={loading}
                    disableElevation
                    fullWidth
                    sx={{ bgcolor: "secondary.main", color: "white", mt: 2 }}
                    variant="contained"
                  >
                    {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : "Sign In"}
                  </Button>
                </motion.div>
              </ValidatorForm>

              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
              >
                <Divider sx={{ my: 2 }}>Or</Divider>
                {/* <Button
                  disableElevation
                  fullWidth
                  sx={{ bgcolor: "secondary.input_field" }}
                  variant="contained"
                  startIcon={<GoogleIcon />}
                  onClick={() => handleGoogleAuth}
                >
                  Authorize with Google
                </Button> */}

                <Typography sx={{ fontSize: 14, mt: 2 }}>
                  By using Dagizo you are agreeing to{" "}
                  <MuiLink
                    sx={{ textDecoration: "none" }}
                    component={Link}
                    to={"terms-and-conditions"}
                  >
                    Terms & Conditions and Privacy Policy
                  </MuiLink>
                </Typography>
              </motion.div>

            </Grid>
            <Grid xs={2}></Grid>
          </Grid>
        </AuthLayout>
      )}
    </>
  );
};

export default Signin;
