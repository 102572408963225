import { createSlice } from "@reduxjs/toolkit";

const branchSlice = createSlice({
  name: "branch",
  initialState: {
    branchToUpdate: null,
    allBranches: [],
    currentBranch: null,
  },
  reducers: {
    setBranchToUpdate: (state, action) => {
      const { branchToUpdate } = action.payload;
      state.branchToUpdate = branchToUpdate;
    },
    setAllBranches: (state, action) => {
      const { allBranches } = action.payload;
      state.allBranches = allBranches;
    },
    setCurrentBranch: (state, action) => {
      const { currentBranch } = action.payload;
      state.currentBranch = currentBranch;
    },
  },
  // extraReducers: {},
});

export const { setBranchToUpdate, setAllBranches, setCurrentBranch } = branchSlice.actions;

export default branchSlice.reducer;
