import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Button, Typography } from "@mui/material";

import CategoryIcon from "../../images/CategoryIcon.png";
import EditIcon from "@mui/icons-material/Edit";


const NoCategories = () => {
  const navigate = useNavigate();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100vh" }}
    >
      <Box>
        <img src={CategoryIcon} alt="Category Icon"></img>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography>
          You currently don't have any saved Categories.<br></br>Let's start by
          creating a Category.
        </Typography>
      </Box>
      <Box sx={{ mt: 2, width: 280 }}>
        <Button
          onClick={() => navigate("")}
          startIcon={
            <EditIcon
              sx={{
                border: 1,
                borderRadius: 1,
                bgcolor: "white",
                borderColor: "white",
                color: "black",
              }}
            />
          }
          disableElevation
          fullWidth
          variant="contained"
          sx={{
            bgcolor: "secondary.main",
            color: "white",
            height: 55,
            fontSize: "1rem",
          }}
        >
          Create a Category
        </Button>

      </Box>
    </Stack>
  );
};

export default NoCategories;