import React from "react";
import { Outlet } from "react-router-dom";
import { Stack } from "@mui/material";
import AccountAndNotifications from "../common/utils/AccountAndNotifications";

const Categories = () => {
  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        })}
      >
        <AccountAndNotifications />
      </Stack>
      <Outlet />
    </Stack>
  );
};

export default Categories;
