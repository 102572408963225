import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  Select,
  Checkbox,
  ListItemText,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import EditIcon from '@mui/icons-material/Edit';
import ImageUploader from "../common/utils/ImageUploader";
import { useSelector } from "react-redux";
import MainButton from "../MainButton";
import VariantModal from "../Settings/modals/VariantModal";
import { notify } from "../Toast";
import BackButton from "../../utils/BackButton";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AxiosPrivate from "../../functions/axios/axiosPrivate";
import { updateFoodVisibilit } from "../../functions/menu";
import { Refresh } from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditMenuItemDetails = ({
  values,
  setValues,
  variants,
  setVariants,
  selectedTaxes,
  setSelectedTaxes,
  loading,
  submit,
  images,
  setImages,
  radioValue,
  setRadioValue,
  visible,
  setVisible,
  food,
}) => {
  const [open, setOpen] = useState(false);
  const [periods, setPeriods] = useState(React.useMemo(() => [], []));
  const [taxes, setTaxes] = useState(React.useMemo(() => [], []));

  const fetchPeriods = async () => {
    const res = await AxiosPrivate.get(`/restaurant/serving-periods`);
    setPeriods(res.data.data);
  };

  const update = () => {
    updateFoodVisibilit(food.id, food.isVisible).then((res) => {
      window.location.reload();
    });
  };

  const fetchTaxes = async () => {
    const res = await AxiosPrivate.get(`/restaurant/dagizo-tax-setup`);
    setTaxes(res.data.data);
  };

  useEffect(() => {
    fetchPeriods();
    fetchTaxes();
  }, []);


  const {
    food_name,
    food_image,
    food_content,
    description,
    price,
    tax,
    dish_category,
    discount_rate,
    serving_hours,
    preparation_time,
    in_stock,
    discount_active,
    show_preparation_time,
    food_category_id,
    openSnackBar,
    snackBarMessage,
    snackBarSeverity,
  } = values;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const changeAvatar = (url, index) => {
    let allImages = [...images];
    allImages[index] = { url };
    setImages(allImages);
  };

  const getTax = (ids) => {
    const filtered = taxes.filter((i) => ids.some((item) => item === i.id));
    const taxList = filtered.map((i) => {
      return i.tax_type;
    });
    return taxList?.join(", ");
  };

  const handleTaxesChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTaxes(typeof value === "string" ? value.split(",") : value);
  };

  const handleDeleteVariant = (e, index) => {
    e.preventDefault();
    let list = [...variants];
    list.splice(index, 1);
    setVariants(list);
  };

  useEffect(() => {
    const handleWheel = (event) => {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    };

    const handleKeyUp = (event) => {
      if (document.activeElement.type === "number") {
        const { value } = event.target;
        if (value === "") {
          document.activeElement.blur();
        }
      }
    };

    document.addEventListener("wheel", handleWheel);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("wheel", handleWheel);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <Box
      sx={(theme) => ({
        bgcolor: "secondary.input_field",
        // [theme.breakpoints.down("md")]: {
        //   display: "none",
        // },
      })}
    >
      <Stack>
        <Typography sx={{}} variant="h6"></Typography>
        <form>
          <Grid container spacing={2}>
            {" "}
            <Grid xs={12}>
              <div className="flex flex-wrap gap-4">
                {images?.map((i, index) => (
                  <ImageUploader
                    key={index}
                    changeAvatar={(url) => changeAvatar(url, index)}
                    title={"Upload Food Image"}
                    current={i?.url}
                  />
                ))}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setImages([...images, { url: "" }]);
                  }}
                  className="bg-gray-200 px-2"
                >
                  <AddIcon />
                </button>
              </div>

              {/* <input className="hidden"  type="number" name="food_category_id" value={categoryId} /> */}
              <div className="grid md:grid-cols-2 md:gap-x-6">
                <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Food Name
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    placeholder="Food name"
                    value={values.food_name}
                    onChange={handleChange("food_name")}
                  />
                </div>

                <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Dish Category
                  </Typography>

                  <Select
                    fullWidth
                    value={values.dish_category}
                    onChange={handleChange("dish_category")}
                  >
                    <MenuItem value="main-dish">Main Dish</MenuItem>
                    <MenuItem value="side">Side</MenuItem>
                    <MenuItem value="extra">Extra</MenuItem>
                  </Select>
                </div>

                <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Food Content
                  </Typography>
                  <TextField
                    fullWidth
                    value={food_content}
                    placeholder="Food content..."
                    onChange={handleChange("food_content")}
                  ></TextField>
                </div>

                <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Description
                  </Typography>
                  <TextField
                    fullWidth
                    value={values.description}
                    placeholder="Food description.."
                    onChange={handleChange("description")}
                  ></TextField>
                </div>
              </div>

              {food?.variants?.length === 0 && <div className="flex justify-center">
                <button
                  className="border px-4 py-2 mt-6 text-primary shadow-md font-bold"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                >
                  Add food variants
                </button>
              </div>}

              {variants.length > 0 && (
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                  {variants.map((variant, index) => (
                    <div
                      className="bg-white rounded shadow px-4 py-2"
                      key={index}
                    >
                      <div className="flex justify-between">
                        <p className="mb-2 font-bold">{variant.name}</p>
                        <div className="flex flex-row items-center justify-between">
                          <IconButton> <EditIcon sx={{ fontSize: 18 }} onClick={() => setOpen(true)} /></IconButton>
                          <IconButton onClick={(e) => handleDeleteVariant(e, index)}>
                            <HighlightOffIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        </div>
                      </div>

                      <div className="border-l-[10px] border-blue-400 pl-2">
                        {variant.values.map((item, index) => (
                          <div key={index}>
                            <p className="text-sm">
                              {item.value_name}: KES {item.price}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className="grid md:grid-cols-2 gap-x-6">
                {variants.length < 1 && (
                  <div>
                    <Typography color="black" sx={{ mt: 2 }}>
                      Price
                    </Typography>
                    <TextField
                      fullWidth
                      type={"number"}
                      placeholder="200"
                      value={values.price}
                      onChange={handleChange("price")}
                    ></TextField>
                  </div>
                )}

                <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Serving Hours
                  </Typography>
                  <Select
                    fullWidth
                    value={values.serving_hours}
                    onChange={handleChange("serving_hours")}
                  >
                    {periods.length > 0 &&
                      periods.map((period, index) => (
                        <MenuItem key={index} value={period.id}>
                          {period.serving_type}
                        </MenuItem>
                      ))}
                  </Select>
                </div>

                <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Tax
                  </Typography>
                  <Select
                    multiple
                    fullWidth
                    value={selectedTaxes}
                    onChange={handleTaxesChange}
                    placeholder="Choose one tax or several"
                    renderValue={(selected) => getTax(selected)}
                    MenuProps={MenuProps}
                  >
                    {taxes.length > 0 &&
                      taxes.map((tax) => (
                        <MenuItem key={tax.id} value={tax.id}>
                          <Checkbox
                            checked={selectedTaxes.indexOf(tax.id) > -1}
                          />
                          <ListItemText
                            primary={tax.tax_type + " (" + tax.tax_rate + "%)"}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </div>

                {dish_category === "main-dish" && <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Discount
                  </Typography>
                  <TextField
                    fullWidth
                    type={"number"}
                    placeholder="10"
                    minValue={0}
                    value={values.discount_rate}
                    onChange={handleChange("discount_rate")}
                  ></TextField>
                </div>}

                <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Preparation Time (minutes)
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    type={"number"}
                    value={values.preparation_time}
                    placeholder="30"
                    onChange={handleChange("preparation_time")}
                  />
                </div>
              </div>

              {values.dish_category !== "main-dish" && (
                <div className="grid md:grid-cols-3 md:gap-6 mt-6">
                  <div>
                    <Typography color="black" sx={{ mt: 2, mb: 0.5 }}>
                      Maximum Count
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      placeholder="Number of free items"
                      type="number"
                      value={values.maximum_count}
                      onChange={handleChange("maximum_count")}
                    />
                  </div>

                  <div>
                    <Typography color="black" sx={{ mt: 2 }}>
                      Food Item is Required
                    </Typography>

                    <FormControl>
                      <RadioGroup
                        row
                        value={radioValue}
                        onChange={(event) => setRadioValue(event.target.value)}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div>
                    <Typography color="black" sx={{ mt: 2 }}>
                      Visible on menu
                    </Typography>

                    <FormControl>
                      <RadioGroup
                        row
                        value={visible}
                        onChange={(event) => update(values.isVisible)}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          <MainButton
            onClick={submit}
            loading={loading}
            title="Update Menu Item"
          />
        </form>

        <VariantModal
          open={open}
          setOpen={setOpen}
          variants={variants}
          setVariants={setVariants}
          editData={variants.length > 0 ? variants : null}
        />
      </Stack>
    </Box>
  );
};

export default EditMenuItemDetails;
