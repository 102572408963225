import React, { useEffect, useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Box,
  Switch,
  AvatarGroup,
  Avatar,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { red, green, purple, teal, blue } from "@mui/material/colors";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import moment from "moment";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

// Components
// import CustomDataGrid from "../../styling/CustomComponents/CustomDataGrid";
import OrderDataGrid from "./OrderDataGrid";
import ViewOrderDetailsDrawer from "./drawers/ViewOrderDetailsDrawer";

// Functions
import { changeOrderStatus } from "../../functions/orders";
import CustomSnackBar from "../common/utils/CustomSnackBar";
import { notify } from "../Toast";
import OrderModal from "./OrderModal";
import { useUpdateOrderStatus } from "../../services/orders";
import toast from "react-hot-toast";
// import { useMutation, useQueryClient } from "@tanstack/react-query";

function getChipProps(params) {
  if (params.value === "Completed") {
    return {
      label: params.value,
      style: {
        width: "100px",
        color: green[600],
        borderColor: green[100],
        backgroundColor: green[100],
        borderRadius: 5,
      },
    };
  } else if (params.value === "Confirmed") {
    return {
      label: "Preparing...",
      style: {
        width: "100px",
        color: blue[600],
        borderColor: blue[100],
        backgroundColor: blue[100],
        borderRadius: 5,
      },
    };
  } else if (params.value === "Ready") {
    return {
      label: params.value,
      style: {
        width: "100px",
        color: teal[600],
        borderColor: teal[100],
        backgroundColor: teal[100],
        borderRadius: 5,
      },
    };
  } else if (params.value === "INCOMPLETE") {
    return {
      label: params.value,
      style: {
        width: "145px",
        color: red[600],
        borderColor: red[200],
        // backgroundColor: red[200],
        borderRadius: 5,
        fontWeight: "600",
      },
    };
  } else {
    return {
      label: params.value,
      style: {
        width: "100px",
        color: red[600],
        borderColor: red[100],
        backgroundColor: red[100],
        borderRadius: 5,
      },
    };
  }
}

const OrderListTable = ({ ordersData, refresh }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [rowParams, setRowParams] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open, setOpen] = useState(false);

  // const queryClient = useQueryClient()
  const updateStatus = useUpdateOrderStatus();

  useEffect(() => {
    if (updateStatus.isSuccess) {
      setOpen(false);
      refresh();
    }
    if (updateStatus.isError) {
      console.log("new Err", updateStatus.error);
      notify(updateStatus.error.message, "error");
    }
  }, [updateStatus.isSuccess, updateStatus.isError]);

  const handleCloseMenu = () => {
    setAnchorElNav(null);
  };

  const handleViewItemClick = (prop) => {
    // console.log(`${prop} click params`, rowParams);
    // handleCloseMenu();
    setOpenDrawer(true);
  };

  const handleChangeItemStatusClick = (prop) => {
    updateStatus.mutate({ type: prop, order_id: rowParams.id });
  };

  const handleOrdersActionsClick = (params) => (event) => {
    setRowParams(params.row);
    setAnchorElNav(event.currentTarget);
  };

  const columns = [
    {
      field: "order_number",
      headerName: "Order Number",
      renderCell: (params) => {
        return (
          <div className="flex gap-2 items-center">
            <AvatarGroup max={2}>
              {params.row.order_details?.map((item, index) => (
                <Avatar
                  key={index}
                  src={item.food_image[0]?.url}
                  alt="Food Image"
                />
              ))}
            </AvatarGroup>
            <span>{params.value}</span>
          </div>
        );
      },
      width: 200,
    },
    {
      field: "ordered_date",
      type: "dateTime",
      valueFormatter: (params) => moment(params?.value).fromNow(),
      headerName: "Date",
      width: 150,
    },
    {
      field: "order_type",
      headerName: "Order Type",
      width: 150,
    },
    {
      field: "table",
      headerName: "Table",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="flex flex-wrap items-center gap-2">
            <span className=" lowercase">
              {params.value?.room_id?.place_name || "--"}
            </span>
            {params.value?.table_name && (
              <Chip size="small" color="primary" label={params.value?.table_name} />
            )}
          </div>

        );
      },
    },
    // {
    //   field: "payment_status",
    //   headerName: "Paid",
    //   type: "boolean",
    //   editable: true,
    //   width: 150,
    //   renderCell: (params) => {
    //     return  (
    //       <Switch checked={params.value} />
    //     )
    //   },
    // },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <span
            style={{
              color: "#F4B315",
              width: "100%",
            }}
          >
            {params.value.toLocaleString("en", {
              style: "currency",
              currency: "KSH",
            })}
          </span>
        );
      },
    },

    {
      field: "order_status",
      headerName: "Status",
      width: 170,
      renderCell: (params) => {
        return (
          <div className="flex items-center gap-2">
            <Chip variant="outlined" size="medium" {...getChipProps(params)} />
            {params.value !== "INCOMPLETE" && (
              <button
                className="border rounded-md"
                onClick={() => {
                  setRowParams(params);
                  setOpen(true);
                }}
              >
                <ModeEditIcon
                  sx={{ p: 1, borderRadius: "50%", fontSize: 35 }}
                />
              </button>
            )}
          </div>
        );
      },
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        // console.log(params.row);

        return (
          <button
            className="border px-4 py-1 rounded-md"
            onClick={() => {
              setRowParams(params.row);
              handleViewItemClick("view");
            }}
          >
            <ArrowRightAltIcon /> view
          </button>
        );
      },
    },
  ];

  return (
    <>
      {" "}
      <Box
        sx={{
          mt: 5,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "primary.lightest_gray",
            fontSize: 18,
            color: "black",
            fontWeight: 700,
          },
          width: "100%",
        }}
      >
        <ViewOrderDetailsDrawer
          orderDetails={rowParams}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          refresh={refresh}
          handleSubmit={(status) => handleChangeItemStatusClick(status)}
        />
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={() => handleViewItemClick("view")}>
            <Box display="flex" alignItems="center" textAlign="center">
              View Order
            </Box>
          </MenuItem>
        </Menu>{" "}
        <OrderDataGrid rows={ordersData} columns={columns} />
        <OrderModal
          open={open}
          setOpen={setOpen}
          order={rowParams.row}
          onSubmit={(status) => handleChangeItemStatusClick(status)}
          loading={updateStatus.isLoading}
        />
      </Box>
    </>
  );
};

export default OrderListTable;
