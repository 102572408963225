import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Stack, TextField, Select, Checkbox, ListItemText, MenuItem, FormControl, FormControlLabel, RadioGroup, Radio, IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from 'react-router-dom'
import CustomSnackBar from '../../common/utils/CustomSnackBar'
import { useLocation } from 'react-router-dom'
import ImageUploader from "../../common/utils/ImageUploader";
import { useSelector } from "react-redux";
import MainButton from "../../MainButton";
import VariantModal from "../../Settings/modals/VariantModal";
import { notify } from "../../Toast";
import AddIcon from '@mui/icons-material/Add';
import AxiosPrivate from "../../../functions/axios/axiosPrivate";
import EditIcon from '@mui/icons-material/Edit';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddMenuItemForm = ({ category_id }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [addons, setAddons] = useState([])
  const [variants, setVariants] = useState([])
  const [selectedAddons, setSelectedAddons] = useState(React.useMemo(() => [], []));
  const [selectedTaxes, setSelectedTaxes] = useState(React.useMemo(() => [], []));
  const [periods, setPeriods] = useState(React.useMemo(() => [], []));
  const [taxes, setTaxes] = useState(React.useMemo(() => [], []));
  const [images, setImages] = useState([""]);
  const [radioValue, setRadioValue] = React.useState(false);
  const [visible, setVisible] = React.useState(true);

  const { currentBranch } = useSelector((state) => state.branch);
  const branchId = currentBranch?.id

  const { restaurant: { id } } = useSelector((state) => state.auth);

  const fetchAddons = async () => {
    const res = await AxiosPrivate.get(`/restaurant/food-addon/${id}`)
    setAddons(res.data.data)
  }

  const fetchPeriods = async () => {
    const res = await AxiosPrivate.get(`/restaurant/serving-periods`)
    setPeriods(res.data.data)
  }

  const fetchTaxes = async () => {
    const res = await AxiosPrivate.get(`/restaurant/dagizo-tax-setup`)
    setTaxes(res.data.data)
  }

  useEffect(() => {
    fetchAddons()
    fetchPeriods()
    fetchTaxes()
  }, [])

  console.log('images', images.filter(i => i !== ""))

  //  console.log("REs_CODE", restaurant_code)
  const [values, setValues] = useState({
    food_name: "",
    food_image: images.filter(i => i !== ""),
    food_content: "",
    description: "",
    price: "0",
    tax: "",
    dish_category: "",
    discount_rate: 0,
    serving_hours: "",
    preparation_time: "",
    in_stock: true,
    discount_active: false,
    show_preparation_time: true,
    food_category_id: category_id,
    maximum_count: "",
    isRequired: radioValue,
    isVisible: visible
  });

  const {
    food_name,
    food_image,
    food_content,
    description,
    price,
    tax,
    dish_category,
    discount_rate,
    serving_hours,
    preparation_time,
    in_stock,
    discount_active,
    show_preparation_time,
    food_category_id,
    maximum_count,
    isRequired,
    isVisible
  } = values;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setValues({ ...values, openSnackBar: false });
  };

  const changeAvatar = (url, index) => {
    let allImages = [...images]
    allImages[index] = url
    setImages(allImages)
    // setValues({ ...values, food_image: food_image.push(url) });
  };

  console.log('values', values)

  const handleAddonsChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAddons(typeof value === "string" ? value.split(",") : value);
  };

  const allTaxes = selectedTaxes.map((tax) => {
    return {
      id: tax
    }
  })

  const handleTaxesChange = (event) => {
    const {
      target: { value },
    } = event;

    console.log('value', value)
    setSelectedTaxes(typeof value === "string" ? value.split(",") : value);
  };

  const getTax = (ids) => {
    const filtered = taxes.filter(i => ids.some(item => item === i.id));
    const taxList = filtered.map((i) => {
      return i.tax_type
    })
    return taxList?.join(", ")
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("BRANCH ID", menu_id)
    if (food_name) {
      setLoading(true)

      const filteredImages = images.filter(i => i !== "")
      const imageData = filteredImages.map(i => {
        return {
          url: i
        }
      })
      const defaultPrice = variants[0]?.values[0]?.price

      if (imageData.length === 0) {
        notify("Image is required", "error")
        setLoading(false)
        return
      }
      // console.log(values)
      return AxiosPrivate.post(`/restaurant/food/${category_id}/`,
        {
          food_name,
          food_image: imageData,
          food_content,
          description,
          price: variants.length > 0 ? defaultPrice : price,
          tax_id: allTaxes,
          dish_category,
          discount_rate: parseInt(discount_rate),
          serving_hours_id: serving_hours,
          preparation_time,
          in_stock,
          discount_active,
          show_preparation_time,
          // food_category_id,
          branch_id: branchId,
          // addon_ids: selectedAddons
          variants,
          maximum_count,
          isRequired: Boolean(radioValue),
          isVisible: Boolean(visible),
        }
      )
        .then((res) => {
          if (res.data.status === true) {
            // console.log("CREATE FOOD SUCCESS RESPONSE", res.data.status);
            notify(res.data.message, 'success')
            setLoading(false)
            navigate(-1)
          } else if (res.data.status === false) {
            // console.log("CREATE FOOD ERROR RESPONSE", res);
            setLoading(false)
            notify(res.data.message, 'error')
          }
        })
        .catch((err) => {
          setLoading(false)
          notify(err.data.message, 'error')
        });
    }
  };

  useEffect(() => {
    const handleWheel = (event) => {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    };

    const handleKeyUp = (event) => {
      if (document.activeElement.type === "number") {
        const { value } = event.target;
        if (value === "") {
          document.activeElement.blur();
        }
      }
    };

    document.addEventListener("wheel", handleWheel);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("wheel", handleWheel);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <Box
      sx={(theme) => ({
        bgcolor: "secondary.input_field",
      })}
    >

      <Stack>
        <Typography sx={{}} variant="h6"></Typography>
        <form>
          <Grid container spacing={2}>
            {" "}
            <Grid xs={12}>
              <div className="flex flex-wrap gap-4">
                {images?.map((i, index) => (
                  <ImageUploader
                    key={index}
                    changeAvatar={(url) => changeAvatar(url, index)}
                    title={"Upload Food Image"}
                  />
                ))}
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    setImages([...images, ""])
                  }}
                  className="bg-gray-200 px-2"
                >
                  <AddIcon />
                </button>
              </div>

              {/* <input className="hidden"  type="number" name="food_category_id" value={categoryId} /> */}
              <div className="grid md:grid-cols-2 md:gap-x-6">
                <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Food Name
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    placeholder="Food name"
                    value={values.food_name}
                    onChange={handleChange("food_name")}
                  />
                </div>

                <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Dish Category
                  </Typography>

                  <Select fullWidth value={values.dish_category} onChange={handleChange("dish_category")}>
                    <MenuItem value="main-dish">Main Dish</MenuItem>
                    <MenuItem value="side">Side</MenuItem>
                    <MenuItem value="extra">Extra</MenuItem>
                  </Select>
                </div>

                <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Food Content
                  </Typography>
                  <TextField
                    fullWidth
                    value={food_content}
                    placeholder="Food content..."
                    onChange={handleChange("food_content")}
                  ></TextField>
                </div>

                <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Description
                  </Typography>
                  <TextField
                    fullWidth
                    value={values.description}
                    placeholder="Food description.."
                    onChange={handleChange("description")}
                  ></TextField>
                </div>
              </div>

              {variants.length === 0 && <div className="flex justify-center">
                <button
                  className="border px-4 py-2 mt-6 text-primary shadow-md font-bold"
                  onClick={(e) => {
                    e.preventDefault()
                    setOpen(true)
                  }}
                >
                  Add food variants
                </button>
              </div>}

              {variants.length > 0 && (
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                  {variants.map((variant, index) => (
                    <div className="bg-white rounded shadow px-4 py-2" key={index}>
                      <div className="flex justify-between">
                        <p className="mb-2 font-bold">{variant.name}</p>
                        <IconButton> <EditIcon sx={{ fontSize: 18 }} onClick={() => setOpen(true)} /></IconButton>
                      </div>

                      <div className="border-l-[10px] border-blue-400 pl-2">
                        {variant.values.map((item, index) => (
                          <div key={index}>
                            <p className="text-sm">{item.value_name}: KES {item.price}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className="grid md:grid-cols-2 gap-x-6">
                {variants.length < 1 && (
                  <div>
                    <Typography color="black" sx={{ mt: 2 }}>
                      Price
                    </Typography>
                    <TextField
                      fullWidth
                      type={"number"}
                      placeholder="200"
                      value={values.price}
                      onChange={handleChange("price")}
                    ></TextField>
                  </div>
                )}

                <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Serving Hours
                  </Typography>
                  <Select fullWidth value={values.serving_hours} onChange={handleChange("serving_hours")}>
                    {periods.length > 0 &&
                      periods.map((period) => (
                        <MenuItem value={period.id}>{period.serving_type}</MenuItem>
                      ))}
                  </Select>
                </div>

                <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Tax
                  </Typography>
                  <Select
                    multiple
                    fullWidth
                    value={selectedTaxes}
                    onChange={handleTaxesChange}
                    placeholder="Choose one tax or several"
                    renderValue={(selected) => getTax(selected)}
                    MenuProps={MenuProps}
                  >
                    {taxes.length > 0 &&
                      taxes.map((tax) => (
                        <MenuItem key={tax.id} value={tax.id}>
                          <Checkbox
                            checked={selectedTaxes.indexOf(tax.id) > -1}
                          />
                          <ListItemText primary={tax.tax_type + " (" + tax.tax_rate + "%)"} />
                        </MenuItem>
                      ))}
                  </Select>
                </div>

                {values.dish_category === "main-dish" && <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Discount
                  </Typography>
                  <TextField
                    fullWidth
                    type={"number"}
                    placeholder="10"
                    value={values.discount_rate}
                    onChange={handleChange("discount_rate")}
                  ></TextField>
                </div>}

                <div>
                  <Typography color="black" sx={{ mt: 2 }}>
                    Preparation Time (minutes)
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    type={"number"}
                    value={values.preparation_time}
                    placeholder="30"
                    onChange={handleChange("preparation_time")}
                  />
                </div>
              </div>

              {values.dish_category !== "main-dish" && (
                <div className="grid md:grid-cols-3 md:gap-6 mt-6">
                  <div>
                    <Typography color="black" sx={{ mt: 2, mb: 0.5 }}>
                      Maximum Count
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      placeholder="Number of free items"
                      type="number"
                      value={values.maximum_count}
                      onChange={handleChange("maximum_count")}
                    />
                  </div>

                  <div>
                    <Typography color="black" sx={{ mt: 2 }}>
                      Food Item is Required
                    </Typography>

                    <FormControl>
                      <RadioGroup row value={radioValue} onChange={(event) => setRadioValue(event.target.value)}>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div>
                    <Typography color="black" sx={{ mt: 2 }}>
                      Visible on menu
                    </Typography>

                    <FormControl>
                      <RadioGroup row value={visible} onChange={(event) => setVisible(event.target.value)}>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              )}


            </Grid>
          </Grid>
          <MainButton onClick={handleSubmit} loading={loading} title="Add Menu Item" />
        </form>

        <VariantModal open={open} setOpen={setOpen} variants={variants} setVariants={setVariants} editData={variants.length > 0 ? variants : null} />
      </Stack>
    </Box>
  );
};

export default AddMenuItemForm;
