import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { Stack, LinearProgress } from "@mui/material";
import { useLocation } from "react-router-dom";

import AccountAndNotifications from "../common/utils/AccountAndNotifications";
import FoodDetailsView from "../../functions/FoodDetails";
import LoadingCard from "../Loading";
import AxiosPrivate from "../../functions/axios/axiosPrivate";

const FoodDetails = () => {

  const [foodDetails, setFoodDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const { pathname } = useLocation();
//   console.log(pathname.split('/'))
 
  const food_code = pathname.split('/')[9]

  const fetchFoodDetails = async () => {
    setLoading(true)
    try {
        const res = await AxiosPrivate.get(`restaurant/food-detail/${food_code}`)
        console.log("RES", res)
        setFoodDetails(res.data.data)
        setLoading(false)
    } catch (err) {
        console.log(err)
    }
  }

  useEffect(() => {
    fetchFoodDetails()
  }, []);

  return (
    <Stack>
      <div className="flex justify-between items-center">
        <p className="text-2xl font-medium text-gray-700">Food Item</p>
      
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        })}
      >
        <AccountAndNotifications />
      </Stack>
      </div>
      {loading && <LoadingCard />}
      <FoodDetailsView data={foodDetails}/>
    </Stack>
  );
};

export default FoodDetails;