import React, { useState } from 'react';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import GlobalFilter from '../common/utils/GLobalFilter';
import CreateMenuModal from '../../components/Menu/modals/CreateMenuModal';

function MenuTable({ columns, data, open, setOpen, toDelete, setToDelete, menu, setMenu }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
      pageOptions,
      gotoPage,
      pageCount,
      setPageSize,
      prepareRow,
      state,
      setGlobalFilter,
    } = useTable({columns, data}, useGlobalFilter, useSortBy, usePagination )

    const { globalFilter, pageIndex, pageSize } = state;
   
    // Render the UI for your table 
    return (
    <div className="relative overflow-x-auto sm:rounded-lg font-poppins mt-5">
        <div className="flex justify-between items-center mb-4 pt-4 lg:pt-4">
            <div className=''>
                <div className="flex items-center space-x-5">
                    <h2 className='text-4xl font-semibold pb-3 text-gray-900'>Menu Listings</h2>
                </div>
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
            <button
                className="flex items-center py-1 px-2 bg-yellow-400 text-black hover:text-gray-200 hover:bg-yellow-500 rounded mt-1"
                onClick={() => {
                    setOpen(true)
                    setMenu(null)
                    setToDelete(false)
                }}
             >
                <p className="text-sm font-medium">+ Add Menu</p>
            </button>
            <CreateMenuModal
                openCreateMenuModal={open}
                closeCreateMenuModal={() => setOpen(false)}
                toDelete={toDelete}
                menu={menu}
            />
        </div>
        <table {...getTableProps()} className="w-full text-sm text-left text-gray-600 shadow-md border border-gray-200 rounded-xl">
            {/* Head */}
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 rounded-lg">
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-gray-200">
                    {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col" className="py-3 min-w-36 px-4">
                        <div className='flex space-x-3 items-center font-semibold text-gray-800'>
                            {column.render('Header')}
                            <span>
                                {column.isSorted ?  (column.isSortedDesc ? 
                                <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path></svg> : 
                                <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z"></path></svg>
                                ) : ''}
                            </span>
                        </div>
                    </th>
                    ))}
                </tr>
            ))}
            </thead>

            {/* Body */}
            <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
                prepareRow(row)
                return (
                <tr {...row.getRowProps()} className="bg-white border-b hover:bg-gray-50">
                    {row.cells.map(cell => {
                    return <td {...cell.getCellProps()} className="px-4 py-1">{cell.render('Cell')}</td>
                    })}
                </tr>
                )
            })}
            </tbody>
        </table>

        {/* Pagination buttons */}
        <nav
            className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-0 sm:grid-cols-12"
            aria-label="Pagination"
            >
            <div className="hidden sm:flex space-x-5 items-center">
                <p className="text-sm text-gray-700">
                Showing page <span className="font-medium text-gray-900">{pageIndex + 1} </span> of <span className="font-medium text-gray-900">{pageOptions.length}</span>
                </p>
                <select className='py-0.5 text-xs outline:focus-none rounded-lg focus:ring-blue-300 focus:ring-1' value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                {
                    [10, 20, 30, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))
                }
                </select>
            </div>

            <div className="flex-1 flex justify-between sm:justify-end">
                <button
                className="relative inline-flex items-center px-4 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7"></path>
                    </svg>
                </button>

                <button
                className="ml-3 relative inline-flex items-center px-4 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                >Previous</button>

                <button
                className="ml-3 relative inline-flex items-center px-4 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => nextPage()}
                disabled={!canNextPage}
                >Next</button>

                <button
                className="ml-3 relative inline-flex items-center px-4 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path>
                    </svg>
                </button>
            </div>
        </nav>
        
    </div>
    )
  }

export default MenuTable;