import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { logoutUser } from "../../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import {
  MenuItem,
  IconButton,
  Toolbar,
  Badge,
  Typography,
  Menu,
  Avatar,
  Stack,
  Tooltip,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

// Redux
import { setAllBranches, setCurrentBranch } from "../../../features/branches/branchSlice";
import CustomSelect from './../../CustomSelect'
import SwitchBranchModal from "../../modals/SwitchBranchModal"

// Function Imports
import { fetchBranches } from "../../../functions/restaurant";
import { Notification } from "../Notifications";
import useLogout from "../../../hooks/useLogout";
import { useGetBranches } from "../../../services/branches";

const AccountAndNotifications = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [username, setUsername] = useState("");
  const [openBranch, setOpenBranch] = useState(false);
  const [selectedClubListName, setSelectedClubListName] = useState('')
  const [selectedClubListSlug, setSelectedClubListSlug] = useState('')
  const [selectedClubListIcon, setSelectedClubListIcon] = useState('../../../images/no-image.png')

  const logout = useLogout()

  const { currentBranch } = useSelector((state) => state.branch);
  const { restaurant, user, job_name, branch_id } = useSelector((state) => state.auth);
  const { first_name, last_name, user_image } = user;

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const restaurantId = restaurant?.id
  const { branches } = useGetBranches(restaurantId);

  if (!currentBranch && branches?.length > 0 && !job_name) {
    dispatch(setCurrentBranch({ currentBranch: branches[0] }));
  } else if (job_name) {
    const branch = branches?.find(branch => branch.id === branch_id);
    dispatch(setCurrentBranch({ currentBranch: branch }));
  }

  useEffect(() => {
    setUsername(first_name + " " + last_name);
  }, [first_name, last_name]);

  useEffect(() => {
    if (currentBranch?.restaurant?.restaurant_code === restaurant?.restaurant_code) {
      setSelectedClubListName(currentBranch?.branch_name)
      setSelectedClubListSlug(currentBranch?.branch_name)
      setSelectedClubListIcon(currentBranch?.restaurant_image)
    } else {
      dispatch(setCurrentBranch({ currentBranch: null }));
      setSelectedClubListName('')
      setSelectedClubListSlug('')
      setSelectedClubListIcon('')
    }
  }, [currentBranch])

  useEffect(() => {
    if (currentBranch) {
      setSelectedClubListName(currentBranch?.branch_name)
      setSelectedClubListSlug(currentBranch?.branch_name)
      setSelectedClubListIcon(currentBranch?.restaurant_image)
    }
  }, [currentBranch])

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      sx={{ mr: 4 }}
    >
      <div className={`sticky z-30 h-12 mr-4 md:mr-6 mt-1 transitionEase`}>
        <CustomSelect
          openBranch={openBranch}
          setOpenBranch={setOpenBranch}
          // selectedIcon={currentBranch?.restaurant_image}
          selectedIcon={selectedClubListIcon}
          // selectedName={currentBranch?.branch_name}
          selectedName={selectedClubListName}
          hiddenProps={'hidden lg:block'}
          tempIcon={'/images/quality.png'}
        />
      </div>

      <Notification />

      <Tooltip title="Open settings">
        <div onClick={handleOpenUserMenu} className="flex items-center gap-2 cursor-pointer">
          <Avatar sx={{ width: 36, height: 36 }} alt="Remy Sharp" src={user_image} />
          <p className="hidden md:block capitalize">
            {username}
          </p>
        </div>
      </Tooltip>

      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          onClick={() => {
            navigate("/dashboard/settings")
            handleCloseUserMenu()
          }}
        >
          <Typography textAlign="center">Profile</Typography>
        </MenuItem>

        <MenuItem onClick={() => logout()}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>

      <SwitchBranchModal
        openBranch={openBranch}
        setOpenBranch={setOpenBranch}
        list={branches}
        changeData={(branch) => {
          dispatch(setCurrentBranch({ currentBranch: branch }));
        }}
        current={currentBranch}
      />
    </Stack>
  );
};
export default AccountAndNotifications;
