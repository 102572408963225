import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Grid from "@mui/material/Unstable_Grid2";

// functions
import { createBulkRooms } from "../../../../functions/restaurant";

// cUSTOM cOMPONENTS
import CustomSnackBar from "../../../common/utils/CustomSnackBar";
import { notify } from "../../../Toast";

const RoomSettingModal = ({ openRoomSettingModal, closeRoomSettingModal, refetch }) => {
  const [values, setValues] = useState({
    place_type: "Room",
    from_no: 1,
    prefix: "",
    units: 1,
    person_per_table: 1,
    openSnackBar: false,
    snackBarMessage: "",
    snackBarSeverity: "success",
  });
  const [loading, setLoading] = useState(false);

  const { currentBranch } = useSelector((state) => state.branch);
  const branchId = currentBranch?.id

  const {
    place_type,
    from_no,
    prefix,
    units,
    person_per_table,
    openSnackBar,
    snackBarMessage,
    snackBarSeverity,
  } = values;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClose = () => {
    closeRoomSettingModal();
  };

  const closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setValues({ ...values, openSnackBar: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    if (!branchId) {
      notify('Please Select Branch', 'error')
      setLoading(false)
    } else {
      createBulkRooms(
        place_type,
        Number(from_no),
        prefix,
        Number(units),
        Number(person_per_table),
        branchId
      )
        .then((res) => {
          notify(res.data.message, 'success')
          setLoading(false)
          refetch()
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
          handleClose()
        })
        .catch((err) => {
          setLoading(false)
          notify(err.message, 'error')
          console.log("🚀 ~ file: AreaSettingModal.js:93 ~ ).then ~ err", err);
        });
    }
  };

  return (
    <Modal open={openRoomSettingModal} onClose={handleClose}>
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40vw",
          bgcolor: "background.paper",

          borderRadius: 2,
          borderColor: `primary.main`,
          elevation: 24,
          [theme.breakpoints.down("md")]: {
            width: "80vw",
          },
        })}
      >
        <Box sx={{ position: "absolute", top: 5, right: 5 }}>
          <IconButton onClick={handleClose}>
            <CloseOutlinedIcon
              sx={{ borderRadius: 25, bgcolor: "black", color: "white" }}
            />
          </IconButton>
        </Box>
        <Stack sx={{ p: 4 }}>
          <Typography variant="h4" fontWeight="500" color="black">
            Rooms Setting
          </Typography>
          <Typography sx={{ my: 2 }} variant="h6">
            Create Rooms for this Branch
          </Typography>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Grid container spacing={2}>
              {" "}
              <Grid xs={12} md={6}>
                <Typography color="black">Room Prefix</Typography>
                <TextField
                  placeholder="E.g G, FF, SF e.t.c"
                  fullWidth
                  value={values.prefix}
                  onChange={handleChange("prefix")}
                ></TextField>
              </Grid>
              <Grid xs={12} md={6}>
                <Typography color="black">Number of Rooms</Typography>
                <TextField
                  fullWidth
                  type={"number"}
                  value={values.units}
                  onChange={handleChange("units")}
                ></TextField>
              </Grid>
            </Grid>
            <Button
              type="submit"
              disabled={loading}
              onClick={(e) => handleSubmit(e)}
              disableElevation
              sx={{ mt: 4, height: 55, fontSize: "1rem", color: "white" }}
              variant="contained"
              fullWidth
            >
              {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : "Create Room"}
            </Button>
          </form>
        </Stack>
      </Box>
    </Modal>
  );
};

export default RoomSettingModal;
