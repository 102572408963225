import { useMutation, useQuery, useQueryClient } from "react-query";
import AxiosPrivate from "../functions/axios/axiosPrivate";
import { notify } from "../components/Toast";

export const useGetOrders = (branch_id) => {
  const result = useQuery(["getOrders", branch_id], () =>
    AxiosPrivate.get(`/orders/restaurant-orders/${branch_id}`).then(
      (res) => res.data.data
    )
  );

  return {
    ...result,
    orders: result.data,
  };
};

export const getOrders = async (branch_id) => {
  return await AxiosPrivate.get(`/orders/restaurant-orders/${branch_id}`)
};

export const useUpdateOrderStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (formData) =>
      AxiosPrivate({
        url: `/orders/orders-tracking-api/`,
        method: "POST",
        data: formData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getOrders");
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
};

export const useConfirmPayment = (orderId) => {
  const queryClient = useQueryClient();

  return useMutation(
    (formData) =>
      AxiosPrivate({
        url: `/orders/receive-cash-payments/${orderId}/`,
        method: "POST",
        data: formData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getOrders");
        notify("Transaction Completed Successfully", "success");
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
};
