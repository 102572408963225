import { Stack, Box, Typography } from "@mui/material";
import RoomsAndAreasNav from "./RoomsAndAreasNav";

const RoomsAndAreas = () => {
  return (
    <Stack sx={{ bgcolor: "secondary.input_field", borderRadius: 2 }}>
      <Box sx={{ p: 4 }}>
        <Typography variant="h5" sx={{ color: "black" }}>
          Rooms and Areas Setting
        </Typography>
        <Typography>Configure Rooms and Areas For This Location</Typography>
      </Box>
      <Stack>
        <Box>
          <RoomsAndAreasNav />
        </Box>
      </Stack>
    </Stack>
  );
};

export default RoomsAndAreas;