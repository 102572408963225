import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DownloadIcon from '@mui/icons-material/Download';

import QRCodeReact from "qrcode.react";
import ReactToPdf from "react-to-pdf";
import QrPDF from '../../QrPDF';
import QRCodeDocument, { QRCodeDocumentPreview } from '../../QRCodes/QRCodeDocument';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QRCodeModal({ open, setOpen, ids, room, tables, user }) {
    const ref = React.createRef();
    const options = {
        orientation: 'landscape',
        unit: 'in',
        // format: [4,2]
    };

    const handleClose = () => {
        setOpen(false);
    };

    function downloadQR(table, placeName) {
        const canvas = document.querySelector(".DagizoQRCode > canvas");
        const link = document.createElement("a");
        link.href = canvas.toDataURL();
        link.download = `${placeName} Table ${table} -QR.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div>
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', color: 'black', backgroundColor: 'white' }}>
            <Toolbar>
                <Typography sx={{ ml: 2, flex: 1, color: 'black' }} variant="h6" component="div">
                QR-Code Preview
                </Typography>

                <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                >
                <CloseIcon />
                </IconButton>
                
            </Toolbar>
            </AppBar>
                <QRCodeDocumentPreview
                    ids={ids}
                    room={room}
                    tables={tables}
                    user={user}
                />
            {/* <QrPDF /> */}
            {/* <div className='w-full' ref={ref}>
                <div className="DagizoQRCode m-10 bg-primary">
                    <QRCodeReact
                        value={`https://mobile.dagizo.io/?restaurant_code=${restaurant_id}&table_id=${table}`}
                        size={600}
                        style={{ borderRadius: '50%' }}
                    />
                </div>
            </div> */}
        </Dialog>
        </div>
    );
}