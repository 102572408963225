import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Stack, TextField, Select, Checkbox, ListItemText, MenuItem, Tabs, Tab, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from 'react-router-dom'
import CustomSnackBar from '../common/utils/CustomSnackBar'
import { useLocation } from 'react-router-dom'
import ImageUploader from "../common/utils/ImageUploader";
import { useSelector } from "react-redux";
import MainButton from "../MainButton";
import VariantModal from "../Settings/modals/VariantModal";
import { notify } from "../Toast";
import BackButton from "../../utils/BackButton";
import EditMenuItemDetails from "./FoodDetailsTab";
import DoneIcon from '@mui/icons-material/Done';
import EditMenuItemAddons from "./FoodAddonsTab";
import { getAllFoodAddons, getFoodInACategory } from "../../functions/menu";
import AxiosPrivate from "../../functions/axios/axiosPrivate";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditMenuItemForm = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [food, setFood] = useState(null)
  const [variants, setVariants] = useState([])
  const [fetchedTaxes, setFetchedTaxes] = useState(false)
  const [selectedTaxes, setSelectedTaxes] = useState(React.useMemo(() => [], []));
  const [periods, setPeriods] = useState(React.useMemo(() => [], []));
  const [taxes, setTaxes] = useState(React.useMemo(() => [], []));
  const [addons, setAddons] = useState(React.useMemo(() => [], []));
  const [images, setImages] = useState([{ url: "" }]);
  const [radioValue, setRadioValue] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const [sides, setSides] = useState([])
  const [extras, setExtras] = useState([])

  const menu_id = pathname.split('/')[3]
  const category_id = pathname.split('/')[4]
  const food_id = pathname.split('/')[5]

  const [values, setValues] = useState({
    food_name: "",
    food_image: [""],
    food_content: "",
    description: "",
    price: "0",
    tax: "",
    dish_category: "",
    discount_rate: 0,
    serving_hours: "",
    preparation_time: "",
    in_stock: true,
    discount_active: false,
    show_preparation_time: true,
    food_category_id: category_id,
    maximum_count: "",
    isRequired: radioValue,
    isVisible: visible
  });

  const [currentTab, setCurrentTab] = useState('details');
  const [addonFetched, setAddonFetched] = useState(false);

  const handleChangeTab = (event, newCurrentTab) => {
    setCurrentTab(newCurrentTab);
  };

  const { currentBranch } = useSelector((state) => state.branch);
  const branchId = currentBranch?.id

  const { restaurant: { id } } = useSelector((state) => state.auth);

  const fetchPeriods = async () => {
    const res = await AxiosPrivate.get(`/restaurant/serving-periods`)
    setPeriods(res.data.data)
  }

  const fetchTaxes = async () => {
    const res = await AxiosPrivate.get(`/restaurant/dagizo-tax-setup`)
    setTaxes(res.data.data)
    setFetchedTaxes(true)
  }

  const fetchAddons = async () => {
    const res = await getAllFoodAddons(menu_id);
    setAddons(res.data.data);
  };

  useEffect(() => {
    fetchPeriods()
    fetchTaxes()
    fetchAddons()
  }, [])

  useEffect(() => {
    if (food_id) {
      AxiosPrivate.get(`/restaurant/single-food-tem/${food_id}`)
        .then((res) => {
          const food = res.data.food;
          const currentTaxes = taxes?.filter(f => food.tax_object.some(item => item.tax.tax_code === f.tax_code));
          const taxId = currentTaxes?.map((tax) => {
            return tax.id
          })
          setSelectedTaxes(taxId)
          setFood(food)
          setValues({
            food_name: food.food_name,
            food_image: food.food_image_json,
            food_content: food.food_content,
            description: food.description,
            price: food.discount_rate > 0 ? food.original_price : food.price,
            tax: "",
            dish_category: food.dish_category,
            discount_rate: parseInt(food.discount_rate),
            serving_hours: food.serving_hours_id?.id,
            preparation_time: food.preparation_time,
            in_stock: food.is_in_stock,
            discount_active: food.discount_active,
            show_preparation_time: food.show_preparation_time,
            food_category_id: category_id,
            maximum_count: food.maximum_count,
            isRequired: food.isRequired,
            isVisible: food.isVisible
          })
          setVariants(food.variants)
          setImages(food.food_image_json)
          setVisible(food.isVisible)
          setRadioValue(food.isRequired)
          // setSelectedAddons(food.food_addons)
          // getSelectedAddons(food.food_addons)

          const list = addons.filter(i => food?.food_addons.some(item => item.addon.food_code === i.food_code))
          // console.log('list', list)
          // const filtered = list.map(i => {
          //   return i.id
          // })
          const selectedSides = list?.filter(i => i.dish_category === "side")
          const selectedExtras = list?.filter(i => i.dish_category === "extra")
          setSides(selectedSides)
          setExtras(selectedExtras)
        })
    }
  }, [food_id, fetchedTaxes, addons.length])

  const {
    food_name,
    food_image,
    food_content,
    description,
    price,
    tax,
    dish_category,
    discount_rate,
    serving_hours,
    preparation_time,
    in_stock,
    discount_active,
    show_preparation_time,
    maximum_count,
    isRequired,
    isVisible,
    food_category_id,
    openSnackBar,
    snackBarMessage,
    snackBarSeverity,
  } = values;


  const allTaxes = selectedTaxes.map((tax) => {
    return {
      id: tax
    }
  })

  const selectedAddons = [...sides, ...extras]

  const allAddons = selectedAddons?.map((addon) => {
    return {
      id: addon.id
    }
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(values, food);

    const comparePrice = food.discount_rate > 0 ? food.original_price : food.price;

    if (food_name) {
      setLoading(true)

      const filteredImages = images.filter(i => i.url !== "")
      const defaultPrice = variants[0]?.values[0]?.price

      if (filteredImages.length < 1) {
        notify("Image is required", "error")
        setLoading(false)
        return
      }

      return AxiosPrivate.post(`/restaurant/food-edit-delete/${food.id}/`,
        {
          food_name,
          food_image: filteredImages,
          food_content,
          description,
          price: variants.length > 0 ? defaultPrice : price,
          tax_id: allTaxes,
          dish_category,
          discount_rate: parseInt(discount_rate),
          serving_hours_id: serving_hours,
          preparation_time,
          is_in_stock: in_stock,
          discount_active,
          show_preparation_time,
          // food_category_id,
          branch_id: branchId,
          addon_ids: allAddons,
          variants,
          maximum_count,
          isRequired: Boolean(radioValue),
          isVisible: Boolean(visible),
          discount_isEdited: parseInt(food.discount_rate) !== parseInt(values.discount_rate),
          amount_isEdited: parseInt(values.price) !== parseInt(comparePrice)
        }
      )
        .then((res) => {
          // console.log(res)
          if (res.data.status === true) {
            notify(res.data.message, 'success')
            setLoading(false)
            navigate(-1)
          } else if (res.data.status === false) {
            setLoading(false)
            notify(res.data.message, 'error')
          }
        })
        .catch((err) => {
          setLoading(false)
          notify(err.data.message, 'error')
        });
    }
  };

  return (
    <>
      <Box>
        <div className='flex items-center space-x-5 pb-4'>
          <Typography variant="h4" sx={{ color: "black", fontWeight: 700 }}>Edit Food</Typography>
          <BackButton />
        </div>
      </Box>

      <Grid container spacing={2}>
        <Grid xs={12}>
          <Stack
            sx={{
              bgcolor: "secondary.input_field",
              borderRadius: 5,
              p: 5,
            }}
          >

            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
            >
              <Box>
                {" "}
                <Tabs value={currentTab} onChange={handleChangeTab}>
                  <Tab value={"details"} label="Food Details" />
                  {dish_category === "main-dish" && (<Tab value={"addons"} label="Addons" />)}
                </Tabs>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  disabled={loading}
                  startIcon={loading ? <CircularProgress color="inherit" size="1.2rem" /> : <DoneIcon />}
                  sx={{ mx: 2, px: 4, color: "white" }}
                  onClick={handleSubmit}
                >
                  Update
                  {/* {loading && (<CircularProgress color="inherit" size="1.5rem" />)} */}
                </Button>
              </Box>
            </Stack>

            <Stack>
              {currentTab === "details" && (
                <EditMenuItemDetails
                  values={values}
                  setValues={setValues}
                  variants={variants}
                  setVariants={setVariants}
                  selectedTaxes={selectedTaxes}
                  setSelectedTaxes={setSelectedTaxes}
                  loading={loading}
                  submit={handleSubmit}
                  images={images}
                  setImages={setImages}
                  radioValue={radioValue}
                  setRadioValue={setRadioValue}
                  visible={visible}
                  setVisible={setVisible}
                  food={food}
                />
              )}{" "}
              {currentTab === "addons" && (
                <EditMenuItemAddons
                  addons={addons}
                  sides={sides}
                  setSides={setSides}
                  extras={extras}
                  setExtras={setExtras}
                />
              )}
            </Stack>
            {/* 
                    <Box>
                        <EditMenuItemDetails />
                    </Box> */}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default EditMenuItemForm;