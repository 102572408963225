import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image,
  } from "@react-pdf/renderer";

  import logo from "../../images/Dagizo_Logo.png"
  import menu from "../../images/Restaurant_QRCode.png"

  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#F4B313",
      color: "white",
      flexDirection: 'column'
    },
    section: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
    qrImage: {
      width: "100%",
      height: "100%"
    },
  });

  
  // Create Document Component
  function QrPDF() {

    return (
      <PDFViewer style={styles.viewer}>
        {/* Start of the document*/}
        <Document>
          {/*render a single page*/}
          <Page size="A4" style={styles.page}>
            <View style={{ backgroundColor: '#F68C24', height: '15%', flexDirection: 'row' }}>
              <View style={{ width: '80%', justifyContent: 'center', alignItems: 'center' }}>
                <Image
                  style={{ width: 200 }}
                  src={logo}
                />
              </View>

              <View style={{ width: '20%', backgroundColor: '#000', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 12, marginBottom: 10 }}>TABLE</Text>
                <Text style={{ fontSize: 22 }}>01</Text>
              </View>
              
            </View>
            <View style={styles.section}>
              <View style={{ color: "#000", paddingBottom: 20, justifyContent: 'center' }}>
                <Text style={{ alignSelf: 'center', marginBottom: 5, fontSize: 18 }}>SCAN QR CODE FOR</Text>
                
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  <View style={{ borderTopColor: '#000', borderTopWidth: 1, width: 50, marginTop: 15 }}></View>
                  <Text style={{ marginHorizontal: 5, fontSize: 28 }}>MENU</Text>
                  <View style={{ borderTopColor: '#000', borderTopWidth: 1, width: 50, marginTop: 15 }}></View>
                </View>

                <Image
                  style={{ width: 300, marginTop: 20 }}
                  src={menu}
                />
                {/* <View>
                  <Image allowDangerousPaths src={dataUrl} style={styles.qrImage} />
                </View> */}
                
              </View>
              
            </View>
            <View style={{ backgroundColor: '#fff', height: '10%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', color: "#000", fontSize: 12, paddingHorizontal: 30 }}>
              <View>
                <Text style={{ marginBottom: 5 }}>dagizo57@gmail.com</Text>
                <Text>dagizo.io</Text>
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ color: '#aaa' }}>Powered by</Text>
                <Image
                  style={{ width: 100, marginLeft: 10 }}
                  src={logo}
                />
              </View>
              
            </View>
          </Page>
        </Document>
      </PDFViewer>
    );
  }
  export default QrPDF;