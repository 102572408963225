import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";

import CustomerNav from "./CustomerNav";
import { fetchCustomers } from "../../functions/customer";

import AccountAndNotifications from "../common/utils/AccountAndNotifications";
import CustomerListTable from "./CustomerListTable";

const Customer = () => {
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    loadCustomers();
  }, []);

  const loadCustomers = () =>
    fetchCustomers().then((res) => {
      console.log(res);
      setCustomers(res.data);
      console.log(customers);

      // setLoading(false);
    });
    
  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        })}
      >
        <AccountAndNotifications />
      </Stack>
      <CustomerNav />
      <CustomerListTable />
    </Stack>
  );
};

export default Customer;
