import AxiosPrivate from "./axios/axiosPrivate";

export const fetchCustomers = async (
  restaurant_image,
  restaurant_name,
  email,
  password
) => {
  return await AxiosPrivate.get("/auth/register-restaurant/", {
    restaurant_image,
    restaurant_name,
    email,
  });
};
