import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackBar = ({ openSnackBar, handleClose, horizontal, snackBarMessage, snackBarSeverity }) => {
  const vertical = "top";

  return (
    <Snackbar
      key={new Date().getTime()}
      sx={{ ml: 15 }}
      open={openSnackBar}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert onClose={handleClose} severity={snackBarSeverity} sx={{ width: "100%" }}>
        {snackBarMessage}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackBar;
