import { useQuery } from "react-query";
import AxiosPrivate from "../functions/axios/axiosPrivate";

export const useGetCardStats = (branch_id) => {
  const result = useQuery(["getCardStats", branch_id], () =>
    AxiosPrivate
      .get(`/restaurant/dashboard-report/${branch_id}`)
      .then((res) => res.data)
  );

  return {
    ...result,
    cardStats: result,
  };
};

export const useGetOrderStats = (branch_id) => {
  const result = useQuery(["getOrderStats", branch_id], () =>
    AxiosPrivate
      .get(`/orders/orders-graphical-report/${branch_id}`)
      .then((res) => res.data.data)
  );

  return {
    ...result,
    orderStats: result,
  };
};