import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IconButton, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';

import AccountAndNotifications from "../common/utils/AccountAndNotifications";
import StyledTextField from "../../styling/CustomComponents/StyledTextField";
import useLogout from "../../hooks/useLogout";
import { notify } from "../Toast";
import IddleModal from "../modals/IddleModal";
import useTimeOut from "../../hooks/useTimeOut";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#eee',
  '&:hover': {
    backgroundColor: '#eee',
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const DashboardHeader = () => {
  const [open, setOpen] = useState(false)
  const session = 120000 // 5 min
  const warning = 20000 // 30 sec
  const { user } = useSelector((state) => state?.auth);

  const logout = useTimeOut()

  const checkInactivity = () => {
    const expireTime = localStorage.getItem('expireTime')

    if (expireTime - warning < Date.now() && expireTime > Date.now()) {
      // notify("Your Session is About to expire", "error")
      setOpen(true)
    }

    if (expireTime < Date.now()) {
      logout(user.email)
      console.log("logout")
    }
  }
  const updateExpiretime = () => {
    const expireTime = Date.now() + session
    localStorage.setItem('expireTime', expireTime)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checkInactivity()
    }, warning)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    updateExpiretime()

    window.addEventListener('click', updateExpiretime)
    window.addEventListener('keypress', updateExpiretime)
    window.addEventListener('scroll', updateExpiretime)
    window.addEventListener('mousemove', updateExpiretime)

    return () => {
      window.addEventListener('click', updateExpiretime)
      window.addEventListener('keypress', updateExpiretime)
      window.addEventListener('scroll', updateExpiretime)
      window.addEventListener('mousemove', updateExpiretime)
    }
  }, [])

  return (
    <>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={(theme) => ({
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
        my: 2,
      })}
    >
      <div className="flex items-center">
        <div>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mx: 2 }}
          >
            <MenuIcon />
          </IconButton>
        </div>

        <div>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </div>

      </div>

      <AccountAndNotifications />
    </Stack>

    <IddleModal open={open} setOpen={setOpen} warning={warning} />
    </>
  );
};

export default DashboardHeader;