import React, { useEffect, useState } from "react";
import ModalLayout from "../../modals/Layout";
import { Box, Button, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Stack, Typography } from "@mui/material";

const OrderModal = ({ open, setOpen, order, onSubmit, loading }) => {
    const [value, setValue] = useState("")

    console.log('value', value)

    useEffect(() => {
        if (order) {
            if (order.order_status === "Confirmed") {
                setValue('confirm')
            } else if (order.order_status === "Ready") {
                setValue('ready')
            } else if (order.order_status === "Completed") {
                setValue('complete')
            }
        }
    }, [order])

    return (
        <ModalLayout open={open} setOpen={setOpen} title={`Track Order`}>
            <form className='' onSubmit={() => onSubmit(value)}>
                <Grid container>
                    <Grid xs={12}>
                        <div className="my-8">
                            <Typography color="black" sx={{ mt: 2 }}>
                                Status
                            </Typography>

                            <FormControl>
                            <RadioGroup row value={value} onChange={(event) => setValue(event.target.value)}>
                                <FormControlLabel
                                    value="confirm"
                                    control={<Radio />}
                                    label="Receive Order"
                                />
                                <FormControlLabel
                                    value="confirm"
                                    control={<Radio />}
                                    label="Start Preparing"
                                />
                                <FormControlLabel
                                    value="ready"
                                    control={<Radio />}
                                    label="Finish Preparing"
                                />
                                <FormControlLabel
                                    value="complete"
                                    control={<Radio />}
                                    label="Complete"
                                />
                            </RadioGroup>
                            </FormControl>
                        </div>

                    </Grid>
                </Grid>

                <Button
                    type="submit"
                    onClick={(e) => {
                        e.preventDefault()
                        onSubmit(value)
                        setValue("")
                    }}
                    disabled={loading}
                    disableElevation
                    sx={{ mt: 2, height: 55, fontSize: "1rem", color: "white" }}
                    variant="contained"
                    fullWidth
                >
                    {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : "Update Status"}
                </Button>
            </form>
        </ModalLayout>
    );
};

export default OrderModal;