import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: { user: null, restaurant: null, accessToken: null, lastUserEmail: null },
  reducers: {
    setCredentials: (state, action) => {
      const { user, restaurant, accessToken, user_permissions, job_name, branch_id } = action.payload;
      state.user = user;
      state.restaurant = restaurant;
      state.accessToken = accessToken;
      state.user_permissions = user_permissions;
      state.job_name = job_name;
      state.branch_id = branch_id;
    },
    logoutUser: (state, action) => {
      state.user = null;
      state.restaurant = null;
      state.accessToken = null;
    },
    setLastUserEmail: (state, action) => {
      state.lastUserEmail = action.payload;
    },
  },
  // extraReducers: {},
});

export const { setCredentials, logoutUser, setLastUserEmail } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentRestaurant = (state) => state.auth.restaurant;
export const selectCurrentToken = (state) => state.auth.accessToken;
