import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import ActiveHours from "../../common/utils/ActiveHours";
import PeriodMoal from "../../Settings/modals/PeriodModal";
import NoData from "../../NoData";
import AxiosPrivate from "../../../functions/axios/axiosPrivate";

const OpenHours = () => {
  const [open, setOpen] = useState(false)
  const [periods, setPeriods] = useState([])
  const [currenPeriod, setCurrenPeriod] = useState(null)
  const [edit, setEdit] = useState(false)
  const [toDelete, setToDelete] = useState(false)

  const [sunday, setSunday] = useState({
    day: "sunday",
    from_time: null,
    to_time: null,
  });

  const fetchPeriods = async () => {
    try {
        const res = await AxiosPrivate.get(`/restaurant/serving-periods/`)
        console.log("Restaurant Periods", res.data.data)
        setPeriods(res.data.data)
    } catch (err) {
        console.log(err)
    }
  }

  useEffect(() => {
    fetchPeriods()
  }, [])

  const handleOpen = () => {
    setOpen(true)
    setCurrenPeriod(false)
    setToDelete(false)
    setEdit(false)
  }
  
  return (
    <div>
      <div className="w-full mt-8">
        <div className="flex justify-between mb-8">
            {/* <Typography variant="h5" sx={{ color: "black", fontWeight: 600 }}>
                Set Serving Periods
            </Typography> */}
            <button onClick={handleOpen} className="bg-primary text-white px-4 py-1 rounded"><AddIcon /> Serving Period</button>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {periods.length > 0 ? periods.map((period) => (
            <div className="flex justify-between bg-blue-50 p-4 rounded-lg border" key={period.id}>
              <div>
                <p className="font-bold">{period.serving_type}</p>
                <p className="text-sm">{period.from_time} - {period.from_time}</p>
              </div>
              <div className="flex items-center">
                <IconButton
                  onClick={() => {
                    setOpen(true)
                    setCurrenPeriod(period)
                    setEdit(true)
                    setToDelete(false)
                  }}
                >
                  <ModeEditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setOpen(true)
                    setCurrenPeriod(period)
                    setEdit(false)
                    setToDelete(true)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          )) : (
            <NoData />
          )}
        </div>
      </div>

      <PeriodMoal
        open={open}
        setOpen={setOpen}
        period={currenPeriod}
        edit={edit}
        toDelete={toDelete}
        refrech={fetchPeriods}
      />
    </div>
  );
};

export default OpenHours;