import { Badge, IconButton } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { onValue, ref } from "firebase/database";
import { db } from "../../../firebase";
import NotificationDrawer from "./Drawer";
import notificationAudio from "../../../audio/notification.mp3";
import toast from "react-hot-toast";
import moment from "moment";
import NotificationsIcon from "@mui/icons-material/Notifications";

export const Notification = () => {
  const [open, setOpen] = useState(false);
  const [newOrders, setOrders] = useState([]);
  const [clients, setClients] = useState([]);
  const isFirstLoadRef = useRef(true);
  const prevClientsRef = useRef([]);
  const prevNewOrdersRef = useRef([]);

  const { currentBranch } = useSelector((state) => state.branch);
  const branchId = currentBranch?.id;

  const playNotificationSound = (order) => {
    const table = order?.order?.order_number?.split(':')
    notify(order?.subject, order?.message, table, order?.created)
    const audio = new Audio(notificationAudio);
    audio.play();
  };

  const getNotifications = () => {
    const dbRef = ref(db, `notifications/${branchId}`);
    return onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        const messages = [];
        snapshot.forEach((message) => {
          const data = message.val();
          if (!data.is_read) {
            messages.push(data);
          }
        });

        if (
          !isFirstLoadRef.current &&
          messages.length > prevClientsRef.current.length
        ) {
          playNotificationSound();
        }

        setClients(messages);
        prevClientsRef.current = messages;
      } else {
        setClients([]);
      }
      isFirstLoadRef.current = false;
    });
  };

  const getOrders = () => {
    const dbRef = ref(db, `orders/${branchId}`);
    return onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        const orders = [];
        snapshot.forEach((order) => {
          const data = order.val();
          orders.push(data);
        });

        if (
          !isFirstLoadRef.current &&
          orders.length > prevNewOrdersRef.current.length
        ) {
          const lastOrder = orders?.sort((a, b) => new Date(b.created) - new Date(a.created))
          const order = lastOrder && lastOrder[0]
          const table = order?.order?.order_number?.split(':')

          playNotificationSound(order);
          notify(order?.subject, order?.message, table, order?.created)
        }
        setOrders(orders);
        prevNewOrdersRef.current = orders;
      } else {
        setOrders([]);
      }

      isFirstLoadRef.current = false;
    });
  };

  useEffect(() => {
    getNotifications();
    getOrders();
  }, []);

  const notify = (title, message, table, time) => {

    toast.custom(
      (t) => (
        <div
          className={`${t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <div className="w-24 h-16 flex flex-col items-center justify-center bg-gray-800 text-white rounded-lg">
                  <span className="text-xs">{table[0]}</span>
                  <span className="text-sm font-semibold">{table[1]}</span>
                </div>
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm font-bold text-gray-900">{title}</p>
                <p className="mt-1 text-sm text-gray-500">{message}</p>
                <p className="mt-1 text-xs text-gray-400">
                  {moment(time).fromNow()}
                </p>
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.remove(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Close
            </button>
          </div>
        </div>
      ),
      {
        duration: Infinity,
        position: "top-center",
        id: table[1]
      }
    );
  };

  const client = clients?.filter((i) => i.is_read === false);
  const orders = newOrders?.filter((i) => i.is_read === false);
  const count = client?.length + orders?.length;

  return (
    <>
      <IconButton sx={{ mr: 2 }} onClick={() => setOpen(true)}>
        <Badge badgeContent={count || 0} color="error">
          <NotificationsIcon
            fontSize="large"
            sx={{ color: "primary.main" }}
            className="border border-gray-300 rounded-full p-1"
          />
        </Badge>
      </IconButton>

      <NotificationDrawer open={open} setOpen={setOpen} branchId={branchId} />
    </>
  );
};
