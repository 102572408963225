import { useMutation, useQuery, useQueryClient } from "react-query";
import AxiosPrivate from "../functions/axios/axiosPrivate";
import { notify } from "../components/Toast";

export const useGetBranches = (restaurant_id) => {
  const result = useQuery(["getBranches", restaurant_id], () =>
    AxiosPrivate
      .get(`/restaurant/all-branches-in-restaurant/${restaurant_id}/`)
      .then((res) => res.data.data)
  );

  return {
    ...result,
    branches: result.data,
  };
};

export const useCreateBranch = (restaurant_id) => {
    const queryClient = useQueryClient();
  
    return useMutation(
      (formData) =>
        AxiosPrivate({
          url: `/restaurant/branch/${restaurant_id}/`,
          method: "POST",
          data: formData,
        }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getBranches");
          notify("Branch created successfully", "success")
        },
        onError: (err) => {
            notify(err.message, "error")
        },
      }
    );
  };

export const useEditBranch = () => {
    const queryClient = useQueryClient();
  
    return useMutation(
      (formData) =>
        AxiosPrivate({
          url: `/restaurant/branch-detail/${formData.id}/`,
          method: "POST",
          data: formData,
        }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getBranches");
          notify("Branch edited successfully", "success")
        },
        onError: (err) => {
            notify(err.message, "error")
        },
      }
    );
  };