import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, IconButton, FormControlLabel, Checkbox, Button, CircularProgress } from "@mui/material";
import mpesaLogo from "../../../images/payments/mpesa.png"
import cashLogo from "../../../images/payments/cash.png"
import paypalLogo from "../../../images/payments/paypal.png"
import visaLogo from "../../../images/payments/visa.png"
import PaymentCard from "../../Cards/payment";
import { useCreatePayment, useGetPaymentsMode, useUpdatePayment } from "../../../services/payments";
import { useSelector } from "react-redux";

const Payments = () => {
  const [partial, setPartial] = useState(false)
  const [methods, setMethods] = useState([])

  const { currentBranch } = useSelector((state) => state.branch);
  const branchId = currentBranch?.id

  const { payments, isSuccess } = useGetPaymentsMode(branchId)
  const createPayment = useCreatePayment(branchId)
  const updatePayment = useUpdatePayment()

  useEffect(() => {
    if (isSuccess) {
        if (payments?.data[0]?.metadata?.length > 0) {
            const list = payments?.data[0]?.metadata?.map((pay) => {
                return {
                    ...pay,
                    logo: getLogo(pay.payment_name),
                    checked: true,
                    disabled: false
                }
            })
            setMethods(list)
        }
        if (payments?.data[0]?.payment_type === "Partial") {
            setPartial(true)
        }
    }
  }, [isSuccess, branchId])

  const getLogo = (name) => {
    switch (name) {
        case "mpesa" :
            return mpesaLogo
        case "visa" :
            return visaLogo
        case "paypal" :
            return paypalLogo
        default :
            return cashLogo
    }
  }

  const addPayment = (object) => {
    let list = [...methods]
    list.push({
        ...object,
        checked: true,
        disabled: false
    })
    setMethods(list)
  }

  const removePayment = (index) => {
    let list = [...methods]
    list.splice(index, 1)
    setMethods(list)
  }

  const formattedPayments = methods.map((method) => {
    return {
        payment_name: method.payment_name,
        payment_description: ""
    }
  })

  const formattedData = {
    payment_type: methods.length < 2 ? "Full" : partial ? "Partial" : "Full",
    metadata: formattedPayments
  }

  const handleCreate = () => {
    if (methods.length < 2) {
        setPartial(false)
    }

    createPayment.mutate(formattedData)
  }

  const handleUpdate = () => {
    if (methods.length < 2) {
        setPartial(false)
    }

    updatePayment.mutate({
        ...formattedData,
        id: payments?.data[0].id
    })
  }

  return (
    <div className="w-full md:w-3/4 xl:w-1/2 flex flex-col gap-5 mt-10 mb-10">
        {methods.length > 0 && methods.map((pay, index) =>  (
            <PaymentCard name={pay.payment_name} onChange={() => removePayment(index)} logo={pay.logo} checked={pay.checked} disabled={false} />
        ))}

        {providers.filter((pro) => !methods.some((method) => pro.payment_name === method.payment_name))
        .map((pay) =>  (
            <PaymentCard name={pay.payment_name} onChange={() => addPayment(pay)} logo={pay.logo} checked={pay.checked} disabled={pay.disabled} />
        ))}
        {methods.length > 1 && (
            <FormControlLabel
                sx={{ pl: 3 }}
                control={<Checkbox value={partial} defaultChecked={partial} onChange={() => setPartial(!partial)} />}
                label="Enable Partial Payments"
            />
        )}
        
        <Button
            disabled={(methods.length === 0 || createPayment.isLoading || updatePayment.isLoading)}
            onClick={payments?.data[0]?.id ? handleUpdate : handleCreate}
            disableElevation
            sx={{ mt: 2, height: 55, fontSize: "1rem", color: "white" }}
            variant="contained"
            fullWidth
        >
            {(createPayment.isLoading || updatePayment.isLoading) ? (<CircularProgress color="inherit" size="1.5rem" />) : payments?.data[0]?.id ? "Update" : "Save"}
        </Button>
    </div>
  );
};

export default Payments;

const providers = [
    {
        payment_name: "mpesa",
        logo: mpesaLogo,
        checked: false,
        disabled: false
    },
    {
        payment_name: "cash",
        logo: cashLogo,
        checked: false,
        disabled: false
    },
    {
        payment_name: "visa",
        logo: visaLogo,
        checked: false,
        disabled: false
    },
    {
        payment_name: "paypal",
        logo: paypalLogo,
        checked: false,
        disabled: false
    },
]