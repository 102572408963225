import React, { useEffect } from 'react'
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import * as animationData from '../data/verification.json';
import { motion } from "framer-motion";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

function WaitActivation() {
    const navigate = useNavigate();

    return (
        <div className='h-screen flex flex-col justify-center items-center transition px-4'>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}
                className='flex flex-col items-center'
            >
                <h1 className='text-2xl md:text-4xl font-bold mb-2'>Account Not Active</h1>
                <p className='text-gray-600 text-sm font-light text-center'>Please wait for the verification to complete or contact the Admin for more information</p>
            </motion.div>
            
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
            >
                <Lottie options={defaultOptions}
                    height={200}
                    width={300}
                />
            </motion.div>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.8 }}
            >
                <button onClick={() => navigate('/signin')} className='flex gap-2 bg-primary px-6 py-2 rounded-full text-gray-600'>
                    <ArrowRightAltIcon />
                    Back to Sign In
                </button>
            </motion.div>
            
        </div>
    )
}

export default WaitActivation