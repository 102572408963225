import React from "react";
import ModalLayout from "../modals/Layout";
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TelegramIcon,
    TumblrShareButton,
    TwitterShareButton,
    TwitterIcon,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    WorkplaceShareButton
  } from "react-share";
  import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import { notify } from "../Toast";

const ShareMenuModal = ({ open, setOpen, url }) => {
    const copyText = (text) => {
        navigator.clipboard.writeText(text)
        notify("Menu copied to clipboard", "success")
    }

    return (
        <ModalLayout open={open} setOpen={setOpen} title={`Share Menu`}>
            <div className="mt-4">
                <p>Share Menu on social media using the bellow icons</p>
                <div className="flex flex-wrap gap-4 mt-2 mb-6">
                    <WhatsappShareButton
                        url={url}
                        quote={'Dagizo'}
                        hashtag="#dagizo"
                    >
                        <WhatsappIcon size={40} round />
                    </WhatsappShareButton>

                    <FacebookShareButton
                        url={url}
                        quote={'Dagizo'}
                        hashtag="#dagizo"
                    >
                        <FacebookIcon size={40} round />
                    </FacebookShareButton>

                    <TwitterShareButton
                        url={url}
                        quote={'Dagizo'}
                        hashtag="#dagizo"
                    >
                        <TwitterIcon size={40} round />
                    </TwitterShareButton>

                    <TelegramShareButton
                        url={url}
                        quote={'Dagizo'}
                        hashtag="#dagizo"
                    >
                        <TelegramIcon size={40} round />
                    </TelegramShareButton>

                    <LinkedinShareButton
                        url={url}
                        quote={'Dagizo'}
                        hashtag="#dagizo"
                    >
                        <LinkedinIcon size={40} round />
                    </LinkedinShareButton>

                    <EmailShareButton
                        url={url}
                        quote={'Dagizo'}
                        hashtag="#dagizo"
                    >
                        <EmailIcon size={40} round />
                    </EmailShareButton>
                </div>
                <p>Or copy URL</p>
                <div className="flex justify-between gap-2 items-center bg-gray-200 rounded px-1 py-1 border border-gray-300 mt-4">
                <div className="flex items-center gap-2 pl-1">
                    <LinkIcon sx={{ fontSize: 20 }} />
                    <p className="md:hidden">{url.substring(0, 10) + '...'}</p>
                    <p className="hidden md:block">{url.substring(0, 30) + '...'}</p>
                </div>
                
                <button onClick={() => copyText(url)} className="flex items-center gap-2 bg-gray-800 text-gray-200 px-4 py-1 rounded"><ContentCopyIcon sx={{ fontSize: 16 }} /> copy</button>
                
                </div>
            </div>
        </ModalLayout>
    );
};

export default ShareMenuModal;