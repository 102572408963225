import { Routes, Route } from "react-router-dom";

import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import LocalDiningRoundedIcon from "@mui/icons-material/LocalDiningRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import QrCodeScannerRoundedIcon from "@mui/icons-material/QrCodeScannerRounded";
import ChairIcon from "@mui/icons-material/Chair";
import FastfoodIcon from "@mui/icons-material/Fastfood";

// Auth
import LandingPage from "../pages/LandingPage";
import Signin from "../pages/auth/Signin";
import Signup from "../pages/auth/Signup";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";

// HOMEPAGE
import HomePage from "../pages/HomePage";

// Dashboard
import Dashboard from "../components/Dashboard";

// Restaurant
import Restaurant from "../components/Restaurant";
import CreateBranch from "../components/Restaurant/CreateBranch";
import BranchProfile from "../components/Restaurant/BranchProfile";
// import OpenHours from "../components/Restaurant/OpenHours";
import RoomsAndAreas from "../components/Restaurant/RoomsAndAreas/RoomsAndAreas";
import NoRooms from "../components/Restaurant/RoomsAndAreas/NoRooms";
import NoAreas from "../components/Restaurant/RoomsAndAreas/NoAreas";

import RoomSettingTable from "../components/Restaurant/DataGrids/data/RoomSettingTable";

import Orders from "../components/Orders";
import Categories from "../components/Categories";
import Feedback from "../components/Feedback";
import Messages from "../components/Messages";
import Customer from "../components/Customer";
import QRCodes from "../components/QRCodes";
import Reports from "../components/Reports";
import Settings from "../components/Settings";

// Menu
import Menu from "../components/Menu";
import NoMenu from "../components/Menu/NoMenu";
import CSVFileUpload from "../components/Menu/CSVFileUpload";
import WithMenu from "../components/Menu/WithMenu";
import MenuCategories from "../components/menuCategories/MenuCategories";
import MenuFood from "../components/Menu/MenuFood";
import AddFood from "../components/Menu/AddFood";
import FoodDetails from "../components/Menu/FoodDetails";

// QR Codes
import CreateQrCode from "../components/QRCodes/CreateQrCode";
import NoQrCodes from "../components/QRCodes/NoQrCodes";
import NoCategories from "../components/Categories/NoCategories";

import { ProtectedRoute } from "../helpers/ProtectedRoute";
import AddEmployeeForm from "../components/Settings/forms/AddEmployeeForm";
import AdminProfileForm from "../components/Settings/forms/AdminProfileForm";
import Employee from "../components/Settings/Emplooyee";
import RoomsAndAreasNav from "../components/Restaurant/RoomsAndAreas/RoomsAndAreasNav";
import QrPDF from "../components/QrPDF";
import FetchAddOns from "../functions/FetchAddOns";
// import Taxes from "../components/Settings/Taxes";
import EditMenuItemForm from "../components/Menu/EditFood";
import RestaurantProfileForm from "../components/Settings/forms/RestaurantProfile";
import JobPositions from "../components/Settings/JobPositions";
import Welcome from "../pages/Welcome";
import WaitActivation from "../pages/WaitActivation";
import NoPermissions from "../pages/permissions";
import GeneralSettings from "../components/Settings/General";
import ConfirmEmail from "../pages/auth/ConfirmEmail";

export const publicRoutes = [
  {
    path: "/",
    name: "Landing",
    component: Signin,
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/activation",
    name: "Activation",
    component: WaitActivation,
  },
  {
    path: "/permissions",
    name: "Permissions",
    component: NoPermissions,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/verify/:ID",
    name: "VerifyEmail",
    component: ConfirmEmail
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
  },
];

export const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    show: true,
    icon: GridViewRoundedIcon,
    routeCode: 1001,
  },

  // Restaurant routes
  {
    path: "/dashboard/branches",
    name: "Branches",
    component: Restaurant,
    show: true,
    icon: LocalDiningRoundedIcon,
    routeCode: 1002,
  },
  {
    path: "/dashboard/restaurant/profile",
    name: "Profile",
    component: BranchProfile,
    show: false,
  },
  // {
  //     path: '/dashboard/restaurant/open-hours',
  //     name: 'OpenHours',
  //     component: OpenHours,
  //     show: false,
  // },
  {
    path: "/dashboard/restaurant/rooms-and-areas",
    name: "RoomsAndAreas",
    component: RoomsAndAreas,
    show: false,
  },
  {
    path: "/dashboard/restaurant/csv-upload",
    name: "CSVFileUpload",
    component: CSVFileUpload,
    show: false,
  },

  // Rooms / Areas
  {
    path: "/dashboard/rooms-and-areas",
    name: "Rooms / Areas",
    component: RoomsAndAreas,
    show: true,
    icon: ChairIcon,
    routeCode: 1003,
  },

  // Menu routes
  {
    path: "/dashboard/menu",
    name: "Menu",
    component: Menu,
    show: true,
    icon: MenuBookRoundedIcon,
    routeCode: 1004,
  },
  {
    path: "/dashboard/menu/csv-upload",
    name: "CSVFileUpload",
    component: CSVFileUpload,
    show: false,
  },
  {
    path: "/dashboard/menu/with-menu",
    name: "WithMenu",
    component: WithMenu,
    show: false,
  },
  {
    path: "/dashboard/menu/:id",
    name: "MenuCategories",
    component: MenuCategories,
    show: false,
  },
  {
    path: "/dashboard/menu/:id/:category",
    name: "FoodAddonTab",
    // component: FoodAddonTab,
    component: MenuFood,
    show: false,
  },
  {
    path: "/dashboard/menu/category/:id/add_food",
    name: "AddFood",
    component: AddFood,
    show: false,
  },
  {
    path: "/dashboard/menu/:menu_id/:id/:food_id",
    name: "AddFood",
    component: EditMenuItemForm,
    show: false,
  },
  {
    path: "/dashboard/menu/:menu_id/category/:category_id/restaurant/:restaurant_code/food/:food_code",
    name: "FoodDetails",
    component: FoodDetails,
    show: false,
  },

  // Addons
  // {
  //     path: '/dashboard/addons',
  //     name: 'Addons',
  //     component: FetchAddOns,
  //     show: true,
  //     icon: FastfoodIcon
  // },

  // Order routes
  {
    path: "/dashboard/orders",
    name: "Orders",
    component: Orders,
    show: true,
    icon: AssignmentRoundedIcon,
    routeCode: 1005,
  },
  {
    path: "/dashboard/feedback",
    name: "Feedback",
    component: Feedback,
    show: false,
  },
  {
    path: "/dashboard/messages",
    name: "Messages",
    component: Messages,
    show: false,
  },
  {
    path: "/dashboard/customer",
    name: "Customer",
    component: Customer,
    show: false,
  },
  {
    path: "/dashboard/qr-codes",
    name: "QR Codes",
    component: CreateQrCode,
    show: true,
    icon: QrCodeScannerRoundedIcon,
    routeCode: 1006,
  },
  {
    path: "/dashboard/qr-codes/pdf",
    name: "QR Codes",
    component: QrPDF,
    show: false,
    icon: QrCodeScannerRoundedIcon,
  },

  // Settings routes
  {
    path: "/dashboard/settings",
    name: "Settings",
    component: Settings,
    show: true,
    group: true,
    icon: SettingsRoundedIcon,
    routeCode: 1007,
    sublinks: [
      {
        path: "/dashboard/settings",
        name: "General",
        component: GeneralSettings,
      },
      {
        path: "/dashboard/settings/profile",
        name: "Profile",
        component: AdminProfileForm,
      },
      {
        path: "/dashboard/settings/roles",
        name: "Roles",
        component: JobPositions,
      },
      {
        path: "/dashboard/settings/employees",
        name: "Employees",
        component: RestaurantProfileForm,
      },
      {
        path: "/dashboard/settings/organisation",
        name: "Organisation",
        component: RestaurantProfileForm,
      },
      // {
      //     path: '/dashboard/settings/taxes',
      //     name: 'Taxes',
      //     component: Taxes,
      // },
      // {
      //     path: '/dashboard/settings/open-hours',
      //     name: 'Serving Hours',
      //     component: OpenHours,
      // },
      // {
      //     path: '/dashboard/settings/taxes',
      //     name: 'Addons',
      //     component: AdminProfileForm,
      // },
    ],
  },
];
