import { Divider, IconButton, Tooltip, Drawer, useTheme, styled } from "@mui/material";
import { useState, useEffect } from "react";
import { getSectionRooms } from "../../../functions/restaurant";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import icon from '../../../images/Room_Icon.png'
import DeleteIcon from '@mui/icons-material/Delete';

const DrawerHeader = styled('div')(({ theme }) => ({
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const RoomsDrawer = ({ onClose, open, roomID, section, serviceFees }) => {
    const theme = useTheme();
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleGetData = () => {
        if (roomID) {
            setLoading(true);
            getSectionRooms(roomID).then(res => {
                const { data: { data } } = res;
                setRooms(data);
                setLoading(false);
            })
        }
    };

    const container = window !== undefined ? () => window.document.body : undefined;

    useEffect(() => handleGetData(), [roomID]);

    return (
        <Drawer
            container={container}
            anchor="right"
            variant="temporary"
            open={open}
            onClose={onClose}
            sx={{
                zIndex: 9991,
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    borderWidth: 0,
                    width: { xs: 350, md: 500 },
                    backgroundColor: '#FAFAFA',
                },
            }}
        >
            <DrawerHeader>
                <IconButton onClick={onClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />

            <div className="px-8">
                {loading ? (
                    <div className='mt-6'>
                        <div className='h-5 w-3/5 bg-gray-200 animate-pulse rounded'></div>
                        <div className='h-3 w-2/5 bg-gray-200 animate-pulse rounded mt-4'></div>
                    </div>
                ) : (
                    <div className="mt-4">
                        <h1 className='font-bold text-xl mt-4'>{section} || <span className="font-semibold text-sm">{rooms.length} rooms</span></h1>
                    </div>
                )}

                <div className="mt-6">
                    {loading ?
                        Array(5).fill(0).map((el, __) => (
                            <div key={el} className='w-full flex justify-between items-center my-4 p-4 shadow bg-white'>
                                <div className='w-4/6 flex items-center gap-3'>
                                    <div className='h-10 w-10 bg-gray-200 animate-pulse rounded'></div>
                                    <div className='w-5/6 flex flex-col gap-2'>
                                        <div className='h-4 w-3/5 bg-gray-200 animate-pulse rounded'></div>
                                        <div className='h-3 w-2/5 bg-gray-200 animate-pulse rounded'></div>
                                    </div>
                                </div>

                                <div className='w-2/6 flex justify-end gap-3'>
                                    <div className='h-6 w-6 bg-gray-200 animate-pulse rounded-full'></div>
                                    <div className='h-6 w-6 bg-gray-200 animate-pulse rounded-full'></div>
                                </div>
                            </div>
                        ))

                        :

                        <div className="space-y-4">
                            {rooms.toReversed().map((room, __) => (
                                <div key={room.id} className="flex items-center justify-between gap-2 bg-white p-4 shadow">
                                    <div className="flex items-center gap-2">
                                        <img className='h-10' src={icon} alt="" />
                                        <div>
                                            <p>{room?.room_name}</p>
                                            <p className="text-xs font-semibold">Fees: Ksh {serviceFees}.00</p>
                                        </div>
                                    </div>
                                    <Tooltip title="Delete section room">
                                        <IconButton>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>
        </Drawer>
    )
}

export default RoomsDrawer