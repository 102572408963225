import React, { useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import {
  DataGrid,
  // GridToolbar,
} from "@mui/x-data-grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

// Components
import CustomNoRowsOverlay from "./NoRowsOverlay";

export function SortedDescendingIcon() {
  return <ArrowDropDownIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ArrowDropUpIcon className="icon" />;
}

const CustomDataGrid = ({ rows, columns, title }) => {
  const [pageSize, setPageSize] = useState(10);

  return (
    <div className="w-full border rounded px-6 py-4">
      {!title && (
        <h2 className="font-bold text-2xl mb-6">Most ordered foods</h2>
      )}
        
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
          },
        }}

        getRowId={(row) => row.food_code || row.restaurant_code || row.order_id || row.id }
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        onPageSizeChange={(newValue) => setPageSize(newValue)}
        components={{
          ColumnSortedDescendingIcon: SortedDescendingIcon,
          ColumnSortedAscendingIcon: SortedAscendingIcon,
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: CustomNoRowsOverlay,
          //   Toolbar: GridToolbarQuickFilter,
          // Toolbar: GridToolbar,
          // Pagination: CustomPagination,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
};
export default CustomDataGrid;
