import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";

const PaymentCard = ({ name, onChange, logo, checked, disabled }) => {

  return (
    <>
          <FormControlLabel
            sx={checked ? {border: '2px solid #332A7A', width: '100%', p:1.5, borderRadius: 2, position: 'relative'} : {border: '1px solid #ddd', width: '100%', p:1.5, borderRadius: 2, position: 'relative' }}
            control={<Checkbox checked={checked} onChange={() => onChange()} sx={{mr:1}} />}
            label={
            <div className="absolute left-16 right-4 top-2 flex justify-between">
                <div>
                    <p className={`${checked ? 'text-[#332A7A] font-bold' : 'text-gray-600'} capitalize`}>
                        {name}
                    </p>
                    <p className="text-gray-400 text-sm">
                        Enable {name} payments
                    </p>
                </div>
                <img src={logo} width="70" alt="logo" />
            </div>
            }
            disabled={disabled}
          />
    </>
  );
};

export default PaymentCard;