import React from 'react';
import {Outlet} from "react-router-dom"
import {Stack, Box, Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2";
import AccountAndNotifications from '../common/utils/AccountAndNotifications';
import SettingsNav from './nav/SettingsNav';



const Settings = () => {
    return (
      <>
        {" "}
        <Box><Typography variant="h4" sx={{color: "black", fontWeight: 700, mt: 4 }}>Settings</Typography></Box>
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid xs={2}><SettingsNav/></Grid>
          <Grid xs={9}><Outlet/></Grid>
          <Grid xs={1}></Grid>
        </Grid>
      </>
    );
}

export default Settings;