import AxiosPrivate from "../functions/axios/axiosPrivate";

export const pesaPalSetup = async (branch_id, data) => {
    return await AxiosPrivate.post(
        `/pesapal/pesapal-onboard-restaurant-branch-todagizo/${branch_id}`, data
    );
};

export const getPesaPalSetup = async (branch_id) => {
    return await AxiosPrivate.get(
        `/pesapal/pesapal-onboard-restaurant-branch-todagizo/${branch_id}`
    );
};