import React from "react";
import { Box, Button, Typography, Stack, TextField, Select, Checkbox, ListItemText, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import MultiSelect from "../MultiSelect";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditMenuItemAddons = ({ addons, sides, setSides, extras, setExtras }) => {

  return (
    <Box
      sx={(theme) => ({
        bgcolor: "secondary.input_field",
      })}
    >
        <Stack>
            <Typography sx={{}} variant="h6"></Typography>
            <form>
            <Grid container spacing={2}>
                {" "}
                <Grid xs={12}>
                    <Typography color="black" sx={{ my: 2, fontWeight: 'bold', fontSize: 18 }}>
                        Select Addons
                    </Typography>

                    <div className="flex flex-col gap-4">
                      <MultiSelect addons={addons.filter(i => i.dish_category === "side")} selectedAddons={sides} setSelectedAddons={setSides} title="Sides" />
                      <MultiSelect addons={addons.filter(i => i.dish_category === "extra")} selectedAddons={extras} setSelectedAddons={setExtras} title="Extras" />
                    </div>
                    
                </Grid>
            </Grid>
            </form>
        </Stack>
    </Box>
  );
};

export default EditMenuItemAddons;