import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from "framer-motion";
import { Button } from '@mui/material';
import LoadingCard from '../components/Loading';
import Lottie from 'react-lottie';
import * as animationData from '../data/payment.json';
import queryString from 'query-string';
import axios from '../functions/axios/axios';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

function PesaPalConfirm() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const trackingID = queryParams.OrderTrackingId;

    const BASE_URL = process.env.REACT_APP_API;
    const handleFetchData = async () => {
        setLoading(true);
        try {
            const res = await axios.get(BASE_URL + `/pesapal/pesapal-pull-transaction-status/?orderTrackingId=${trackingID}`)
            setData(res.data);
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        handleFetchData()
    }, []);

    return (
        <>
            {!loading &&
                <div className='h-screen flex justify-center items-center transition'>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, delay: 0.8 }}
                    >
                        <div className='bg-white shadow-xl rounded-xl px-4 pb-4'>
                            <Lottie options={defaultOptions}
                                height={150}
                                width={150}
                            />
                            <h2 className='font-semibold text-xl text-center'>Successful Transaction!</h2>
                            <div className='p-4'>
                                {[
                                    { name: "Date", value: new Date(data?.created).toLocaleString("en-US", { day: "2-digit", month: "short", year: "2-digit" }) },
                                    { name: "Amount", value: data?.currency + " " + data?.amount },
                                    { name: "Payment Method", value: data?.payment_method },
                                    { name: "Payment Account", value: data?.payment_account },
                                    { name: "Confirmation Code", value: data?.confirmation_code },
                                    { name: "Description", value: data?.description }
                                ].map((el, __) => (
                                    <div className='grid grid-cols-2'>
                                        <h2 className='border-b border-dashed py-1'>{el.name}</h2>
                                        <p className='border-b border-dashed py-1 pl-2 text-sm font-semibold'>: {" "}{el.value || "--"}</p>
                                    </div>
                                ))}
                            </div>
                            <Button
                                variant="contained"
                                sx={{ width: "100%" }}
                                onClick={() => window.history.back()}
                            >
                                Done
                            </Button>
                        </div>
                    </motion.div >
                </div >}

            {loading && <LoadingCard />}
        </>
    )
}

export default PesaPalConfirm