import React, { useState } from "react";
import { Modal, Box, Stack, IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import AddEmployeeForm from "../forms/AddEmployeeForm";

const AddEmployeeModal = ({
  openAddEmployeeModal,
  closeAddEmployeeModal,
  refreshEmployees,
  editData = null,
}) => {
  const [values, setValues] = useState({
    roomName: "",
    peoplePerTable: "",
    maxTables: 10,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClose = () => {
    closeAddEmployeeModal();
  };

  return (
    <Modal open={openAddEmployeeModal} onClose={handleClose}>
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40vw",
          bgcolor: "background.paper",
          borderRadius: 2,
          borderColor: `primary.main`,
          elevation: 24,
          [theme.breakpoints.down("md")]: {
            width: "80vw",
          },
        })}
      >
        <Box sx={{ position: "absolute", top: 5, right: 5 }}>
          <IconButton onClick={handleClose}>
            <CloseOutlinedIcon
              sx={{ borderRadius: 25, bgcolor: "black", color: "white" }}
            />
          </IconButton>
        </Box>
        <Stack>
          <AddEmployeeForm
            refreshEmployees={refreshEmployees}
            handleClose={closeAddEmployeeModal}
            editData={editData}
          />
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddEmployeeModal;
