import React, { useState } from 'react'
import { Modal, Box, IconButton, Stack, Typography, Divider } from '@mui/material';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FavoriteIcon from '../../svg/FavoriteIcon'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FavoriteActiveIcon from '../../svg/FavoriteActiveIcon'

const SwitchBranchModal = ({
  openBranch,
  setOpenBranch,
  list,
  changeData,
  current
}) => {
  const [search, setSearch] = useState('')
  const [likes, setLikes] = useState([])

  const filtered = list?.filter((entry) => entry.branch_name?.toLowerCase().includes(search))

  const addFavorite = (data) => {
    const likeExist = likes.filter((like) => like.branch_name === data.branch_name)
    if (likeExist.length > 0) {
      const newData = likes.filter((like) => like.branch_name !== data.branch_name)
      setLikes(newData)
    } else {
      setLikes([...likes, data])
    }
  }

  return (
    <Modal
      open={openBranch}
      onClose={() => setOpenBranch(!openBranch)}
    >
      <Stack
        sx={(theme) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40vw",
          bgcolor: "#f2f2f2",
          borderRadius: 2,
          borderColor: `primary.main`,
          elevation: 24,
          [theme.breakpoints.down("md")]: {
            width: "80vw",
          },
          [theme.breakpoints.up("lg")]: {
            width: "30vw",
          },
        })}
      >
        <Box sx={{ position: "absolute", top: 5, right: 5 }}>
          <IconButton onClick={() => setOpenBranch(!openBranch)}>
            <CloseOutlinedIcon
              sx={{ borderRadius: 25, bgcolor: "black", color: "white" }}
            />
          </IconButton>
        </Box>

        <Stack sx={{ p: 4, my: 2 }}>
          {" "}
          <Typography variant="h5" fontWeight="500" color="black">
            Switch Branch
          </Typography>

          <div>
            <div className="relative w-full my-6">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
              </div>
              <input
                type="text"
                id="voice-search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="bg-white shadow text-gray-900 text-sm focus:outline-none block w-full pl-10 p-2.5"
                placeholder="Search Branches..."
              />
              <button type="button" className="flex absolute inset-y-0 right-0 items-center pr-3">
                  <svg aria-hidden="true" className="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z" clip-rule="evenodd"></path></svg>
              </button>
            </div>

            {/* {likes.length > 0 && (
              <div className='flex flex-wrap gap-2 mb-4'>
                {likes.map((data, index) => (
                  <div
                    key={index}
                    onClick={() => (
                      changeData(data),
                      setOpenBranch(!openBranch)
                    )}
                    className='border border-gray-200 flex flex-col gap-2 items-center p-2 cursor-pointer rounded-lg'
                  >
                    {data.restaurant_image && <img alt='' src={data.restaurant_image} width={24} height={24} />}
                    <p className='text-sm'>{data.branch_name}</p>
                  </div>
                ))}
              </div>
            )} */}

            <div className='overflow-scroll flex flex-col gap-4 max-h-64'>
              {filtered && filtered.length > 0 ? (
                filtered.map((data, index) => (
                  <div key={index}>
                    <div 
                      onClick={() => (
                        changeData(data),
                        setOpenBranch(!openBranch)
                      )}
                      className='flex bg-white shadow-md border-l-[8px] border-[#F4B313] items-center justify-between px-4 py-3 cursor-pointer rounded'
                    >
                      <div className='flex items-center'>
                        {data?.restaurant_image && (
                          <img alt='' src={data?.restaurant_image} className="rounded-full object-cover" width={42} height={42} />
                        )}
                        <p className='text-black ml-4 font-semibold'>{data?.branch_name}</p>
                      </div>

                      <div className='flex items-center gap-2'>
                        {data?.id === current?.id && (
                          <span className='bg-sky-100 text-sky-600 text-sm px-2 py-1 rounded'>current</span>
                        )}
                        <ArrowForwardIosIcon sx={{ fontSize: 16, color: 'black' }} />
                      </div>
{/* 
                      <div onClick={() => addFavorite(data)} className='cursor-pointer'>
                        {likes.filter((like) => like.branch_name === data.branch_name)?.length > 0 ? (
                          <FavoriteActiveIcon width='24' height='24' />
                        ) : (
                          <FavoriteIcon fill='#5555' width='24' height='24' />
                        )}
                      </div> */}
                    </div>
                  </div>
                ))
              ) : (
                <div className='flex justify-center mt-10'>
                  <h1>No Branch Found</h1>
                </div>
              )}
            </div>
          </div>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default SwitchBranchModal
