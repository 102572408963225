import AxiosPrivate from "./axios/axiosPrivate";

export const getTables = async ( location_id ) => {
  return await AxiosPrivate.get(`/restaurant/bulk-create-table/${location_id}/`);
};

export const createBulkTabless = async (
  location_id,
  from_no,
  units,
  branchId
) => {
  return await AxiosPrivate.post(`/restaurant/bulk-create-table/${branchId}/`, {
    location_id,
    from_no,
    units,
  }
  );
};


export const getRoomTables = async (table_id) => {
  return await AxiosPrivate.get(
    `/restaurant/bulk-create-table/${table_id}/`
  );
};

export const editTable = async (table_name, id, table_number) => {
  return await AxiosPrivate.post(
    `/restaurant/table-edit-delete/${id}/`, {
      table_name, table_number
    }
  );
};

export const deleteTable = async (id) => {
  return await AxiosPrivate.delete(
    `/restaurant/table-edit-delete/${id}/`
  );
};