import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { Box } from "@mui/material";

// Functions
import {
  fetchRestaurantOrdersPerMenu,
  fetchBranchOrdersPerMenu,
} from "../../../functions/dashboard";

const OrdersOverviewPerMenu = () => {
  const [menus, setMenus] = useState([]);
  const [orderTotals, setOrderTotals] = useState([]);
  const allBranches = useSelector((state) => state?.branch?.allBranches);

  const branch_id = useSelector(
    (state) => state?.branch?.allBranches.restaurant_code
  );

  const restaurant_code = useSelector((state) => state?.auth?.restaurant?.id);

  const getRestaurantOrdersOverview = async () => {
    const res = await fetchRestaurantOrdersPerMenu(restaurant_code);
    const orders_per_menu =
      res.data.data.orders_overview.number_of_orders_per_menu;

    let orders_overview = [];
    for (let i = 0; i < orders_per_menu.length; i++) {
      orders_overview.push(Object.values(orders_per_menu[i]));
    }
    const menu_names = [];
    const menu_numbers = [];

    for (let i = 0; i < orders_overview.length; i++) {
      menu_names.push(orders_overview[i][0]);
      menu_numbers.push(orders_overview[i][1]);
    }

    setMenus(menu_names);
    setOrderTotals(menu_numbers);
  };

  const getBranchOrdersOverview = async () => {
    if (branch_id !== undefined) {
      const res = await fetchBranchOrdersPerMenu(branch_id);
      const orders_per_menu =
        res.data.data.orders_overview.number_of_orders_per_menu;

      let orders_overview = [];
      for (let i = 0; i < orders_per_menu.length; i++) {
        orders_overview.push(Object.values(orders_per_menu[i]));
      }
      const menu_names = [];
      const menu_numbers = [];

      for (let i = 0; i < orders_overview.length; i++) {
        menu_names.push(orders_overview[i][0]);
        menu_numbers.push(orders_overview[i][1]);
      }

      setMenus(menu_names);
      // console.log(
      //   "🚀 ~ file: OrdersOverviewPerMenu.js:65 ~ getBranchOrdersOverview ~ menu_names",
      //   menu_names
      // );
      setOrderTotals(menu_numbers);
    }
  };

  useEffect(() => {
    if (allBranches.length > 0) {
      getRestaurantOrdersOverview();
    } else {
      getBranchOrdersOverview();
    }
  }, [restaurant_code, allBranches]);

  return (
    <Box sx={{ mt: 3 }}>
      <Chart
        type="pie"
        width="100%"
        // DATA HERE
        series={orderTotals}
        options={{
          labels: menus,
          colors: ["#F4B315", "#F93121", "#000"],
          dataLabels: {
            enabled: false,
          },
          title: {
            text: "Orders Overview",
            style: { fontSize: 20, fontFamily: "Mulish", fontWeight: 700},
          },
          noData: {
            text: "No Orders Data",
            align: "center",
            verticalAlign: "middle",
            style: {
              color: undefined,
              fontSize: "25px",
              fontFamily: "Mulish",
            },
          },

          plotOptions: {
            pie: {
              expandOnClick: true,
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
          },
          tooltip: {
            style: {
              fontSize: 10,
              fontFamily: "Mulish",
            },
          },

          legend: {
            position: "bottom",
            verticalAlign: "left",
            horizontalAlign: "left",
            fontSize: "14",
            fontWeight: 400,
            fontFamily: "Mulish",
            formatter: function (seriesName, opts) {
              return [
                seriesName,
                " - ",
                opts.w.globals.series[opts.seriesIndex],
              ];
            },
          },
        }}
      ></Chart>
    </Box>
  );
};

export default OrdersOverviewPerMenu;
