import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import NoBranches from "./NoBranches";
import BranchList from "./BranchList";
import { useGetBranches } from "../../services/branches";
import LoadingCard from "../Loading";
// Function Imports

const Restaurant = () => {
  const { restaurant } = useSelector((state) => state.auth);
  const { branches, isLoading } = useGetBranches(restaurant?.id)

  return (
    <Stack>
      {isLoading ? (
        <LoadingCard />
      ) : branches?.length > 0 ? (
        <BranchList branches={branches} />
      ) : (
        <NoBranches />
      )}
    </Stack>
  );
};

export default Restaurant;
