/**
 * It renders a page that tells the user that they don't have any saved branches and gives them the
 * option to create a branch or import from excel
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Button, Typography } from "@mui/material";

import Restaurant_Outline from "../../images/Restaurant_Outline.png";

import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const NoBranches = () => {
  const navigate = useNavigate();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100vh" }}
    >
      <Box>
        <img src={Restaurant_Outline} alt="Restaurant Outline"></img>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography>
          You currently don't have any saved Branch.<br></br>Let's start by
          creating a branch
        </Typography>
      </Box>
      <Box sx={{ mt: 2, width: 280 }}>
        <Button
          onClick={() => navigate("create-branch")}
          startIcon={
            <EditIcon
              sx={{
                border: 1,
                borderRadius: 1,
                bgcolor: "white",
                borderColor: "white",
                color: "black",
              }}
            />
          }
          disableElevation
          fullWidth
          variant="contained"
          sx={{
            bgcolor: "secondary.main",
            color: "white",
            height: 55,
            fontSize: "1rem",
          }}
        >
          Create a Branch
        </Button>
        {/* <Button
          onClick={() => navigate("csv-upload")}
          startIcon={
            <FileDownloadIcon
              sx={{
                color: "black",
              }}
            />
          }
          disableElevation
          fullWidth
          variant="contained"
          sx={{ mt: 2, color: "black", height: 55, fontSize: "1rem" }}
        >
          Import from Excel
        </Button> */}
      </Box>
    </Stack>
  );
};

export default NoBranches;
