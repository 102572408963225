import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { Stack, LinearProgress } from "@mui/material";
import { useLocation } from "react-router-dom";

import AccountAndNotifications from "../common/utils/AccountAndNotifications";
// import MenuListTable from "./datagrids/MenuListTable";
import FetchFood from "../../functions/FetchFood";

// Functions
import { getFoodInACategory } from "../../functions/menu";
import NoFood from "./NoFood";
import LoadingCard from "../Loading";

const MenuFood = () => {

  const [foodData, setFoodData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { pathname } = useLocation();
  // console.log(pathname.split('/'))
  const categoryId = pathname.split('/')[4]

  const fetchFoodInACategory = async () => {
    setLoading(true);
    try {
      const res = await getFoodInACategory(categoryId);
      // console.log('Food In a Cat', res)
      setFoodData(res.data.data);
      setLoading(false)
    } catch (err) {
      console.log(err)
    }

  };

  useEffect(() => {
    fetchFoodInACategory();
  }, []);

  return (
    <Stack>
      {/* <div className="flex justify-between items-center">
        <p className="text-2xl font-medium text-gray-700">Food Items</p>
      
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        })}
      >
        <AccountAndNotifications />
      </Stack>
      </div> */}
      {loading && <LoadingCard />}
      {!loading && foodData.length < 1 && <NoFood />}
      {/* {!loading && menuData.length > 0 && <MenuListTable menuData={menuData} />} */}
      {!loading && foodData.length > 0 && <FetchFood menuData={foodData} refresh={fetchFoodInACategory} />}
    </Stack>
  );
};

export default MenuFood;