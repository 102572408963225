import React from 'react'
import { MdOutlineCancel } from 'react-icons/md'
import { Modal, Box, IconButton, Stack, Typography } from '@mui/material';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";


const ModalLayout = ({ open, setOpen, title, children }) => {

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Stack
        sx={(theme) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40vw",
          bgcolor: "background.paper",

          borderRadius: 2,
          borderColor: `primary.main`,
          elevation: 24,
          [theme.breakpoints.down("md")]: {
            width: "80vw",
          },
        })}
      >
        <Box sx={{ position: "absolute", top: 5, right: 5 }}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseOutlinedIcon
              sx={{ borderRadius: 25, bgcolor: "black", color: "white" }}
            />
          </IconButton>
        </Box>

        <Stack sx={{ p: 4, my: 2 }}>
          {" "}
          <Typography variant="h4" fontWeight="500" color="black">
            {title}
          </Typography>

          {/* <div className='h-[60vh] overflow-scroll'> */}
            {children}
          {/* </div> */}
          
        </Stack>
      </Stack>
    </Modal>
  )
}

export default ModalLayout