import { GoogleMap, MarkerF, InfoWindowF } from "@react-google-maps/api";
import { useState } from "react";

const OrderMap = ({ orderLocation, orderAddress = null }) => {
  const [showWindowInfo, setShowWindowInfo] = useState(true);

  const position =
    orderLocation.lng && orderLocation.lat
      ? orderLocation
      : { lng: 36.817223, lat: -1.2921 };

  return (
    <GoogleMap
      mapContainerClassName="map-container"
      center={position}
      zoom={10}
      mapContainerStyle={{ height: "100%", width: "100%" }}
    >
      <MarkerF
        position={position}
        onClick={() => setShowWindowInfo(!showWindowInfo)}
      >
        {orderLocation && showWindowInfo && (
          <InfoWindowF position={position}>
            <h2>{orderAddress}</h2>
          </InfoWindowF>
        )}
      </MarkerF>
    </GoogleMap>
  );
};

export default OrderMap;
