import React, { useEffect, useState } from "react";

import { Box, Button, Typography, Stack, CircularProgress, Select, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

// GEOCODE IMPORTS
import Geolocation from "../../common/utils/Geolocation";
import ImageUploader from "../../common/utils/ImageUploader";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../Toast";
import { setCredentials } from "../../../features/auth/authSlice";
import AxiosPrivate from "../../../functions/axios/axiosPrivate";

const RestaurantProfileForm = () => {
  const [values, setValues] = useState({
    restaurant_name: "",
    restaurant_image: "",
    address: "",
    first_contacts: "",
    second_contacts: "",
    type: "",
    email: "",
  });
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const { user, restaurant, accessToken } = useSelector((state) => state.auth);

  const { restaurant_name, restaurant_image, address, first_contacts, second_contacts, type, email } = values;

  const getProfile = async () => {
    try {
      const res = await AxiosPrivate.get(`/restaurant/edit-restaurant-details/${restaurant?.id}`)
      const data =  res.data?.data

      console.log('data', data)
      setValues({
        restaurant_name: data.restaurant_name,
        first_contacts: data.first_contacts,
        second_contacts: data.second_contacts,
        restaurant_image: data.restaurant_image,
        address: data.address,
        email: data.email,
        type: data.type,
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getProfile()
  }, [])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const changeAvatar = (url) => {
    setValues({ ...values, restaurant_image: url });
  };

  // const handleDateChange = (date) => {
  //   setValues({ ...values, dateOfBirth: date });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
        restaurant_name &&
        restaurant_image &&
        address &&
        first_contacts &&
        second_contacts &&
        type &&
        email
    ) {
      setLoading(true)
      return (
        AxiosPrivate.post(`/restaurant/edit-restaurant-details/${restaurant?.id}`, values)
        .then((res) => {
          setLoading(false)
          notify(res.data.message, 'success')
          console.log(res);
          console.log(res.data.status);
          dispatch(
            setCredentials({
              user,
              restaurant: {
                ...restaurant,
                restaurant_name,
                restaurant_image,
                address,
                first_contacts,
                second_contacts,
                type,
                email
              },
              accessToken,
            })
          );
          // setValues({ ...values, branchName: "", branchCode: "" });
        })
        .catch((err) => {
          setLoading(false)
          notify(err.data?.message, 'error')
          console.log(err);
          // if (err.response.status === 400) toast.error(err.response.data);
        })
      );
    }
  };

  return (
    <Box
      sx={(theme) => ({
        flexGrow: 1,
        bgcolor: "secondary.input_field",

        borderRadius: 5,
        p: 5,
        [theme.breakpoints.down("md")]: {},
      })}
    >
      <ValidatorForm autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
        <Grid container>
          <Grid xs={12}>
            {" "}
            <Stack direction="row" justifyContent="flex-start">
              {" "}
              <ImageUploader
                changeAvatar={changeAvatar}
                title={"Change Your Profile Image"}
                current={restaurant_image}
              />
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid xs={12} lg={6}>
            <Box>
              {" "}
              <Typography sx={{ color: "black" }}>Name</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("restaurant_name")}
                name="name"
                value={restaurant_name}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              {" "}
              <Typography sx={{ color: "black" }}>Email</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("email")}
                name="email"
                value={email}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              {" "}
              <Typography sx={{ color: "black" }}>Address</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("address")}
                name="address"
                value={address}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>First Contact</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("first_contacts")}
                name="first_contacts"
                value={first_contacts}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>Second Contact</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("second_contacts")}
                name="second_contacts"
                value={second_contacts}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>Type</Typography>
              <Select fullWidth value={type} onChange={handleChange("type")}>
                <MenuItem value="Restaurant">Restaurant</MenuItem>
                <MenuItem value="Hotel">Hotel</MenuItem>
              </Select>
            </Box>
          </Grid>
          {/* <Grid xs={12} lg={6}>
            <Box>
              <Typography sx={{ color: "black" }}>Date Of Birth</Typography>
              <DatePicker handleDateChange={handleDateChange} />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>Location</Typography>
              <Geolocation
                setLatitude={setLatitude}
                setLongitude={setLongitude}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>Gender</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("gender")}
                name="gender"
                value={gender}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>
          </Grid> */}
        </Grid>
        <Button
          disabled={loading}
          sx={{ mt: 4, color: "white", fontSize: "1rem" }}
          type="submit"
          variant="contained"
          fullWidth
          disableElevation
        >
          {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : "Save Changes"}
        </Button>
      </ValidatorForm>
    </Box>
  );
};

export default RestaurantProfileForm;