import { Button, CircularProgress } from "@mui/material"

const MainButton = ({ loading, title, onClick }) => {
    return (
        <Button
            type="submit"
            disabled={loading}
            disableElevation
            fullWidth
            onClick={onClick}
            // sx={{ bgcolor: "secondary.main", color: "white", mt: 2 }}
            sx={{ mt: 4, height: 55, fontSize: "1rem", color: "white" }}
            variant="contained"
        >
            {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : title}
        </Button>
    )
}

export default MainButton