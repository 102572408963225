import React, { useState } from "react";
// import { useSelector } from "react-redux";
import { Box, Stack, Tabs, Tab, LinearProgress, Button } from "@mui/material";
import { useLocation } from 'react-router-dom'

import AddMenuItemForm from "./forms/AddMenuItemForm";

const FoodTab = () => {
  const [currentTab, setCurrentTab] = useState("details");

  const handleNavChange = (event, newCurrentTab) => {
    setCurrentTab(newCurrentTab);
  };

  const { pathname } = useLocation()
  const menu_id = pathname.split('/')[3]
  const category_id = pathname.split('/')[4]
  const restaurant_code = pathname.split('/')[7]


  return (
    <Stack
      sx={{
        bgcolor: "secondary.input_field",
        borderRadius: 5,
        p: 5,
      }}
    >
      <Box>
        <AddMenuItemForm category_id={category_id}/>
      </Box>
    </Stack>
  );
};

export default FoodTab;
