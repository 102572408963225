import React, { useState } from "react";
import { Box, Stack, Button, Typography } from "@mui/material";

import Restaurant_Menu from "../../images/Restaurant_Menu.png";
import EditIcon from "@mui/icons-material/Edit";
import AddEmployeeModal from "./modals/AddEmployeeModal";

const NoEmployee = () => {

  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);

  const closeAddEmployeeModal = () => setOpenAddEmployeeModal(false);

  return (
    <>
      {" "}
      <AddEmployeeModal
        openAddEmployeeModal={openAddEmployeeModal}
        closeAddEmployeeModal={closeAddEmployeeModal}
      />
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", height: "100vh" }}
      >
        <Box>
          <img src={Restaurant_Menu} alt="Restaurant Outline"></img>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            You currently don't have any Employees in here.<br></br>Let's start by
            creating an Employee Profile.
          </Typography>
        </Box>
        <Box sx={{ mt: 2, width: 280 }}>
          <Button
            onClick={() => setOpenAddEmployeeModal(true)}
            startIcon={
              <EditIcon
                sx={{
                  border: 1,
                  borderRadius: 1,
                  bgcolor: "white",
                  borderColor: "white",
                  color: "black",
                }}
              />
            }
            disableElevation
            fullWidth
            variant="contained"
            sx={{
              bgcolor: "secondary.main",
              color: "white",
              height: 55,
              fontSize: "1rem",
            }}
          >
            Add Employee
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default NoEmployee;