import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useUpdateOrderStatus } from "../../../services/orders";
import { notify } from "../../Toast";
import SideDrawer from "../../common/SideDrawer";
import OrderModal from "../OrderModal";
import PaymentModal from "../PaymentModal";
import OrderDetails from "../OrderDetails";

const OrderDrawerDetails = React.memo(
  ({
    orderDetails,
    open,
    setOpen,
    openPayment,
    setOpenPayment,
    handleChangeItemStatusClick,
    updateStatus,
    openDrawer,
    setOpenDrawer,
    refresh,
  }) => {
    return (
      <>
        <SideDrawer
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          title="Order Details"
        >
          <OrderDetails
            handleClickChip={() => setOpen(true)}
            handleOpenPayment={() => setOpenPayment(true)}
            orderDetails={orderDetails}
          />
        </SideDrawer>

        <OrderModal
          open={open}
          setOpen={setOpen}
          order={orderDetails}
          onSubmit={(status) => handleChangeItemStatusClick(status)}
          loading={updateStatus.isLoading}
        />

        <PaymentModal
          open={openPayment}
          setOpen={setOpenPayment}
          order={orderDetails}
          refresh={refresh}
        />
      </>
    );
  }
);

const ViewOrderDetailsDrawer = ({
  openDrawer,
  setOpenDrawer,
  orderDetails,
  refresh,
}) => {
  const updateStatus = useUpdateOrderStatus();
  const [open, setOpen] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);

  useEffect(() => {
    if (updateStatus.isSuccess) {
      setOpen(false);
      refresh();
    }
    if (updateStatus.isError) {
      console.log("new Err", updateStatus.error);
      notify(updateStatus.error.message, "error");
    }
  }, [updateStatus.isSuccess, updateStatus.isError]);

  const handleChangeItemStatusClick = (prop) => {
    updateStatus.mutate({ type: prop, order_id: orderDetails.id });
  };

  return (
    <Stack>
      {orderDetails && (
        <OrderDrawerDetails
          orderDetails={orderDetails}
          open={open}
          setOpen={setOpen}
          openPayment={openPayment}
          setOpenPayment={setOpenPayment}
          handleChangeItemStatusClick={handleChangeItemStatusClick}
          updateStatus={updateStatus}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          refresh={refresh}
        />
      )}
    </Stack>
  );
};

export default ViewOrderDetailsDrawer;
