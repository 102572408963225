import React from "react";
import { Box, Stack } from "@mui/material";
import DashboardHeader from "./DashboardHeader";
import DashboardStats from "./DashboardStats";
import { useSelector } from "react-redux";
import LaunchIcon from '@mui/icons-material/Launch';
import pizza from "../../images/pizza.svg"
import BannerSVG from "../svg/Banner";
import Lottie from 'react-lottie';
import * as animationData from '../../data/dashboard_animation.json'
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const Dashboard = () => {
  const storeData = useSelector((state) => state.auth);
  const { user } = storeData;
  const { first_name } = user;

  const navigate = useNavigate();

  return (
    <Stack>
      <Box
      >
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="bg-[lavender] rounded mt-2">
            <div className="relative overflow-hidden px-8 pt-4">
              <div width="80" height="77" className="hidden lg:block absolute top-1 right-10 text-yellow-500">
                <Lottie options={defaultOptions}
                  height={200}
                  width={350}
                />
              </div>
              <div className="text-xl flex flex-col pb-2 text-black">
                  <span className="text-3xl font-bold">Dashboard</span>
                  <small className="text-sm">Hello <span className="font-bold capitalize">{first_name}</span> 👋🏼, Welcome back!</small>
              </div>
              <div className="mb-4">
                <button onClick={() => navigate('/dashboard/orders')} className=" px-2 py-1 border border-black text-black text-xs rounded-md">
                  {/* <LaunchIcon sx={{ fontSize: 16, mr: 1 }} /> */}
                  Go to Orders
                </button>
              </div>
            </div>
          </div>
        </motion.div>
        
        <DashboardStats/>
       
      </Box>
    </Stack>
  );
};

export default Dashboard;