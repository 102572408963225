import React from "react"
import {Outlet} from "react-router-dom"
import {Stack, Box} from "@mui/material"
import MenuNav from "./MenuNav";
import DishesNav from "./DishesNav";
import MenuListTable from "./MenuListTable";

const WithMenu = () =>{
    return (
      <Stack>
        <MenuNav />
        <Box sx={{mt:5}}>
          <DishesNav />
        </Box>
        <MenuListTable/>
        <Outlet />
      </Stack>
    );
}
export default WithMenu;