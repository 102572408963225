import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import store from "../../store/store";
import { useSelector } from "react-redux";

const BASE_URL = process.env.REACT_APP_API;

const AxiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json, text/plain, */*"
  }
});

const onRequest = (request) => {
    // get the user data if logged in to access the token and pass it to the header Authorization
    // const access = store.getState().auth.accessToken
    const accessToken = localStorage.getItem("accessToken")
    // const { accessToken } = useSelector((state) => state.auth);

    // console.log('accessToken', accessToken)
    // const { accessToken } = auth;
    const access = accessToken;
    request.headers.Authorization = `Bearer ${access}` || "";
    return request;
};

const onRequestError = (error) => {
    return Promise.reject(error);
};
const onResponse = (response) => {
    return response;
};

const onResponseError = (error) => {

    // detect the current HTTP status code
    const statusCode = error?.response?.status;

    // 401 means unauthorized. If unauthorized, it logs the current user out

    if (statusCode === 401) {
        localStorage.clear()
        // localStorage.removeItem("auth");
        // localStorage.removeItem("accessToken");
        // window.location.href = "/signin";
    }
    return Promise.reject(error);
};

AxiosPrivate.interceptors.request.use(onRequest, onRequestError);
AxiosPrivate.interceptors.response. use(onResponse, onResponseError);
export default AxiosPrivate;

export const userHeaders = AxiosPrivate.defaults.headers