import React, { useEffect } from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../../data/loading.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

function LoadingCard() {

    return (
        <div className='h-[100vh] flex justify-center items-center'>
            <Lottie options={defaultOptions}
                height={400}
                width={400}
            />
        </div>
    )
}

export default LoadingCard