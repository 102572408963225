import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Dagizo_Logo from "../../images/Dagizo_Logo.png";

// SIGN IN FUNCTIONS
import { sendResetPassword } from "../../functions/auth";

import { useEffect } from "react";
import AuthLayout from "../../components/Layout/Auth";

const ResetPassword = () => {
  const [values, setValues] = useState({
    password: "",
    passwordConfirmation: "",
    buttonText: "Reset Password",
    showPassword: false,
    showPasswordConfirmation: false,
  });
  const {
    password,
    passwordConfirmation,
    buttonText,
    showPassword,
    showPasswordConfirmation,
  } = values;

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== password) {
        return false;
      }
      return true;
    });
  }, [password]);

  const navigate = useNavigate();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const setPasswordVisibility = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: !values[prop],
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, buttonText: "Requesting..." });

    if (password === passwordConfirmation) {
      console.log(
        "PASSWORD",
        password,
        "PASSWORD CONFIRMATION",
        passwordConfirmation
      );
      return sendResetPassword(password, passwordConfirmation)
        .then((response) => {
          console.log("RESET PASSWORD SUCCESS", response);
          //  toast.success(response.data.message);
          setValues({ ...values, buttonText: "Done" });

          navigate("/signin");
        })
        .catch((error) => {
          console.log("RESET PASSWORD ERROR", error.response.data);
          // toast.error(error.response.data.error);
          setValues({ ...values, buttonText: "Reset Password" });
        });
    }
  };

  return (
    <AuthLayout>
      <div className="">
        <Grid container spacing={1} sx={{ mt: 10 }}>
          <Grid>
            {" "}
            <Button
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosNewIcon />}
              sx={{ fontSize: "1.2rem" }}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mt: 6 }}>
          <Grid>
            <Box>
              {" "}
              <img src={Dagizo_Logo} alt="DAGIZO" width="150"></img>
            </Box>

            <ValidatorForm autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
              <Typography
                variant="h5"
                color="black"
                fontWeight="500"
                sx={{ mt: 3 }}
              >
                RESET PASSWORD
              </Typography>
              <Typography>You can reset your password here</Typography>
              <Typography sx={{ mt: 2 }}>New Password</Typography>
              <TextValidator
                type={values.showPassword ? "text" : "password"}
                sx={{
                  mt: 1,
                  backgroundColor: "secondary.input_field",
                  "& fieldset": {
                    border: "none",
                  },
                }}
                fullWidth
                name="password"
                value={password}
                onChange={handleChange("password")}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={setPasswordVisibility("showPassword")}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Typography sx={{ mt: 2 }}>Confirm New Password</Typography>
              <TextValidator
                type={showPasswordConfirmation ? "text" : "password"}
                sx={{
                  mt: 1,
                  backgroundColor: "secondary.input_field",
                  "& fieldset": {
                    border: "none",
                  },
                }}
                fullWidth
                name="password"
                value={passwordConfirmation}
                onChange={handleChange("passwordConfirmation")}
                validators={["isPasswordMatch", "required"]}
                errorMessages={[
                  "Passwords Do Not Match",
                  "This Field is Required",
                ]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={setPasswordVisibility(
                          "showPasswordConfirmation"
                        )}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPasswordConfirmation ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                type="submit"
                disableElevation
                fullWidth
                sx={{ bgcolor: "secondary.main", color: "white", mt: 2 }}
                variant="contained"
              >
                {buttonText}
              </Button>
            </ValidatorForm>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
