import React, { useState } from "react";
import ModalLayout from "../modals/Layout";
import { CircularProgress } from "@mui/material";
import { notify } from "../Toast";
import AxiosPrivate from "../../functions/axios/axiosPrivate";

const DeleteFoodModal = ({ open, setOpen, food, refresh }) => {
    const [loading, setLoading] = useState(false)

    const handleDelete = (e) => {
        e.preventDefault()
        setLoading(true)

        return AxiosPrivate.delete(`/restaurant/food-edit-delete/${food.id}`)
        .then((res) => {
            notify(res.data.message, "success")
            refresh()
            setOpen(false)
            setLoading(false)
        })
        .catch((err) => {
            notify(err.data?.message)
            setLoading(false)
        });
    }

    return (
        <ModalLayout open={open} setOpen={setOpen} title={`Delete Food Item`}>
            <div>
                <p className="my-6">Are you sure you want to delete <b>{food?.food_name}</b> ?</p>
                <div className="flex gap-4 justify-end">
                    <button onClick={() => setOpen(false)} className="bg-gray-200 px-6 py-2 rounded">Cancel</button>
                    <button disabled={loading} onClick={handleDelete} className="flex items-center gap-3 bg-red-600 px-6 py-2 text-red-100 rounded">
                        Delete {loading && (<CircularProgress color="inherit" size="1.2rem" />)}
                    </button>
                </div>
            </div>
        </ModalLayout>
    );
};

export default DeleteFoodModal;