import React, { useEffect, useState } from "react";
import SideDrawer from "../SideDrawer";
import { useGetNotifications } from "../../../services/notifications";
import { useSelector } from "react-redux";
import { Tabs, Tab } from "@mui/material";
import { onValue, ref, child, get, orderByValue } from "firebase/database";
import { db } from "../../../firebase";
import NotificationCard from "./Card";
import NoData from "../../NoData";
import { useGetOrders } from "../../../services/orders";
import ViewOrderDetailsDrawer from "../../Orders/drawers/ViewOrderDetailsDrawer";
import notificationAudio from "../../../audio/notification.mp3";

function NotificationDrawer({ open, setOpen, branchId }) {
  const [clientLoading, setClientLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [orderLoading, setorderLoading] = useState(true);
  const [openOrder, setOpenOrder] = useState(false);
  const [currentTab, setCurrentTab] = useState("client");
  const [newOrders, setOrders] = useState([]);
  const [clients, setClients] = useState([]);
  const [currenOrder, setCurrenOrder] = useState(null);

  const { data } = useGetOrders(branchId);

  const handleChange = (event, newCurrentTab) => {
    setCurrentTab(newCurrentTab);
  };

  const getNotifications = () => {
    const dbRef = ref(db, `notifications/${branchId}`);

    return onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        const messages = [];
        snapshot.forEach((message) => {
          const data = message.val();
          messages.push(data);
        });

        setClients(messages);
        setClientLoading(false);
      } else {
        setClients([]);
        setClientLoading(false);
      }
    });
  };

  const getOrders = () => {
    const dbRef = ref(db, `orders/${branchId}`);
    return onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        const messages = [];
        snapshot.forEach((message) => {
          const data = message.val();
          messages.push(data);
        });
        setOrders(messages);
        setorderLoading(false);
      } else {
        setOrders([]);
        setorderLoading(false);
      }
    });
  };

  useEffect(() => {
    getNotifications();
    getOrders();
  }, []);

  useEffect(() => {
    getNotifications();
    getOrders();
  }, [branchId]);

  return (
    <>
      <SideDrawer
        open={open}
        onClose={() => setOpen(false)}
        title="Notifications"
      >
        <div className="flex justify-center mb-4">
          <Tabs value={currentTab} onChange={handleChange}>
            <Tab value={"client"} label="Client Notifications" />
            <Tab value={"orders"} label="Orders Notifications" />
          </Tabs>
        </div>

        {currentTab === "client" && (
          <div className="mt-2">
            {clientLoading ? (
              [1, 2, 3, 4, 5, 6, 7].map((__, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center py-3 border-b"
                >
                  <div className="w-full flex items-center space-x-4">
                    <div className="w-16 h-14 flex flex-col items-center justify-center bg-gray-200 text-white rounded-lg">
                      <div className="w-10 h-4 bg-white animate-pulse rounded-lg"></div>
                      <div className="w-8 h-2 mt-2 bg-white animate-pulse rounded-lg"></div>
                    </div>
                    <div className="w-full flex flex-col gap-2 space-y-1">
                      <div className="w-1/3 h-4 bg-gray-200 animate-pulse rounded-lg"></div>
                      <div className="w-2/3 h-2 bg-gray-200 animate-pulse rounded-lg"></div>
                    </div>
                  </div>
                  <div className="flex-none px-4 py-2 text-stone-600 text-xs md:text-sm">
                    <div className="w-10 h-2 bg-gray-200 animate-pulse rounded-lg"></div>
                  </div>
                </div>
              ))
            ) : clients?.length > 0 ? (
              clients
                ?.sort((a, b) => new Date(b.created) - new Date(a.created))
                ?.map((notification) => (
                  <NotificationCard notification={notification} />
                ))
            ) : (
              <NoData title="Notification" />
            )}
          </div>
        )}

        {currentTab === "orders" && (
          <div className="mt-2">
            {orderLoading ? (
              [1, 2, 3, 4, 5, 6, 7].map((l, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center py-3 border-b"
                >
                  <div className="w-full flex items-center space-x-4">
                    <div className="w-16 h-14 flex flex-col items-center justify-center bg-gray-200 text-white rounded-lg">
                      <div className="w-10 h-4 bg-white animate-pulse rounded-lg"></div>
                      <div className="w-8 h-2 mt-2 bg-white animate-pulse rounded-lg"></div>
                    </div>
                    <div className="w-full flex flex-col gap-2 space-y-1">
                      <div className="w-1/3 h-4 bg-gray-200 animate-pulse rounded-lg"></div>
                      <div className="w-2/3 h-2 bg-gray-200 animate-pulse rounded-lg"></div>
                    </div>
                  </div>
                  <div className="flex-none px-4 py-2 text-stone-600 text-xs md:text-sm">
                    <div className="w-10 h-2 bg-gray-200 animate-pulse rounded-lg"></div>
                  </div>
                </div>
              ))
            ) : newOrders?.length > 0 ? (
              newOrders
                ?.sort((a, b) => new Date(b.created) - new Date(a.created))
                ?.map((notification) => (
                  <NotificationCard
                    notification={notification}
                    showDrawer={(id) => {
                      if (id) {
                        const order = data.filter((order) => order.id === id);

                        console.log("current", order);
                        setCurrenOrder(order[0]);
                        setOpen(false);
                        setOpenOrder(true);
                      }
                    }}
                  />
                ))
            ) : (
              <NoData title="Notification" />
            )}
          </div>
        )}
      </SideDrawer>

      <ViewOrderDetailsDrawer
        orderDetails={currenOrder}
        openDrawer={openOrder}
        setOpenDrawer={setOpenOrder}
      />
    </>
  );
}

export default NotificationDrawer;
