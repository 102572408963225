import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import MenuTable from '../components/tables/MenuTable';
import NoImage from "../images/no-image.png";


function FetchMenus({ menuData }) {
    const [open, setOpen] = useState(false)
    const [toDelete, setToDelete] = useState(false)
    const [menu, setMenu] = useState(null)

    const columns = useMemo(() => [
        {   
            Header: "Menu Name",
            Cell: ({ row }) => (
                <div className="flex items-center space-x-2 cursor-pointer" onClick={(e) => handleRowClick(row)}>
                    <img className='h-7 w-7 rounded-full object-cover object-center' src={row.original.menu_image || NoImage} alt="menu"/>
                    <p className='border-b border-blue-600 text-blue-600'>{row.original.menu_name}</p>
                </div>
            )
        },
        {
            Header: "Menu Code",
            accessor: 'menu_code',
        },
        {
            Header: "No. of Orders",
            accessor: 'no_orders',
        },
        {   
            Header: "Status",
            Cell: ({ row }) => (
                <div className={`py-1 rounded-full w-16 text-center text-xs ${row.original.is_active ? "bg-green-100 " : "bg-red-100"}`}>
                    <p className={`${row.original.is_active ? "text-green-500" : "text-red-500"}`}>{row.original.is_active ? "Active" : "Inactive"}</p>
                </div>
            )
        },
        {
            Header: "Action",
            id: "Action",
            Cell: ({ row }) => (
                <div className="flex items-center">
                    <button
                        onClick={() => {
                            setOpen(true)
                            setMenu(row?.original)
                            setToDelete(false)
                        }}
                        className='hover:bg-gray-300 text-xs font-medium grid justify-center items-center w-8 h-8 rounded-full'
                    >
                    <EditIcon className='w-5 h-5' />
                    </button>

                    <button
                        onClick={() => {
                            setOpen(true)
                            setMenu(row?.original)
                            setToDelete(true)
                        }}
                        className='hover:bg-gray-300 text-xs font-medium grid justify-center items-center w-8 h-8 rounded-full'
                    >
                    <DeleteIcon className='w-5 h-5' />
                    </button>
                </div>
            )
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []);

    
    const navigate = useNavigate();
    const handleRowClick = (row) => {
        const id = row.original.id;
        const res_code = row.original.branch
        navigate(`/dashboard/menu/${id}`);
    }

    // console.log("MenuData", menuData)


    return (
        <>
            <MenuTable 
            columns={columns} 
            data={menuData} 
            // csvData={menuData} 
            // fileName='menus'
            open={open}
            setOpen={setOpen}
            toDelete={toDelete}
            setToDelete={setToDelete}
            menu={menu}
            setMenu={setMenu}
            />
        </>
    )
}

export default FetchMenus;