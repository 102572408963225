import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Stack, LinearProgress } from "@mui/material";

import AccountAndNotifications from "../common/utils/AccountAndNotifications";
import MenuListTable from "./datagrids/MenuListTable";
import FetchMenus from "../../functions/FetchMenus";

// Functions
import { fetchBranchMenus } from "../../functions/menu";
import NoMenu from "./NoMenu";
import { truncate } from "lodash";
import LoadingCard from "../Loading";

const Menu = () => {
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { currentBranch } = useSelector((state) => state.branch);
  const branchId = currentBranch?.id

  const getAllBranchMenus = async () => {
    setLoading(true);

    const res = await fetchBranchMenus(branchId);
    setMenuData(res.data.data);
    setLoading(false)
  };

  useEffect(() => {
    getAllBranchMenus();
  }, [branchId]);

  return (
    <Stack>
      <div className="flex justify-between items-center">
        {/* <p className="text-2xl font-medium text-gray-700">Menus</p> */}
      
      {/* <Stack
        direction="row"
        justifyContent="flex-end"
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        })}
      >
        <AccountAndNotifications />
      </Stack> */}
      </div>
      {loading && <LoadingCard />}
      {!loading && menuData.length < 1 && <NoMenu />}
      {/* {!loading && menuData.length > 0 && <MenuListTable menuData={menuData} />} */}
      {!loading && menuData.length > 0 && <FetchMenus menuData={menuData}/>}
    </Stack>
  );
};

export default Menu;