import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, MenuItem, IconButton, Menu, Avatar } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import CustomDataGrid from "../../../styling/CustomComponents/CustomDataGrid";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { setBranchToUpdate } from "../../../features/branches/branchSlice";

export function SortedDescendingIcon() {
  return <ArrowDropDownIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ArrowDropUpIcon className="icon" />;
}

const BranchListTable = ({ branches }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [rowParams, setRowParams] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElNav(null);
  };

  const handleBranchActionsClick = (event, params) => {

    handleOpenMenu(event);
    setRowParams(params.row);
  };

  const handleBranchEditClick = () => {
    dispatch(setBranchToUpdate({ branchToUpdate: rowParams }));
    navigate("create-branch", { state: { edit: true } });
  };

  const columns = [
    {
      field: "restaurant_image",
      headerName: "Image",
      renderCell: (params) => {
        return (
          <Avatar src={params.value} alt="logo" />
        );
      },
      width: 80,
    },
    {
      field: "branch_name",
      headerName: "Branch Name",
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "first_contacts",
      headerName: "Restaurant Contact",
      width: 150,
    },
    {
      field: "second_contacts",
      headerName: "Manager Contact",
      width: 150,
    },
    {
      field: "address",
      headerName: "Address",
      width: 200,
    },
    {
      field: "action",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(event) => handleBranchActionsClick(event, params)}
          >
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
  ];



  return (
    <Box
      sx={{
        mt: 5,
        "& .MuiDataGrid-columnHeaders": {
          color: "black",
          fontSize: 18,
        },
        width: "100%",
      }}
    >
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleBranchEditClick()}>Edit</MenuItem>
      </Menu>{" "}
      <CustomDataGrid
        getRowId={(row) => row.restaurant_code}
        autoHeight
        rows={branches}
        columns={columns}
        title="Branches"
      />
    </Box>
  );
};

export default BranchListTable;
