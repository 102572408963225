import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion"
import SideDrawer from '../../../common/SideDrawer';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getAllFoodAddons } from '../../../../functions/menu';
import { Avatar, AvatarGroup } from '@mui/material';

export default function FoodDrawer({ open, setOpen, food, menu_id }) {
    const [loading, setLoading] = useState(true)
    const [addons, setAddons] = useState([])

    const fetchAddons = async () => {
        const res = await getAllFoodAddons(menu_id);
        setAddons(res.data.data);
    };

    useEffect(() => {
        fetchAddons()

        setTimeout(() => {
            open ? setLoading(false) : setLoading(true)
        }, 1000);
    }, [open])

    const allAddons = addons?.filter(add => food?.food_addons?.some(i => i.addon.food_code === add.food_code))

    return (
        <div>
            <SideDrawer open={open} onClose={() => setOpen(false)}>
                <div className='mb-6'>
                    {loading ? (
                        <div className='mt-6'>
                            <div className='h-5 w-3/5 bg-gray-200 animate-pulse rounded'></div>
                            <div className='h-3 w-2/5 bg-gray-200 animate-pulse rounded mt-4'></div>

                            <div className='grid grid-cols-2 gap-6'>
                                <div className='h-44 bg-gray-200 animate-pulse rounded mt-4'></div>
                                <div className='h-44 bg-gray-200 animate-pulse rounded mt-4'></div>
                            </div>

                            <div className='h-5 w-3/5 bg-gray-200 animate-pulse rounded mt-8'></div>
                            <div className='h-3 w-2/5 bg-gray-200 animate-pulse rounded mt-4 mb-2'></div>

                            {[1, 2].map((i, index) => (
                                <div key={index} className='flex items-center gap-2 mt-2'>
                                    <div className='h-12 w-12 bg-gray-200 animate-pulse mt-6 rounded-full'></div>
                                    <div className='w-5/6'>
                                        <div className='h-3 w-5/12 bg-gray-200 animate-pulse rounded mt-6'></div>
                                        <div className='h-2 w-3/12 bg-gray-200 animate-pulse rounded mt-4'></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            <div className='flex justify-between mt-6'>
                                <div className='w-4/6'>
                                    <h1 className='font-bold text-2xl'>{food?.food_name}</h1>
                                </div>

                                <div className='w-2/6 flex flex-col items-end'>
                                    <p className='bg-[#0000ff2b] text-sm text-blue-800 px-2 py-1 font-semibold rounded'>{food?.dish_category}</p>
                                </div>
                            </div>

                            <div className='flex items-center gap-4 mb-4 mt-2'>
                                <p className='text-gray-500 text-sm'>#{food?.food_code}</p>
                                <p className='flex items-center gap-1 text-xs text-blue-400'>
                                    <ArrowForwardIcon style={{ fontSize: 14 }} />
                                    <div className='flex items-center gap-2'>
                                        {food?.discount_rate > 0 ? <span className='text-gray-400 line-through font-bold text-sm'>{food?.original_price} KES</span> : null}
                                        <span className='text-primary font-bold text-xl'> {food?.price} KES</span>
                                    </div>
                                </p>
                            </div>

                            <div className='grid grid-cols-2 gap-6'>
                                {food?.food_image_json?.map((image) => (
                                    <img className='rounded-lg object-cover object-center' src={image?.url} alt="food_image" />
                                ))}
                            </div>

                            <h6 className='font-bold text-lg mt-4'>Description</h6>
                            <p className='text-gray-500 text-sm'>{food?.description}</p>

                            <div className='flex gap-2 items-center mt-4'>
                                <AccessAlarmsIcon
                                    sx={{ color: "blue", backgroundColor: '#0000ff2b', p: 1.5, borderRadius: '50%', fontSize: 40 }}
                                />
                                <div>
                                    <h6 className='font-bold'>Serving Hours</h6>
                                    <p className='text-gray-400 text-sm'>{food?.serving_hours_id?.serving_type}</p>
                                </div>
                            </div>

                            <div className='flex gap-2 items-center mt-4'>
                                <PointOfSaleIcon
                                    sx={{ color: "blue", backgroundColor: '#0000ff2b', p: 1.5, borderRadius: '50%', fontSize: 40 }}
                                />
                                <div>
                                    <h6 className='font-bold'>Tax</h6>
                                    <p className='text-gray-400 text-sm'>{food?.tax_object?.map((tax) => (
                                        <span className='mr-2'>{tax.tax?.tax_type} ({tax.tax?.tax_rate}%)</span>
                                    ))}</p>
                                </div>
                            </div>

                            <div className='flex gap-2 items-center mt-4'>
                                <OutdoorGrillIcon
                                    sx={{ color: "blue", backgroundColor: '#0000ff2b', p: 1.5, borderRadius: '50%', fontSize: 40 }}
                                />
                                <div>
                                    <h6 className='font-bold'>Preparation Time</h6>
                                    <p className='text-gray-400 text-sm'>{food?.preparation_time} min</p>
                                </div>
                            </div>

                            {allAddons?.length > 0 && (
                                <div>
                                    <h6 className='font-bold text-lg mt-6'>Addons</h6>
                                    <div className="grid md:grid-cols-2 gap-4 mt-2">
                                        {allAddons.map((addon, index) => (
                                            <div className="bg-white rounded shadow px-4 py-2" key={index}>
                                                <div className='w-full flex items-center gap-2'>
                                                    <AvatarGroup max={2}>
                                                        {addon?.food_image_json?.map((item) => (
                                                            <Avatar alt="Food Image" src={item.url} sx={{ width: 56, height: 56 }} />
                                                        ))}
                                                    </AvatarGroup>
                                                    <div className='w-full'>
                                                        <p className="font-bold text-sm">{addon?.food_name}</p>
                                                        <p className="text-sm text-primary font-bold">{addon?.price} KES</p>
                                                        <div className='w-full flex justify-between items-center'>
                                                            <p className="text-sm text-gray-400">{addon?.dish_category}</p>
                                                            {addon?.isRequired && (
                                                                <p className="px-2 bg-gray-100 text-sm text-blue-400 rounded">required</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {food?.variants?.length > 0 && (
                                <div>
                                    <h6 className='font-bold text-lg mt-6'>Variants</h6>
                                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2">
                                        {food?.variants?.map((variant, index) => (
                                            <div className="bg-white rounded shadow px-4 py-2" key={index}>
                                                <p className="mb-2 font-bold">{variant?.name}</p>

                                                <div className="border-l-[10px] border-blue-400 pl-2">
                                                    {variant.values.map((item, index) => (
                                                        <div key={index}>
                                                            <p className="text-sm">{item.value_name}: KES {item.price}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {food?.discount_rate > 0 ? (
                                <div className='flex justify-between items-center border-b pb-3 mt-6'>
                                    <h6 className='font-bold text-sm'>Discount Rate</h6>
                                    <p className='text-gray-500 text-sm'>{food?.discount_rate}%</p>
                                </div>
                            ) : (
                                <div className='flex justify-between items-center border-b pb-3 mt-6'>
                                    <h6 className='font-bold text-sm'>Discount</h6>
                                    <p className='text-gray-500 text-sm'>Not available</p>
                                </div>
                            )}

                            {food?.dish_category !== "main-dish" && (
                                <>
                                    <div className='flex justify-between items-center border-b pb-3 mt-4'>
                                        <h6 className='font-bold text-sm'>Visible on Menu</h6>
                                        <p className='text-gray-500 text-sm'>{food?.isVisible ? "Yes" : "No"}</p>
                                    </div>

                                    <div className='flex justify-between items-center border-b pb-3 mt-4'>
                                        <h6 className='font-bold text-sm'>Required</h6>
                                        <p className='text-gray-500 text-sm'>{food?.isRequired ? "Yes" : "No"}</p>
                                    </div>
                                </>
                            )}

                            <div className='flex justify-between items-center border-b pb-3 mt-4'>
                                <h6 className='font-bold text-sm'>Available is Stock</h6>
                                <p className='text-gray-500 text-sm'>{food?.is_in_stock ? "Yes" : "No"}</p>
                            </div>
                        </>
                    )}

                </div>
            </SideDrawer>
        </div>
    );
}