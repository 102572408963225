import React from "react";
import { Box, TextField, Typography, IconButton, Icon, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export default function Geolocation({ setLatitude, setLongitude }) {
  const [address, setAddress] = React.useState("");

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setLatitude(latLng.lat);
    setLongitude(latLng.lng);
  };

const handleClearClick = () => {
  setAddress("");
};

  return (
    <Box>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Box>
            <TextField
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: address ? "visible" : "hidden" }}
                    onClick={handleClearClick}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              fullWidth
              {...getInputProps({ placeholder: "Type Location" })}
            />

            <Box>
              {loading ? <Typography>...loading</Typography> : null}

              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? "#F4B315" : "#fff",
                };
             
                return (
                  <Stack
                     key={suggestion.description}
                  direction="row"
                  alignItems="center"
                  sx={{my:1}}
                    {...getSuggestionItemProps(suggestion, { style })}
                  ><Icon fontSize="small" sx={{mr:1}}><LocationOnIcon/></Icon>
                    {suggestion.description}
                  </Stack>
                );
              })}
            </Box>
          </Box>
        )}
      </PlacesAutocomplete>
    </Box>
  );
}