import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Stack, LinearProgress, Box, Tabs, Button, Tab } from "@mui/material";
import OrderListTable from "./OrdersListTable";

import { fetchBranchOrders } from "../../functions/orders";
import NoOrders from "./NoOrders";
import AddIcon from "@mui/icons-material/Add";
import TuneIcon from "@mui/icons-material/Tune";
import { useGetOrders } from "../../services/orders";
import LoadingCard from "../Loading";
import { onValue, ref, child, get } from "firebase/database";
import { db } from "../../firebase";
import { getOrders } from "../../services/orders";
import toast from "react-hot-toast";

const Orders = () => {
  const allBranches = useSelector((state) => state?.branch?.allBranches);
  const { currentBranch } = useSelector((state) => state.branch);
  const [isLoading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState("all");
  const [orders, setOrders] = useState([]);
  // const [tableView, setTableView] = useState("Split-Screen");

  // const handleTableViewChange = (event, newValue) => {
  //   setTableView(newValue);
  // };
  const branchId = currentBranch.id || {};
  const handleChange = (event, newCurrentTab) => {
    setCurrentTab(newCurrentTab);
  };

  // const { orders, isLoading } = useGetOrders(branchId);
  const handleGetData = () => {
    getOrders(branchId).then((r) => {
      setLoading(false);
      setOrders(r.data.data);
    });
  };

  const updateData = () => {
    const dbRef = ref(db, `orders/${branchId}`);
    return onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        handleGetData()
      }
    });
  };

  useEffect(() => {
    handleGetData();
    updateData()
  }, []);

  const pendingOrders = orders?.filter((i) => i.order_status === "PENDING");
  const confirmedOrders = orders?.filter((i) => i.order_status === "Confirmed");
  const readyOrders = orders?.filter((i) => i.order_status === "Ready");
  const completeOrders = orders?.filter((i) => i.order_status === "Completed");

  return (
    <Stack>
      {/* <OrdersNav
        tableView={tableView}
        handleTableViewChange={handleTableViewChange}
      /> */}
      {/* {tableView === "Split-Screen" ? <OrderListTable /> : <OrderCards />}
      {orders?.length ? (
        tableView === "Split-Screen" ? (
          <OrderListTable />
        ) : (
          <OrderCards />
        )
      ) : (
        <NoOrders />
      )} */}
      {isLoading && <LoadingCard />}
      {!isLoading && orders?.length == 0 && <NoOrders />}
      {!isLoading && orders?.length > 0 && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ borderBottom: 1, borderColor: "divider", mt: 4, mb: 2 }}
          >
            <Box>
              {" "}
              <Tabs value={currentTab} onChange={handleChange}>
                <Tab value={"all"} label="All" />
                <Tab value={"pending"} label="Pending" />
                <Tab value={"confirmed"} label="Preparing" />
                <Tab value={"ready"} label="Ready" />
                <Tab value={"completed"} label="Completed" />
              </Tabs>
            </Box>
            {/* 
          <Box>
            <Button
              variant="contained"
              disableElevation
              startIcon={<TuneIcon />}
              sx={{ mx: 2, px: 4, color: "black", backgroundColor: '#ccc' }}
              onClick={() => handleOpenCreatePlaceModal("Room")}
            >
              Sort
            </Button>
          </Box> */}
          </Stack>

          <Stack>
            {currentTab === "all" && <OrderListTable ordersData={orders} refresh={handleGetData} />}
            {currentTab === "pending" && (
              <OrderListTable ordersData={pendingOrders} refresh={handleGetData} />
            )}
            {currentTab === "confirmed" && (
              <OrderListTable ordersData={confirmedOrders} refresh={handleGetData} />
            )}
            {currentTab === "ready" && (
              <OrderListTable ordersData={readyOrders} refresh={handleGetData} />
            )}
            {currentTab === "completed" && (
              <OrderListTable ordersData={completeOrders} refresh={handleGetData} />
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default Orders;
