import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CardStats from "./CardStats";

import FoodItemsTable from "./ChartsGraphsTables/FoodItemsTable";
import OrdersAreaChart from "./ChartsGraphsTables/OrdersAreaChart";
import OrdersOverviewPerMenu from "./ChartsGraphsTables/OrdersOverviewPerMenu";
import OrdersOverview from "./ChartsGraphsTables/OrdersOverview";
import { useSelector } from "react-redux";
import { useGetCardStats } from "../../services/charts";

const DashboardStats = () => {
  const { currentBranch } = useSelector((state) => state.branch);
  const { data, isLoading } = useGetCardStats(currentBranch?.id)

  // console.log('data', data)

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid sx={{width: "100%", mb:2, mt:1 }}>
          <CardStats data={data} loading={isLoading} />
        </Grid>
        <Grid xs={12} xl={8}>
          <Stack>
            <OrdersAreaChart />
            <FoodItemsTable />
          </Stack>
        </Grid>
        <Grid
        xs={0}
          xl={4}
          sx={(theme) => ({
            [theme.breakpoints.down("xl")]: {
              display: "none",
            },
          })}
        >
          <Stack>
            <Box
              sx={{
                mt: 2,
                height: 433,
                border: 1,
                borderColor: "secondary.tint_5_bg",
                borderRadius: 2,
              }}
            >
              <Box>
                <OrdersOverviewPerMenu />
              </Box>
            </Box>

            <Box
              sx={{
                mt: 5,
                border: 1,
                borderColor: "secondary.tint_5_bg",
                borderRadius: 2,
                height: 400,
              }}
            >
              <OrdersOverview
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardStats;