import React, { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import AddonTable from '../components/tables/AddonTable';
import FoodAddons from '../components/Menu/modals/FoodAddons';
import { LinearProgress } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import LoadingCard from '../components/Loading';
import AxiosPrivate from './axios/axiosPrivate';

function FetchAddOns() {
    const { pathname } = useLocation()
    const cat_id = pathname.split('/')[4]
    const res_code = pathname.split('/')[7]

    const [visible, setVisible] = React.useState(false)
    const [addonData, setAddonData] = React.useState(useMemo(() => [], []))
    const [loading, setLoading] = React.useState(false)
    const [addon, setAddon] = React.useState(null)
    const [toDelete, setToDelete] = React.useState(false)

    const { restaurant : { id } } = useSelector((state) => state.auth);

    const handleClose = () => {
        setVisible(false)
    }
    
    const columns = useMemo(() => [
        // {
        //     Header: "Id",
        //     accessor: 'id',
        // },
        {   
            Header: "Food Name",
            Cell: ({ row }) => (
                <div className="flex items-center space-x-2">
                    {/* <img className='h-7 w-7 rounded-full object-cover object-center' src={row.original.food_image} alt="food_image"/> */}
                    <p className="cursor-pointer hover:text-yellow-600 transition-all capitalize">{row.original.addon_name}</p>
                </div>
            )
        },
        {
            Header: "Maximum Count",
            accessor: 'maximum_count',
        },
        {
            Header: "Price",
            accessor: 'price',
        },
        {   
            Header: "Required",
            Cell: ({ row }) => (
                <div className={`py-1 rounded-full w-16 text-center text-xs ${row.original.isRequired ? "bg-green-100 " : "bg-red-100"}`}>
                    <p className={`${row.original.isRequired ? "text-green-500" : "text-red-500"}`}>{row.original.isRequired ? "yes" : "no"}</p>
                </div>
            )
        },
        {   
            Header: "Status",
            Cell: ({ row }) => (
                <div className={`py-1 rounded-full w-16 text-center text-xs ${row.original.isRequired ? "bg-green-100 " : "bg-red-100"}`}>
                    <p className={`${row.original.isRequired ? "text-green-500" : "text-red-500"}`}>{row.original.isRequired ? "visible" : "hidden"}</p>
                </div>
            )
        },
        {
            Header: "Action",
            id: "Action",
            Cell: ({ row }) => (
            <div className="flex items-center">
                <button
                    onClick={() => {
                        handleVisible()
                        setAddon(row?.original)
                        setToDelete(false)
                    }}
                    className='hover:bg-gray-300 text-xs font-medium grid justify-center items-center w-8 h-8 rounded-full'
                >
                   <EditIcon className='w-5 h-5' />
                </button>
                {/* onClick={(e) => handleRowClick(row)} */}
                <button
                    onClick={() => {
                        handleVisible()
                        setAddon(row?.original)
                        setToDelete(true)
                    }}
                    className='hover:bg-gray-300 text-xs font-medium grid justify-center items-center w-8 h-8 rounded-full'
                >
                   <DeleteIcon className='w-5 h-5' />
                </button>
            </div>
            )
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []);

    
    // const navigate = useNavigate();

    // const handleRowClick = (row) => {
    //     const menu_id = row.original.menu_category.menu_code;
    //     const category_id = row.original.category_detail.id;
    //     const restaurant_code = row.original.menu_category.restaurant_code
    //     const food_code = row.original.food_code
    //     navigate(`/homepage/menu/${menu_id}/category/${category_id}/restaurant/${restaurant_code}/food/${food_code}`);
    // }

    const handleVisible = () => {
        setVisible(true)
    }

    const fetchFoodAddons = async () => {
        try {
            setLoading(true)
            const res = await AxiosPrivate.get(`/restaurant/food-addon/${id}`)
            setAddonData(res.data.data)
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    React.useEffect(() => {
        fetchFoodAddons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    
    return (
        <>
            {loading && <LoadingCard />}
            <AddonTable 
            columns={columns} 
            data={addonData} 
            csvData={addonData} 
            fileName='food_items'
            restaurant_code={res_code}
            category_id={cat_id}
            handleVisible={handleVisible}
            setAddon={setAddon}
            setToDelete={setToDelete}
            />
            <FoodAddons
            addon={addon}
            toDelete={toDelete}
            visible={visible}
            handleClose={handleClose}
            foodCode={res_code}
            reload={fetchFoodAddons}
            />
            <Tooltip anchorId="view-food-addon" />
        </>
    )
}

export default FetchAddOns;