import React, { useState, useRef, useEffect } from "react";
import { storage } from "../../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import { Box, Stack, Button, Avatar, CircularProgress } from "@mui/material";
import { makeStyles } from '@mui/styles'
import { useDropzone } from 'react-dropzone'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { notify } from "../../Toast";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    // marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    // padding: 5,
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%',
  },

  root: (props) => ({
    ...{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .64s ease-in-out',
      height: 100,
      justifyContent: 'center',
    },
    ...(props.isDragActive ? { borderColor: '#2164f3' } : {}),
    ...(props.isDragAccept ? { borderColor: '#00e676' } : {}),
    ...(props.isDragReject ? { borderColor: '#ff1744' } : {}),
  }),
})

const ImageUploader = ({ changeAvatar, title, current, preview_url }) => {
  const [file, setFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const filePickerRef = useRef();
  const maxSize = 2097152;

  const { restaurant } = useSelector((state) => state.auth);

  const path = restaurant?.restaurant_name?.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    // accept: ['image/jpeg', 'image/png', 'image/jpg'],
    // accept: {
    //   'image/jpeg': [],
    //   'image/png': [],
    //   'image/jpg': []
    // },
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg']
    },
    maxFiles: 1,
    minSize: 0,
    // maxSize: 2000000,
    maxSize,
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length < 1) {
        // notify('Image is too large, the maximum is 2MB', 'error')
        fileRejections.map(({ file, errors }) => (
          errors.map(e => {
            if (e.code === "file-too-large") {
              return notify('Image is too large, the maximum is 2MB', 'error')
            } else {
              return notify(e.message, 'error')
            }
          })
        ))
      } else {
        return acceptedFiles.map((file) => {
          setFile(file)
          uploadImage(file)
        })[0]
      }
    },
  })

  const props = { isDragActive, isDragAccept, isDragReject }
  const classes = useStyles(props)

  const uploadImage = async (file) => {
    if (!file) return;
    // console.log("SENDING IMAGE FROM IMAGE UPLOADER");
    setUploading(true)
    const imageRef = ref(storage, `/${path}/${Date.now()}`);
    let res1 = await uploadBytes(imageRef, file);
    console.log(res1);
    let url = await getDownloadURL(res1.ref);
    setPreviewUrl(url)
    changeAvatar(url);
    setUploading(false)
  };

  const pickedImageHandler = () => {
    filePickerRef.current.click();
  };

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  return (
    <Stack alignItems="center" justifyContent="start" sx={{ my: 2 }}>

      <div {...getRootProps({})} className='flex items-center w-full'>
        <div
          className={`relative w-32 h-32 z-0 border border-dashed ${isDragActive ? 'border-orange-600' : isDragAccept ? 'border-green-500' : 'border-gray-400'} rounded-md flex items-center justify-center gap-x-2 cursor-pointer`}
        >
          <input {...getInputProps()} ref={filePickerRef} accept=".jpg,.png,.jpeg" />
          {previewUrl || preview_url ? (
            <div className={classes.thumb} style={{ border: 'none', margin: 0 }}>
              <div className={classes.thumbInner}>
                <img
                  alt=''
                  src={preview_url || previewUrl || '/icons/upload-image.svg'}
                  className={`w-32 h-32 z-0 rounded-md`}
                // layout='fill'
                />
              </div>
            </div>
          ) : current ? (
            <div className={classes.thumb} style={{ border: 'none', margin: 0 }}>
              <div className={classes.thumbInner}>
                <img
                  src={current}
                  className='z-0 object-cover w-32 h-32 shadow'
                // layout='fill'
                />
              </div>
            </div>
          ) : (
            <div className={` ${classes.thumbInner} flex-col gap-2`}>
              <CloudUploadIcon />
              <h1 className='w-full text-sm text-center text-primary'>{title}</h1>
            </div>
          )}
          {uploading && (
            <div className="absolute z-90 top-0 left-0 bottom-0 right-0 text-white flex bg-[#00000080] justify-center items-center">
              <CircularProgress sx={{ color: 'white' }} size="1.1rem" />
              <p className="text-xs font-semibold px-2 py-1">Uploading...</p>
            </div>
          )}
        </div>
      </div>

    </Stack>
  );
};

export default ImageUploader;