import React from 'react'

const ArrowIcon = ({ fill, width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.8327 1.08325L6.99935 6.91659L1.16602 1.08325'
        stroke={fill}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ArrowIcon
