import { useState } from "react";
import AuthLayout from "../../components/Layout/Auth";
import {
    InputAdornment,
    IconButton,
    Divider,
    Button,
    Typography,
    Grid,
    Link as MuiLink
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { motion } from "framer-motion";
import Dagizo_Logo from "../../images/Dagizo_Logo.png";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { verifyEmailAddress } from "../../functions/auth";
import { notify } from "../../components/Toast";
import Verified from "../verifySuccess";

const ConfirmEmail = () => {
    if (localStorage.getItem("emailVerified") === null) {
        localStorage.setItem("emailVerified", JSON.stringify(false));
    }
    const { ID } = useParams();
    const [isVerified, setIsVerified] = useState(false)
    const [loading, setIsloading] = useState(false);
    const [values, setValues] = useState({
        password: null,
        newPassword: null,
        confirmPassword: null,
        showPassword: null
    });

    const {
        password,
        newPassword,
        confirmPassword,
        showPassword,
    } = values;

    const handleChange = (e) => {
        const { value, name } = e.target
        setValues({ ...values, [name]: value })
    };

    const handleClickShowPassword = (name) => {
        if (showPassword && showPassword === name) {
            setValues({ ...values, showPassword: null })
        } else {
            setValues({ ...values, showPassword: name })
        }
    };

    const handleMouseDownPassword = () => { };

    const passwordsMatch = () => {
        return newPassword === confirmPassword;
    };

    const unmatchOld = () => {
        return password === (newPassword || confirmPassword);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!passwordsMatch()) {
            notify("Password does not match", "error")
        } else if (unmatchOld()) {
            notify("New password should not match old password", "error")
        } else if (!passwordsMatch || !newPassword || !confirmPassword) {
            notify("All fields are required", "error")
        } else {
            setIsloading(true)
            verifyEmailAddress(password, newPassword, confirmPassword, ID).then(res => {
                if (res.data.status === true) {
                    notify("Account created successfully!", "success");
                    localStorage.setItem("emailVerified", true);
                    setIsVerified(true)
                } else if (res.data.status === false) {
                    notify(res.data.error, "error");
                }
            }).catch(err => {
                notify(err.response.data.error, "error");
            })
            setIsloading(false)
        }
    };

    const isEmailVerified = JSON.parse(localStorage.getItem("emailVerified"));

    function func() {
        let value = false;
        if (isEmailVerified || isVerified) {
            return value = true;
        }
        return value;
    }

    const inputs = [
        {
            name: "password",
            label: "Password"
        },
        {
            name: "newPassword",
            label: "New Password"
        },
        {
            name: "confirmPassword",
            label: "Confirm Password"
        }
    ]

    console.log(func());

    return (
        !func() ? <AuthLayout>
            <Grid container spacing={1} sx={{ mt: 5 }}>
                <Grid>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.4 }}
                        className="mb-10"
                    >
                        <img src={Dagizo_Logo} alt="DAGIZO" width="170px" />
                    </motion.div>
                    <ValidatorForm autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.4 }}
                        >
                            <Typography
                                variant="h5"
                                color="black"
                                fontWeight="700"
                                sx={{ mt: 3 }}
                            >
                                VERIFY YOUR EMAIL
                            </Typography>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6 }}
                        >
                            <Typography sx={{ fontSize: 14, mb: 4 }}>
                                Verify your email by entering the passcode provided.
                            </Typography>
                        </motion.div>

                        {inputs.map((el, i) => (
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.8 }}
                                key={i}
                            >
                                <Typography sx={{ mt: 2 }}>{el.label}</Typography>
                                <TextValidator
                                    type={values.showPassword === el.name ? "text" : "password"}
                                    sx={{
                                        mt: 1,
                                        backgroundColor: "secondary.input_field",
                                        "& fieldset": {
                                            border: "none",
                                        },
                                    }}
                                    fullWidth
                                    name={el.name}
                                    value={values[el.name]}
                                    onChange={(e) => handleChange(e)}
                                    validators={["required"]}
                                    errorMessages={["This Field is Required"]}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleClickShowPassword(el.name)}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {values.showPassword === el.name ? (
                                                        <VisibilityOffIcon />
                                                    ) : (
                                                        <VisibilityIcon />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </motion.div>
                        ))}

                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                        >
                            <Button
                                type="submit"
                                disabled={loading}
                                disableElevation
                                fullWidth
                                sx={{ bgcolor: "secondary.main", color: "white", mt: 2 }}
                                variant="contained"
                            >
                                {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : "Verify"}
                            </Button>
                        </motion.div>
                    </ValidatorForm>

                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                    >
                        <Divider sx={{ my: 2 }}>Or</Divider>
                        <Typography sx={{ fontSize: 14, mt: 2 }}>
                            By using Dagizo you are agreeing to{" "}
                            <MuiLink
                                sx={{ textDecoration: "none" }}
                                component={Link}
                                to={"terms-and-conditions"}
                            >
                                Terms & Conditions and Privacy Policy
                            </MuiLink>
                        </Typography>
                    </motion.div>

                </Grid>
            </Grid>
        </AuthLayout> : <Verified />
    )
}

export default ConfirmEmail