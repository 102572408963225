import React from "react";
import { Box, Stack, Button, Typography } from "@mui/material";

import Restaurant_Menu from "../../images/Restaurant_Menu.png";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const NoFood = () => {
  const { pathname } = useLocation()
  const menuId = pathname.split('/')[3]
  const categoryId = pathname.split('/')[4]
  const resCode = pathname.split('/')[7]

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100vh" }}
    >
      <Box>
        <img src={Restaurant_Menu} alt="Restaurant Outline"></img>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography>
          You currently don't have any saved Food items.<br></br>Let's start by
          creating one.
        </Typography>
      </Box>
      <Box sx={{ mt: 2, width: 280 }}>
        {/* <Link to={`/homepage/menu/${menuId}/category/${categoryId}/restaurant_code/${resCode}/add_food`}> */}
        <Link to={`/dashboard/menu/category/${categoryId}/add_food`}>
          <Button
            startIcon={
              <EditIcon
                sx={{
                  border: 1,
                  borderRadius: 1,
                  bgcolor: "white",
                  borderColor: "white",
                  color: "black",
                }}
              />
            }
            disableElevation
            fullWidth
            variant="contained"
            sx={{
              bgcolor: "secondary.main",
              color: "white",
              height: 55,
              fontSize: "1rem",
            }}
          >
            Add Food
          </Button>
        </Link>
      </Box>
    </Stack>
  );
};

export default NoFood;
