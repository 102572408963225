import React, { useEffect, useState } from "react";

import { Box, Button, Typography, Stack, CircularProgress, Select, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ImageUploader from "../../common/utils/ImageUploader";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../Toast";
import { setCredentials } from "../../../features/auth/authSlice";
import AxiosPrivate from "../../../functions/axios/axiosPrivate";

const AdminProfileForm = () => {
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    user_image: null,
    phone: "",
    email: "",
    gender: "",
  });
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const { user, restaurant, accessToken } = useSelector((state) => state.auth);

  console.log('user', user)

  const { first_name, last_name, user_image, phone, email, gender } =
    values;
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const getProfile = async () => {
    try {
      const res = await AxiosPrivate.get(`/users/user-profile/${user.id}`)
      const data =  res.data

      console.log('data', data)
      setValues({
        first_name: data.first_name,
        last_name: data.last_name,
        user_image: data.user_image,
        phone: data.phone,
        email: data.email,
        gender: data.gender,
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getProfile()
  }, [])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const changeAvatar = (url) => {
    setValues({ ...values, user_image: url });
  };

  // const handleDateChange = (date) => {
  //   setValues({ ...values, dateOfBirth: date });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      first_name &&
      last_name &&
      user_image &&
      phone &&
      email &&
      gender
    ) {
      setLoading(true)
      return (
        AxiosPrivate.post(`/users/user-profile/${user.id}`, values)
        .then((res) => {
          setLoading(false)
          notify(res.data.message, 'success')
          console.log(res);
          console.log(res.data.status);
          dispatch(
            setCredentials({
              user: {
                ...user,
                user_image: values.user_image,
                first_name: values.first_name,
                last_name: values.last_name,
              },
              restaurant,
              accessToken,
            })
          );
          // setValues({ ...values, branchName: "", branchCode: "" });
        })
        .catch((err) => {
          setLoading(false)
          notify(err.data?.message, 'error')
          console.log(err);
          // if (err.response.status === 400) toast.error(err.response.data);
        })
      );
    }
  };

  return (
    <Box
      sx={(theme) => ({
        flexGrow: 1,
        bgcolor: "secondary.input_field",

        borderRadius: 5,
        p: 5,
        [theme.breakpoints.down("md")]: {},
      })}
    >
      <ValidatorForm autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
        <Grid container>
          <Grid xs={12}>
            {" "}
            <Stack direction="row" justifyContent="flex-start">
              {" "}
              <ImageUploader
                changeAvatar={changeAvatar}
                title={"Change Your Profile Image"}
                current={user_image}
              />
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid xs={12} lg={6}>
            <Box>
              {" "}
              <Typography sx={{ color: "black" }}>First Name</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("first_name")}
                name="name"
                value={first_name}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              {" "}
              <Typography sx={{ color: "black" }}>Last Name</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("last_name")}
                name="name"
                value={last_name}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              {" "}
              <Typography sx={{ color: "black" }}>Email</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("email")}
                name="email"
                value={email}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>Phone Number</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("phone")}
                name="phone"
                value={phone}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>Gender</Typography>
              <Select fullWidth value={gender} onChange={handleChange("gender")}>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Famale">Female</MenuItem>
              </Select>
            </Box>
          </Grid>
          {/* <Grid xs={12} lg={6}>
            <Box>
              <Typography sx={{ color: "black" }}>Date Of Birth</Typography>
              <DatePicker handleDateChange={handleDateChange} />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>Location</Typography>
              <Geolocation
                setLatitude={setLatitude}
                setLongitude={setLongitude}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>Gender</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("gender")}
                name="gender"
                value={gender}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>
          </Grid> */}
        </Grid>
        <Button
          disabled={loading}
          sx={{ mt: 4, color: "white", fontSize: "1rem" }}
          type="submit"
          variant="contained"
          fullWidth
          disableElevation
        >
          {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : "Save Changes"}
        </Button>
      </ValidatorForm>
    </Box>
  );
};

export default AdminProfileForm;
