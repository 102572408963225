import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Grid from "@mui/material/Unstable_Grid2";
import MenuImageUploader from "../utils/MenuImageUploader";
import CustomSnackBar from "../../common/utils/CustomSnackBar";
import BranchSelect from "../../common/utils/BranchSelect";
import { fetchBranches } from "../../../functions/restaurant";
import { setAllBranches } from "../../../features/branches/branchSlice";

import { createMenu, deleteMenu, updateMenu } from "../../../functions/menu";
import ImageUploader from "../../common/utils/ImageUploader";
import { notify } from "../../Toast";

const CreateMenuModal = ({
  openCreateMenuModal,
  closeCreateMenuModal,
  toDelete,
  menu,
}) => {
  const [values, setValues] = useState({
    menu_name: "",
    menu_image: "",
    openSnackBar: false,
    snackBarMessage: "",
    snackBarSeverity: "success",
  });

  const {
    menu_name,
    menu_image,
    openSnackBar,
    snackBarMessage,
    snackBarSeverity,
  } = values;
  const [loading, setLoading] = useState(false);

  const { currentBranch } = useSelector((state) => state.branch);
  const branchId = currentBranch?.id

  useEffect(() => {
    if (menu) {
      setValues({
        menu_name: menu.menu_name,
        menu_image: menu.menu_image,
        openSnackBar: false,
        snackBarMessage: "",
        snackBarSeverity: "success",
      })
    } else {
      setValues({
        menu_name: "",
        menu_image: "",
        openSnackBar: false,
        snackBarMessage: "",
        snackBarSeverity: "success",
      })
    }
  }, [menu])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const changeAvatar = (url) => {
    setValues({ ...values, menu_image: url });
  };
  const handleClose = () => {
    closeCreateMenuModal();
  };

  const closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setValues({ ...values, openSnackBar: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!branchId) {
      notify("Please Select Branch", "error")

      return;
    } else {
      if (menu_name) {
        setLoading(true)

        if (menu) {
          const menu_id = menu.id
          const is_active = true
          return updateMenu(menu_name, menu_image, menu_id, is_active)
            .then((res) => {
              if (res.data.status === true) {
                // console.log("CREATE MENU RESPONSE", res.data.status);
                notify(res?.data?.message, "success")
                setLoading(false)
                setTimeout(() => {
                  handleClose();
                  window.location.reload(false);
                }, 1000);
              } else if (res.data.status === false) {
                notify(res?.data?.message, "error")
                setLoading(false)
              }
            })
            .catch((err) => {
              setLoading(false)
              console.log(
                "🚀 ~ file: CreateMenuModal.js:72 ~ returncreateMenu ~ err",
                err
              );
              notify(err?.message, "error")
            });
        } else {
          e.preventDefault();

          setLoading(true)
          return createMenu(menu_name, menu_image, branchId)
            .then((res) => {
              notify(res.data.message, "success")
              setLoading(false)
              setTimeout(() => {
                handleClose();
                window.location.reload(false);
              }, 1000);
            })
            .catch((err) => {
              setLoading(false)
              console.log(
                "🚀 ~ file: CreateMenuModal.js:72 ~ returncreateMenu ~ err",
                err
              );
              notify(err?.message, "error")
            });
        }
      }
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const menu_id = menu.id
    setLoading(true)
    return deleteMenu(menu_id)
      .then((res) => {
        if (res.data.status === true) {
          // console.log("CREATE MENU RESPONSE", res.data.status);
          notify(res.data.message, "success")
          setLoading(false)
          setTimeout(() => {
            handleClose();
            window.location.reload(false);
          }, 1000);
        } else if (res.data.status === false) {
          notify(res.data.message, "error")
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log(
          "🚀 ~ file: CreateMenuModal.js:72 ~ returncreateMenu ~ err",
          err
        );
        notify(err?.message, "error")
      });
  };

  return (
    <Modal open={openCreateMenuModal} onClose={handleClose}>
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40vw",
          bgcolor: "background.paper",

          borderRadius: 2,
          borderColor: `primary.main`,
          elevation: 24,
          [theme.breakpoints.down("md")]: {
            width: "80vw",
          },
        })}
      >
        <Box sx={{ position: "absolute", top: 5, right: 5 }}>
          <IconButton onClick={handleClose}>
            <CloseOutlinedIcon
              sx={{ borderRadius: 25, bgcolor: "black", color: "white" }}
            />
          </IconButton>
        </Box>
        <Stack sx={{ p: 4 }}>
          <Typography variant="p" fontWeight="500" color="black" fontSize="24px">
            {toDelete ? "Delete" : menu ? "Edit" : "Create"} Menu
          </Typography>
          {!toDelete ? (
            <form onSubmit={handleSubmit} className="mt-4">
              <Grid container spacing={2}>
                {" "}
                <Grid xs={12}>

                  {/* <Stack direction="row" sx={{ justifyContent: "flex-start" }}>
                    <Box>
                      {" "}
                      <MenuImageUploader
                        changeAvatar={changeAvatar}
                        title={"Upload Menu Image"}
                      />
                    </Box>
                  </Stack> */}

                  <Stack direction="row" justifyContent="start">
                    <ImageUploader
                      changeAvatar={changeAvatar}
                      title={"Upload Menu Image"}
                      current={menu_image}
                    />
                  </Stack>

                  <Typography color="black" sx={{ mt: 2 }}>
                    Menu Name
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    value={menu_name}
                    onChange={handleChange("menu_name")}
                  />

                </Grid>
              </Grid>
              <Button
                type="submit"
                disabled={loading}
                onClick={handleSubmit}
                disableElevation
                sx={{ mt: 4, height: 55, fontSize: "1rem", color: "white" }}
                variant="contained"
                fullWidth
              >
                {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : menu ? "Update Menu" : "Add Menu"}
              </Button>
            </form>
          ) : (
            <div>
              <p className="my-6">Are you sure you want to delete <b>{menu.menu_name}</b> ?</p>
              <div className="flex gap-4 justify-end">
                <button onClick={handleClose} className="bg-gray-200 px-6 py-2 rounded">Cancel</button>
                <button disabled={loading} onClick={handleDelete} className="flex items-center gap-3 bg-red-600 px-6 py-2 text-red-100 rounded">
                  Delete {loading && (<CircularProgress color="inherit" size="1.2rem" />)}
                </button>
              </div>
            </div>
          )}


        </Stack>
      </Box>
    </Modal>
  );
};

export default CreateMenuModal;
