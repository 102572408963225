import React, { useState } from "react";

import { Tabs, Tab, Stack } from "@mui/material";

import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import Box from "@mui/material/Box";

const CustomerNav = () => {
  const [value, setValue] = useState("All Customers");
  const [tableView, setTableView] = useState("Split-Screen")

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
   const handleTableViewChange = (event, newValue) => {
     setTableView(newValue);
   };

  return (
    <Box
      sx={{
        width: "100%",

        "& .MuiTab-root": {
          textTransform: "none",
          fontSize: "1rem",

          mr: 10,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Box>
          <Tabs value={value} onChange={handleChange}>
            <Tab value="All Customers" label="All Customers" />
            <Tab value="Returning Customers" label="Returning Customers" />
            <Tab value="New Customers" label="New Customers" />
            <Tab value="Referall Customers" label="Referall Customers" />
          </Tabs>
        </Box>

        <Box>
          <Tabs
            TabIndicatorProps={{ hidden: true }}
            value={tableView}
            onChange={handleTableViewChange}
          >
            <Tab value="Split-Screen" icon={<SplitscreenIcon />} />
            <Tab value="Grid-View" icon={<GridViewRoundedIcon />} />
          </Tabs>
        </Box>
      </Stack>
    </Box>
  );
};

export default CustomerNav;
