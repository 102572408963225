import React from 'react';
import { Stack, Box, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2";
import AccountAndNotifications from '../common/utils/AccountAndNotifications';
import MenuNav from './nav/MenuNav';
import FoodTab from './FoodTab';
import BackButton from '../../utils/BackButton';

const AddFood = () => {

  return (
    <>
      <Box>
        <div className='flex items-center space-x-5 pb-4'>
          <Typography variant="h4" sx={{ color: "black", fontWeight: 700 }}>Add Food</Typography>
          <BackButton />
        </div>
      </Box>
      <Grid container spacing={2}>
        <Grid xs={12}><FoodTab /></Grid>
      </Grid>
    </>
  );
}

export default AddFood;