import React, { useState } from "react";
import { Box, Stack, Button, Typography } from "@mui/material";

import Room_Icon from "../../../images/Room_Icon.png";
import EditIcon from "@mui/icons-material/Edit";

import RoomSettingModal from "./modals/RoomSettingModal";

const NoRooms = ({ refetch }) => {
  const [openRoomSettingModal, setOpenRoomSettingModal] = useState(false);

  const handleOpen = () => setOpenRoomSettingModal(true);
  const closeRoomSettingModal = () => setOpenRoomSettingModal(false);

  return (
    <Stack justifyContent="center" alignItems="center" sx={{ height: 400 }}>
      <Box>
        <img src={Room_Icon} alt="Room Icon"></img>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography>
          You currently don't have any saved Rooms.<br></br>Let's start by
          creating a room
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button
          onClick={handleOpen}
          startIcon={
            <EditIcon
              sx={{
                border: 1,
                borderRadius: 1,
                bgcolor: "white",
                borderColor: "white",
                color: "black",
              }}
            />
          }
          disableElevation
          fullWidth
          variant="contained"
          sx={{
            color: "white",
            height: 55,
            fontSize: "1rem",
          }}
        >
          Create Rooms
        </Button>
        <RoomSettingModal
          openRoomSettingModal={openRoomSettingModal}
          closeRoomSettingModal={closeRoomSettingModal}
          refetch={refetch}
        />
      </Box>
    </Stack>
  );
};

export default NoRooms;
