import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setRoomToGenerateQR } from "../../../../features/rooms/roomSlice";
import { Box, MenuItem, IconButton, Menu } from "@mui/material";

import CustomDataGrid from "../../../../styling/CustomComponents/CustomDataGrid";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import TableCreationModal from "../modals/TableCreationModal";
import TableDrawer from "../../../TableDrawer";
import RoomsDrawer from "../../../Settings/RoomsDrawer/RoomsDrawer";

export function SortedDescendingIcon() {
  return <ArrowDropDownIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ArrowDropUpIcon className="icon" />;
}

const RoomsAndAreasTable = ({ roomsAndAreasData, onEdit, type }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [rowParams, setRowParams] = useState([]);
  const [openTableCreationModal, setOpenTableCreationModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openID, setOpenId] = useState(null);
  const [roomOpen, setRoomOpen] = useState(false);

  const handleOpen = () => setOpenTableCreationModal(true);
  const closeTableCreationModal = () => setOpenTableCreationModal(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElNav(null);
  };
  const handleAddTables = () => {
    handleCloseMenu()
    handleOpen();
  };

  const handleGenerateQR = () => {
    handleCloseMenu();
    dispatch(setRoomToGenerateQR({ roomToGenerateQR: rowParams }));
    navigate("/dashboard/qr-codes", {
      state: { shouldGenerateQR: true },
    });
  };

  const handleActionsClick = (event, params) => {
    console.log("🚀 ~ file: RoomsAndAreasTable.js:53 ~ handleActionsClick ~ params", params.row)
    handleOpenMenu(event);
    setRowParams(params.row);
  };

  // console.log(roomsAndAreasData)

  const columns = [
    // {
    //   field: "place_type",
    //   headerName: "Place Type",
    //   width: 200,
    // },
    {
      field: "place_name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "maximum_tables",
      headerName: "NO of Tables",
      width: 200,
    },
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        return (
          <IconButton onClick={(event) => handleActionsClick(event, params)}>
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
  ];


  const floorColumns = [
    { field: 'section_name', headerName: 'Name', width: 200 },
    { field: 'number_of_rooms', headerName: 'Number of Rooms', width: 200 },
    { field: 'service_charge', headerName: 'Service Charge ( KSH )', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => {
        return (
          <IconButton onClick={(event) => { handleActionsClick(event, params); setOpenId(params.row.id) }}>
            <MoreHorizIcon />
          </IconButton>
        );
      },
      sortable: false,
    }
  ];

  return (
    <Box
      sx={{
        mt: 5,
        "& .MuiDataGrid-columnHeaders": {
          color: "black",
          fontSize: 18,
        },
        width: "100%",
      }}
    >
      {" "}
      <TableCreationModal
        openTableCreationModal={openTableCreationModal}
        closeTableCreationModal={closeTableCreationModal}
        location_id={rowParams.id}
      />
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenu()
            onEdit(rowParams, false)
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenu()
            onEdit(rowParams, true)
          }}
        >
          Delete
        </MenuItem>
        {
          type !== 'rooms' &&
          <>
            <MenuItem onClick={handleGenerateQR}>Generate QR</MenuItem>
            <MenuItem onClick={handleAddTables}>Add Tables</MenuItem>
          </>
        }
        <MenuItem
          onClick={() => { handleCloseMenu(); type === 'rooms' ? setRoomOpen(true) : setOpen(true) }}
        >
          {type === 'rooms' ? "Show Rooms" : "Show Tables"}
        </MenuItem>
      </Menu>{" "}
      <CustomDataGrid rows={roomsAndAreasData} columns={type === "rooms" ? floorColumns : columns} title="Rooms & Areas" />
      <TableDrawer open={open} setOpen={setOpen} room={rowParams} />
      <RoomsDrawer onClose={() => setRoomOpen(false)} roomID={openID} open={roomOpen} section={rowParams?.section_name} serviceFees={rowParams?.service_charge} />
    </Box>
  );
};

export default RoomsAndAreasTable;
