const employeeRoles = {
    viewPermissions() {
        return [
            {
                name: "Dashboard",
                code: 1001
            },
            {
                name: "Branches",
                code: 1002
            },
            {
                name: "Rooms",
                code: 1003
            },
            {
                name: "Menu",
                code: 1004
            },
            {
                name: "Orders",
                code: 1005
            },
            {
                name: "QR codes",
                code: 1006
            },
            {
                name: "Settings",
                code: 1007
            },
            // {
            //     name: "Full Access",
            //     code: 1000
            // }
        ]
    },

    actionsPermissions() {
        return [
            {
                name: "Edit",
                code: 2001
            },
            {
                name: "Delete",
                code: 2002
            },
            {
                name: "Progress orders",
                code: 2003
            },
            {
                name: "Receive cash",
                code: 2004
            },
            // {
            //     name: "Full access",
            //     code: 1000
            // }
        ]
    }
};

export { employeeRoles }