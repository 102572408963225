import toast from 'react-hot-toast';

export const notify = (message, type) => {
  switch (type) {
    case 'error':
      toast.error(message)
      break
    case 'success':
      toast.success(message)
      break
    case 'warning':
      toast.warning(message)
      break
    default:
      toast.info(message)
  }
}
