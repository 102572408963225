import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetOrders } from "../../services/orders";
import { isMobile } from "react-device-detect";

import OrderMap from "./OrderMap";
import ViewOrderDetailsDrawer from "./drawers/ViewOrderDetailsDrawer";
import OrderDetails from "./OrderDetails";

const ExpandOrder = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { orderId } = useParams();
  const { currentBranch } = useSelector((state) => state.branch);

  const branch_id = currentBranch.id || null;

  const { orders } = useGetOrders(branch_id);
  // console.log(orders)

  const expandedOrder = orders?.find((order) => order.id === orderId);
  // console.log(expandedOrder);

  return (
    <div>
      {!isMobile && (
        <div className="grid grid-cols-3 max-h-screen h-screen overflow-hidden">
          <div className="h-screen col-span-2 flex-1 z-[9999]">
            <OrderMap
              orderLocation={{
                lat: expandedOrder ? expandedOrder?.delivery_latitude : 0,
                lng: expandedOrder ? expandedOrder?.delivery_logitude : 0,
              }}
              orderAddress={expandedOrder?.delivery_address}
            />
          </div>

          <div className="space-y-8 overflow-hidden h-full overflow-y-scroll px-6">
            <OrderDetails
              orderDetails={expandedOrder}
              showDeliveryDetails={true}
              showInterractionButtons={false}
              showMap={false}
            />
          </div>
        </div>
      )}
      {isMobile && (
        <div>
          <div className="flex flex-row items-center px-6 py-4 bg-gray-200 justify-between sticky top-0 z-50">
            {["Delivery Location", "Order Details"].map((el, i) => (
              <div
                onClick={() => setActiveTab(i)}
                key={i}
                className={`${
                  i === activeTab ? `bg-primary text-white` : null
                } w-full p-2.5 rounded-full text-center font-semibold`}
              >
                <p>{el}</p>
              </div>
            ))}
          </div>

          {activeTab === 0 && (
            <div className="h-screen w-full">
              <OrderMap
                orderLocation={{
                  lat: expandedOrder ? expandedOrder?.delivery_latitude : 0,
                  lng: expandedOrder ? expandedOrder?.delivery_logitude : 0,
                }}
                orderAddress={expandedOrder?.delivery_address}
              />
            </div>
          )}
          {activeTab === 1 && (
            <div className="px-6">
              <OrderDetails
                orderDetails={expandedOrder}
                showDeliveryDetails={true}
                showInterractionButtons={false}
                showMap={false}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExpandOrder;
