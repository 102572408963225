import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Stack,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";


import StyledSwitch from "../../../styling/CustomComponents/StyledSwitch";

// Import functions
import { setBranchOperatingHours } from "../../../functions/restaurant";

export const flattenObj = (obj, parent, res = {}) => {
    for (let key in obj) {
      let propName = parent ? parent + "_" + key : key;
      if (typeof obj[key] == "object" && !Array.isArray(obj[key])) {
        flattenObj(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  }
const ActiveHours = ({ day, setOpenHours, toggle }) => {
  const [checked, setChecked] = useState(true);
  const [openingTime, setOpeningTime] = useState(null);
  const [closingTime, setClosingTime] = useState(null);

  const branch_id = useSelector((state) => state?.branch?.branchToUpdate?.restaurant_code);

  useEffect(() => {
    if (day.from_time && day.to_time) {
      
      const dayObj = flattenObj(day)

      console.log("DAY BEFORE API CALL", dayObj);
      setBranchOperatingHours(dayObj, branch_id).then((res) => {
        console.log("ACTIVE HOURS RESPONSE", res);
      });
    }
  }, [day, setOpenHours, branch_id]);

  const handleOpeningTimeChange = (value) => {
    setOpeningTime(value.$d);
    allTimeSet();
  };

  const handleClosingTimeChange = (value) => {
    setClosingTime(value.$d);
    allTimeSet();
  };

  const allTimeSet = () => {
    if (openingTime && closingTime) {
      setOpenHours({
        ...day,
        from_time: new Date(openingTime).toLocaleTimeString(),
        to_time: new Date(closingTime).toLocaleTimeString()
      });
    } else {
      return;
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      fullWidth
      sx={(theme) => ({
        flexGrow: 1,
        // bgcolor: "secondary.input_field",
        borderRadius: 5,
        // p: 2,
        [theme.breakpoints.down("md")]: {},
      })}
    >
      {toggle && (
        <Box>
          <FormGroup>
            <FormControlLabel
              control={<StyledSwitch sx={{ m: 1 }} defaultChecked />}
              label={checked ? "Open" : "Closed"}
              onChange={handleChange}
            />
          </FormGroup>
        </Box>
      )}
      
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "20vw" }}
        >
          <div className="flex items-center space-x-2">
            <Box sx={{ width: 120 }}>
              {" "}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Start"
                  disabled={!checked}
                  value={openingTime}
                  onChange={(newValue) => handleOpeningTimeChange(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Typography>To</Typography>
            <Box sx={{ width: 120 }}>
              {" "}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="End"
                  disabled={!checked}
                  value={closingTime}
                  onChange={(newValue) => handleClosingTimeChange(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </div>
        </Stack>
      </Box>
    </Stack>
  );
};

export default ActiveHours;
