import React from 'react';
import * as animationData from '../../data/nodata_animation.json';
import Lottie from 'react-lottie';

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const NoData = ({ title }) => {
    return (
        <div className="flex gap-6 flex-col justify-center items-center w-full h-96">
            <h6 className='font-bold text-lg'>No {title} Found</h6>
            <div className="w-1/2 mt-10">
                <Lottie options={defaultOptions} />
            </div>
            
        </div>
    )
}

export default NoData