import React, { useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import FoodTable from "../components/tables/FoodTable";
import DeleteFoodModal from "../components/modals/DeleteFoodModal";
import FoodDrawer from "../components/Restaurant/RoomsAndAreas/modals/FoodSidebar";
import { Avatar, AvatarGroup } from "@mui/material";
import { FormSwitch } from "../components/common/SwitchComponent";
import { updateFoodVisibility } from "./menu";
import { notify } from "../components/Toast";

function FetchFood({ menuData, refresh }) {
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [food, setFood] = useState(null);
  const [search, setSearch] = useState("");

  const { pathname } = useLocation();

  const cat_id = pathname.split("/")[4];
  const menu_id = pathname.split("/")[3];
  const res_code = pathname.split("/")[7];

  const filteredData = menuData?.filter((entry) =>
    entry.food_name?.toLowerCase().includes(search)
  );

  const handleVisiblity = (id, availability) => {
    updateFoodVisibility(id, availability).then((res) => {
      notify(res.data.message, "success");
      refresh();
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Images",
        Cell: ({ row }) => {
          // console.log(Array.isArray(row.original.food_image_json))
          return (
            <div className="flex items-center space-x-2">
              <AvatarGroup max={3}>
                {Array.isArray(row.original?.food_image_json)
                  ? row.original.food_image_json.map((item) => (
                    <Avatar alt="Food Image" src={item.url} />
                  ))
                  : null}
              </AvatarGroup>
            </div>
          );
        },
      },
      {
        Header: "Food Name",
        Cell: ({ row }) => (
          <div className="flex items-center space-x-2">
            <p
              className="cursor-pointer hover:text-yellow-600 transition-all capitalize"
              onClick={(e) => handleRowClick(row)}
            >
              {row.original.food_name}
            </p>
          </div>
        ),
      },
      {
        Header: "Code",
        accessor: "food_code",
      },
      {
        Header: "Category",
        accessor: "dish_category",
      },
      {
        Header: "Price",
        Cell: ({ row }) => {
          // console.log(row);
          return (
            <div className="">
              <p className={`${row?.original?.discount_rate > 0 ? "line-through" : null}`}>
                {row?.original?.discount_rate > 0
                  ? row?.original?.original_price
                  : row?.original?.price}
              </p>
            </div>
          );
        },
      },
      {
        Header: "Discounted Price",
        Cell: ({ row }) => (
          <div className="">
            <p>{row?.original?.discount_rate > 0 ? row?.original?.price : "--"}</p>
          </div>
        ),
      },
      {
        Header: "Prep Time",
        accessor: "preparation_time",
      },
      {
        Header: "Status",
        Cell: ({ row }) => (
          <div className="flex justify-between items-center gap-2">
            <div>
              <div
                className={`px-2 py-1 rounded-full text-center text-xs ${row.original.is_in_stock ? "bg-green-100 " : "bg-red-100"
                  }`}
              >
                <p
                  className={`${row.original.is_in_stock ? "text-green-500" : "text-red-500"
                    }`}
                >
                  {row.original.is_in_stock ? "available" : "not available"}
                </p>
              </div>
            </div>

            <FormSwitch
              label=""
              onChange={() =>
                handleVisiblity(row.original.id, row.original.is_in_stock)
              }
              defaultValue={row.original.is_in_stock}
            />
          </div>
        ),
      },
      {
        Header: "Action",
        id: "Action",
        Cell: ({ row }) => (
          <div className="flex items-center">
            {/* <div id="add-food-addon" data-tooltip-content="Add Food Addon" onClick={() => handleVisible()} className='hover:bg-gray-300 text-xs font-medium grid justify-center items-center w-8 h-8 rounded-full'>
                   <AiOutlineAppstoreAdd className='w-5 h-5' />
                </div> */}
            <button
              id="view-food-item"
              data-tooltip-content="View Food"
              onClick={() => {
                setOpenDrawer(true);
                setFood(row.original);
              }}
              className="hover:bg-gray-300 text-xs font-medium grid justify-center items-center w-8 h-8 rounded-full"
            >
              <MdOutlineRemoveRedEye className="w-5 h-5" />
            </button>
            <button
              onClick={() => {
                const id = row.original?.id;
                const category_id = row.original.menu_category?.id;
                navigate(`/dashboard/menu/${menu_id}/${category_id}/${id}`);
              }}
              className="hover:bg-gray-300 text-xs font-medium grid justify-center items-center w-8 h-8 rounded-full"
            >
              <EditIcon className="w-5 h-5" />
            </button>

            <button
              onClick={() => {
                setOpen(true);
                setFood(row.original);
              }}
              className="hover:bg-gray-300 text-xs font-medium grid justify-center items-center w-8 h-8 rounded-full"
            >
              <DeleteIcon className="w-5 h-5" />
            </button>
          </div>
        ),
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  const navigate = useNavigate();

  const handleRowClick = (row) => {
    const id = row.original?.id;
    const category_id = row.original.menu_category?.id;
    navigate(`/dashboard/menu/${menu_id}/${category_id}/${id}`);
    // const category_id = row.original.menu_category.id;
    // navigate(`/dashboard/menu/category/${category_id}/add_food`);
  };

  return (
    <>
      <FoodTable
        columns={columns}
        data={filteredData}
        // csvData={filteredData}
        // fileName='food_items'
        // restaurant_code={res_code}
        category_id={cat_id}
        search={search}
        setSearch={setSearch}
      />
      {/* <FoodAddons 
            visible={visible}
            handleClose={handleClose}
            foodCode={res_code}
            /> */}
      {/* <Tooltip anchorId="add-food-addon" /> */}
      <Tooltip anchorId="view-food-item" />

      <DeleteFoodModal
        open={open}
        setOpen={setOpen}
        food={food}
        refresh={refresh}
      />
      <FoodDrawer
        open={openDrawer}
        setOpen={setOpenDrawer}
        food={food}
        menu_id={menu_id}
      />
    </>
  );
}

export default FetchFood;
