import { createSlice } from "@reduxjs/toolkit";

const roomSlice = createSlice({
  name: "room",
  initialState: {
    roomToGenerateQR: null,
    
  },
  reducers: {
    setRoomToGenerateQR: (state, action) => {
      const { roomToGenerateQR } = action.payload;
      state.roomToGenerateQR = roomToGenerateQR;
    },
  },
  // extraReducers: {},
});

export const { setRoomToGenerateQR } = roomSlice.actions;

export default roomSlice.reducer;