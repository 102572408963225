import React, { useState } from "react";
import { Box, Stack, Button, Typography } from "@mui/material";

import Restaurant_Menu from "../../images/Restaurant_Menu.png";
import EditIcon from "@mui/icons-material/Edit";
import CreateMenuModal from "./modals/CreateMenuModal";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";


const NoMenu = () => {
  const [openCreateMenuModal, setOpenCreateMenuModal] = useState(false);
  const closeCreateMenuModal = () => setOpenCreateMenuModal(false);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100vh" }}
    >
      <Box>
        <img src={Restaurant_Menu} alt="Restaurant Outline"></img>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography>
          You currently don't have any saved Menus.<br></br>Let's start by
          creating a Menu.
        </Typography>
      </Box>
      <Box sx={{ mt: 2, width: 280 }}>
        <Button
          onClick={() => setOpenCreateMenuModal(true)}
          startIcon={
            <EditIcon
              sx={{
                border: 1,
                borderRadius: 1,
                bgcolor: "white",
                borderColor: "white",
                color: "black",
              }}
            />
          }
          disableElevation
          fullWidth
          variant="contained"
          sx={{
            bgcolor: "secondary.main",
            color: "white",
            height: 55,
            fontSize: "1rem",
          }}
        >
          Create a Menu
        </Button>
        <CreateMenuModal
          openCreateMenuModal={openCreateMenuModal}
          closeCreateMenuModal={closeCreateMenuModal}
        />
      </Box>
    </Stack>
  );
};

export default NoMenu;
