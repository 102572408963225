import React from "react";
import { useSelector } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { useGetOrderStats } from "../../../services/charts";

export default function OrdersAreaChart() {
  const { currentBranch } = useSelector((state) => state.branch);
  const { data, isLoading } = useGetOrderStats(currentBranch?.id)

  return (
    <>
    {isLoading ? (
      <div className="animate-pulse">
        <div className="w-full h-[60vh] bg-gray-200"></div>
      </div>
    ) : (
      <div className="rounded-lg shadow-lg border border-[lavender] px-6 py-4">
        <h2 className="font-bold text-2xl mb-6">Orders</h2>

        <ResponsiveContainer width="100%" height={400}>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#f4b31570" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#f4b31570" stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip cursor={{ stroke: '#f4b313', strokeWidth: 2 }} />
            {/* <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" /> */}
            <Area type="monotone" dataKey="amount" stroke="#f4b313" fillOpacity={1} fill="url(#colorUv)" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      
    )}
      
    </>
    
  );
}