import React from "react";
import { Pagination, PaginationItem } from "@mui/material";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";

import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, Menu, MenuItem, Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import { red, green, amber } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import rowData from "./data/rowData";

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      shape="rounded"
      onChange={(value) => apiRef.current.setPage(value - 1)}
      renderItem={(item) => (
        <PaginationItem
          sx={{
            color: "primary.main",
          }}
          components={{
            previous: KeyboardDoubleArrowLeftIcon,
            next: KeyboardDoubleArrowRightIcon,
          }}
          {...item}
        />
      )}
    />
  );
};

function getChipProps(params) {
  if (params.value === "Completed") {
    return {
      label: params.value,
      style: {
        width: "100px",
        color: green[600],
        borderColor: green[100],
        backgroundColor: green[100],
        borderRadius: 5,
      },
    };
  } else if (params.value === "Pending") {
    return {
      label: params.value,
      style: {
        width: "100px",
        color: amber[600],
        borderColor: amber[100],
        backgroundColor: amber[100],
        borderRadius: 5,
      },
    };
  } else {
    return {
      label: "Cancel",
      style: {
        width: "100px",
        color: red[600],
        borderColor: red[100],
        backgroundColor: red[100],
        borderRadius: 5,
      },
    };
  }
}

export function SortedDescendingIcon() {
  return <ArrowDropDownIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ArrowDropUpIcon className="icon" />;
}

const CustomerListTable = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElNav(null);
  };

  const rows = rowData;
  const columns = [
    { field: "customerID", headerName: "Customer ID", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "date", headerName: "Date", width: 250 },
    { field: "orderID", headerName: "Order ID", width: 200 },

    {
      field: "price",
      headerName: "Price",
      width: 100,
      renderCell: (params) => {
        return (
          <span
            style={{
              color: "#F4B315",
              width: "100%",
            }}
          >
            ${params.value}
          </span>
        );
      },
    },
    {
      field: "orderType",
      headerName: "Order Type",
      width: 200,
    },
    {
      field: "orderStatus",
      headerName: "Status",
      width: 250,
      renderCell: (params) => {
        return (
          <Chip variant="outlined" size="medium" {...getChipProps(params)} />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        // console.log(params.row);

        return (
          <IconButton>
            <MoreVertIcon onClick={handleOpenMenu} />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        mt: 5,
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "primary.lightest_gray",
          fontSize: 18,
          color: "black",
          fontWeight: 700,
        },
        // width: "100%",
      }}
    >
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleCloseMenu}>
          <Box display="flex" alignItems="center" textAlign="center">
            View Details
          </Box>
        </MenuItem>
        <MenuItem onClick={handleCloseMenu}>
          {" "}
          <Box display="flex" alignItems="center" textAlign="center">
            Contact Customer
          </Box>
        </MenuItem>

        <MenuItem onClick={handleCloseMenu}>
          {" "}
          <Box display="flex" alignItems="center" textAlign="center">
            Remove Customer
          </Box>
        </MenuItem>
      </Menu>{" "}
      <DataGrid
        checkboxSelection
        autoHeight
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[5, 10, 15, 20]}
        components={{
          // Toolbar: QuickSearchToolbar,
          ColumnSortedDescendingIcon: SortedDescendingIcon,
          ColumnSortedAscendingIcon: SortedAscendingIcon,
          Pagination: CustomPagination,
        }}
      />
    </Box>
  );
};

export default CustomerListTable;