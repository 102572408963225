import React, { useEffect } from 'react'
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import * as animationData from '../data/scan-menu.json';
import { motion } from "framer-motion";
import { useDispatch, useSelector } from 'react-redux';
import { setLastUserEmail } from '../features/auth/authSlice';

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

function Welcome() {
    const { lastUserEmail } = useSelector((state) => state?.auth);

    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        setTimeout(() => {
            navigate('/dashboard', { replace: true })
        }, 3000)
    }, [lastUserEmail])

    return (
        <div className='h-screen flex justify-center items-center transition'>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.8 }}
            >
                <Lottie options={defaultOptions}
                    height={200}
                    width={300}
                />
            </motion.div>
        </div>
    )
}

export default Welcome