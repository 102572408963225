import React from 'react'
import { Stack, Typography, Box, Button } from '@mui/material'
import Restaurant_Menu from "../../images/Restaurant_Menu.png";
import EditIcon from "@mui/icons-material/Edit";

const NoJobs = ({ handleOpen }) => {
    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", height: "60vh" }}
        >
            <Box>
                <img src={Restaurant_Menu} alt="Restaurant Outline"></img>
            </Box>
            <Box sx={{ textAlign: "center" }}>
                <Typography>
                    You currently don't have any jobs in here.<br></br>Let's start by
                    creating some job positions and assign permissions.
                </Typography>
            </Box>
            <Box sx={{ mt: 2, width: 280 }}>
                <Button
                    onClick={handleOpen}
                    startIcon={
                        <EditIcon
                            sx={{
                                border: 1,
                                borderRadius: 1,
                                bgcolor: "white",
                                borderColor: "white",
                                color: "black",
                            }}
                        />
                    }
                    disableElevation
                    fullWidth
                    variant="contained"
                    sx={{
                        bgcolor: "secondary.main",
                        color: "white",
                        height: 55,
                        fontSize: "1rem",
                    }}
                >
                    Add Job
                </Button>
            </Box>
        </Stack>
    )
}

export default NoJobs