import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import 'leaflet/dist/leaflet.css'

import { ThemeProvider } from "@mui/material";
import theme from "./styling/theme";
import { BrowserRouter} from "react-router-dom";
import './index.css'
import { Toaster } from 'react-hot-toast'


// import { persistor, store } from "./redux/store";
import {persistor,store} from "./store/store"
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {" "}
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <App />
              <Toaster />
            </BrowserRouter>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          </QueryClientProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
