import { Routes, Route, Navigate } from "react-router-dom";

// HOMEPAGE
import HomePage from "../pages/HomePage";

import { ProtectedRoute } from "../helpers/ProtectedRoute";
import { publicRoutes, dashboardRoutes } from "./routeList";

import Settings from "../components/Settings";
import AdminProfileForm from "../components/Settings/forms/AdminProfileForm";
import Employee from "../components/Settings/Emplooyee";
import JobPositions from "../components/Settings/JobPositions";

// QR Codes
import QRCodes from "../components/QRCodes";
import CreateQrCode from "../components/QRCodes/CreateQrCode";
import NoQrCodes from "../components/QRCodes/NoQrCodes";
import Categories from "../components/Categories";
import NoCategories from "../components/Categories/NoCategories";
import CSVFileUpload from "../components/Menu/CSVFileUpload";
import WithMenu from "../components/Menu/WithMenu";
import NoMenu from "../components/Menu/NoMenu";
import Menu from "../components/Menu";
import CreateBranch from "../components/Restaurant/CreateBranch";
import BranchProfile from "../components/Restaurant/BranchProfile";
import OpenHours from "../components/Restaurant/OpenHours";
import RoomsAndAreas from "../components/Restaurant/RoomsAndAreas/RoomsAndAreas";
import Taxes from "../components/Settings/General/tax";
import RestaurantProfileForm from "../components/Settings/forms/RestaurantProfile";
import GeneralSettings from "../components/Settings/General";
import ExpandOrder from "../components/Orders/ExpandOrder";
import PesaPalConfirm from "../pages/pesapalConfirm";

//Download Receipt
import Receipt from "../components/Receipt";

const MainRoute = () => {
  return (
    <div className="App">
      {" "}
      <Routes>
        {/* PUBLIC ROUTES*/}
        <Route path="/" element={<Navigate to="/signin" />} />

        {/* Download Receipt */}
        <Route path="/receipt/download/:orderNo" element={<Receipt />} />

        {/* Payment */}
        <Route path="/pesapal-confirmation" element={<PesaPalConfirm />} />

        {publicRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={<route.component />}
            />
          );
        })}

        <Route path="*" element={<p>There's nothing here: 404!</p>} />

        {/* PROTECTED ROUTES */}
        {/* VIEW ORDER FROMM NEW TAB */}
        <Route
          path="/orders/:orderId"
          element={
            <ProtectedRoute>
              <ExpandOrder />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        >
          {dashboardRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<route.component />}
            />
          ))}

          {/* RESTAURANT ROUTES */}
          <Route path="/dashboard/branches/create-branch">
            <Route index element={<BranchProfile />} />
            {/* <Route path="/dashboard/restaurant/create-branch/profile" element={<BranchProfile />} />
                <Route path="/dashboard/restaurant/create-branch/open-hours" element={<OpenHours />} />
                <Route path="/dashboard/restaurant/create-branch/rooms-and-areas" element={<RoomsAndAreas />}>
                </Route> */}
          </Route>

          {/* MENU PAGE ROUTES */}
          <Route path="/dashboard/menu" element={<Menu />}>
            <Route index element={<NoMenu />} />

            <Route
              path="/dashboard/menu/csv-upload"
              element={<CSVFileUpload />}
            />
            <Route path="/dashboard/menu/with-menu" element={<WithMenu />}>
              <Route path="/dashboard/menu/with-menu/latest-dishes" />
              <Route path="/dashboard/menu/with-menu/featured-dishes" />
            </Route>
          </Route>

          <Route path="categories" element={<Categories />}>
            <Route index element={<NoCategories />} />
          </Route>

          <Route path="/dashboard/qr-codes" element={<CreateQrCode />}>
            {/* <Route index element={<NoQrCodes />} />
                <Route path="/dashboard/qr-codes/create-qrcode" element={<CreateQrCode />} /> */}
          </Route>

          {/* SETTINGS COMPONENT */}
          <Route path="/dashboard/settings">
            <Route path="/dashboard/settings" element={<GeneralSettings />} />
            <Route
              path="/dashboard/settings/profile"
              element={<AdminProfileForm />}
            />
            {/* <Route path="/dashboard/settings/taxes" element={<Taxes />} /> */}
            <Route index element={<GeneralSettings />} />
            <Route
              path="/dashboard/settings/employees"
              element={<Employee />}
            />
            <Route
              path="/dashboard/settings/roles"
              element={<JobPositions />}
            />
            {/* <Route path="/dashboard/settings/open-hours" element={<OpenHours />} /> */}
            <Route
              path="/dashboard/settings/organisation"
              element={<RestaurantProfileForm />}
            ></Route>
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default MainRoute;
