import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";

import logo from "../../images/Dagizo_Logo.png"
import fork from "../../images/fork.png"
// import { useSelector } from "react-redux";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#F4B313",
    color: "white",
    flexDirection: 'column'
  },
  section: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  qrImage: {
    // position: 'absolute',
    // top: 0,
    // right: '50%',
    zIndex: 999999,
    width: 250,
    height: 250
  }
});

function QRCodePage({ id, room, table, user }) {
  const qr = document.getElementById(id)
  const dataUrl = qr.toDataURL('image/jpg', 0.3);

  const image = user?.restaurant_image

  return (
    <Page key={`page_${id}`} size="A4" style={styles.page}>
      {/* <View style={styles.view}>
        <Image allowDangerousPaths src={dataUrl} style={styles.qrImage} />
      </View> */}
      <View style={{ backgroundColor: '#F68C24', height: '15%', flexDirection: 'row' }}>
        <View style={{ width: '80%', justifyContent: 'center', alignItems: 'center' }}>
          <Image
            cache={false}
            style={{ height: 80 }}
            src={image || logo}
          // src="https://firebasestorage.googleapis.com/v0/b/dagizo-app.appspot.com/o/THE%20ARABIAN%20CUISINE%2F1685055630030?alt=media&token=7325ad8b-48c5-4e88-b3f0-b722fdfabf19"
          />
        </View>

        <View style={{ width: '20%', backgroundColor: '#000', justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ fontSize: 12, marginBottom: 10 }}>{room?.place_name}</Text>
          <Text style={{ fontSize: 22 }}>{table?.table_number}</Text>
        </View>

      </View>
      <View style={styles.section}>
        <View style={{ color: "#000", paddingBottom: 20, justifyContent: 'center' }}>
          <Text style={{ alignSelf: 'center', marginBottom: 5 }}>SCAN QR CODE FOR</Text>

          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <View style={{ borderTopColor: '#000', borderTopWidth: 1, width: 50, marginTop: 15 }}></View>
            <Text style={{ marginHorizontal: 5, fontSize: 28 }}>MENU</Text>
            <View style={{ borderTopColor: '#000', borderTopWidth: 1, width: 50, marginTop: 15 }}></View>
          </View>

          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
            <Image allowDangerousPaths src={dataUrl} style={styles.qrImage} />
            {/* <Image src={fork} style={{ height: 240, marginLeft: -10 }} /> */}
          </View>

        </View>

      </View>
      <View style={{ backgroundColor: '#fff', height: '6%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', color: "#000", fontSize: 10, paddingHorizontal: 30 }}>
        <View>
          <Text style={{ marginBottom: 5 }}>info@dagizo.io</Text>
          <Text>dagizo.io</Text>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ color: '#aaa' }}>Powered by</Text>
          <Image
            style={{ width: 80, marginLeft: 10 }}
            src={logo}
          />
        </View>

      </View>
    </Page>
  );
}

export function QRCodeDocumentPreview({ ids, room, tables, user }) {
  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        {ids.map((id) => (
          <QRCodePage
            key={id}
            id={id}
            room={room}
            table={tables.filter(item => item.id === id)[0]}
            user={user}
          />
        ))}
      </Document>
    </PDFViewer>

  );
}

function QRCodeDocument({ ids, room, tables, user }) {
  return (
    <Document>
      {ids.map((id) => (
        <QRCodePage
          key={id}
          id={id}
          room={room}
          table={tables.filter(item => item.id === id)[0]}
          user={user}
        />
      ))}
    </Document>
  );
}

export default QRCodeDocument;