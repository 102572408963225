import React, { useEffect, useState } from "react";
import ModalLayout from "../modals/Layout";
import { CircularProgress } from "@mui/material";
import { notify } from "../Toast";
import AxiosPrivate from "../../functions/axios/axiosPrivate";

const IddleModal = ({ open, setOpen, warning }) => {
    const toSecond = Math.floor(warning / 1000)
    const [seconds, setSeconds] = useState(toSecond)

    const reset = () => {
        if (open) {
            setSeconds(toSecond)
            setOpen(false)
        }
    }

    // console.log('toSecond', toSecond)

    useEffect(() => {
        const interval = setInterval(() => {
            open && setSeconds(seconds - 1 )
        }, 1000)

        return () => clearInterval(interval)
    }, [seconds, open])

    useEffect(() => {
        window.addEventListener('click', reset)
        window.addEventListener('keypress', reset)
        window.addEventListener('scroll', reset)
        window.addEventListener('mousemove', reset)
    
        return () => {
          window.addEventListener('click', reset)
          window.addEventListener('keypress', reset)
          window.addEventListener('scroll', reset)
          window.addEventListener('mousemove', reset)
        }
      }, [open])

    return (
        <ModalLayout open={open} setOpen={setOpen} title={`Session Time Out`}>
            <div className="mt-4">
                <p>We detected inactivities on your account</p>
                <p>Therefore you will be logged out in {seconds} Seconds</p>
                {/* <div className="flex gap-4 justify-end">
                    <button onClick={() => setOpen(false)} className="bg-gray-200 px-6 py-2 rounded">Cancel</button>
                    <button disabled={loading} onClick={handleDelete} className="flex items-center gap-3 bg-red-600 px-6 py-2 text-red-100 rounded">
                        Delete {loading && (<CircularProgress color="inherit" size="1.2rem" />)}
                    </button>
                </div> */}
            </div>
        </ModalLayout>
    );
};

export default IddleModal;