import React, { useEffect, useState } from "react";
import ModalLayout from "../modals/Layout";
import { Box, Button, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useConfirmPayment } from "../../services/orders";
import { useSelector } from "react-redux";
import { notify } from "../Toast";

const PaymentModal = ({ open, setOpen, order, refresh }) => {
    const [amount, setAmount] = useState();

    const { user } = useSelector((state) => state.auth);
    const confirmPayment = useConfirmPayment(order?.id)

    useEffect(() => {
        if (order) {
            if (order?.payment_status === "Partially Paid") {
                setAmount(order.amount - Number(order?.pesapal_payment?.amount?.substring(0, 1)))
            }
            else {
                setAmount(order.amount)
            }
        }
    }, [order])

    useEffect(() => {
        if (confirmPayment.isSuccess) {
            setOpen(false);
            refresh();
        }
        if (confirmPayment.isError) {
            notify(confirmPayment.error.message, "error")
        }
    }, [confirmPayment.isSuccess, confirmPayment.isError])

    const handleSubmit = (e) => {
        e.preventDefault()
        confirmPayment.mutate({
            amount: +amount,
            user_email: user?.email
        })
    }

    return (
        <ModalLayout open={open} setOpen={setOpen} title={`${order.order_number}`}>
            <div className="my-2">
                {order?.payment_status === "Partially Paid" ? (
                    <div>
                        <div className="flex items-center gap-2 mt-4">
                            <h6>Amount paid:</h6>
                            <h1 className="text-primary font-bold text-lg">KSH {order?.pesapal_payment?.amount?.substring(0, 1)}</h1>
                        </div>
                        <div className="flex items-center gap-2 my-4">
                            <h6>Balance:</h6>
                            <h1 className="text-primary font-bold text-lg">KSH {amount}</h1>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-end gap-2 my-6">
                        <h1 className="text-primary font-bold text-2xl">KES {order?.amount}</h1>
                        <h6>amount to be paid</h6>
                    </div>
                )}

                <ValidatorForm autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
                    <Typography color="black">
                        Cash Received
                    </Typography>
                    <TextValidator
                        fullWidth
                        sx={{ my: 2 }}
                        onChange={(e) => setAmount(e.target.value)}
                        name="amount"
                        placeholder="Enter amount received from the customer"
                        value={amount}
                        validators={["required"]}
                        errorMessages={["This Field is Required"]}
                        disabled={true}
                    />

                    <Button
                        type="submit"
                        disabled={confirmPayment.isLoading}
                        disableElevation
                        sx={{ mt: 2, height: 55, fontSize: "1rem", color: "white" }}
                        variant="contained"
                        fullWidth
                    >
                        {confirmPayment.isLoading ? (<CircularProgress color="inherit" size="1.5rem" />) : "Complete Transaction"}
                    </Button>
                </ValidatorForm>
            </div>
        </ModalLayout>
    );
};

export default PaymentModal;