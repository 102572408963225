import * as React from 'react'
import { Typography, Popover } from '@mui/material'
import { Info } from '@mui/icons-material'


const PopoverInfo = ({ content }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Info style={{ fontSize: 16, color: '#707173', marginTop: -5 }} />
      </Typography>
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className='flex gap-2 p-2 w-80'>
          <Info style={{ fontSize: 20, color: '#151516' }} />
          <p className='text-xs text-[#626871]'>{content}</p>
        </div>
      </Popover>
    </div>
  )
}

export default PopoverInfo
