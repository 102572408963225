import { useMutation, useQuery, useQueryClient } from "react-query";
import AxiosPrivate from "../functions/axios/axiosPrivate";

export const useGetNotifications = (branch_id) => {
  const result = useQuery(["getNotifications", branch_id], () =>
    AxiosPrivate
      .get(`/notifications/get-all-unread-notifications/${branch_id}`)
      .then((res) => res.data)
  );

  return {
    ...result,
    notifications: result.data,
  };
};

export const useUpdateOrderStatus = () => {
    const queryClient = useQueryClient();
  
    return useMutation(
      (formData) =>
        AxiosPrivate({
          url: `/notifications/attend-client-notification/${formData.id}`,
          method: "POST",
          data: formData,
        }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getNotifications");
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  };