import AxiosPrivate from "./axios/axiosPrivate";

export const fetchBranchStats = async (branch_id) => {
  return await AxiosPrivate.get(`/restaurant/branch-stats/${branch_id}/`, {});
};


export const fetchRestaurantOrdersPerMenu = async (restaurant_id) => {
  return await AxiosPrivate.get(`/restaurant/dashboard/${restaurant_id}`, {});
};

export const fetchBranchOrdersPerMenu = async (branch_id) => {
  return await AxiosPrivate.get(`/restaurant/branch-stats/${branch_id}`, {});
};