import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import  OrdersIcon from "../../images/OrdersIcon.png"

const NoOrders = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100vh" }}
    >
      <Box>
        <img src={OrdersIcon} alt="Orders Icon"></img>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h5">You currently don't have any Orders</Typography>
      </Box>
    </Stack>
  );
};

export default NoOrders;
