import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { setLastUserEmail, logoutUser } from '../features/auth/authSlice';
import { setAllBranches } from '../features/branches/branchSlice';

const useTimeOut = () => {
    const dispatch = useDispatch()

    return useCallback((email) => {
        console.log('email', email)
        dispatch(logoutUser());
        dispatch(setAllBranches({ allBranches: [] }));
        dispatch(setLastUserEmail(email));
    })
}

export default useTimeOut