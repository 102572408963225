import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Stack, Box, Typography, Button } from "@mui/material";
import BranchListTable from "./DataGrids/BranchListTable";

const BranchList = ({branches}) => {
  const restaurant_name = useSelector((state) => state?.auth?.restaurant?.restaurant_name)

  const navigate = useNavigate();
  return (
    <Stack>
      <Box
        sx={(theme) => ({
          mt: -5,
          mb: 5,
          [theme.breakpoints.down("md")]: {
            mt: 0,
            mb: 5,
          },
        })}
      >
        {/* <Typography variant="h5" fontWeight={600}>
          {restaurant_name}
        </Typography> */}
      </Box>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: 5 }}>
        <Box>
          {" "}
          <div className="flex items-center space-x-5">  
            <Typography variant="h4" color="black" fontWeight={700}>
              Branches List
            </Typography>
          </div>
        </Box>
        <Box>
          <Button onClick={() => navigate("create-branch")} variant="contained" disableElevation sx={{color: "white"}}>
            Add Branch
          </Button>
        </Box>
      </Stack>
      <BranchListTable branches={branches}/>
    </Stack>
  );
};

export default BranchList;