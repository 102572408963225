import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { notify } from "../../Toast";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import ImageUploader from "../../common/utils/ImageUploader";
import CustomSnackBar from "../../common/utils/CustomSnackBar";

import {
  addNewEmployee,
  editEmployee,
  getJobPositions
} from "../../../functions/restaurant";

const AddEmployeeForm = ({ handleClose, refreshEmployees, editData }) => {
  const navigate = useNavigate()
  const [jobPositions, setJobPositions] = useState([]);

  const [values, setValues] = useState({
    first_name: null,
    profile_pic_url: null,
    mobile_no: null,
    email: null,
    last_name: null,
    job_position: null,
    employer_id: null,
    position_name: null,
    openSnackBar: false,
    national_id: "",
    kra_pin: "",
    snackBarMessage: "",
    snackBarSeverity: "success",
  });

  const { currentBranch } = useSelector((state) => state?.branch);

  const handleGetJobPositions = () => {
    getJobPositions(currentBranch?.id).then((r) => {
      setJobPositions(r.data.data);
    });
  };

  useEffect(() => {
    handleGetJobPositions();
  }, []);

  useEffect(() => {
    if (editData) {
      setValues({
        ...values,
        first_name: editData.first_name,
        last_name: editData.last_name,
        mobile_no: editData.phone,
        job_position: editData.job_position.id,
        position_name: editData.job_position.position_name,
        email: editData.email,
        profile_pic_url: editData.image,
        kra_pin: editData.kra_pin,
        national_id: editData.national_id
      });
    }
  }, [editData]);


  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setValues({ ...values, openSnackBar: false });
  };

  const changeAvatar = (url) => {
    setValues({ ...values, profile_pic_url: url });
  };

  const {
    first_name,
    profile_pic_url,
    job_position,
    mobile_no,
    email,
    last_name,
    openSnackBar,
    snackBarSeverity,
    snackBarMessage,
    kra_pin,
    national_id
  } = values;

  const handleSubmit = () => {
    if (!first_name || !email || !last_name || !mobile_no || !job_position || !national_id) {
      notify("Make sure all fields are filled", "error")
    } else if (!profile_pic_url) {
      notify("Please provide employee profile image", "error")
    } else {
      return addNewEmployee(
        {
          first_name,
          last_name,
          email,
          phone: mobile_no,
          jobposition_id: job_position,
          restaurant_id: currentBranch?.id,
          employee_image: profile_pic_url,
          kra_pin,
          national_id
        },
        currentBranch?.id
      )
        .then((res) => {
          if (res.data.status === true) {
            notify(res.data.message, "success");
            refreshEmployees();
            handleClose();
          } else if (res.data.status === false) {

            notify(res.data.error, "error");
          }
        })
        .catch((err) => {
          notify(err.response.data.error, "error");
        });
    }
  };

  const handleEditEmployee = () => {
    editEmployee(editData.id, {
      first_name,
      last_name,
      email,
      phone: mobile_no,
      jobposition_id: job_position,
      restaurant_id: currentBranch?.id,
      employee_image: profile_pic_url,
    }).then((r) => {
      if (r.data.status) {
        notify(r.data.message, "success");
        refreshEmployees();
        handleClose()
      } else if (!r.data.status) {
        notify(r.data.error, "error");
        handleClose()
      }
    })
      .catch((err) => {
        console.log(err);
        notify(err.response.data.error, "error");
      });
  };


  return (
    <Box
      sx={(theme) => ({
        flexGrow: 1,
        bgcolor: "secondary.input_field",

        borderRadius: 5,
        p: 5,
        [theme.breakpoints.down("lg")]: {},
      })}
    >
      <Box>
        <CustomSnackBar
          openSnackBar={openSnackBar}
          handleClose={closeSnackBar}
          horizontal={"center"}
          snackBarMessage={snackBarMessage}
          snackBarSeverity={snackBarSeverity}
        />
      </Box>

      <ValidatorForm autoComplete="off">
        <Grid container>
          <Grid xs={12}>
            <Stack direction="row" justifyContent="flex-start">
              {" "}
              <ImageUploader changeAvatar={changeAvatar} title={"Avatar"} preview_url={profile_pic_url} />
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid xs={12} lg={6}>
            <Box>
              <Typography sx={{ color: "black" }}>First Name</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("first_name")}
                name="name"
                value={first_name}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
                sx={{
                  input: {
                    padding: "10px",
                  },
                }}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>Email</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("email")}
                name="email"
                value={email}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
                sx={{
                  input: {
                    padding: "10px",
                  },
                }}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>KRA Pin</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("kra_pin")}
                name="kra_pin"
                value={kra_pin}
                // validators={["required"]}
                errorMessages={["This Field is Required"]}
                sx={{
                  input: {
                    padding: "10px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid xs={12} lg={6}>
            <Box>
              <Typography sx={{ color: "black" }}>Last Name</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("last_name")}
                name="last_name"
                value={last_name}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
                sx={{
                  input: {
                    padding: "10px",
                  },
                }}
              />
            </Box>{" "}
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>Phone Number</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("mobile_no")}
                name="mobile_no"
                value={mobile_no}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
                sx={{
                  input: {
                    padding: "10px",
                  },
                }}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>ID Number</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("national_id")}
                name="national_id"
                value={national_id}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
                sx={{
                  input: {
                    padding: "10px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={12}>
            <Box sx={{ mt: -2 }}>
              <Typography sx={{ color: "black" }}>Job Position</Typography>
              <Select
                fullWidth
                onChange={handleChange("job_position")}
                sx={{
                  "& .MuiSelect-select": {
                    padding: "10px",
                  },
                }}
              >
                {jobPositions.map((j, i) => (
                  <MenuItem
                    value={j.id}
                    key={i}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {j.position_name?.toLowerCase()}
                  </MenuItem>
                ))}
              </Select>

              <Typography
                onClick={() => navigate("/dashboard/settings/roles")}
                sx={{
                  color: "blue",
                  fontSize: "14px",
                  mt: 1,
                  cursor: "pointer",
                }}
              >
                + Add Job Position
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Button
          onClick={editData ? handleEditEmployee : handleSubmit}
          sx={{ mt: 4, color: "white", fontSize: "1rem" }}
          type="submit"
          variant="contained"
          fullWidth
          disableElevation
        >
          Save Changes
        </Button>
      </ValidatorForm>
    </Box>
  );
};

export default AddEmployeeForm;
