import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import { CircularProgress, Drawer, IconButton, styled, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { deleteTable, editTable, getTables } from '../../functions/tables';
import tableImage from '../../images/Area_Icon.png'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import NoData from '../NoData';
import { motion } from "framer-motion"
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { notify } from '../Toast';

const DrawerHeader = styled('div')(({ theme }) => ({
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function TableDrawer({ open, setOpen, room }) {
    const [loading, setLoading] = useState(true)
    const [reqLoad, setReqLoad] = useState(false)
    const [tables, setTables] = useState([])
    const [edit, setEdit] = useState(false)
    const [toDelete, setToDelete] = useState(false)
    const [table_name, setTable_name] = useState('')
    const [table_number, setTable_number] = useState('')
    const [currentTable, setCurrentTable] = useState('')
    const theme = useTheme();

    const container = window !== undefined ? () => window.document.body : undefined;
    const roomId = room?.id

    const fetchTables = () => {
        if (roomId && open) {
            setTimeout(() => {
                getTables(roomId)
                    .then((res) => {
                        if (res.data.data) {
                            setTables(res.data.data)
                            setLoading(false)
                        } else {
                            setLoading(false)
                        }
                    })
            }, 1000);
        } else {
            setLoading(true)
            setTables([])
        }
    }

    useEffect(() => {
        fetchTables()
    }, [roomId, open]);

    const handleDelete = (id) => {
        setReqLoad(true)
        deleteTable(id)
            .then((res) => {
                notify(res.data.message, 'success')
                fetchTables()
                setReqLoad(false)
            })
            .catch((err) => {
                notify(err.message, 'error')
                setReqLoad(false)
            })
    }

    const handleEdit = (id) => {
        setReqLoad(true)
        editTable(table_name, id, table_number)
            .then((res) => {
                notify(res.data.message, 'success')
                fetchTables()
                setReqLoad(false)
            })
            .catch((err) => {
                notify(err.message, 'error')
                setReqLoad(false)
            })
    }

    return (
        <div>
            <Drawer
                container={container}
                anchor="right"
                variant="temporary"
                open={open}
                onClose={() => {
                    setOpen(false)
                    setToDelete(false)
                    setEdit(false)
                    setCurrentTable("")
                }}
                sx={{
                    zIndex: 9991,
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        borderWidth: 0,
                        width: { xs: 350, md: 500 },
                        backgroundColor: '#FAFAFA',
                    },
                }}
            >
                <DrawerHeader>
                    <IconButton onClick={() => setOpen(false)}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <div className='px-8'>
                    {loading ? (
                        <div className='mt-6'>
                            <div className='h-5 w-3/5 bg-gray-200 animate-pulse rounded'></div>
                            <div className='h-3 w-2/5 bg-gray-200 animate-pulse rounded mt-4'></div>
                        </div>
                    ) : (
                        <div>
                            <h1 className='font-bold text-xl mt-4'>{room?.place_name}</h1>
                            <h1 className='text-gray-600'>{room?.place_type} No. {room?.room_number}</h1>
                        </div>
                    )}

                    <div className='mt-6'>
                        {loading ? [1, 2, 3, 4, 5].map((i, index) => (
                            <div key={index} className='w-full flex justify-between items-center my-4 p-4 shadow bg-white'>
                                <div className='w-4/6 flex items-center gap-3'>
                                    <div className='h-10 w-10 bg-gray-200 animate-pulse rounded'></div>
                                    <div className='w-5/6 flex flex-col gap-2'>
                                        <div className='h-4 w-3/5 bg-gray-200 animate-pulse rounded'></div>
                                        <div className='h-3 w-2/5 bg-gray-200 animate-pulse rounded'></div>
                                    </div>
                                </div>

                                <div className='w-2/6 flex justify-end gap-3'>
                                    <div className='h-6 w-6 bg-gray-200 animate-pulse rounded-full'></div>
                                    <div className='h-6 w-6 bg-gray-200 animate-pulse rounded-full'></div>
                                </div>
                            </div>
                        )) : tables.length > 0 ? tables.map((table, key) => (
                            <motion.div
                                initial={{ opacity: 0, x: 50 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: key / 2 }}
                                className={`flex ${toDelete && currentTable === table.id ? "flex-col" : "justify-between"} ${edit && currentTable !== table.id ? "items-center" : "items-start"} my-4 p-4 shadow bg-white`}
                                key={table.id}
                            >
                                <div className='flex items-center gap-3'>
                                    <img className='h-10' src={tableImage} alt="" />
                                    <div className='flex flex-col gap-2'>
                                        {edit && currentTable === table.id ? (
                                            <motion.div
                                                initial={{ opacity: 0, x: 30 }}
                                                animate={{ opacity: 1, x: 0 }}
                                                transition={{ duration: 0.5 }}
                                            >
                                                <input
                                                    type="text"
                                                    autoFocus
                                                    className='bg-[#fafafa] px-2 py-1 rounded-md focus:outline-none border border-blue-200'
                                                    value={table_name}
                                                    onChange={(e) => setTable_name(e.target.value)}
                                                />
                                            </motion.div>
                                        ) : (
                                            <h1
                                                onClick={(e) => {
                                                    if (e.detail === 2)
                                                        setEdit(true)
                                                    setTable_name(table.table_name)
                                                    setCurrentTable(table.id)
                                                }}
                                                className='font-semibold'
                                            >{table.table_name}</h1>
                                        )}

                                        <p className='flex items-center gap-1 text-xs text-blue-400'> <ArrowCircleRightIcon style={{ fontSize: 14 }} /> <span className='text-gray-400'>Table No. {table.table_number}</span></p>
                                    </div>
                                </div>

                                {edit && currentTable === table.id ? (
                                    <motion.div
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.5 }}
                                        className='flex items-center text-gray-400'
                                    >
                                        {reqLoad ? (
                                            <CircularProgress sx={{ color: 'skyblue' }} size="1.5rem" />
                                        ) : (
                                            <IconButton
                                                sx={{ color: 'skyblue' }}
                                                onClick={() => handleEdit(table.id)}
                                            >
                                                <CheckIcon />
                                            </IconButton>
                                        )}

                                        <IconButton
                                            disabled={reqLoad}
                                            sx={{ color: 'red' }}
                                            onClick={() => {
                                                setEdit(false)
                                                setTable_name('')
                                                setCurrentTable('')
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </motion.div>
                                ) : toDelete && currentTable === table.id ? (
                                    <motion.div
                                        initial={{ opacity: 0, x: 80 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ duration: 0.6 }}
                                        className={`w-full p-2 text-xs mt-4 text-dark bg-red-50 border border-dashed border-red-400 rounded`}
                                    >
                                        <h1>Are you sure you want to delete <b>{table.table_name}</b> ?</h1>
                                        <div className='flex justify-start items-center gap-4 mt-2'>
                                            <button
                                                disabled={reqLoad}
                                                onClick={() => {
                                                    setEdit(false)
                                                    setToDelete(false)
                                                    setCurrentTable('')
                                                    setTable_name('')
                                                }}
                                                className='bg-white border px-4 py-1 text-gray-500 rounded'
                                            >
                                                Cancel
                                            </button>
                                            <button disabled={reqLoad} onClick={() => handleDelete(table.id)} className='bg-red-500 px-4 py-1 text-red-100 rounded'>Delete</button>
                                            {reqLoad && (<CircularProgress sx={{ color: 'red' }} size="1.2rem" />)}
                                        </div>
                                    </motion.div>
                                ) : (
                                    <div className='flex text-gray-400'>
                                        <IconButton
                                            onClick={() => {
                                                setEdit(true)
                                                setToDelete(false)
                                                setTable_name(table.table_name)
                                                setCurrentTable(table.id)
                                            }}
                                        >
                                            <ModeEditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                setEdit(false)
                                                setToDelete(true)
                                                setCurrentTable(table.id)
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                )}
                            </motion.div>
                        )) : (
                            <div><NoData title="Table" /></div>
                        )}
                    </div>

                </div>



            </Drawer>
        </div>
    );
}