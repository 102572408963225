import QRCodeReact from "qrcode.react";
import Logo from '../../images/Restaurant_QRCode.png'

const QRGenerator = ({ value, documentId }) => (
  <QRCodeReact
    id={documentId}
    value={value}
    size={200}
    bgColor='#fff'
    fgColor="#000"
    level="M"
    includeMargin={true}
    // renderAs="svg"
    imageSettings={{
      src: "/qr.png",
      x: undefined,
      y: undefined,
      height: 60,
      width: 60,
      excavate: true,
    }}
  />
);

export default QRGenerator;