const rowData = [
  {
    id: 1,
    customerID: "#679234",
    email: "johndoe@gmail.com",
    date: "26 March 2020, 12:42 AM",
    orderID: "#55587921",
    price: 145.58,
    orderType: "Take Away",
    orderStatus: "Completed",
  },
  {
    id: 11,
    customerID: "#995324",
    email: "johndoe@gmail.com",
    date: "26 March 2020, 12:42 AM",
    orderID: "#55587921",
    price: 25.91,
    orderType: "Take Away",
    orderStatus: "Pending",
  },
  {
    id: 12,
    customerID: "#356478",
    email: "johndoe@gmail.com",
    date: "26 March 2020, 12:42 AM",
    orderID: "#55587921",
    price: 110.55,
    orderType: "Take Away",
    orderStatus: "Cancel",
  },
  {
    id: 1,
    customerID: "#679234",
    email: "johndoe@gmail.com",
    date: "26 March 2020, 12:42 AM",
    orderID: "#55587921",
    price: 145.58,
    orderType: "Take Away",
    orderStatus: "Completed",
  },
  {
    id: 11,
    customerID: "#995324",
    email: "johndoe@gmail.com",
    date: "26 March 2020, 12:42 AM",
    orderID: "#55587921",
    price: 25.91,
    orderType: "Take Away",
    orderStatus: "Pending",
  },
  {
    id: 12,
    customerID: "#356478",
    email: "johndoe@gmail.com",
    date: "26 March 2020, 12:42 AM",
    orderID: "#55587921",
    price: 110.55,
    orderType: "Take Away",
    orderStatus: "Cancel",
  },
  {
    id: 1,
    customerID: "#679234",
    email: "johndoe@gmail.com",
    date: "26 March 2020, 12:42 AM",
    orderID: "#55587921",
    price: 145.58,
    orderType: "Take Away",
    orderStatus: "Completed",
  },
  {
    id: 11,
    customerID: "#995324",
    email: "johndoe@gmail.com",
    date: "26 March 2020, 12:42 AM",
    orderID: "#55587921",
    price: 25.91,
    orderType: "Take Away",
    orderStatus: "Pending",
  },
  {
    id: 12,
    customerID: "#356478",
    email: "johndoe@gmail.com",
    date: "26 March 2020, 12:42 AM",
    orderID: "#55587921",
    price: 110.55,
    orderType: "Take Away",
    orderStatus: "Cancel",
  },
  {
    id: 1,
    customerID: "#679234",
    email: "johndoe@gmail.com",
    date: "26 March 2020, 12:42 AM",
    orderID: "#55587921",
    price: 145.58,
    orderType: "Take Away",
    orderStatus: "Completed",
  },
  {
    id: 11,
    customerID: "#995324",
    email: "johndoe@gmail.com",
    date: "26 March 2020, 12:42 AM",
    orderID: "#55587921",
    price: 25.91,
    orderType: "Take Away",
    orderStatus: "Pending",
  },
  {
    id: 12,
    customerID: "#356478",
    email: "johndoe@gmail.com",
    date: "26 March 2020, 12:42 AM",
    orderID: "#55587921",
    price: 110.55,
    orderType: "Take Away",
    orderStatus: "Cancel",
  },
];

export default rowData;