import { RiArrowGoBackLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

const BackButton = () => {
    const navigate = useNavigate()
    return (
        <button className="flex items-center py-1 px-2 hover:text-indigo-500 hover:bg-gray-100 rounded" onClick={() => navigate(-1)}>
            <RiArrowGoBackLine className="w-7 h-4"/>
            <p className="text-sm font-medium">Back</p>
        </button>
    )
}

export default BackButton