import React from "react";
import { Box, Stack, Button, Typography,} from "@mui/material";
import GroupsIcon from '@mui/icons-material/Groups';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import CountUp from "react-countup";
import { useGetBranches } from "../../services/branches";

const CardStats = ({ data, loading }) => {
  const { restaurant } = useSelector((state) => state.auth);
  const { branches, isLoading } = useGetBranches(restaurant?.id)

  return (
    <Box
      sx={(theme) => ({
        flexGrow: 1,
        mt: 2,
        // display: "none",
        // [theme.breakpoints.down("md")]: {
        //   display: "none",
        // },
      })}
    >
      {!loading ? (
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.2 }}
          >
            <div style={{ boxShadow: "5px 5px 40px 2px rgb(0 0 0 / 0.1)" }} className="flex items-center rounded-lg overflow-y-hidden p-4">
              <Box>
                <FastfoodIcon
                  sx={{ color: "#FE6313", mr:2, backgroundColor: '#FEEFE7', p: 1.5, borderRadius: '50%', fontSize: 50 }}
                />
              </Box>

              <Box>
                <p className="text-gray-500">Branches</p>
                <Typography
                  variant="h5"
                  color="secondary.tint_1"
                  fontWeight="700"
                >
                  <CountUp start={0} duration={5} end={branches?.length || 0} />
                </Typography>
                {/* <Typography sx={{}}>
                  <Button sx={{ color: "green", ml: -2, mr: -1 }}> +23% </Button>
                  Since Last Month
                </Typography> */}
              </Box>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <div style={{ boxShadow: "5px 5px 40px 2px rgb(0 0 0 / 0.1)" }} className="flex items-center rounded-lg overflow-y-hidden p-4">
              <Box>
                <GroupsIcon
                  sx={{ color: "#FFB649", mr:2, backgroundColor: '#FFF5E6', p: 1.5, borderRadius: '50%', fontSize: 50 }}
                />
              </Box>
              <Box>
                <p className="text-gray-500">Customers</p>
                <Typography
                  variant="h5"
                  color="secondary.tint_1"
                  fontWeight="700"
                >
                  <CountUp start={0} duration={5} end={data?.clients_visited || 0} />
                </Typography>
                {/* <Typography sx={{}}>
                  <Button sx={{ color: "red", ml: -2, mr: -1 }}> -13% </Button>
                  Since Last Month
                </Typography> */}
              </Box>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            <div style={{ boxShadow: "5px 5px 40px 2px rgb(0 0 0 / 0.1)" }} className="flex items-center rounded-lg overflow-y-hidden p-4">
              <Box>
                <ShoppingCartIcon
                  sx={{ color: "#45D3ED", mr:2, backgroundColor: '#ECFBFE', p: 1.5, borderRadius: '50%', fontSize: 50 }}
                />
              </Box>

              <Box>
                <p className="text-gray-500">Total Orders</p>
                <Typography
                  variant="h5"
                  color="secondary.tint_1"
                  fontWeight="700"
                >
                  <CountUp start={0} duration={5} end={data?.orders || 0} />
                </Typography>
                {/* <Typography sx={{}}>
                  <Button sx={{ color: "green", ml: -2, mr: -1 }}> +23% </Button>
                  Since Last Month
                </Typography> */}
              </Box>
            </div>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.8 }}
          >
            <div style={{ boxShadow: "5px 5px 40px 2px rgb(0 0 0 / 0.1)" }} className="flex items-center rounded-lg overflow-y-hidden p-4">
              <Box>
                <InsertChartIcon
                  sx={{ color: "#332A7B", mr:2, backgroundColor: '#EBEAF1', p: 1.5, borderRadius: '50%', fontSize: 50 }}
                />
              </Box>
              <Box>
                <p className="text-gray-500">Revenue (KSH)</p>
                <Typography
                  variant="h5"
                  color="secondary.tint_1"
                  fontWeight="700"
                >
                  <CountUp start={0} duration={5} end={data?.profits || 0} />
                </Typography>
                {/* <Typography sx={{}}>
                  <Button sx={{ color: "red", ml: -2, mr: -1 }}> -12% </Button>
                  Since Last Month
                </Typography> */}
              </Box>
            </div>
          </motion.div>
        </div>
      ) : (
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          {[1, 2, 3, 4].map((i, index) => (
            <motion.div
              // initial={{ opacity: 0, y: 50 }}
              // animate={{ opacity: 1, y: 0 }}
              // transition={{ duration: index / 4 }}
              // className=""
              key={index}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  border: 1,
                  borderColor: "secondary.tint_5_bg",
                  borderRadius: 2,
                  p: 2,
                }}
              >
                <div>
                  <div className='h-12 w-12 bg-gray-200 animate-pulse rounded-full mr-4'></div>
                </div>

                <div className="w-full">
                  <div className='h-3 w-4/5 bg-gray-200 animate-pulse rounded mb-2'></div>
                  <div className='h-4 w-2/5 bg-gray-200 animate-pulse rounded'></div>
                </div>
              </Stack>
            </motion.div>
          ))}
          
        </div>
      )}
    </Box>
  );
};

export default CardStats;
