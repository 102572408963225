import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { logoutUser } from '../features/auth/authSlice';
import { setAllBranches } from '../features/branches/branchSlice';

const useLogout = () => {
    const dispatch = useDispatch()

    return useCallback(() => {
        dispatch(logoutUser());
        dispatch(setAllBranches({ allBranches: [] }));
    })
}

export default useLogout