import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid"
import { Box, MenuItem, IconButton, Menu, LinearProgress, Modal, Stack } from '@mui/material';
import { deleteJobPositions } from "../../../functions/restaurant";
import { notify } from "../../Toast";
import AlertDialog from "../../Alerts/Alerts";
import AddJobPosition from "../forms/AddJobPosition";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CustomNoRowsOverlay from "../../../styling/CustomComponents/NoRowsOverlay";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export function SortedDescendingIcon() {
    return <ArrowDropDownIcon className="icon" />;
}

export function SortedAscendingIcon() {
    return <ArrowDropUpIcon className="icon" />;
}

const RolesTable = ({ rowsData = [], handleGetJobPositions }) => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [openDialog, setOpenDialog] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [selected, setSelected] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorElNav(null);
    };

    const handleClose = () => {
        setOpenEditForm(false);
        setSelected(null);
    }

    const handleDeleteJobPosition = () => {
        deleteJobPositions(selected.id).then((res) => {
            if (res.data.status === true) {
                notify(res.data.message, "success");
                handleGetJobPositions();
                setSelected(null);
            } else if (res.data.status === false) {
                notify(res.data.message, "error");
            }
        })
            .catch((err) => {
                notify(err.response.data.error, "error");
            });
    }

    const columns = [
        {
            field: "position_name", headerName: "Position Name", flex: 2, renderCell: (param) => (
                <p className="capitalize">{rowsData.indexOf(param.row) + 1}. {param.row.position_name.toLowerCase()}</p>
            )
        },
        {
            field: "created", headerName: "Date Created", flex: 2, renderCell: (param) => (
                <p>{new Date(param.row.created).toLocaleDateString("en-US", { day: "numeric", month: "long", year: "numeric" })}</p>
            )
        },
        {
            field: "permissions", headerName: "Total Permissions", flex: 2, renderCell: (param) => (
                <p>{param.row.permissions.length}</p>
            )
        },
        {
            field: "action",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={(e) => {
                            handleOpenMenu(e);
                            setSelected(params.row);
                        }}
                    >
                        <MoreHorizIcon />
                    </IconButton>
                );
            },
        },
    ]

    return (
        <>
            <Box
                sx={{
                    mt: 5,
                    "& .MuiDataGrid-columnHeaders": {
                        color: "black",
                        fontSize: 18,
                    },
                    width: "100%",
                }}
            >
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    keepMounted
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem
                        onClick={(e) => {
                            handleCloseMenu(e);
                            setOpenEditForm(true);
                        }}
                    >
                        Edit Permissions
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setOpenDialog(true);
                            handleCloseMenu();
                        }}
                    >
                        Delete
                    </MenuItem>
                </Menu>

                <DataGrid
                    sx={{
                        "& .MuiDataGrid-columnHeaders": {
                            fontSize: 16,
                        },
                    }}
                    getRowId={(row) =>
                        row.id
                    }
                    rows={rowsData}
                    columns={columns}
                    autoHeight
                    pageSize={pageSize}
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                    onPageSizeChange={(newValue) => setPageSize(newValue)}
                    components={{
                        ColumnSortedDescendingIcon: SortedDescendingIcon,
                        ColumnSortedAscendingIcon: SortedAscendingIcon,
                        LoadingOverlay: LinearProgress,
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Box>

            <Modal open={openEditForm} onClose={handleClose}>
                <Box
                    sx={(theme) => ({
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "40vw",
                        bgcolor: "background.paper",
                        borderRadius: 2,
                        borderColor: `primary.main`,
                        elevation: 24,
                        [theme.breakpoints.down("md")]: {
                            width: "80vw",
                        },
                    })}
                >
                    <Box sx={{ position: "absolute", top: 5, right: 5 }}>
                        <IconButton onClick={handleClose}>
                            <CloseOutlinedIcon
                                sx={{ borderRadius: 25, bgcolor: "black", color: "white" }}
                            />
                        </IconButton>
                    </Box>
                    <Stack sx={{ p: 4 }}>
                        <AddJobPosition
                            refreshData={handleGetJobPositions}
                            handleClose={handleClose}
                            editData={selected}
                        />
                    </Stack>
                </Box>
            </Modal>

            <AlertDialog
                title="Delete job position"
                bodyText="Are you sure you want to delete this job position? This action is irreversible!"
                open={openDialog}
                handleDelete={handleDeleteJobPosition}
                handleClose={() => { setOpenDialog(false); setSelected(null) }}
            />
        </>
    )
}

export default RolesTable