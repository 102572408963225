import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Tabs, Tab, Box, Stack, Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// Functions
import { fetchBranchRooms, getSections } from "../../../functions/restaurant";
import RoomsAndAreasTable from "./tables/RoomsAndAreasTable";
import NoRooms from "./NoRooms";
import NoAreas from "./NoAreas";
import CreatePlaceModal from "./modals/CreatePlace";
import { getTables } from "../../../functions/tables";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const RoomsAndAreasNav = () => {
  const [currentTab, setCurrentTab] = useState("rooms");
  const [roomsData, setRoomsData] = useState([]);
  const [areasData, setAreasData] = useState([]);
  const [placeType, setPlaceType] = useState("Place");
  const [openCreatePlaceModal, setOpenCreatePlaceModal] = useState(false);
  const [room, setRoom] = useState(null);
  const [toDelete, setToDelete] = useState(false);

  const { restaurant } = useSelector((state) => state.auth);
  const { currentBranch } = useSelector((state) => state.branch);
  const branchId = currentBranch?.id

  const closeCreatePlaceModal = () => {
    setOpenCreatePlaceModal(false);
  };

  const handleOpenCreatePlaceModal = (prop) => {
    setPlaceType(prop);
    setOpenCreatePlaceModal(true);
  };

  let currentPath = "";
  const location = useLocation();

  const pathnameArray = location.pathname.split("/");

  if (pathnameArray[5] === "rooms" || pathnameArray[5] === undefined) {
    currentPath = "rooms";
  } else if (pathnameArray[5] === "areas") {
    currentPath = "areas";
  }

  const fetchBranchRoomsAndAreas = () => {
    let roomsArray = [];
    let areasArray = [];
    fetchBranchRooms(branchId).then((res) => {
      if (res.data.data !== undefined) {
        if (res.data.data.length > 0) {
          roomsArray = res.data.data.filter(
            (item) => item.place_type === "Room"
          );
          areasArray = res.data.data.filter(
            (item) => item.place_type === "Area"
          );
          setAreasData(areasArray);
        } else {
          setAreasData([]);
        }
      } else {
        setAreasData([]);
      }
    });
  };

  const getBranchSections = () => {
    getSections(branchId).then(res => {
      const { data: { data } } = res;
      setRoomsData(data);
    })
  }

  useEffect(() => { getBranchSections() }, [])

  useEffect(() => {
    setCurrentTab(currentPath);
  }, [currentPath]);

  const roomId = roomsData[0]?.id

  useEffect(() => {
    if (roomId) {
      getTables(roomId);
    }
  }, [roomId]);

  useEffect(() => {
    fetchBranchRoomsAndAreas();
  }, [branchId, currentTab]);

  useEffect(() => {
    if (restaurant.type === "Restaurant") {
      setCurrentTab("areas")
    } else {
      setCurrentTab("rooms")
    }
  }, [])

  const handleChange = (event, newCurrentTab) => {
    setCurrentTab(newCurrentTab);
  };

  const handleEdit = (location, del) => {
    console.log('location', location)
    setRoom(location)
    del ? setToDelete(true) : setToDelete(false)
    setOpenCreatePlaceModal(true)
  }


  return (
    <Box
      sx={{
        width: "100%",

        "& .MuiTab-root": {
          textTransform: "none",
          fontSize: "1rem",
          mr: 3,
        },
      }}
    >
      {" "}
      <CreatePlaceModal
        place_type={placeType}
        openCreatePlaceModal={openCreatePlaceModal}
        closeCreatePlaceModal={closeCreatePlaceModal}
        room={room}
        toDelete={toDelete}
        refetch={currentTab === 'rooms' ? getBranchSections : fetchBranchRoomsAndAreas}
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Box>
          {" "}
          {restaurant.type === "Restaurant" ? (
            <Tabs value={currentTab} onChange={handleChange}>
              <Tab value={"areas"} label="Areas" />
            </Tabs>
          ) : (
            <Tabs value={currentTab} onChange={handleChange}>
              <Tab value={"rooms"} label="Rooms" />
              <Tab value={"areas"} label="Areas" />
            </Tabs>
          )}
        </Box>

        <Box>
          {currentTab === "rooms" ? (
            <Button
              variant="contained"
              disableElevation
              startIcon={<AddIcon />}
              sx={{ mx: 2, color: "white" }}
              onClick={() => {
                setToDelete(false)
                setRoom(null)
                handleOpenCreatePlaceModal("Room")
              }}
            >
              Add Rooom
            </Button>
          ) : (
            <Button
              variant="contained"
              disableElevation
              startIcon={<AddIcon />}
              sx={{ mx: 2, color: "white" }}
              onClick={() => {
                setToDelete(false)
                setRoom(null)
                handleOpenCreatePlaceModal("Area")
              }}
            >
              Add Area
            </Button>
          )}
        </Box>
      </Stack>
      <Stack>
        {currentTab === "rooms" && roomsData.length > 0 && (
          <RoomsAndAreasTable roomsAndAreasData={roomsData} type={currentTab} onEdit={(location, del) => handleEdit(location, del)} />
        )}
        {currentTab === "rooms" && roomsData.length === 0 && <NoRooms refetch={fetchBranchRoomsAndAreas} />}
        {currentTab === "areas" && areasData.length > 0 && (
          <RoomsAndAreasTable roomsAndAreasData={areasData} onEdit={(location, del) => handleEdit(location, del)} />
        )}
        {currentTab === "areas" && areasData.length === 0 && <NoAreas refetch={fetchBranchRoomsAndAreas} />}
      </Stack>
    </Box>
  );
};

export default RoomsAndAreasNav;