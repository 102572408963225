import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { Box } from "@mui/material";

// Functions
import {
  fetchRestaurantOrdersPerMenu,
  fetchBranchOrdersPerMenu,
} from "../../../functions/dashboard";


const OrdersOverview = ( ) => {
  const allBranches = useSelector((state) => state?.branch?.allBranches);
  const restaurant_code = useSelector((state) => state?.auth?.restaurant?.id);

  const branch_id = useSelector(
    (state) => state?.branch?.allBranches.restaurant_code
  );
  // console.log(
  //   "🚀 ~ file: OrdersOverview.js:20 ~ OrdersOverview ~ branch_id",
  //   branch_id
  // );

  const status = [
    "completed_orders",
    "served_orders",
    "cancelled_orders",
    "ongoing_orders",
    "ordered_orders",
  ];
  const [orderNumbers, setOrderNumbers] = useState([]);

  const getRestaurantOrdersPerStatus = async () => {
    const res = await fetchRestaurantOrdersPerMenu(restaurant_code);

    const orders_per_status = res.data.data.orders_overview;
    // console.log(
    //   "🚀 ~ file: OrdersOverview.js:25 ~ getRestaurantOrdersPerStatus ~ orders_per_status",
    //   orders_per_status
    // );
    let order_numbers = [];

    for (let i = 0; i < status.length; i++) {
      order_numbers.push(
        orders_per_status[status[i]].total_pending_transmision
      );
    }

    setOrderNumbers(order_numbers);
    // console.log(
    //   "🚀 ~ file: OrdersOverview.js:33 ~ getRestaurantOrdersPerStatus ~ order_numbers",
    //   order_numbers
    // );
  };

  const getBranchOrdersPerStatus = async () => {
    if (branch_id !== undefined || branch_id !== null) {
      const res = await fetchBranchOrdersPerMenu(branch_id);

      const orders_per_status = res.data.data.orders_overview;
      // console.log(
      //   "🚀 ~ file: OrdersOverview.js:25 ~ getRestaurantOrdersPerStatus ~ orders_per_status",
      //   orders_per_status
      // );
      let order_numbers = [];

      for (let i = 0; i < status.length; i++) {
        order_numbers.push(
          orders_per_status[status[i]].total_pending_transmision
        );
      }

      setOrderNumbers(order_numbers);
      // console.log(
      //   "🚀 ~ file: OrdersOverview.js:33 ~ getRestaurantOrdersPerStatus ~ order_numbers",
      //   order_numbers
      // );
    }
  };

  useEffect(() => {
    if (allBranches.length > 0) {
      getRestaurantOrdersPerStatus();
    } else {
      getBranchOrdersPerStatus();
    }
  }, [restaurant_code,allBranches]);

  return (
    <Box sx={{ mt: 2 }}>
      <Chart
        type="donut"
        // RECEIVE DATA HERE
        series={orderNumbers}
        options={{
          labels: status,
          colors: ["#F4B315", "#F93121", "#000"],

          dataLabels: {
            enabled: false,
          },
          title: {
            text: "Orders Overview",
            style: { fontSize: 20, fontFamily: "Mulish", fontWeight: 600 },
          },
          noData: {
            text: "No Orders Yet",
            align: "center",
            verticalAlign: "middle",
            style: {
              color: undefined,
              fontSize: "25px",
              fontFamily: "Mulish",
            },
          },
          plotOptions: {
            pie: {
              donut: {
                size: "80%",
                labels: {
                  show: true,
                  total: {
                    show: true,
                    showAlways: true,
                    label: "Total",
                    fontSize: "24px",
                    fontFamily: "Mulish",
                    fontWeight: 600,
                    color: "#373d3f",
                  },
                },
              },
            },
          },

          chart: {
            toolbar: {
              show: false,
            },
          },
          tooltip: {
            style: {
              fontSize: 10,
              fontFamily: "Mulish",
            },
          },

          legend: {
            position: "bottom",
            verticalAlign: "left",
            horizontalAlign: "left",
            fontSize: "14",
            fontWeight: 400,
            fontFamily: "Mulish",
            formatter: function (seriesName, opts) {
              return [
                seriesName,
                " - ",
                opts.w.globals.series[opts.seriesIndex],
              ];
            },

            containerMargin: {
              left: 35,
              right: 60,
            },
          },
        }}
      ></Chart>
    </Box>
  );
};

export default OrdersOverview;
