import "./App.css";
import MainRoute from "./routes";

const App = () => {
  return (
    <div className="App">
      <MainRoute />
    </div>

  );
};

export default App;
