import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import IosShareIcon from '@mui/icons-material/IosShare';
import { isMobile } from 'react-device-detect';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Typography,
  Stack,
  Toolbar,
  Collapse,
  List,
  Chip,
  Avatar,
} from "@mui/material";
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import StyledList from "../styling/CustomComponents/StyledList";

import AccountAndNotifications from "../components/common/utils/AccountAndNotifications";
import Dagizo_Logo from "../images/Dagizo_Logo.png";
import { dashboardRoutes } from "../routes/routeList";
import DashboardHeader from "../components/Dashboard/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import { useGetOrders } from "../services/orders";
import ShareMenuModal from "../components/modals/SocialShare";
import { notify } from "../components/Toast";
import { setLastUserEmail } from "../features/auth/authSlice";

const drawerWidth = 280;

const HomePage = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedListItem, setSelectedListItem] = useState("");
  const [expand, setExpand] = useState(null)
  const [active, setActive] = useState('')
  const [isExpanded, setIsExpanded] = useState(false)
  const [share, setShare] = useState(false)
  const dispatch = useDispatch()

  const { currentBranch } = useSelector((state) => state.branch);
  const branchId = currentBranch?.id;
  const { lastUserEmail } = useSelector((state) => state?.auth);
  const { user_permissions, user } = useSelector((state) => state?.auth);

  const branchURL = `https://dagizo-prod.web.app/restaurant-menu?restaurant_code=${branchId}`

  const { data } = useGetOrders(branchId);

  const navigate = useNavigate();
  const location = useLocation();
  const pathnameArray = location.pathname.split("/");

  useEffect(() => {
    if (lastUserEmail) {
      dispatch(setLastUserEmail(null));
    }
  }, [])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const orders = data?.filter(order => order.order_status === "PENDING")

  const handleListItemClick = useCallback(
    (link) => {
      return async (e) => {
        e.preventDefault();
        if (link.group) {
          setIsExpanded(!isExpanded)
          setExpand(link.sublinks[0]?.name)
        } else {
          setIsExpanded(false)
          setExpand(null)
          navigate(link.path);
        }
        setSelectedListItem(link.name);
        isMobile && handleDrawerToggle()
      };
    },
    [navigate, isExpanded]
  );

  const handleSublinkClick = useCallback(
    (link) => {
      return async (e) => {
        e.preventDefault();
        setExpand(link.name)
        setActive(link.name)
        navigate(link.path);
        isMobile && handleDrawerToggle()
      };
    },
    [navigate]
  );

  useEffect(() => {
    if (pathnameArray[2] !== undefined && pathnameArray[2] !== "dashboard") {
      const route = dashboardRoutes.filter(r => r.path === "/dashboard/" + pathnameArray[2])
      setSelectedListItem(route[0]?.name)
    } else {
      setSelectedListItem("Dashboard")
    }
  }, [pathnameArray]);

  const drawer = (
    <Stack>
      <Box alignItems="center" sx={{ my: 4, display: "flex", justifyContent: "center", flexDirection: "column" }}>
        <img src={Dagizo_Logo} alt="Dagizo Logo" width="150"></img>

        <div className="mt-2">
          <Chip
            onClick={() => {
              setMobileOpen(false)
              setShare(true)
            }}
            avatar={<Avatar><ShareIcon /></Avatar>}
            // icon={<IosShareIcon />}
            size="small"
            label="share menu"
            variant="outlined"
            color="success"
          />
        </div>
      </Box>
      <Box sx={{ mx: 2, height: "60vh" }}>
        <StyledList>
          {dashboardRoutes.filter(r => user?.user_type !== "Employee" ? r.show : r.show && user_permissions?.includes(r.routeCode)).map((route, index) => (
            <div key={index}>
              <ListItemButton
                key={index}
                sx={{ mb: 1 }}
                selected={selectedListItem === route.name}
                onClick={handleListItemClick(route)}
              >
                <ListItemIcon sx={{ mr: -2 }}>
                  <route.icon />
                </ListItemIcon>
                <ListItemText primary={route.name} />
                {route.name === "Orders" && orders?.length > 0 && (
                  <span className={`w-8 h-6 flex items-center justify-center font-semibold ${selectedListItem === route.name ? "bg-white text-primary" : "bg-primary text-white"} rounded`}>{orders?.length}</span>
                )}
                {route.sublinks &&
                  route.sublinks?.length > 0 &&
                  (isExpanded && selectedListItem === route.name ? (
                    <ExpandLess
                      className={`${selectedListItem === route.name ? 'text-white font-bold ' : 'text-black'
                        }`}
                    />
                  ) : (
                    <ExpandMore
                      className={`${selectedListItem === route.name ? 'text-white font-bold' : 'text-black'
                        }`}
                    />
                  ))}
              </ListItemButton>

              {route.sublinks && route.sublinks?.length > 0 && (
                <Collapse in={isExpanded} timeout='auto' unmountOnExit>
                  <List>
                    {route.sublinks.map((route, index) => {
                      const { name, path } = route
                      const isActive = active === name.toLowerCase()
                      return (
                        <div key={index}>
                          {name && (
                            <ListItemButton
                              key={index}
                              sx={expand === route.name ? {
                                backgroundColor: '#f4b31330',
                                borderRadius: 1,
                                mt: index === 0 && -1.5,
                                pl: 7
                              } : {
                                mt: index === 0 && -1.5,
                                pl: 7
                              }}
                              // selected={expand === route.name}
                              onClick={handleSublinkClick(route)}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: expand === route.name && '700',
                                    }}
                                    className={`text-gray-500 sifonn-font ${expand === route.name && 'font-bold text-black'
                                      }  capitalize`}
                                  >
                                    {name}
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          )}
                        </div>
                      )
                    })}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </StyledList>
      </Box>
    </Stack>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          display: { xs: "flex", md: "none" },
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          bgcolor: "white",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="shadow"
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 2, display: { xs: "flex", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              ml: "30%",
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              textDecoration: "none",
            }}
          >
            <img src={Dagizo_Logo} alt="Dagizo Logo"></img>
          </Typography> */}

          <AccountAndNotifications />
        </Stack>
      </AppBar>

      <Box
        component="nav"
        className="shadow"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { sm: 0 },
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              borderWidth: 0,
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "#FAF8F9",
              borderWidth: 0,
            },
          }}
          open={open}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          width: { md: `calc(100% - ${drawerWidth}px)` },
        })}
      >
        <AppBar sx={{ mb: 2, backgroundColor: 'white', boxShadow: "5px 5px 15px 2px rgb(0 0 0 / 0.1)" }} position="static">
          <DashboardHeader />
        </AppBar>

        <Box
          component="main"
          sx={{
            px: 3,
            pt: { xs: 5, md: 0 },
          }}
        >
          <Outlet />
        </Box>

      </Box>

      <ShareMenuModal open={share} setOpen={setShare} url={branchURL} />
    </Box>
  );
};

export default HomePage;
