import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Stack,
  Tabs,
  Tab,
  LinearProgress,
  Button,
  Typography,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import NoEmployee from "./NoEmployee";
import AddEmployeeModal from "./modals/AddEmployeeModal";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// Functions
import { getRestaurantStaff } from "../../functions/restaurant";
import StaffListTable from "./datagrids/StaffListTable";
import LoadingCard from "../Loading";
import axios from "axios";

const Employee = () => {
  const { currentBranch } = useSelector((state) => state?.branch);
  const [restaurantStaff, setRestaurantStaff] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("all");
  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);

  const handleOpen = () => setOpenAddEmployeeModal(true);
  const closeAddEmployeeModal = () => setOpenAddEmployeeModal(false);

  const handleNavChange = (event, newCurrentTab) => {
    setCurrentTab(newCurrentTab);
  };

  const fetchRestaurantStaff = () => {
    setLoading(true);
    getRestaurantStaff(currentBranch?.id).then((res) => {
      setLoading(false);
      setRestaurantStaff(res.data.data);
    });
  };

  useEffect(() => {
    fetchRestaurantStaff();
  }, [currentBranch.id]);

  return (
    <Stack
      sx={{
        bgcolor: "secondary.input_field",
        borderRadius: 5,
        p: 5,
      }}
    >
      <Box
        sx={{
          //   width: "100%",
          "& .MuiTab-root": {
            textTransform: "none",
            fontSize: "1rem",
            mr: 5,
          },
        }}
      >
        <AddEmployeeModal
          openAddEmployeeModal={openAddEmployeeModal}
          closeAddEmployeeModal={closeAddEmployeeModal}
          refreshEmployees={fetchRestaurantStaff}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            {/* <Tabs
              value={currentTab}
              onChange={handleNavChange}
              aria-label="basic tabs example"
            >
              {" "}
              <Tab value={"all"} label="All" />
              <Tab value={"restaurant_admins"} label="Admins" />
              <Tab value={"branch_managers"} label="Managers" />
              <Tab value={"chefs"} label="Chefs" />
              <Tab value={"waiters"} label="Waiters" />
            </Tabs> */}
            <Typography sx={{ fontSize: 28, fontWeight: 700, color: "black" }}>
              List of employees
            </Typography>
          </Box>
          <Box>
            {" "}
            <Button
              onClick={() => setOpenAddEmployeeModal(true)}
              startIcon={<AddRoundedIcon />}
              disableElevation
              variant="contained"
              sx={{
                color: "white",
              }}
            >
              Add Employee
            </Button>
          </Box>
        </Stack>
      </Box>
      <Box>
        {loading && <LoadingCard />}
        {!loading && restaurantStaff.length === 0 && <NoEmployee />}
        {restaurantStaff.length > 0 && (
          <StaffListTable
            restaurantStaff={restaurantStaff}
            refreshEmployees={fetchRestaurantStaff}
          />
        )}
      </Box>
    </Stack>
  );
};

export default Employee;
