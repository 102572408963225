import React, { useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import {
  DataGrid,
  // GridToolbar,
} from "@mui/x-data-grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

// // Components
// import CustomNoRowsOverlay from "./NoRowsOverlay";

export function SortedDescendingIcon() {
  return <ArrowDropDownIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ArrowDropUpIcon className="icon" />;
}

const MenuDataGrid = ({ rows, columns }) => {
  const [pageSize, setPageSize] = useState(10);

  return (
    <>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
          },
        }}
        getRowId={(row) => row.menu_code}
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        onPageSizeChange={(newValue) => setPageSize(newValue)}
        components={{
          ColumnSortedDescendingIcon: SortedDescendingIcon,
          ColumnSortedAscendingIcon: SortedAscendingIcon,
          LoadingOverlay: LinearProgress,
        //   NoRowsOverlay: CustomNoRowsOverlay,
    
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </>
  );
};
export default MenuDataGrid;