import { Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { MdOutlineCancel } from 'react-icons/md'
import { Modal, Box, IconButton, Grid, Stack, Typography, TextField, Button, CircularProgress } from '@mui/material';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ImageUploader from '../common/utils/ImageUploader';
import { notify } from '../Toast';
import AxiosPrivate from '../../functions/axios/axiosPrivate';

const AddMenuCategory = ({ visible, handleCancelButton, handleVisible, menu_id, edit, toDelete, category, refresh }) => {
  const [section_name, setSection_name] = useState('')
  const [section_image, setSection_image] = useState('')
  const [loading, setloading] = useState(false)

  useEffect(() => {
    if (category) {
      setSection_name(category.section_name)
      setSection_image(category.section_image)
    } else {
      setSection_name("")
      setSection_image("")
    }
  }, [category, edit])

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!menu_id) {
      notify("Menu ID is required", "error")
      return;
    } else {
      if (section_name) {
        setloading(true)

        if (category) {
          return AxiosPrivate.post(`/restaurant/section-edit-delete/${category.id}/`,
            { section_name, section_image }
          )
            .then((res) => {
              notify(res.data.message, "success")
              handleVisible()
              setloading(false)
              refresh()
            })
            .catch((err) => {
              notify(err.data?.message)
              setloading(false)
            });
        } else {
          return AxiosPrivate.post(`/restaurant/add-category/${menu_id}/`,
            { category_name: section_name, category_image: section_image }
          )
            .then((res) => {
              notify(res.data.message, "success")
              handleVisible()
              setloading(false)
              refresh()
            })
            .catch((err) => {
              notify(err.data?.message)
              setloading(false)
            });
        }
      }
    }
  };

  const handleDelete = (e) => {
    e.preventDefault()
    setloading(true)

    return AxiosPrivate.delete(`/restaurant/section-edit-delete/${category.id}/`)
      .then((res) => {
        notify(res.data.message, "success")
        handleVisible()
        setloading(false)
        refresh()
      })
      .catch((err) => {
        notify(err.data?.message)
        setloading(false)
      });
  }

  return (
    <Modal open={visible} onClose={handleVisible}>
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40vw",
          bgcolor: "background.paper",

          borderRadius: 2,
          borderColor: `primary.main`,
          elevation: 24,
          [theme.breakpoints.down("md")]: {
            width: "80vw",
          },
        })}
      >
        <Box sx={{ position: "absolute", top: 5, right: 5 }}>
          <IconButton onClick={handleVisible}>
            <CloseOutlinedIcon
              sx={{ borderRadius: 25, bgcolor: "black", color: "white" }}
            />
          </IconButton>
        </Box>

        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
          <div>
            <div className="mx-auto flex items-center justify-center pb-4">
              <div className='flex justify-between items-center w-full'>
                <h2 className='text-xl font-medium text-yellow-500'>{toDelete ? "Delete" : category ? "Edit" : "Add"} Menu Section</h2>
                <MdOutlineCancel className='w-5 h-5 hover:text-red-600 cursor-pointer' onClick={() => handleCancelButton()} />
              </div>
            </div>

            {!toDelete ? (
              <form className='' onSubmit={handleSubmit}>
                <Grid container>
                  {" "}
                  <Grid xs={12}>
                    <Stack direction="row" sx={{ justifyContent: "flex-start" }}>
                      <Box>
                        {" "}
                        <ImageUploader
                          changeAvatar={(link) => setSection_image(link)}
                          title={"Upload Section Image"}
                          current={category?.section_image}
                        />
                      </Box>
                    </Stack>

                    <Typography color="black" sx={{ mt: 2 }}>
                      Section Name
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      value={section_name}
                      onChange={(e) => setSection_name(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading || !section_image || !section_name}
                  disableElevation
                  sx={{ mt: 4, height: 55, fontSize: "1rem", color: "white" }}
                  variant="contained"
                  fullWidth
                >
                  {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : category ? "Update Menu Section" : "Create Menu Section"}
                </Button>
              </form>
            ) : (
              <div>
                <p className="my-6">Are you sure you want to delete <b>{category.section_name}</b> ?</p>
                <div className="flex gap-4 justify-end">
                  <button onClick={handleVisible} className="bg-gray-200 px-6 py-2 rounded">Cancel</button>
                  <button disabled={loading || !section_image} onClick={handleDelete} className="flex items-center gap-3 bg-red-600 px-6 py-2 text-red-100 rounded">
                    Delete {loading && (<CircularProgress color="inherit" size="1.2rem" />)}
                  </button>
                </div>
              </div>
            )}

          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default AddMenuCategory