import React, { useEffect, useState } from "react";
import ModalLayout from "../../modals/Layout";
import { Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import ActiveHours from "../../common/utils/ActiveHours";
import { notify } from "../../Toast";
import AxiosPrivate from "../../../functions/axios/axiosPrivate";

const TaxModal = ({ open, setOpen, tax, edit, toDelete, refrech }) => {
    const [tax_type, setTax_type] = useState('')
    const [tax_rate, setTax_rate] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (tax) {
            setTax_type(tax.tax_type)
            setTax_rate(tax.tax_rate)
        } else {
            setTax_type("")
            setTax_rate("")
        }
    }, [tax])

    const handleSubmit = (e) => {
        e.preventDefault()

        if(!tax_type || !tax_rate) {
            notify("All the fields are required", "error")
            return
        }

        setLoading(true)
        if (tax) {
            return AxiosPrivate.post(`/restaurant/dagizo-tax-setup-crud/${tax.id}/`,
                { tax_type, tax_rate }
            )
            .then((res) => {
                notify(res.data.message, "success")
                setOpen(false)
                setLoading(false)
                refrech()
            })
            .catch((err) => {
                notify(err.data?.message)
                setLoading(false)
            });
        } else {
            return AxiosPrivate.post(`/restaurant/dagizo-tax-setup/`,
                { tax_type, tax_rate }
            )
            .then((res) => {
                notify(res.data.message, "success")
                refrech()
                setOpen(false)
                setLoading(false)
            })
            .catch((err) => {
                notify(err.data?.message)
                setLoading(false)
            });
        }
    }

    const handleDelete = (e) => {
        e.preventDefault()
        setLoading(true)

        return AxiosPrivate.delete(`/restaurant/dagizo-tax-setup-crud/${tax.id}`)
        .then((res) => {
            notify(res.data.message, "success")
            refrech()
            setOpen(false)
            setLoading(false)
        })
        .catch((err) => {
            notify(err.data?.message)
            setLoading(false)
        });
    }

    return (
        <ModalLayout open={open} setOpen={setOpen} title={`${toDelete ? "Delete" : tax ? "Edit" : "Add"} Tax`}>
            {!toDelete ? (
                <form className='' onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid xs={12}>
                            <Typography color="black" sx={{ mt: 2 }}>
                                Tax Type
                            </Typography>
                            <TextField
                                fullWidth
                                required
                                value={tax_type}
                                onChange={(e) => setTax_type(e.target.value)}
                            />

                            <Typography color="black" sx={{ mt: 2 }}>
                                Tax Rate
                            </Typography>
                            <TextField
                                fullWidth
                                required
                                value={tax_rate}
                                onChange={(e) => setTax_rate(e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Button
                        type="submit"
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        sx={{ mt: 4, height: 55, fontSize: "1rem", color: "white" }}
                        variant="contained"
                        fullWidth
                    >
                        {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : tax ? "Update Tax" : "Save Tax"}
                    </Button>
                </form>
            ) : (
                <div>
                  <p className="my-6">Are you sure you want to delete <b>{tax.tax_type}</b> ?</p>
                  <div className="flex gap-4 justify-end">
                    <button onClick={() => setOpen(false)} className="bg-gray-200 px-6 py-2 rounded">Cancel</button>
                    <button disabled={loading} onClick={handleDelete} className="flex items-center gap-3 bg-red-600 px-6 py-2 text-red-100 rounded">
                      Delete {loading && (<CircularProgress color="inherit" size="1.2rem" />)}
                    </button>
                  </div>
                </div>
            )}
        </ModalLayout>
    );
};

export default TaxModal;