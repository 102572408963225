import React, { useState, useEffect } from 'react'
import { Modal, Stack, Box, Typography, Button, IconButton } from "@mui/material"
import { getJobPositions } from '../../functions/restaurant';
import { useSelector } from 'react-redux';
import LoadingCard from '../Loading';
import RolesTable from './datagrids/RolesTable';
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AddJobPosition from './forms/AddJobPosition';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import NoJobs from './NoJobs';

const JobPositions = () => {
    const { currentBranch } = useSelector((state) => state?.branch);
    const [jobPositions, setJobPositions] = useState([]);
    const [loading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);


    const handleGetJobPositions = () => {
        setIsLoading(true);
        getJobPositions(currentBranch?.id).then((r) => {
            setJobPositions(r.data.data);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        handleGetJobPositions();
    }, [currentBranch]);

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Stack
            sx={{
                bgcolor: "secondary.input_field",
                borderRadius: 5,
                p: 5,
            }}
        >
            <Box
                sx={{
                    "& .MuiTab-root": {
                        textTransform: "none",
                        fontSize: "1rem",
                        mr: 5,
                    },
                }}
            >
                <Modal open={open} onClose={handleClose}>
                    <Box
                        sx={(theme) => ({
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "40vw",
                            bgcolor: "background.paper",
                            borderRadius: 2,
                            borderColor: `primary.main`,
                            elevation: 24,
                            [theme.breakpoints.down("md")]: {
                                width: "80vw",
                            },
                        })}
                    >
                        <Box sx={{ position: "absolute", top: 5, right: 5 }}>
                            <IconButton onClick={handleClose}>
                                <CloseOutlinedIcon
                                    sx={{ borderRadius: 25, bgcolor: "black", color: "white" }}
                                />
                            </IconButton>
                        </Box>
                        <Stack sx={{ p: 4 }}>
                            <AddJobPosition
                                refreshData={handleGetJobPositions}
                                handleClose={handleClose}
                            />
                        </Stack>
                    </Box>
                </Modal>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box>
                        <Typography sx={{ fontSize: 28, fontWeight: 700, color: "black" }}>
                            Job listings
                        </Typography>
                    </Box>
                    <Box>
                        <Button
                            onClick={() => setOpen(true)}
                            startIcon={<AddRoundedIcon />}
                            disableElevation
                            variant="contained"
                            sx={{
                                color: "white",
                            }}
                        >
                            Add Job Position
                        </Button>
                    </Box>
                </Stack>
            </Box>
            <Box>
                {loading && <LoadingCard />}
                {!loading && jobPositions.length === 0 && <NoJobs handleOpen={() => setOpen(true)} />}
                {!loading && jobPositions.length > 0 && (
                    <RolesTable
                        rowsData={jobPositions}
                        handleGetJobPositions={handleGetJobPositions}
                    />
                )}
            </Box>
        </Stack>
    )
}

export default JobPositions