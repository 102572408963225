import AxiosPrivate from "./axios/axiosPrivate";

export const createMenu = async (menu_name, menu_image, branchId) => {
  return await AxiosPrivate.post(`/restaurant/menu/${branchId}/`, {
    menu_name,
    menu_image,
  });
};

export const readMenu = async (menu_id) => {
  return await AxiosPrivate.get(`/restaurant/menu-detail/${menu_id}/`);
};

export const updateMenu = async (menu_name, menu_image, menu_id, is_active) => {
  return await AxiosPrivate.post(`/restaurant/menu-edit-delete/${menu_id}/`, {
    menu_name,
    menu_image,
    is_active,
  });
};

export const deleteMenu = async (menu_id) => {
  return await AxiosPrivate.delete(`/restaurant/menu-edit-delete/${menu_id}/`);
};

export const fetchBranchMenus = async (branch_id) => {
  return await AxiosPrivate.get(`/restaurant/menu/${branch_id}/`);
};

export const getFoodPerMenu = async (menu_id) => {
  return await AxiosPrivate.get(`/restaurant/food-menu/${menu_id}/`);
};

export const addFoodItem = async (
  food_name,
  food_image,
  food_content,
  description,
  price,
  tax,
  discount_rate,
  serving_hours_id,
  preparation_time,
  menu_id,
  in_stock,
  discount_active,
  show_preparation_time,
  restaurant_code
) => {
  console.log("RES_CODE", restaurant_code);
  return await AxiosPrivate.post(`/restaurant/food/${restaurant_code}`, {
    food_name,
    price,
    description,
    food_image,
    food_content,
    tax,
    discount_rate,
    serving_hours_id,
    preparation_time,
    menu_id,
    in_stock,
    discount_active,
    show_preparation_time,
  });
};

export const getFoodInACategory = async (id) => {
  return await AxiosPrivate.get(`/restaurant/food-in-category/${id}/`);
};

export const getAllFoodAddons = async (id) => {
  return await AxiosPrivate.get(
    `/restaurant/food-addons-on-specific-menu/${id}`
  );
};

export const updateFoodVisibility = async (id, availability) => {
  return await AxiosPrivate.post(`restaurant/edit-food-availability/${id}`, {
    is_in_stock: !availability,
  });
};

export const updateFoodVisibilit = async (id, visiblity) => {
  return await AxiosPrivate.post(`/restaurant/edit-food-visibility/${id}`, {
    isVisible: !visiblity,
  });
};
