import { Grid, InputLabel, CircularProgress, Button, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPesaPalSetup, pesaPalSetup } from '../../../services/pesapal';
import { notify } from '../../Toast';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Pesapal = () => {
    const [visibility, setVisibility] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initialData, setInitialData] = useState(null);
    const [values, setValues] = useState({
        consumer_key: null,
        consumer_secret: null,
        restaurant_pesapal_url: null,
    });

    const { consumer_key, consumer_secret, restaurant_pesapal_url } = values;
    const { currentBranch } = useSelector((state) => state.branch);

    const branchId = currentBranch?.id

    const handleVisibility = (input) => {
        const { name } = input;
        if (visibility.includes(name)) {
            const arr = [...visibility];
            arr.splice(visibility.indexOf(name), 1);
            setVisibility(arr);
        } else {
            setVisibility([...visibility, name]);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        pesaPalSetup(branchId, values).then((res) => {
            if (res.data.status === "200") {
                notify("Changes updated successfully", "success");
                handleUpdateData()
            } else if (res.data.status === false) {
                notify(res.data.message, "error");
            }
            setLoading(false)
        })
            .catch((err) => {
                notify(err.response.data.error, "error");
            });
    }

    const handleUpdateData = () => {
        getPesaPalSetup(branchId).then(res => {
            const { data: { keys: { consumer_key, consumer_secret }, ipn: { url } } } = res;
            setValues({ consumer_key, consumer_secret, restaurant_pesapal_url: url });
            setInitialData({ key: consumer_key, secret: consumer_secret, url })
        })
    }

    useEffect(() => {
        handleUpdateData()
    }, []);

    function onUpdate() {
        if (initialData) {
            const { key, secret, url } = initialData;
            return key === consumer_key && secret === consumer_secret && url === restaurant_pesapal_url
        }
    }

    const inputs = [
        {
            name: "consumer_key",
            label: "Consumer Key",
            value: consumer_key
        },
        {
            name: "consumer_secret",
            label: "Consumer Secret",
            value: consumer_secret
        },
        {
            name: "restaurant_pesapal_url",
            label: "IPN URL",
            value: restaurant_pesapal_url
        }
    ]

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <Grid container rowGap={3} sx={{ mt: 2, width: "50%" }}>
                {inputs.map((input, __) => (
                    <Grid xs={12} key={input.name}>
                        <InputLabel htmlFor={input.name}>{input.label}</InputLabel>
                        <OutlinedInput
                            placeholder={input.label}
                            id={input.name}
                            fullWidth
                            value={input.value}
                            name={input.name}
                            onChange={handleChange}
                            type={visibility.includes(input.name) || input.label === "IPN URL" ? "text" : 'password'}
                            endAdornment={
                                input.label !== "IPN URL" &&
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => handleVisibility(input)}
                                        edge="end"
                                    >
                                        {visibility.includes(input.name) ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                ))}
                <Button
                    type="submit"
                    disabled={loading || onUpdate()}
                    onClick={(e) => handleSubmit(e)}
                    disableElevation
                    sx={{ mt: 2, height: 55, fontSize: "1rem", color: "white" }}
                    variant="contained"
                    fullWidth
                >
                    {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : "Save Changes"}
                </Button>
            </Grid>
        </form>
    )
}

export default Pesapal