// import SwitchClubModal from 'components/Modals/SwitchClubModal'
import ArrowIcon from '../svg/Arrow'
// import Image from 'next/image'
import React, { useEffect, useState } from 'react'
// import { useSwitchClubModalToggle } from 'state/application/hooks'
import noImage from "../../images/no-image.png";
import { useSelector } from 'react-redux';

const CustomSelect = ({
  openBranch,
  setOpenBranch,
  selectedIcon,
  selectedName,
  hiddenProps,
  tempIcon,
}) => {
  const { job_name } = useSelector(state => state?.auth);

  const handleClick = () => {
    if (job_name) {
      return;
    } else {
      setOpenBranch(!openBranch)
    }
  }

  return (
    <>
      <div
        className={`bg-[#EBEAF1] py-2 px-4 rounded-lg lg:px-4 noselect text-[#332A7B] max-h-96 overflow-y-auto no-scrollbar`}
      >
        <button
          className='flex items-center justify-center gap-2 w-full cursor-pointer '
          onClick={handleClick}
        >
          <img className='bg-white rounded' alt='' src={selectedIcon || noImage} width={28} height={28} />
          {/* <img alt='' src={noImage} width={28} height={28} /> */}
          <h3 className={`${hiddenProps} text-lg mr-4 capitalize font-semibold`}>{selectedName || 'Select Branch'}</h3>
          {/* <Image alt="" src={"/icons/arrowdown.svg"} width={16} height={16} /> */}
          {!job_name && <div className='hidden lg:block'>
            <ArrowIcon fill="#332A7B" height={'16'} width={'16'} />
          </div>}
        </button>
      </div>
    </>
  )
}

export default CustomSelect
