import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Stack, Box, Button, Typography, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

// Redux
import { setBranchToUpdate, setCurrentBranch } from "../../features/branches/branchSlice";

// UTIL IMPORTS
import Geolocation from "../common/utils/Geolocation";
import ImageUploader from "../common/utils/ImageUploader";
import { notify } from "../Toast";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useCreateBranch, useEditBranch, useGetBranches } from "../../services/branches";

const BranchProfile = () => {
  const [values, setValues] = useState({
    branch_name: "",
    restaurant_image: null,
    first_contacts: "",
    second_contacts: "",
    email: "",
    address: "",
    openSnackBar: false,
    snackBarMessage: "",
    snackBarSeverity: "success",
  });

  const location = useLocation();
  let shouldEdit = location?.state?.edit;

  const {
    restaurant_image,
    branch_name,
    first_contacts,
    second_contacts,
    email,
    address,
  } = values;

  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { restaurant } = useSelector((state) => state.auth);
  const restaurant_id = restaurant?.id

  const { branchToUpdate, currentBranch } = useSelector((state) => state.branch);

  const createBranch = useCreateBranch(restaurant_id)
  const editBranch = useEditBranch()

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const changeAvatar = (url) => {
    setValues({ ...values, restaurant_image: url });
  };

  useEffect(() => {
    if(createBranch.isSuccess || editBranch.isSuccess) {
      if(branchToUpdate && branchToUpdate?.id === currentBranch?.id) {
        const newBranch = {...currentBranch, ...values}
        dispatch(setCurrentBranch({ currentBranch: newBranch }));
        navigate("/dashboard/branches");
      } else {
        navigate("/dashboard/branches");
      }
    }
  }, [createBranch.isSuccess, editBranch.isSuccess])

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      restaurant_image &&
      branch_name &&
      email &&
      first_contacts &&
      address
    ) {
      if (shouldEdit) {
        return editBranch.mutate({
          branch_name,
          restaurant_image,
          first_contacts,
          second_contacts,
          email,
          latitude,
          longitude,
          address,
          id: branchToUpdate?.id
        })
      } else {
        return createBranch.mutate({
          branch_name,
          restaurant_image,
          first_contacts,
          second_contacts,
          email,
          latitude,
          longitude,
          address,
          restaurant_id
        })
      }
    } else {
      notify("All the fields are required", "error")
    }
  };

  const prepopulateForms = () => {
    if (shouldEdit) {
      const { restaurant_image, branch_name, first_contacts, second_contacts, email, address, latitude, longitude } =
        branchToUpdate;

      setValues({
        ...values,
        restaurant_image,
        branch_name,
        first_contacts,
        second_contacts,
        email,
        address,
      });

      setLatitude(latitude)
      setLongitude(longitude)
    } else {
      return;
    }
  };

  useEffect(() => {
    prepopulateForms();
  }, []);

  return (
    <Box
      sx={(theme) => ({
        flexGrow: 1,
        bgcolor: "secondary.input_field",

        borderRadius: 5,
        p: 4,
        [theme.breakpoints.down("md")]: {},
      })}
    >
      <Grid>
        {" "}
        <Button
          onClick={() => navigate(-1)}
          startIcon={<ArrowBackIosNewIcon />}
          sx={{ fontSize: "1.2rem" }}
        >
          Back
        </Button>
      </Grid>

      <ValidatorForm autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
        <Stack direction="row" justifyContent="start">
          <ImageUploader
            changeAvatar={changeAvatar}
            title={"Set Branch Image"}
            current={restaurant_image}
          />
        </Stack>

        <Grid container spacing={5}>
          <Grid xs={12} lg={6}>
            <Box>
              {" "}
              <Typography sx={{ color: "black" }}>Branch Name</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("branch_name")}
                name="branch_name"
                value={branch_name}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              {" "}
              <Typography sx={{ color: "black" }}>Email</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("email")}
                name="email"
                value={email}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>Location</Typography>
              <Geolocation
                setLatitude={setLatitude}
                setLongitude={setLongitude}
              />
            </Box>
          </Grid>
          <Grid xs={12} lg={6}>
            <Box>
              <Typography sx={{ color: "black" }}>Restaurant Contact</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("first_contacts")}
                name="branchCode"
                value={first_contacts}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "black" }}>Manager Contact</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("second_contacts")}
                name="second_contacts"
                value={second_contacts}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              {" "}
              <Typography sx={{ color: "black" }}>Address</Typography>
              <TextValidator
                fullWidth
                onChange={handleChange("address")}
                placeholder="Type Nearest Town/City"
                name="address"
                value={address}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
            </Box>
          </Grid>
        </Grid>
        <Button
          disabled={createBranch.isLoading || editBranch.isLoading}
          sx={{ mt: 4, color: "white", fontSize: "1rem" }}
          type="submit"
          variant="contained"
          fullWidth
          disableElevation
        >
          {(createBranch.isLoading || editBranch.isLoading) ? (<CircularProgress color="inherit" size="1.5rem" />) : "Save Changes"}
        </Button>
      </ValidatorForm>
    </Box>
  );
};

export default BranchProfile;
