import React, { useState, useEffect, useMemo } from 'react'
import MenuCard from '../common/utils/MenuCard'
import { useLocation } from 'react-router-dom'
import AddMenuCategory from '../modals/AddMenuCategory'
import { Stack, LinearProgress } from "@mui/material";
import BackButton from '../../utils/BackButton'
import AxiosPrivate from '../../functions/axios/axiosPrivate';

const MenuCategories = () => {

    const [menuCategories, setMenuCategories] = useState(useMemo(() => [], []))
    const [visible, setVisible] = useState(false)
    const [edit, setEdit] = useState(false)
    const [toDelete, setTodelete] = useState(false)
    const [category, setCategory] = useState(null)

    const { pathname } = useLocation()
    const id = pathname.split('/')[3]

    const fetchMenuCategories = async () => {
        try {
            const res = await AxiosPrivate.get(`/restaurant/categories-in-menu/${id}`)
            setMenuCategories(res.data.data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchMenuCategories()
    }, [id]);

    const handleCancelButton = () => {
        setVisible(false)
    }

    const handleVisible = () => {
        setVisible(false)
    }

    return (
        <div>
            <div className="flex justify-between items-center">
                <p className="text-3xl font-medium text-gray-800">Menu Sections</p>
            </div>

            <div className='pt-4 -ml-3'>
                <BackButton />
            </div>

            <section className='grid md:grid-cols-2 lg:grid-cols-4 gap-6 pt-6'>
                <div className='flex justify-center items-center border border-dashed border-yellow-400 rounded-md shadow h-30'>
                    <button
                        className='text-sm py-1 px-5 text-black bg-yellow-400 rounded-md shadow'
                        onClick={() => {
                            setVisible(true)
                            setEdit(false)
                            setTodelete(false)
                            setCategory(null)
                        }}
                    >
                        + Add New
                    </button>
                </div>

                {menuCategories.length > 0 && menuCategories.map(menuCategory => (
                    <MenuCard
                        data={menuCategory}
                        menuId={id}
                        imageUrl={menuCategory.section_image || 'https://images.unsplash.com/photo-1504674900247-0877df9cc836?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'}
                        handleAction={(item) => {
                            const { data, type } = item;
                            setVisible(true)

                            if (type === "edit") {
                                setEdit(true)
                                setTodelete(false)
                                setCategory(data)
                            } else {
                                setEdit(false)
                                setTodelete(true)
                                setCategory(data)
                            }
                        }}
                    />
                ))}
            </section>

            <AddMenuCategory
                visible={visible}
                handleCancelButton={handleCancelButton}
                handleVisible={() => handleVisible()}
                menu_id={id}
                edit={edit}
                toDelete={toDelete}
                category={category}
                refresh={fetchMenuCategories}
            />
        </div>
    )
}

export default MenuCategories