import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// functions
import { createPlace, createSections, deletePlace, editPlace } from "../../../../functions/restaurant";

// cUSTOM cOMPONENTS
import CustomSnackBar from "../../../common/utils/CustomSnackBar";
import { notify } from "../../../Toast";

const CreatePlaceModal = ({
  openCreatePlaceModal,
  closeCreatePlaceModal,
  place_type,
  room,
  toDelete,
  refetch
}) => {
  const [values, setValues] = useState({
    place_name: "",
    service_charge: "",
    number_of_rooms: "",
    maximum_tables: 10,
    person_per_table: 5,
    openSnackBar: false,
    snackBarMessage: "",
    snackBarSeverity: "success",
  });
  const [loading, setLoading] = useState(false);

  const { currentBranch } = useSelector((state) => state.branch);
  const branchId = currentBranch?.id

  const {
    place_name,
    service_charge,
    number_of_rooms,
    maximum_tables,
    person_per_table,
    openSnackBar,
    snackBarMessage,
    snackBarSeverity,
  } = values;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setValues({ ...values, openSnackBar: false });
  };


  const handleClose = () => {
    closeCreatePlaceModal();
  };


  useEffect(() => {
    if (room) {
      setValues({
        maximum_tables: room.maximum_tables,
        place_name: room.place_name,
        person_per_table: room.person_per_table,
      })
    } else {
      setValues({
        maximum_tables: place_type === "Room" ? 1 : 5,
        place_name: "",
        person_per_table: 1,
      })
    }
  }, [room, place_type])

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    if (!branchId) {
      setLoading(false)
      notify('Please Select Branch', 'error')
    } else {
      if (room) {
        return editPlace(
          place_type,
          place_name,
          maximum_tables,
          person_per_table,
          room.id
        )
          .then((res) => {
            setLoading(false)
            notify(res.data.message, 'success')
            refetch()
            handleClose()
          })
          .catch((err) => {
            setLoading(false)
            console.log("RESTAURANT PLACE ADDITION ERROR", err);
            notify(err.message, 'error')
          });
      } else {
        return place_type === "Room" ?
          createSections({ section_name: place_name, number_of_rooms, service_charge }, branchId).then(res => {
            notify(res.data.message, 'success');
            setLoading(false)
            refetch()
            handleClose()
          }).catch((err) => {
            setLoading(false)
            // console.log("RESTAURANT PLACE ADDITION ERROR", err);
            notify(err.message, 'error')
          }) :
          createPlace(
            place_type,
            place_name,
            maximum_tables,
            person_per_table,
            branchId
          )
            .then((res) => {
              setLoading(false)
              notify(res.data.message, 'success')
              refetch()
              handleClose()
            })
            .catch((err) => {
              setLoading(false)
              console.log("RESTAURANT PLACE ADDITION ERROR", err);
              notify(err.message, 'error')
            });
      }
    }
  };

  const handleDelete = (e) => {
    e.preventDefault()
    setLoading(true)

    return deletePlace(room.id)
      .then((res) => {
        notify(res.data.message, "success")
        handleClose()
        setLoading(false)
        refetch()
      })
      .catch((err) => {
        notify(err.data?.message)
        setLoading(false)
      });
  }

  return (
    <Modal open={openCreatePlaceModal} onClose={handleClose}>
      <Stack
        sx={(theme) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40vw",
          bgcolor: "background.paper",

          borderRadius: 2,
          borderColor: `primary.main`,
          elevation: 24,
          [theme.breakpoints.down("md")]: {
            width: "80vw",
          },
        })}
      >
        <Box sx={{ position: "absolute", top: 5, right: 5 }}>
          <IconButton onClick={handleClose}>
            <CloseOutlinedIcon
              sx={{ borderRadius: 25, bgcolor: "black", color: "white" }}
            />
          </IconButton>
        </Box>
        <Stack sx={{ p: 4, my: 2 }}>
          {" "}
          <Typography variant="h4" fontWeight="500" color="black">
            {place_type === "Room" ? "Section" : place_type} Setting
          </Typography>

          {!toDelete ? (
            <form onSubmit={(e) => handleSubmit(e)}>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid xs={12}>
                  <Typography color="black">{place_type === "Room" ? "Section" : place_type} Name</Typography>
                  <TextField
                    placeholder="E.g: G, FF, SF"
                    fullWidth
                    value={place_name}
                    onChange={handleChange("place_name")}
                  />
                </Grid>

                {place_type === "Room" && (
                  <>
                    <Grid xs={12}>
                      <Typography color="black">Number of rooms</Typography>
                      <TextField
                        placeholder="E.g: 5"
                        fullWidth
                        value={number_of_rooms}
                        onChange={handleChange("number_of_rooms")}
                        type="number"
                      />
                    </Grid>

                    <Grid xs={12}>
                      <Typography color="black">Service Fees</Typography>
                      <TextField
                        placeholder="E.g: 100"
                        fullWidth
                        value={service_charge}
                        onChange={handleChange("service_charge")}
                        type="number"
                      />
                    </Grid>
                  </>
                )}

                {place_type !== "Room" && (
                  <>
                    <Grid xs={12}>
                      <Typography color="black">Number of Tables</Typography>
                      <TextField
                        fullWidth
                        value={values.maximum_tables}
                        onChange={handleChange("maximum_tables")}
                      />
                    </Grid>
                    {/* <Grid xs={12}>
                      <Typography color="black">People Per Table</Typography>
                      <TextField
                        fullWidth
                        type={"number"}
                        value={values.person_per_table}
                        onChange={handleChange("person_per_table")}
                      ></TextField>
                    </Grid> */}
                  </>
                )}

              </Grid>
              <Button
                type="submit"
                disabled={loading}
                onClick={(e) => handleSubmit(e)}
                disableElevation
                sx={{ mt: 4, height: 55, fontSize: "1rem", color: "white" }}
                variant="contained"
                fullWidth
              >
                {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : "Save Changes"}
              </Button>
            </form>
          ) : (
            <div>
              <p className="my-6">Are you sure you want to delete {room?.place_type} <b>{room.place_name}</b> ?</p>
              <div className="flex gap-4 justify-end">
                <button onClick={handleClose} className="bg-gray-200 px-6 py-2 rounded">Cancel</button>
                <button disabled={loading} onClick={handleDelete} className="flex items-center gap-3 bg-red-600 px-6 py-2 text-red-100 rounded">
                  Delete {loading && (<CircularProgress color="inherit" size="1.2rem" />)}
                </button>
              </div>
            </div>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default CreatePlaceModal;
