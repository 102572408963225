import axios from "./axios/axios";

export const registerRestaurant = async (
  user_image,
  restaurant_image,
  restaurant_name,
  first_name,
  last_name,
  email,
  phone_number,
  latitude,
  longitude,
  type
) => {
  return await axios.post(
    `/users/signup/`,
    {
      user_image,
      restaurant_image,
      restaurant_name,
      first_name,
      last_name,
      email,
      phone_number,
      latitude,
      longitude,
      type
    }
  );
};

export const signInWithEmailAndPassword = async (email, password) => {
  return await axios.post(
    '/users/login/',
    { email, password },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

export const verifyEmailAddress = async (currentPassword, newPassword, confirmPassword, id) => {
  return await axios.post(
    `users/users-verify-emails/${id}`,
    { currentPassword, newPassword, confirmPassword },
    {
      headers: {}
    }
  )
};

export const sendForgotPassword = async (email) => {
  return await axios.post(
    `/forgot-password`,
    { email },
    {
      headers: {},
    }
  );
};

export const sendResetPassword = async (password, passwordConfirmation) => {
  return await axios.put(
    `/reset-password`,
    { password, passwordConfirmation },
    {
      headers: {},
    }
  );
};
