import React, { useState } from "react";
import {useNavigate} from "react-router-dom"

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";

const MenuNav =()=> {
  const [value, setValue] = useState("Latest Dishes");
const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",

        "& .MuiTab-root": {
          textTransform: "none",
          fontSize: "1.2rem",
          mr: 10,
        },
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider", border: "50px"}}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            value="Latest Dishes"
            label="Latest Dishes"
            onClick={() => navigate("latest-dishes")}
          />
          <Tab
            value="Featured Dishes"
            label="Featured Dishes"
            onClick={() => navigate("featured-dishes")}
          />
        </Tabs>
      </Box>
    </Box>
  );
}

export default MenuNav