import { Box, Stack, Typography, Chip, Divider, Button } from "@mui/material";
import { red, green, amber } from "@mui/material/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import moment from "moment";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PopoverInfo from "../Popover";
import OrderMap from "./OrderMap";

const OrderDetails = ({
  orderDetails,
  showMap = true,
  showDeliveryDetails = false,
  showInterractionButtons = true,
  handleClickChip,
  handleOpenPayment,
}) => {
  const order = orderDetails || {};

  function getChipProps(params) {
    if (params === "Completed") {
      return {
        label: params,
        style: {
          width: "100px",
          color: green[600],
          borderColor: green[100],
          backgroundColor: green[100],
          borderRadius: 5,
        },
      };
    } else if (params === "Pending") {
      return {
        label: params,
        style: {
          width: "100px",
          color: amber[600],
          borderColor: amber[100],
          backgroundColor: amber[100],
          borderRadius: 5,
        },
      };
    } else {
      return {
        label: params,
        style: {
          width: "100px",
          color: red[600],
          borderColor: red[100],
          backgroundColor: red[100],
          borderRadius: 5,
        },
      };
    }
  }

  // format the dates from backend to am pm format.
  function formatTime(str) {
    const timeString = str || null;
    const time = new Date(timeString);

    const hrs = time.getHours();
    const mins = time.getMinutes();
    const ampm = hrs + 12 >= 12 ? "pm" : "am";
    return `${hrs}:${mins < 10 ? "0" + mins : mins} ${ampm}`;
  }

  //calculate arrival time for take away orders.
  function calculateArrivalTime(orderTime, time) {
    console.log(time);
    var hrs = "";
    var mins = "";
    const ampm = hrs >= 12 ? "pm" : "am";

    const date = new Date(orderTime);
    const arrivingIn = time?.split(" ") || null;

    if (arrivingIn) {
      if (time.toLowerCase() === "now") {
        hrs = date.getHours();
        mins = date.getMinutes();
      } else if (arrivingIn[1]?.toLowerCase() !== "minutes") {
        hrs = date.getHours() + parseInt(arrivingIn[0]);
        mins = date.getMinutes();
      } else {
        const totalMinutes = parseInt(arrivingIn[0]) + date.getMinutes();
        if (totalMinutes > 60) {
          mins = totalMinutes % 60;
          hrs = date.getHours() + (totalMinutes - mins) / 60;
        }
      }
    }

    return `${hrs}:${mins < 10 ? "0" + mins : mins} ${ampm}`;
  }

  return (
    <>
      <Stack sx={{ mt: 3 }} direction="row" justifyContent="space-between">
        {/* <Box sx={{ mr: 2 }}>
            <Icon>
              <FastfoodIcon sx={{ fontsize: "large", color: "primary.main" }} />
            </Icon>
          </Box> */}
        <Box>
          <Typography sx={{ color: "black", fontWeight: 700 }}>
            {order.order_number}
          </Typography>
          <Typography>{order.order_type}</Typography>
          <Typography>
            {moment(order.ordered_date).format("hh:mm:A  YYYY/MM/DD")}
          </Typography>
        </Box>
        {showInterractionButtons && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
            }}
          >
            <Chip
              onClick={handleClickChip}
              variant="outlined"
              size="medium"
              {...getChipProps(order.order_status)}
            />
            {order.payment_status === "PAID" && (
              <a
                href={`/receipt/download/${order?.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer"
              >
                <Chip
                  variant="outlined"
                  size="medium"
                  label="View Receipt"
                  sx={{
                    color: red[600],
                    color: green[600],
                    borderColor: green[100],
                    backgroundColor: green[100],
                    borderRadius: 1,
                  }}
                />
              </a>
            )}
          </Box>
        )}
      </Stack>

      <div>
        <div className="flex justify-between items-center pt-6 pb-4 border-b-2 text-2xl">
          <h1 class="text-gray-600 font-semibold">Order Summary</h1>
          <h1 class="text-green-600 font-semibold">
            <span className="text-sm">{order.table?.room_id?.place_name}</span>{" "}
            {" | "} {order.table?.table_name}
          </h1>
        </div>

        <ul className="py-6 space-y-4">
          {order?.order_details?.length > 0 &&
            order?.order_details?.map((food, index) => (
              <li
                key={index}
                className="grid grid-cols-12 gap-2 p-4"
                style={{ boxShadow: "5px 5px 40px 2px rgb(0 0 0 / 0.1)" }}
              >
                <div className="col-span-2">
                  <img
                    src={food.food_image[0]?.url}
                    alt="Product"
                    className="object-cover h-12 w-12 rounded"
                  />
                </div>
                <div className="flex flex-col col-span-7">
                  <span className="text-gray-600 text-md font-semi-bold">
                    {food?.order_list?.food__food_name}
                    <span className="font-bold ml-2">
                      (x{food?.order_list?.quantity})
                    </span>
                  </span>
                  <span className="text-blue-400 text-sm inline-block">
                    <ArrowRightAltIcon sx={{ fontSize: 14 }} />{" "}
                    <span className="text-gray-400">
                      {food?.order_list?.food__dish_category}
                    </span>
                  </span>
                  {food?.order_list?.additional_notes && (
                    <div className="flex items-center gap-2 text-red-600">
                      <p className="text-sm font-bold">Note</p>
                      <PopoverInfo
                        content={food?.order_list?.additional_notes}
                      />
                    </div>
                  )}
                  {food?.order_list?.ordered_variants?.length > 0 && (
                    <div className="">
                      {food?.order_list?.ordered_variants?.map(
                        (variant, index) => {
                          const { name, price, valueName } = variant;
                          return (
                            <div
                              className="w-full mb-4 mt-2 bg-gray-100 px-2 py-1 border-l-4 border-yellow-600 text-xs"
                              key={index}
                            >
                              <div className="flex gap-2">
                                <p>{name}: </p>
                                <p className="font-bold">{valueName}</p>
                              </div>
                              <p className="font-bold">
                                {price?.toLocaleString("en", {
                                  style: "currency",
                                  currency: "KSH",
                                })}
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                  {food?.order_list?.ordered_addons?.length > 0 && (
                    <div>
                      {food?.order_list?.ordered_addons?.map((addon, index) => (
                        <div className="flex mt-1" key={index}>
                          {addon?.addons_extras ? (
                            <div className="w-full flex items-center gap-2">
                              <img
                                src={
                                  addon?.addons_extras?.food_image_json[0]?.url
                                }
                                alt="Product"
                                className="border-2 border-green-400 object-cover h-11 w-11 p-1 rounded"
                              />
                              <div className="w-4/6">
                                <p className="capitalize text-sm">
                                  {addon?.addons_extras?.food_name}
                                </p>
                                <div className="flex gap-4">
                                  <p className="text-sm text-gray-400">
                                    {addon?.addons_extras?.dish_category}
                                  </p>
                                  <p className="text-sm text-primary font-semibold">
                                    Ksh {addon?.addons_extras?.price}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="">
                  {food?.order_list?.food__discount_rate > 0 && (
                    <span className="text-primary line-through text-sm block">
                      {food?.order_list?.food__original_price?.toLocaleString(
                        "en",
                        {
                          style: "currency",
                          currency: "Ksh",
                        }
                      )}
                    </span>
                  )}

                  <span className="text-primary font-bold">
                    {food?.order_list?.food__price?.toLocaleString("en", {
                      style: "currency",
                      currency: "Ksh",
                    })}
                  </span>
                </div>
              </li>
            ))}
        </ul>
      </div>

      <Divider />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 1.5 }}
      >
        <Typography variant="h5" sx={{ color: "#444", fontWeight: 700 }}>
          Total
        </Typography>
        <Typography
          variant="h5"
          sx={{ color: "primary.main", fontWeight: 700 }}
        >
          Ksh {order.amount}
        </Typography>
      </Stack>

      <div className="mt-6">
        <h6 className="font-bold text-gray-600 text-lg mt-4 mb-1">Customer</h6>
        {order?.paying_name && <p className="text-gray-500 text-sm">{order?.paying_name}</p>}
        <p className="text-gray-500 text-sm">{order?.customer_email}</p>
        <p className="text-gray-500 text-sm mt-1">{order?.customer_phone}</p>
        <div className="flex justify-between items-center border-b pb-3 mt-6">
          <h6 className="text-gray-600 font-bold text-sm">Payment Status</h6>
          <p className="text-gray-500 text-sm">{order?.payment_status}</p>
        </div>

        <div className="flex justify-between items-center pb-3 mt-4">
          <h6 className="text-gray-600 font-bold text-sm">Payment Method</h6>
          <p className="text-gray-500 text-sm">{order?.payment_mode}</p>
        </div>

        {order?.order_type !== "Delivery" && order?.table === null && (
          <div className="border-t py-3 flex flex-row items-center justify-between">
            <h6 className="text-gray-600 font-bold text-sm">Arrival Time</h6>
            <p className="font-black text-lg">
              {calculateArrivalTime(order?.created, order?.arrival_time)}
            </p>
          </div>
        )}

        {order?.pesapal_payment ? (
          <div>
            <div className="bg-blue-100 p-4 my-4">
              <h6 className="text-gray-800 font-bold text-md text-center mb-2">
                Transaction Details
              </h6>
              <div className="flex gap-4 items-center border-b border-blue-200 pb-3 mt-4">
                <h6 className="text-gray-600 font-bold text-sm">
                  Transaction Code:
                </h6>
                <p className="text-gray-500 text-sm">
                  {order?.pesapal_payment?.confirmation_code}
                </p>
              </div>
              <div className="flex gap-4 items-center border-b border-blue-200 pb-3 mt-4">
                <h6 className="text-gray-600 font-bold text-sm">
                  Payment Number:
                </h6>
                <p className="text-gray-500 text-sm">
                  {order?.paying_phone}
                </p>
              </div>
              <div className="flex gap-4 items-center border-b pb-3 mt-4">
                <h6 className="text-gray-600 font-bold text-sm">
                  Amount Paid:
                </h6>
                <p className="text-gray-500 text-sm">
                  KES {order?.pesapal_payment?.amount}
                </p>
              </div>
            </div>
            {/* fix this when partial payment is greater than zero after backend is fixed */}
            {order?.payment_status === "Partially Paid" && (
              <div className="flex justify-center">
                <Button
                  onClick={handleOpenPayment}
                  sx={{ mt: 2, height: 40, fontSize: "1rem" }}
                  variant="contained"
                >
                  Receive Ksh.
                  {order.amount -
                    Number(order?.pesapal_payment?.amount?.substring(0, 1))}{" "}
                  balance
                </Button>
              </div>
            )}

            {/* {order?.payment_status === "Partially Paid" || order?.payment_status === "NOTPAID" && 
                <div className="flex justify-center">
                  <Button
                    onClick={handleOpenPayment}
                    sx={{ mt: 2, height: 40, fontSize: "1rem" }}
                    variant="contained"
                  >
                    Receive payment
                  </Button>
                </div>
              } */}
          </div>
        ) : (
          (order?.payment_status === "Partially Paid" ||
            order?.payment_status === "NOTPAID") && (
            <div className="flex justify-center">
              <Button
                onClick={handleOpenPayment}
                sx={{ mt: 2, height: 40, fontSize: "1rem" }}
                variant="contained"
              >
                Receive payment
              </Button>
            </div>
          )
        )}

        {order?.payment_status === "PAID" && order?.cash > 0 && (
          <div className="flex justify-between items-center pb-3 mt-4">
            <h6 className="text-gray-600 font-bold text-sm">Cash received</h6>
            <p className="text-gray-500 text-sm">KES {order?.cash}</p>
          </div>
        )}
      </div>

      {/* show delivery details on expanded order details only */}
      {showDeliveryDetails && (
        <div className="space-y-3 py-6">
          <h2 className="text-gray-600 font-bold text-lg">Delivery Details</h2>
          <div className="!space-y-2">
            {[
              { name: "Address", text: order?.delivery_address },
              { name: "Phone Number", text: order?.delivery_phone_number },
              {
                name: "Description",
                text: order?.delivery_description,
              },
            ].map((det, i) => (
              <p className="text-base font-semibold" key={i}>
                {det.name}:{" "}
                <span className="font-normal text-sm">{det.text}</span>
              </p>
            ))}
          </div>
        </div>
      )}

      {showMap && order?.order_type === "Delivery" && (
        <div className="py-6 space-y-4">
          <div className="space-y-3">
            <h2 className="text-gray-600 font-bold text-lg">
              Delivery Details
            </h2>
            <div className="!space-y-2">
              {[
                { name: "Address", text: order?.delivery_address },
                {
                  name: "Phone Number",
                  text: order?.delivery_phone_number,
                },
                {
                  name: "Description",
                  text: order?.delivery_description,
                },
              ].map((det, i) => (
                <p className="text-base font-semibold" key={i}>
                  {det.name}:{" "}
                  <span className="font-normal text-sm">{det.text}</span>
                </p>
              ))}
            </div>
          </div>
          <div className="relative">
            <div className="h-48 w-full rounded-xl overflow-hidden">
              <OrderMap
                orderLocation={{
                  lat: order?.delivery_latitude,
                  lng: order?.delivery_logitude,
                }}
                orderAddress={order?.delivery_address}
              />
            </div>
            <a
              href={`/orders/${order?.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="absolute top-0 right-0 w-max bg-primary py-1 px-2 rounded-tr-xl
              text-white z-[999]"
            >
              <div className="flex flex-row items-center space-x-2 cursor-pointer">
                <Typography className="!text-white !text-base">
                  Expand
                </Typography>
                <OpenInNewIcon className="!text-lg !text-white" />
              </div>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderDetails;
