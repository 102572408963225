import React from 'react'
import BackButton from '../utils/BackButton'

const FoodDetailsView = ({data}) => {
  return (
    <div className='pt-10'>
        <div className="overflow-hidden bg-white shadow-lg sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6 flex justify-between">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Details</h3>
                <BackButton />
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 items-center">
                    <dt className="text-sm font-medium text-gray-500">
                        <div className='flex items-center space-x-2'>
                            <img className='w-20 h-20 rounded-full object-center object-cover'  src={data?.food_image} alt="food item"/>
                            {/* <span>Name</span> */}
                        </div>
                    </dt>
                    <dd className="mt-1 text-base text-gray-900 sm:col-span-2 sm:mt-0">{data?.food_name}</dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Content</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data?.food_content}</dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Price</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data?.price}</dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Preparation Time</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data?.preparation_time}</dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Category</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data?.category_detail?.category_name}</dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">In Stock</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data?.is_in_stock ? 'True' : 'False'}</dd>
                </div>
                </dl>
            </div>    
        </div>
    </div>
  )
}

export default FoodDetailsView