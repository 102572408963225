import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import TaxModal from "../modals/TaxModal";
import NoData from "../../NoData";
import AxiosPrivate from "../../../functions/axios/axiosPrivate";

const Taxes = () => {
  const [open, setOpen] = useState(false)
  const [taxes, setTaxes] = useState([])
  const [currenTax, setCurrenTax] = useState(null)
  const [edit, setEdit] = useState(false)
  const [toDelete, setToDelete] = useState(false)

  const fetchTaxes = async () => {
    try {
        const res = await AxiosPrivate.get(`/restaurant/dagizo-tax-setup/`)
        console.log("Restaurant Periods", res.data.data)
        setTaxes(res.data.data)
    } catch (err) {
        console.log(err)
    }
  }

  useEffect(() => {
    fetchTaxes()
  }, [])

  const handleOpen = () => {
    setOpen(true)
    setCurrenTax(false)
    setToDelete(false)
    setEdit(false)
  }
  
  return (
    <div>
      <div className="w-full mt-6">
        <div className="flex justify-between mt-6 mb-6">
            {/* <Typography variant="h5" sx={{ color: "black", fontWeight: 600 }}>
            Set Taxes
            </Typography> */}
          <button onClick={handleOpen} className="bg-primary text-white px-4 py-1 rounded"><AddIcon /> Tax</button>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {taxes.length > 0 ? taxes.map((tax) => (
            <div className="flex justify-between bg-blue-50 p-4 rounded-lg shadow border" key={tax.id}>
              <div>
                <p className="font-bold">{tax.tax_type}</p>
                <p>Rate: {tax.tax_rate} %</p>
              </div>
              <div className="flex items-center">
                <IconButton
                  onClick={() => {
                    setOpen(true)
                    setCurrenTax(tax)
                    setEdit(true)
                    setToDelete(false)
                  }}
                >
                  <ModeEditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setOpen(true)
                    setCurrenTax(tax)
                    setEdit(false)
                    setToDelete(true)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          )) : (
            <NoData title="Tax" />
          )}
        </div>
      </div>

      <TaxModal
        open={open}
        setOpen={setOpen}
        tax={currenTax}
        edit={edit}
        toDelete={toDelete}
        refrech={fetchTaxes}
      />
    </div>
  );
};

export default Taxes;