import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { GoTrashcan } from 'react-icons/go'
import { IconButton } from '@mui/material'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';

const MenuCard = ({ data, menuId, imageUrl, handleAction }) => {

    const { pathname } = useLocation()
    const res_code = pathname.split('/')[5]

    return (
        <div className='rounded-lg border border-gray-200 pb-3 shadow'>
            <Link to={`/dashboard/menu/${menuId}/${data?.id}`}>
                <img className='h-24 w-full object-center object-cover rounded-t-lg' src={data.section_image ? data.section_image : imageUrl} alt="menu" />
            </Link>

            <div className='px-3 flex justify-between items-center pt-2'>
                {/* <Link to={`/dashboard/menu/${menuId}/${data?.category_name}/${data?.id}/restaurant_code/${res_code}`}> */}
                <Link to={`/dashboard/menu/${menuId}/${data?.id}`}>
                    <div className='flex items-center justify-between'>
                        <h3 className='text-gray-800 font-semibold text-base capitalize hover:text-yellow-600 hover:py-1 hover:px-2 hover:rounded-md hover:bg-gray-100 transition-all'>
                            {data?.section_name}
                        </h3>
                    </div>
                </Link>

                <div className='flex items-center'>
                    <IconButton
                        onClick={() => handleAction({ data, type: "edit" })}
                    >
                        <ModeEditIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => handleAction({ data, type: "delete" })}
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

export default MenuCard