import React, { useEffect, useState } from "react";
import ModalLayout from "../../modals/Layout";
import { Box, Button, CircularProgress, Grid, IconButton, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import ClearIcon from '@mui/icons-material/Clear';
import { notify } from "../../Toast";
import AddIcon from '@mui/icons-material/Add';

const initialState = {
    "name": "",
    "values": [
        {
            "value_name": "",
            "price": ""
        }
    ]
}

const VariantModal = ({ open, setOpen, variants, setVariants, editData, setEditVariants, editVariants }) => {
    const [variant, setvariant] = useState(initialState)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (open) {
            if (editData) {
                setvariant({ name: editData[0]?.name, values: editData[0]?.values })
            } else {
                setvariant({
                    "name": "",
                    "values": [
                        {
                            "value_name": "",
                            "price": ""
                        }
                    ]
                })
            }
        }
    }, [open, editData])

    const handleChange = (index, value, key) => {
        let variants = { ...variant }
        variants.values[index][key] = value
        setvariant(variants)
    }

    const handleDelete = (index) => {
        setvariant(prevVariant => ({
            ...prevVariant,
            values: prevVariant.values.filter((_, i) => i !== index)
        }));
    }

    const handleAddValue = () => {
        setvariant(prevVariant => ({
            ...prevVariant,
            values: [...prevVariant.values, {
                "value_name": "",
                "price": ""
            }]
        }));
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        if (variant.name && variant.values.length > 0) {
            const values = variant.values.filter(i => i.value_name !== "" && i.price !== "")
            if (values.length > 0) {
                setVariants([{ ...variant, values }])
                setvariant(initialState)
                setOpen(false)
            } else {
                notify("At least one value should be provided", "error")
            }
        } else {
            notify("All the fields are required", "error")
        }
    }

    return (
        <ModalLayout open={open} setOpen={setOpen} title={`Add Variant`}>
            <form className='' onSubmit={handleSubmit}>
                <Grid container>
                    <Grid>
                        <Typography color="black" sx={{ mt: 2 }}>
                            Variant Name
                        </Typography>
                        <TextField
                            sx={{ mb: 2 }}
                            fullWidth
                            required
                            value={variant.name}
                            onChange={(e) => setvariant({ ...variant, name: e.target.value })}
                        />

                        <div className='h-[30vh] overflow-scroll'>
                            {variant.values?.filter(i => i).map((item, index) => (
                                <div className="grid grid-cols-1 mb-4 gap-4 pb-4 pr-4 bg-gray-50 rounded-md border shadow-md" key={index}>
                                    <div className="w-full flex items-center justify-center gap-4">
                                        <IconButton
                                            sx={{ color: 'red', mt: 4 }}
                                            onClick={() => handleDelete(index)}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                        <div>
                                            <Typography color="black" sx={{ mt: 2 }}>
                                                Value Name
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                required
                                                value={item.value_name}
                                                onChange={(e) => handleChange(index, e.target.value, "value_name")}
                                            />
                                        </div>

                                        <div>
                                            <Typography color="black" sx={{ mt: 2 }}>
                                                Value Price
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                required
                                                value={item.price}
                                                onChange={(e) => handleChange(index, e.target.value, "price")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <button
                            onClick={handleAddValue}
                            className="w-full flex justify-center border text-primary font-bold mt-8 py-2 shadow-md"
                        >
                            <AddIcon /> Add more values
                        </button>
                    </Grid>
                </Grid>

                <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loading}
                    disableElevation
                    sx={{ mt: 2, height: 55, fontSize: "1rem", color: "white" }}
                    variant="contained"
                    fullWidth
                >
                    {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : "Save Variant"}
                </Button>
            </form>
        </ModalLayout>
    );
};

export default VariantModal;