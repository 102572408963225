import React from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Box, Typography, Button } from "@mui/material";

import Restaurant_Menu from "../../images/Restaurant_Menu.png";
const CSVFileUpload = () => {
  const navigate = useNavigate();
  return (
    <>
      {" "}
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          height: 600,
          width:800,
          bgcolor: "secondary.input_field",
          borderRadius: 2,
          mx: "auto",
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            px: "25%",
            py: "15%",
            border: 1,
            borderRadius: 2,
            borderStyle: "dashed",
          }}
        >
          <Box>
            <img src={Restaurant_Menu} alt="Restaurant Outline"></img>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography>
              You currently don't have any saved Menus.<br></br>Let's start by
              creating a Menu.
            </Typography>
          </Box>
          <Box sx={{ mt: 2, width: 280 }}>
            <Button
              onClick={() => navigate("csv-upload")}
              disableElevation
              fullWidth
              variant="contained"
              sx={{ mt: 2, color: "black", height: 55, fontSize: "1rem" }}
            >
              Upload File
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Button
        onClick={() => navigate("csv-upload")}
        disableElevation
        variant="contained"
        sx={{ my: 5, bgcolor: "black", color: "white",width:"25rem", height: 55, fontSize: "1rem", mx:"auto" }}
      >
        Download Our Template
      </Button>
    </>
  );
};

export default CSVFileUpload;
