import React, { useState } from "react";
import {
  Box,
  MenuItem,
  IconButton,
  Menu,
  LinearProgress,
  Switch,
  Tooltip,
  Checkbox,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import BadgeIcon from '@mui/icons-material/Badge';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import EditIcon from '@mui/icons-material/Edit';
import WorkIcon from '@mui/icons-material/Work';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CustomNoRowsOverlay from "../../../styling/CustomComponents/NoRowsOverlay";

import AlertDialog from "../../Alerts/Alerts";

import {
  deleteEmployee,
  activateEmployee,
  deactivateEmployee,
} from "../../../functions/restaurant";
import { notify } from "../../Toast";
import AddEmployeeModal from "../modals/AddEmployeeModal";
import SideDrawer from "../../common/SideDrawer";
import { useEffect } from "react";

export function SortedDescendingIcon() {
  return <ArrowDropDownIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ArrowDropUpIcon className="icon" />;
}

const StaffListTable = ({
  restaurantStaff,
  refreshEmployees,
}) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selected, setSelected] = useState({});
  const [drawerData, setDrawerData] = useState({});

  useEffect(() => {
    const item = restaurantStaff.find(el => el.id === selected.id);
    setDrawerData(item);
  }, [restaurantStaff, selected])

  const handleOpenMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElNav(null);
  };

  const handleUserActivation = (is_active, id) => {
    if (is_active) {
      deactivateEmployee(id)
        .then((r) => {
          if (r.data.status) {
            notify(r.data.message, "success");
            refreshEmployees();
          } else if (!r.data.status) {
            notify(r.data.error, "error");
          }
        })
        .catch((err) => {
          console.log(err);
          notify(err.response.data.error, "error");
        });
    } else {
      activateEmployee(id)
        .then((r) => {
          if (r.data.status) {
            notify(r.data.message, "success");
            refreshEmployees();
          } else if (!r.data.status) {
            notify(r.data.error, "error");
          }
        })
        .catch((err) => {
          console.log(err);
          notify(err.response.data.error, "error");
        });
    }
  };

  const columns = [
    {
      field: "first_name",
      headerName: "Name",
      flex: 2.5,
      renderCell: (param) => (
        <div className="flex flex-row items-center space-x-2">
          <img
            src={
              param.row.image ||
              "https://i.pinimg.com/564x/6d/26/e2/6d26e264e3c11ec2c8c9025c91279ba1.jpg"
            }
            className="h-7 w-7 rounded-full"
          />
          <p>
            {param.row.first_name} {param.row.last_name}
          </p>
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2.5,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 2,
    },
    {
      field: "employee_code",
      headerName: "Employee ID",
      flex: 2,
    },
    {
      field: "job",
      headerName: "Job Position",
      flex: 2,
      renderCell: (param) => (
        <p className="capitalize">
          {param.row.job_position.position_name.toLowerCase()}
        </p>
      ),
    },
    {
      filed: "activate",
      headerName: "Activate",
      flex: 1,
      renderCell: (param) => (
        <Switch
          checked={param.row?.is_active}
          onChange={() =>
            handleUserActivation(param.row?.is_active, param.row?.id)
          }
        />
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(e) => {
              handleOpenMenu(e);
              setSelected(params.row);
            }}
          >
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
  ];

  const handleDelete = () => {
    deleteEmployee(selected.id)
      .then((res) => {
        console.log(res);
        if (res.data.status === true) {
          notify("Employer deleted successfuly", "success");
          refreshEmployees();
        } else if (res.data.status === false) {
          notify(res.data.message, "error");
        }
      })
      .catch((err) => {
        notify("Failed to delete employee", "error");
      });
    setOpenDialog(false);
  };

  const Title = ({ title, Icon }) => (
    <div className="flex space-x-2 items-center">
      <Icon className="!text-gray-600" sx={{ fontSize: 16 }} />
      <h2 className="font-bold">{title}</h2>
    </div>
  )

  return (
    <>
      <Box
        sx={{
          mt: 5,
          "& .MuiDataGrid-columnHeaders": {
            color: "black",
            fontSize: 18,
          },
          width: "100%",
        }}
      >
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          // transformOrigin={{
          //   vertical: "top",
          //   horizontal: "left",
          // }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => {
              setOpenDrawer(true);
              handleCloseMenu();
            }}
          >
            View Employee
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              handleCloseMenu(e);
              setOpenEditForm(true);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenDialog(true);
              handleCloseMenu();
            }}
          >
            Delete
          </MenuItem>
        </Menu>

        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              fontSize: 16,
            },
          }}
          getRowId={(row) =>
            row.id
          }
          rows={restaurantStaff}
          columns={columns}
          autoHeight
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
          onPageSizeChange={(newValue) => setPageSize(newValue)}
          components={{
            ColumnSortedDescendingIcon: SortedDescendingIcon,
            ColumnSortedAscendingIcon: SortedAscendingIcon,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>

      <AlertDialog
        open={openDialog}
        handleDelete={handleDelete}
        handleClose={() => setOpenDialog(false)}
        title="Delete employee"
        bodyText="Are you sure you want to delete this employee? This action is irreversible"
      />

      <AddEmployeeModal
        openAddEmployeeModal={openEditForm}
        closeAddEmployeeModal={() => setOpenEditForm(false)}
        editData={selected}
        refreshEmployees={refreshEmployees}
      />

      <SideDrawer open={openDrawer} title="Employee details" onClose={() => setOpenDrawer(false)} size={450}>
        <div className="space-y-6 py-4">

          <div className="flex justify-between items-start border-b-2 pb-4">
            <div className="flex space-x-4 items-center">
              <img className="h-16 w-16 rounded-2xl" src={selected.image} />
              <div>
                <h2 className="text-lg font-semibold">{selected?.first_name} {selected?.last_name}</h2>
                <p className="text-sm font-semibold text-gray-400">Employeee id: {selected?.employee_code}</p>
              </div>
            </div>

            <Tooltip title="Edit">
              <IconButton onClick={() => { setOpenEditForm(true); setOpenDrawer(false) }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </div>

          {[
            {
              name: "Employee Information",
              icon: BadgeIcon,
              items: [
                { name: "Email Address", value: selected?.email },
                { name: "Phone Number", value: selected?.phone }
              ]
            },
            {
              name: "Employee Validation",
              icon: DocumentScannerIcon,
              items: [
                { name: "KRA Pin", value: selected?.kra_pin || "--" },
                { name: "National ID", value: selected?.national_id || "--" }
              ]
            },
            {
              name: "Employee Job Details",
              icon: WorkIcon,
              items: [
                { name: "Job Name", value: selected?.job_position?.position_name }
              ]
            }
          ].map((c, index) => (
            <div className="space-y-3 border-b-2 pb-4" key={index}>
              <Title title={c.name} Icon={c.icon} />
              <div className="pl-8">
                {c.items.map((el, i) => (
                  <div className="grid grid-cols-3 text-sm" key={i}>
                    <p className="whitespace-nowrap w-max mb-1">{el.name}</p>
                    <p className="whitespace-nowrap col-span-2 font-semibold mb-1">:{" "}{el.value}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div>
            <Title title="Account Status" Icon={PermContactCalendarIcon} />
            <div className="pl-8 flex items-center text-sm font-semibold">
              <Tooltip title={drawerData?.is_active ? "Deactivate employee" : "Activate employee"}>
                <Checkbox
                  checked={drawerData?.is_active}
                  size="small"
                  onChange={() => handleUserActivation(drawerData?.is_active, drawerData?.id)} />
              </Tooltip>
              <p>{drawerData?.is_active ? "Active" : "Deactivated"}</p>
            </div>

            <div className="pl-8 flex items-center text-sm font-semibold">
              <Checkbox checked={drawerData?.is_archive} size="small" />
              <p>{drawerData?.is_archive ? "Archive" : "Inactive"}</p>
            </div>
          </div>
        </div>
      </SideDrawer>
    </>
  );
};

export default StaffListTable;

