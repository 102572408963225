import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  InputAdornment,
  IconButton,
  Divider,
  Button,
  Box,
  Stack,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import Lottie from 'react-lottie';
import * as animationData from '../../data/email.json';
import Grid from "@mui/material/Unstable_Grid2";
import { Link as MuiLink } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { motion } from "framer-motion"

// import GoogleIcon from "../../styling/CustomComponents/icons/GoogleIcon";
import Dagizo_Logo from "../../images/Dagizo_Logo.png";
import CustomSnackBar from "../../components/common/utils/CustomSnackBar";

import { registerRestaurant } from "../../functions/auth";
import { setCredentials } from "../../features/auth/authSlice";

// UTIL IMPORTS
import ImageUploader from "../../components/common/utils/ImageUploader";
import Geolocation from "../../components/common/utils/Geolocation";

import { notify } from "../../components/Toast"
import AuthLayout from "../../components/Layout/Auth";


const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const SignUp = () => {
  if (localStorage.getItem("hasRequestedVerification") === null) {
    localStorage.setItem("hasRequestedVerification", JSON.stringify(false));
  }
  const [values, setValues] = useState({
    restaurant_name: "",
    type: "",
    user_image: null,
    email: "",
    phone_number: "",
    first_name: "",
    last_name: "",

    password: "Dagizo_2023",
    showPassword: false,
    avatar_src: "",
    openSnackbar: false,
  });
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const {
    user_image,
    restaurant_image,
    restaurant_name,
    type,
    first_name,
    last_name,
    email,
    password,
    phone_number,
  } = values;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // SnackBar Functionality
  const [loading, setLoading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [requestVerification, setRequestVerification] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("Sign in Credentials Okay");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const location = useLocation();
  const from = location.state?.from?.pathname || "/welcome";

  const horizontal = "left";
  const closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleChange = (prop) => (event) => {
    event.preventDefault();
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changeUserAvatar = (url) => {
    setValues({ ...values, user_image: url });
  };

  const changeRestaurantAvatar = (url) => {
    setValues({ ...values, restaurant_image: url });
  };

  const hasRequestedVerification = JSON.parse(localStorage.getItem("hasRequestedVerification"));

  function func() {
    let value = false;
    if (requestVerification || hasRequestedVerification) {
      return value = true;
    }
    return value;
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("STEP 1: JUMP INTO FUNCTION");
    if (first_name) {
      setLoading(true)
      console.log("STEP 2: CONFIRMED ALL FIELDS PRESENT");
      registerRestaurant(
        user_image,
        restaurant_image,
        restaurant_name,
        first_name,
        last_name,
        email,
        phone_number,
        latitude,
        longitude,
        type,
      )
        .then((res) => {
          console.log("STEP 3: RES STATUS", res.data);
          const { restaurant, user } = res.data;
          if (res.data.status === true) {
            localStorage.setItem("hasRequestedVerification", JSON.stringify(true));
            setRequestVerification(true);
          }
          // if (res.data.status === true) {
          // const user = {
          //   id: restaurant.id,
          //   email: restaurant.email,
          //   first_name: restaurant.first_name,
          //   last_name: restaurant.last_name,
          //   phone: restaurant.phone,
          //   is_superuser: restaurant.is_superuser,
          //   is_staff: restaurant.is_staff,
          //   is_active: restaurant.is_active,
          //   user_image: restaurant.user_image,
          // // }
          // if (restaurant?.restaurant?.is_active) {
          //   dispatch(
          //     setCredentials({
          //       user: restaurant,
          //       restaurant: restaurant.restaurant,
          //       accessToken: user.access_token,
          //     })
          //   );
          //   localStorage.setItem("accessToken", user.access_token)

          //   notify(res.data.message, 'success')
          //   setTimeout(() => {
          //     // navigate("/signin");
          //     navigate(from);
          //   }, 1000);
          //   // } else if (res.data.status === false) {
          //   //   setLoading(false)
          //   //   console.log('error', res.data)
          //   //   notify(res.data.message, "error");
          //   // }
          // } else {
          //   navigate("/activation")
          // }
        })
        .catch((err) => {
          setLoading(false)
          notify(err.response.data.error, 'error')
          console.log("ERROR STATUS", err);
        });
    }
  };

  return (
    !func() ? <AuthLayout>
      <Grid container spacing={1} sx={{ mt: 5 }}>
        <Grid>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            className="mb-10"
          >
            <img src={Dagizo_Logo} alt="DAGIZO" width="170px" />
          </motion.div>
          <motion.div
            className="relative"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.1 }}
          >
            <ValidatorForm autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
              <Typography
                variant="h5"
                color="black"
                fontWeight="700"
                sx={{ mt: 3 }}
              >
                SIGN UP
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                Already on Dagizo?{" "}
                <MuiLink
                  sx={{ textDecoration: "none", fontWeight: 'bold' }}
                  component={Link}
                  to={"/signin"}
                >
                  Sign In{" "}
                </MuiLink>
                to check how your restaurant is performing.
              </Typography>

              <div className="flex items-center gap-8 mt-4">
                <ImageUploader
                  changeAvatar={changeUserAvatar}
                  title={"Set Your Profile Picture"}
                />
                <ImageUploader
                  changeAvatar={changeRestaurantAvatar}
                  title={"Set Restaurant Logo"}
                />
              </div>

              <Typography sx={{ mt: 2 }}>First Name</Typography>
              <TextValidator
                sx={{
                  mt: 1,
                  backgroundColor: "secondary.input_field",
                  "& fieldset": {
                    border: "none",
                  },
                }}
                fullWidth
                onChange={handleChange("first_name")}
                name="first_name"
                value={first_name}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />

              <Typography sx={{ mt: 2 }}>Last Name</Typography>
              <TextValidator
                sx={{
                  mt: 1,
                  backgroundColor: "secondary.input_field",
                  "& fieldset": {
                    border: "none",
                  },
                }}
                fullWidth
                onChange={handleChange("last_name")}
                name="last_name"
                value={last_name}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />

              <Typography sx={{ mt: 2 }}>
                Category
              </Typography>

              <Select fullWidth value={type} onChange={handleChange("type")}>
                <MenuItem value="Hotel">Hotel</MenuItem>
                <MenuItem value="Restaurant">Restaurant</MenuItem>
              </Select>

              <Typography sx={{ mt: 2 }}>{values.type} Name</Typography>
              <TextValidator
                sx={{
                  mt: 1,
                  backgroundColor: "secondary.input_field",
                  "& fieldset": {
                    border: "none",
                  },
                }}
                fullWidth
                onChange={handleChange("restaurant_name")}
                name="restaurant_name"
                value={restaurant_name}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
              <Typography sx={{ mt: 2 }}>Email Address</Typography>
              <TextValidator
                sx={{
                  mt: 1,
                  backgroundColor: "secondary.input_field",
                  "& fieldset": {
                    border: "none",
                  },
                }}
                fullWidth
                onChange={handleChange("email")}
                name="email"
                value={email}
                validators={["required", "isEmail"]}
                errorMessages={["This Field is Required", "Email is invalid"]}
              />

              <Typography sx={{ mt: 2 }}>Phone Number</Typography>
              <TextValidator
                sx={{
                  mt: 1,
                  backgroundColor: "secondary.input_field",
                  "& fieldset": {
                    border: "none",
                  },
                }}
                fullWidth
                onChange={handleChange("phone_number")}
                name="phone_number"
                value={phone_number}
                validators={["required"]}
                errorMessages={["This Field is Required"]}
              />
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ color: "black" }}>Location</Typography>
                <Geolocation
                  setLatitude={setLatitude}
                  setLongitude={setLongitude}
                />
              </Box>
              <Button
                type="submit"
                disabled={loading}
                disableElevation
                fullWidth
                sx={{ bgcolor: "secondary.main", color: "white", mt: 2 }}
                variant="contained"
              >
                {loading ? (<CircularProgress color="inherit" size="1.5rem" />) : "Sign Up"}
              </Button>
            </ValidatorForm>
          </motion.div>

          <Divider sx={{ my: 2 }}>Or</Divider>

          <Typography sx={{ fontSize: 14, mt: 2 }}>
            By using Dagizo you are agreeing to{" "}
            <MuiLink
              sx={{ textDecoration: "none" }}
              component={Link}
              to={"terms-and-conditions"}
            >
              Terms & Conditions and Privacy Policy
            </MuiLink>
          </Typography>
        </Grid>
        <Grid xs={2}></Grid>
      </Grid>
    </AuthLayout> :
      <div className='h-screen flex flex-col justify-center items-center transition px-4'>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}
          className='flex flex-col items-center'
        >
          <h1 className='text-2xl md:text-4xl font-bold mb-2'>Activation email sent</h1>
          A verification email has been dispatched to your inbox. <br /> If you can't find it, please check your spam or junk folder.
          <a href="https://mail.google.com/" style={{ marginLeft: '8px', color: "blue" }}>Take me to mail</a>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <Lottie options={defaultOptions}
            height={200}
            width={300}
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.8 }}
        >
        </motion.div>

      </div>
  );
};

export default SignUp;
