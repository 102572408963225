import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertDialog({
  handleClose,
  open,
  title,
  bodyText,
  handleDelete,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        sx={{ padding: 6 }}
      >
        <div className="pt-4 px-6 pb-2 space-y-2">
          <DialogTitle
            id="alert-dialog-title"
            sx={{ fontWeight: 800, color: "black", fontSize: 20, padding: 0, margin: 0 }}
          >
            {title}!
          </DialogTitle>
          <DialogContent sx={{ padding: 0, margin: 0 }}>
            <DialogContentText id="alert-dialog-description">
              {bodyText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{ color: "black", borderColor: "gray" }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              autoFocus
              sx={{ color: "white", background: "red" }}
              variant="contained"
            >
              Confirm
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
